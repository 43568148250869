import { Component, Input, OnChanges, SimpleChange } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-wait',
  templateUrl: './app-wait.component.html',
  styleUrls: ['./app-wait.component.scss']
})
export class AppWaitComponent implements OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('show') show: boolean;

  _show = false;
  _disabled = true;

  private timer = null;

  constructor() {
  }

  ngOnChanges(changeMap) {
    if ('show' in changeMap) {
      const change: SimpleChange = changeMap.show;

      if (!this._show && change.currentValue) {
        // Activating
        this._disabled = false;
        if (this.timer) {
          clearTimeout(this.timer);
        }
      }

      if (this._show && !change.currentValue) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        // Deactivating
        this.timer = setTimeout(
          () => {
            this._disabled = true;
          },
          300
        );
      }

      this._show = change.currentValue;
    }
  }
}

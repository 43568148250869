import { LoggingLevel } from '../interfaces/config/IConfig';
export class Logger {

  private static _level = LoggingLevel.Warning;

  public static set LogLevel(level: LoggingLevel) {
    Logger._level = level;
  }

  public static get LogLevel(): LoggingLevel {
    return Logger._level;
  }

  public static Debug(message: any, ...params: any[]) {
    if (Logger._level >= LoggingLevel.Debug) {
      console.log(message, params);
    }
  }

  public static Info(message: string, ...params: any[]) {
    if (Logger._level >= LoggingLevel.Info) {
      console.log(message, params);
    }
  }

  public static Warn(message: string, ...params: any[]) {
    if (Logger._level >= LoggingLevel.Warning) {
      console.warn(message, params);
    }
  }

  public static Error(message: string, ...params: any[]) {
    if (Logger._level >= LoggingLevel.Error) {
      console.error(message, params);
    }
  }

}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const FIREBASE_ADMIN_COLLECTION = 'admins';
export class Admin {
    get User() {
        return this._user;
    }
    constructor(user, _docRef) {
        this._user = user;
        this._docRef = _docRef;
    }
    get Id() {
        return this._docId;
    }
    /** Instantiate a new Translator class */
    static Init(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = user.DB.collection(FIREBASE_ADMIN_COLLECTION).doc(user.Id);
            const snapshot = yield doc.get();
            if (!snapshot || !snapshot.exists) {
                // ERROR, THIS USER IS NOT AN ADMIN
                throw Error('Unauthorised. This user is not an administrator.');
            }
            else {
                const active = snapshot.get('active');
                if (!active) {
                    throw Error('Unauthorised. This administrator does not have the active flag.');
                }
                return new Admin(user, doc);
            }
        });
    }
}

import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";

@Component({
  selector: "commandes",
  templateUrl: "./commandes.component.html",
  styleUrls: ["./commandes.component.scss"],
})
export class CommandesComponent implements OnInit {
  particular: boolean;

  commandParticular;
  commandPro;
  p: number = 1;

  constructor(private hiero: HieroBDD) {}

  ngOnInit(): void {
    this.getCommandParticular().then(
      (value) => (this.commandParticular = value)
    );

    this.getCommandPro().then((value) => (this.commandPro = value));

    this.particular = JSON.parse(localStorage.getItem("isPart"));

    if (this.particular === null) {
      this.particular = true;
    }
  }

  getCommandParticular() {
    const req = this.hiero.DB.collection("particuliers_commandes").orderBy(
      "createdAt",
      "desc"
    );

    return req.get().then((snapshot) => {
      return snapshot.docs.map((command) => command.data());
    });
  }

  getCommandPro() {
    const req = this.hiero.DB.collection("professionnel_commandes").orderBy(
      "createdAt",
      "desc"
    );

    return req.get().then((snapshot) => {
      return snapshot.docs.map((command) => command.data());
    });
  }

  switchBetweenProPar() {
    localStorage.setItem("isPart", JSON.stringify(!this.particular));

    this.particular = JSON.parse(localStorage.getItem("isPart"));
  }
}

<div class="container">
  <div class="row">
    <div class="col-xl-12 p-2">
      <h3>Profil de</h3>
      <div class="col-md-4">
        <div class="mt-5">
          <img
            class="img-fluid"
            src="../../../../../assets/img/user/avatarUser.png"
            alt=""
            width="200"
          />
        </div>
      </div>

      <div class="col-md-8"></div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '../../../common/src/bdd/utility/Language';


@Pipe({name: 'iso639ToNative'})
export class LanguagePipe implements PipeTransform {

  transform(value: string): string {
    return Language.GetNameFromISO639(value);
  }
}

import { Component, NgZone } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ESignupType } from '../../../../../common/src/bdd/user/AuthService';
import { HieroBDD } from '../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';

@Component({
  templateUrl: './login.component.html'
})

export class LoginComponent {

  busy: boolean;

  loginForm: SubmitForm;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private router: Router,
    private zone: NgZone
  ) {
    this.createForm();
  }


  createForm() {
    this.loginForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'email',
          value: '',
          validators: [Validators.required, Validators.email],
          type: 'email',
          title: 'Email',
          autocomplete: 'email',
          placeholder: 'Renseignez votre adresse emeil',
          help: 'Merci de vous identifier avec votre compte email',
          errors: [
          ]
        },
        {
          name: 'password',
          value: '',
          validators: [Validators.required, Validators.minLength(8), Validators.maxLength(50)],
          type: 'password',
          title: 'Mot de passe',
          autocomplete: 'off',
          placeholder: 'Merci de renseigner votre mot de passe',
          help: '',
          errors: [
          ]
        }
      ],
      // Submit callback
      data => {
        this.busy = true;
        const prom = this.hiero.Auth.login({
          email: data.email,
          password: data.password,
          type: ESignupType.EmailPassword
        });

        return prom;
      },

      // Success callback
      () => {
        // What to do with login success ?
        console.log('Success.');
        this.router.navigate(['/', 'app']);
      },

      // Fail callback
      err => {
        // What to do with login failuer
        this.busy = false;
      },

      // Changes callback
      null
    );
  }

}

import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";

@Component({
  selector: "all_users",
  templateUrl: "./allUsers.component.html",
  styleUrls: ["./allUsers.component.scss"],
})
export class AllUsersComponent implements OnInit {
  newRegistration;
  data;
  p: number = 1;

  constructor(
    private hiero: HieroBDD,
    private modalService: NgbModal,
    private localisation: LocalisationService
  ) {}

  ngOnInit(): void {
    this.numberOfUsers().then((value) => {
      this.newRegistration = value.sort(function (a, b) {
        return (
          new Date(b.metadata.creationTime).getTime() -
          new Date(a.metadata.creationTime).getTime()
        );
      });
    });

    this.allUsersReq().then((value) => (this.data = value));
  }

  async numberOfUsers() {
    const fun = fetch(
      "https://us-central1-hierodev.cloudfunctions.net/allUsers"
    );

    const data = await fun;

    const result = await data.json();

    return result;
  }

  allUsersReq() {
    const req = this.hiero.DB.collection("users");

    return req.get().then((snapshot) => {
      return snapshot.docs;
    });
  }

  seeProfil() {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = "Profil de Thomas Martin";
    modalRef.componentInstance.message = this.localisation.localise(
      "prestation_order_accept_msg"
    );
    modalRef.componentInstance.ok = this.localisation.localise(
      "prestation_order_confirm_ok"
    );
    modalRef.componentInstance.cancel = this.localisation.localise(
      "prestation_order_confirm_cancel"
    );
  }
}

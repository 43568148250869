import * as firebase from 'firebase/app';
import { User } from '../user/User';

const FIREBASE_SETTINGS_COLLECTION = 'settings';
const FIREBASE_SETTINGS_I18N = 'i18n';

export class Localisation {

  private _dicts: any;

  public constructor(dictionaries: any) {
    this._dicts = dictionaries;
  }

  public get Current(): any {
    return this._dicts;
  }

  public getDict(langCode: string): any {
    if (!this._dicts) { return {}; }
    if (langCode in this._dicts) {
      return this._dicts[langCode];
    } else {
      return {};
    }
  }

  public get AvailableLanguages(): string[] {
    const langs: string[] = [];
    if (this._dicts) {
      Object.keys(this._dicts).forEach(
        (key: string) => {
          langs.push(key);
        }
      );
    }
    return langs;
  }

  public localise(langCode: string, phraseKey: string): string {
    let phrase = null;

    if (this._dicts) {
      const dict = this._dicts[langCode];
      if (dict) {
        phrase = dict[phraseKey];
      }
    }

    if (!phrase)  { return ''; }
    return phrase;
  }

  public static async Init(user: User): Promise<Localisation> {
    const doc = user.DB.collection(FIREBASE_SETTINGS_COLLECTION).doc(FIREBASE_SETTINGS_I18N);
    const snapshot: firebase.firestore.DocumentSnapshot = await doc.get();
    if (snapshot.exists) {
      const dicts = snapshot.data();
      return new Localisation(dicts);
    } else {
      throw Error('Failed to download localisation');
    }
  }
}




import { Injectable } from '@angular/core';
import { IGeoCoords } from '../../../common/src/bdd/interfaces/ITraducteur';

declare var google: any;

export interface LatLong {
  latitude: number;
  longitude: number;
}

export interface GeoCodeResult {
  formatted_address: string;
  coords: LatLong;
}

@Injectable()
export class GoogleGeo {

  constructor() {
    /* console.log("SETTING UP GOOGLE");
    const API_KEY= 'AIzaSyB-yu5LVOZhsV0FA1zShe-8vwPtXR4rYhI';
    const url = 'https://maps.googleapis.com/maps/api/js?key=' + API_KEY + '&callback=onGoogleMapsLoaded';

    const script = '<script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap"async defer></script>';

    const el = document.createElement('script');
    el.setAttribute('src', url);
    el.setAttribute('async', '');
    el.setAttribute('defer', '');

    (<any>window).onGoogleMapsLoaded = () => {
      console.log("LOADED GOOGLE MAPS!!");
    }

    document.body.appendChild(el); */
  }

  geocode(address:string): Promise<GeoCodeResult[]> {

    let geocoder = new google.maps.Geocoder();
    return new Promise(
      (resolve, reject) => {
        geocoder.geocode( { 'address': address}, function(results, status) {
          if (status === 'OK') {

            let adds: GeoCodeResult[] = [];

            results.forEach(result => {
              adds.push({
                formatted_address: result.formatted_address,
                coords: {
                  latitude: result.geometry.location.lat(),
                  longitude: result.geometry.location.lng()
                }
              });
            });
            console.log(adds);
            resolve(adds);

          } else {
            reject('Geocode was not successful for the following reason: ' + status);
          }
        });
      }
    );
    

  }


}
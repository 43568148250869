import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, ValidationErrors, AbstractControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Admin } from '../../../../../../common/src/bdd/admin/Admin';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { IDocumentType } from '../../../../../../common/src/bdd/interfaces/IDocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { TemplateList, QueryOrderParam } from '../../../../../../common/src/bdd/documents/TemplateList';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
import { ICountry, Countries } from '../../../../../../common/src/bdd/utility/countries';
import { IAppFormDropdowParameters, IDDFormNameData } from '../../../../../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component';
import { ITemplate } from '../../../../../../common/src/bdd/interfaces/ITemplate';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { IPDFDocument } from '../../../../../../common/src/bdd/interfaces/IPDF';
import { PDFParser } from '../../../../../../common/src/components/pdfpreview/pdfparser';
import { PDFRender } from '../../../../../../common/src/components/pdfpreview/pdfrender';


export function haveValidCountryValidator(id: string): ValidationErrors|null {
  return (control: AbstractControl): {[key: string]: any} => {
    let noddress = false;
    if (control.parent) {
      const par = control.parent;
      const country: ICountry = par.get(id).value;

      noddress = (
        (!country || !country.value)
      );
    }

    return (noddress) ? {'validCountry': true} : null;
  };
}

export  function haveValidLanguageValidator(id: string): ValidationErrors|null {
  return (control: AbstractControl): {[key: string]: any} => {
    let noddress = false;
    if (control.parent) {
      const par = control.parent;
      const language: string = par.get(id).value;

      let iso639 = null;
      
      if (language) {
        iso639 = Language.GetISO639(language);
      }
      

      noddress = (
        (!iso639)
      );
    }

    return (noddress) ? {'validLanguage': true} : null;
  };
}

@Component({
  templateUrl: './addtemplate.component.html'
})
export class AddTemplateComponent implements OnDestroy {

  busy = true;
  private admin: Admin;
  private adminSub: Subscription;
  
  private docTypeMap: DocumentType;
  private fullLanguageList: string[];

  newForm: SubmitForm;

  documentTypeDropdown: IAppFormDropdowParameters;
  selectedDocType: string;

  pdfRender: PDFRender;


  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {


    this.fullLanguageList = Language.GetAllNativeAndRegularNames();

    this.adminSub = this.hiero.WatchAdmin({
      next: (admin: Admin) => {
        this.admin = admin;

        DocumentType.Init(admin.User, true)
        .then(
          (map) => {
            this.docTypeMap = map;
            this.createForm();
            this.busy = false;
          }
        );
      }
    });

    this.createForm();

  }

  ngOnDestroy()  {
    if (this.adminSub) {
      this.adminSub.unsubscribe();
    }
  }

  getDocTypeFor(code: string, documentTypeId: string) {
    return this.docTypeMap.GetDocumentName(code, documentTypeId);
  }

  getCountryName(code: string) {
    return Countries.GetCountryMap('fr').get(code);
  }

  createForm() {
    this.newForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'srcCode',
          value: '',
          validators: [haveValidLanguageValidator('srcCode')],
          type: 'text',
          title: 'Langue d\'origine',
          autocomplete: 'off',
          placeholder: 'Commencez à taper le nom de la langue',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
            <ISubmitFormInputErrors>{
              code: 'validLanguage',
              message: 'Merci de sélectionner la langue de la liste défilante !',
            },
          ]
        },
        {
          name: 'srcCountry',
          value: '',
          validators: [haveValidCountryValidator('srcCountry')],
          type: 'text',
          title: 'Pays d\'origine',
          autocomplete: 'off',
          placeholder: 'Taper le nom du pays...',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
            <ISubmitFormInputErrors>{
              code: 'validCountry',
              message: 'Merci de sélectionner le pays de la liste défilante !',
            },
          ]
        },
        {
          name: 'dstCode',
          value: '',
          validators: [haveValidLanguageValidator('dstCode')],
          type: 'text',
          title: 'Traduire vers...',
          autocomplete: 'off',
          placeholder: 'Commencez à taper le nom de la langue',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
            <ISubmitFormInputErrors>{
              code: 'validLanguage',
              message: 'Merci de sélectionner la langue de la liste défilante !',
            },
          ]
        },
        {
          name: 'type',
          value: '',
          validators: [],
          type: 'text',
          title: 'Type de document',
          autocomplete: 'off',
          placeholder: '',
          help: 'Le type de document representé par ce document',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
          ]
        },
        {
          name: 'price',
          value: '',
          validators: [ Validators.required, Validators.min(0.1) ],
          type: 'number',
          title: 'Prix HT',
          autocomplete: 'off',
          placeholder: '',
          min: 0.1,
          help: 'Le prix HT du document percu par le traducteur pour une prestation de ce type',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
            <ISubmitFormInputErrors>{
              code: 'min',
              message: 'Minimum de 0.1€ obligatoire',
            },
          ]
        }
      ],
      // Submit callback
      (changes) => {
      },

      // Success callback
      () => {
        // What to do with login success ?
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );

    this.setupDropdown();
  }

  setupDropdown() {
    const docTypes = [];
    if (this.docTypeMap) {

      this.docTypeMap.ToArray.forEach(
        (docType) => {
          const docName = docType.label.find( label => label.code === 'fr' );
          if (docName) {
            docTypes.push({
              name: docName.value,
              data: docType._id
            });
          }
        }
      );
    }

    if (docTypes.length > 0) {
      this.selectedDocType = docTypes[0].data;
    }

    this.documentTypeDropdown = {
      choices: docTypes,
      selectedIndex: 0,
      title: '',
      input_title: 'Type de document',
      hasButton: false,
      button_title: '',
      help: ''
    };
  }

  formatter = (lang: string) => lang ? lang : '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term =>
          term.length < 2
            ? []
            : this.fullLanguageList.filter((value) => (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null) , 10)
      )
    )

  countryFormatter = (country: ICountry) => country ? country.label : '';

  countrySearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term =>
          term.length < 2
            ? []
            : Countries.GetCountryArray('fr').filter((value: ICountry) => (value.label.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null) , 10)
      )
    )

  onCountrySelected(country: ICountry) {
    console.log(country);
    /* const oldAddress = this.profileForm.GetValue('address');
    if (oldAddress && oldAddress.extra) {
      address.address.extra = oldAddress.extra;
    }

    this.profileForm.SetValue('coords', address.coords);
    this.profileForm.SetValue('address', address.address);*/
  }

  onSrcSelected(country: ICountry) {
    console.log(country);
    /* const oldAddress = this.profileForm.GetValue('address');
    if (oldAddress && oldAddress.extra) {
      address.address.extra = oldAddress.extra;
    }

    this.profileForm.SetValue('coords', address.coords);
    this.profileForm.SetValue('address', address.address);*/
  }

  onDstSelected(country: ICountry) {
    console.log(country);
    /* const oldAddress = this.profileForm.GetValue('address');
    if (oldAddress && oldAddress.extra) {
      address.address.extra = oldAddress.extra;
    }

    this.profileForm.SetValue('coords', address.coords);
    this.profileForm.SetValue('address', address.address);*/
  }

  selectDocumentType(data: IDDFormNameData) {
    this.selectedDocType = data.data;
  }

  async addTemplate() {
    const src = this.newForm.GetValue('srcCode');
    const dst = this.newForm.GetValue('dstCode');
    const srcCountryData = this.newForm.GetValue('srcCountry');

    const price = this.newForm.GetValue('price');

    const srcCode = Language.GetISO639(src);
    const dstCode = Language.GetISO639(dst);
    const srcCountry = srcCountryData.value;

    /// VALIDIITY
    if (!this.newForm.CheckValidity()) {
      return;
    }

    if (!srcCode) { this.newForm.SetGeneralError("Error: missing info: srcCode" ); }
    if (!dstCode) { this.newForm.SetGeneralError("Error: missing info: dstCode" ); }
    if (!srcCountry) { this.newForm.SetGeneralError("Error: missing info: srcCountry" ); }
    if (!this.selectedDocType) { this.newForm.SetGeneralError("Error: missing info: this.selectedDocType" ); }
    if (!price) { this.newForm.SetGeneralError("Error: missing info: price" ); }
    if (!this.pdfRender) { this.newForm.SetGeneralError("Error: missing info: this.pdfRender" ); }


    if (!srcCode || !dstCode || !srcCountry || !this.selectedDocType || !price || !this.pdfRender) {
      console.log(srcCode);
      console.log(dstCode);
      console.log(srcCountry);
      console.log(this.selectedDocType);
      console.log(price);
      return;
    }

    this.busy = true;

    this.pdfRender.clearHighlights();
    const priceNum = parseFloat(price);

    const t: ITemplate = {
      srcLanguageIso639: srcCode,
      srcCountryCode: srcCountry,
      destLanguageIso639: dstCode,
      documentTypeId: this.selectedDocType,
      priceHT: priceNum,
      config: this.pdfRender.Data
    };

    try {
      const newTemplate: Template = await Template.Create(this.admin.User, t);
      this.pdfRender = null;
      this.router.navigate(['/app', 'template', newTemplate.Id]);

    } catch (err) {
      this.newForm.SetGeneralError(err.message);
    }
  }


  public async dropped(files: NgxFileDropEntry[]) {
    if (files.length === 0) {
      return;
    }
    const droppedFile  = files[0];


    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

      this.busy = true;
      try {
        const pdfRender = await PDFParser.Parse(fileEntry);
        this.pdfRender = pdfRender;
        this.busy = false;
      } catch (err) {
        console.warn(err.message);
        this.busy = false;
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }


}

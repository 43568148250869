import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-confirm',
  templateUrl: './confirm-modal.component.html'
})
export class AppModalConfirmComponent {
  @Input() header;
  @Input() message;
  @Input() ok;
  @Input() cancel;

  constructor(public modal: NgbActiveModal) {}
}

		<!-- begin error -->
		<div class="error">
			<div class="error-code m-b-10">404</div>
			<div class="error-content">
				<div class="error-message">Impossible de trouver l'élément</div>
				<div class="error-desc m-b-30">
					la page que vous essayez d'atteindre n'existe pas<br />
					Contactez le support si le problème persiste
				</div>
				<div>
					<a href="app/home" class="btn btn-success p-l-20 p-r-20">Acceuil</a>
				</div>
			</div>
		</div>
		<!-- end error -->
<div class="container">
  <div class="lead font-italic mb-5">
    Gestion de commandes /
    <span class="text-danger"> Commande n° {{ id }}</span>
  </div>

  <div class="row">
    <div class="col-md-7 card">
      <div class="lead">
        <i class="fa fa-pencil text-danger mr-3 mt-3 mb-2"></i> Détails de la
        commande
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Reçu le"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.createdAt.toDate() | date: 'dd/MM/yyyy '
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Statut"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.prestationInfo.state }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Prestation"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.prestationInfo.srcCountryCode === ''
                ? 'Traduction classique'
                : 'Traduction assermentée'
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Document"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.documentType }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Langue d'origine"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.srcLanguageIso639 | iso639ToNative }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Pays d'origine"
            disabled="disabled"
            class="input1"
            *ngIf="command.prestationInfo.srcCountryCode !== ''"
          />
          <input
            type="text"
            placeholder=" {{ command.prestationInfo.srcCountryCode }} "
            disabled="disabled"
            class="input2"
            *ngIf="command.prestationInfo.srcCountryCode !== ''"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Traduit vers"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.destLanguageIso639 | iso639ToNative }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Prix TTC"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.prestationInfo.price.ttc }} &euro; "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Doc à traduire"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.prestationInfo.documents[0].original.length
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Nb de pages"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=""
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="mt-5 d-flex justify-content-end mb-4">
        <button class="btn btn-danger mr-2">Télécharger la facture</button>
        <!-- <button class="btn btn-success mr-2">Terminer la commande</button>-->
      </div>
    </div>

    <div class="col-md-1"></div>

    <div class="col-md-4 card">
      <div class="lead">
        <i class="fa fa-users text-danger mr-3 mt-3"></i> Détails sur le client
        et le traducteur
      </div>
      <hr class="mt-4" />
      <div class="h5 text-muted mb-3">
        <i class="fa fa-user text-danger mr-3"></i> Client
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Type"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ clientType }} "
            disabled="disabled"
            class="input4"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="{{
              clientType === 'Particulier' ? 'Nom' : 'Raison sociale'
            }}"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ name }} "
            disabled="disabled"
            class="input4"
          />
        </span>
      </div>
      <hr />
      <div class="h5 text-muted mt-3">
        <i class="fa fa-language text-danger mr-3"></i> Traducteur
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Raison sociale"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ command.traducteurName }} "
            disabled="disabled"
            class="input4"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Id"
            disabled="disabled"
            class="input5"
          />
          <input
            type="text"
            placeholder=" {{ command.traducteurId }}  "
            disabled="disabled"
            class="input6"
          />
        </span>
      </div>
      <hr />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-7 card">
      <div class="lead">
        <i class="fa fa-trash text-danger mr-3 mt-3"></i> Supprimer la commande
      </div>
      <hr />
      <div
        class="lead font-weight-bold font-italic text-muted"
        *ngIf="
          command.prestationInfo.state === 'waiting-for-payment' ||
          command.prestationInfo.state === 'waiting-for-translator'
        "
      >
        Voulez vous supprimer la commande n° {{ id }} ?
      </div>

      <div
        class="lead font-weight-bold font-italic text-muted"
        *ngIf="
          command.prestationInfo.state !== 'waiting-for-payment' &&
          command.prestationInfo.state !== 'waiting-for-translator'
        "
      >
        Il est trop tard pour supprimer cette commande
      </div>

      <div
        class="d-flex justify-content-end mb-3"
        *ngIf="
          command.prestationInfo.state === 'waiting-for-payment' ||
          command.prestationInfo.state === 'waiting-for-translator'
        "
      >
        <button class="btn btn-danger" (click)="deleteCommand()">
          Supprimer la commande
        </button>
      </div>
    </div>

    <div class="col-md-1"></div>

    <div class="col-md-4 card">
      <div class="lead">
        <i class="fa fa-credit-card text-danger mr-3 mt-3"></i> Paiement de la
        commande
      </div>

      <hr />

      <div
        *ngIf="
          command.prestationInfo.state === 'waiting-for-payment' ||
          command.prestationInfo.state === 'waiting-for-translator'
        "
        class="font-italic lead"
      >
        La commande n'a pas encore été réglé par le client
      </div>

      <div
        class="d-flex justify-content-between"
        *ngIf="
          command.prestationInfo.state !== 'waiting-for-payment' &&
          command.prestationInfo.state !== 'waiting-for-translator'
        "
      >
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Payé le"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder="  "
            disabled="disabled"
            class="input4"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Payé par"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder="Stripe"
            disabled="disabled"
            class="input4"
          />
        </span>
      </div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pdfpreview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/localisation/localise.pipe";
import * as i3 from "../../modules/localisation/localisation.service";
import * as i4 from "./pdfpreview.component";
var styles_PDFPreviewComponent = [i0.styles];
var RenderType_PDFPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PDFPreviewComponent, data: {} });
export { RenderType_PDFPreviewComponent as RenderType_PDFPreviewComponent };
export function View_PDFPreviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LocalisePipe, [i3.LocalisationService]), i1.ɵqud(402653184, 1, { pdfEmbed: 0 }), i1.ɵqud(402653184, 2, { pdfEmbedSafari: 0 }), i1.ɵqud(402653184, 3, { pdfContainer: 0 }), (_l()(), i1.ɵeld(4, 0, [[3, 0], ["pdfContainer", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["pdfEmbedSafari", 1]], null, 0, "iframe", [["class", "pdf-frame"], ["frameBorder", "0"], ["src", ""], ["style", "overflow: auto; width: 100%; height: 100%;"], ["type", "application/pdf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["pdfEmbed", 1]], null, 0, "embed", [["class", "pdf-frame"], ["src", ""], ["style", "overflow: auto; width: 100%; height: 100%;"], ["type", "application/pdf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["href", "https://www.google.com/chrome/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chrome"])), (_l()(), i1.ɵted(-1, null, ["."]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), "prestation_document_get_browser")); _ck(_v, 9, 0, currVal_0); }); }
export function View_PDFPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-preview", [], null, null, null, View_PDFPreviewComponent_0, RenderType_PDFPreviewComponent)), i1.ɵdid(1, 770048, null, 0, i4.PDFPreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PDFPreviewComponentNgFactory = i1.ɵccf("app-pdf-preview", i4.PDFPreviewComponent, View_PDFPreviewComponent_Host_0, { pdf: "pdf" }, { updateHeight: "updateHeight" }, []);
export { PDFPreviewComponentNgFactory as PDFPreviewComponentNgFactory };

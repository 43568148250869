import { User } from '../user/User';

const FIREBASE_ADMIN_COLLECTION = 'admins';

export class Admin {

  private _user: User;
  private _docId: string;
  private _docRef: firebase.firestore.DocumentReference;

  public get User(): User {
    return this._user;
  }

  private constructor(user: User, _docRef: firebase.firestore.DocumentReference) {

    this._user = user;
    this._docRef = _docRef;
  }

  public get Id(): string {
    return this._docId;
  }


  /** Instantiate a new Translator class */
  public static async Init(user: User): Promise<Admin> {

    const doc = user.DB.collection(FIREBASE_ADMIN_COLLECTION).doc(user.Id);

    const snapshot = await doc.get();

    if (!snapshot || !snapshot.exists) {
      // ERROR, THIS USER IS NOT AN ADMIN
      throw Error('Unauthorised. This user is not an administrator.');
    } else {
      const active = snapshot.get('active');
      if (!active) {
        throw Error('Unauthorised. This administrator does not have the active flag.');
      }
      return new Admin(user, doc);
    }

  }

}


interface ICountrySeedName {
  iso639: string;
  value: string;
}

interface ICountrySeed {
  code: string,
  names: ICountrySeedName[]
}

const CountrySeed: ICountrySeed[] = [
  { code: 'AF', names: [{iso639: 'fr', value: "Afghanistan"}]},
  { code: 'ZA', names: [{iso639: 'fr', value: "Afrique du Sud"}]}, 
  { code: 'AL', names: [{iso639: 'fr', value: "Albanie"}]}, 
  { code: 'DZ', names: [{iso639: 'fr', value: "Algérie"}]}, 
  { code: 'DE', names: [{iso639: 'fr', value: "Allemagne"}]}, 
  { code: 'AD', names: [{iso639: 'fr', value: "Andorre"}]}, 
  { code: 'AO', names: [{iso639: 'fr', value: "Angola"}]}, 
  { code: 'AI', names: [{iso639: 'fr', value: "Anguilla"}]}, 
  { code: 'AQ', names: [{iso639: 'fr', value: "Antarctique"}]}, 
  { code: 'AG', names: [{iso639: 'fr', value: "Antigua-et-Barbuda"}]}, 
  { code: 'AN', names: [{iso639: 'fr', value: "Antilles néerlandaises"}]}, 
  { code: 'SA', names: [{iso639: 'fr', value: "Arabie saoudite"}]}, 
  { code: 'AR', names: [{iso639: 'fr', value: "Argentine"}]}, 
  { code: 'AM', names: [{iso639: 'fr', value: "Arménie"}]}, 
  { code: 'AW', names: [{iso639: 'fr', value: "Aruba"}]}, 
  { code: 'AU', names: [{iso639: 'fr', value: "Australie"}]}, 
  { code: 'AT', names: [{iso639: 'fr', value: "Autriche"}]}, 
  { code: 'AZ', names: [{iso639: 'fr', value: "Azerbaïdjan"}]}, 
  { code: 'BS', names: [{iso639: 'fr', value: "Bahamas"}]}, 
  { code: 'BH', names: [{iso639: 'fr', value: "Bahreïn"}]}, 
  { code: 'BD', names: [{iso639: 'fr', value: "Bangladesh"}]}, 
  { code: 'BB', names: [{iso639: 'fr', value: "Barbade"}]}, 
  { code: 'BY', names: [{iso639: 'fr', value: "Bélarus"}]}, 
  { code: 'BE', names: [{iso639: 'fr', value: "Belgique"}]}, 
  { code: 'BZ', names: [{iso639: 'fr', value: "Belize"}]}, 
  { code: 'BJ', names: [{iso639: 'fr', value: "Bénin"}]}, 
  { code: 'BM', names: [{iso639: 'fr', value: "Bermudes"}]}, 
  { code: 'BT', names: [{iso639: 'fr', value: "Bhoutan"}]}, 
  { code: 'BO', names: [{iso639: 'fr', value: "Bolivie"}]}, 
  { code: 'BA', names: [{iso639: 'fr', value: "Bosnie-Herzégovine"}]}, 
  { code: 'BW', names: [{iso639: 'fr', value: "Botswana"}]}, 
  { code: 'BR', names: [{iso639: 'fr', value: "Brésil"}]}, 
  { code: 'BN', names: [{iso639: 'fr', value: "Brunéi Darussalam"}]}, 
  { code: 'BG', names: [{iso639: 'fr', value: "Bulgarie"}]}, 
  { code: 'BF', names: [{iso639: 'fr', value: "Burkina Faso"}]}, 
  { code: 'BI', names: [{iso639: 'fr', value: "Burundi"}]}, 
  { code: 'KH', names: [{iso639: 'fr', value: "Cambodge"}]}, 
  { code: 'CM', names: [{iso639: 'fr', value: "Cameroun"}]}, 
  { code: 'CA', names: [{iso639: 'fr', value: "Canada"}]}, 
  { code: 'CV', names: [{iso639: 'fr', value: "Cap-Vert"}]}, 
  { code: 'EA', names: [{iso639: 'fr', value: "Ceuta et Melilla"}]}, 
  { code: 'CL', names: [{iso639: 'fr', value: "Chili"}]}, 
  { code: 'CN', names: [{iso639: 'fr', value: "Chine"}]}, 
  { code: 'CY', names: [{iso639: 'fr', value: "Chypre"}]}, 
  { code: 'CO', names: [{iso639: 'fr', value: "Colombie"}]}, 
  { code: 'KM', names: [{iso639: 'fr', value: "Comores"}]}, 
  { code: 'CG', names: [{iso639: 'fr', value: "Congo-Brazzaville"}]}, 
  { code: 'KP', names: [{iso639: 'fr', value: "Corée du Nord"}]}, 
  { code: 'KR', names: [{iso639: 'fr', value: "Corée du Sud"}]}, 
  { code: 'CR', names: [{iso639: 'fr', value: "Costa Rica"}]}, 
  { code: 'CI', names: [{iso639: 'fr', value: "Côte d’Ivoire"}]}, 
  { code: 'HR', names: [{iso639: 'fr', value: "Croatie"}]}, 
  { code: 'CU', names: [{iso639: 'fr', value: "Cuba"}]}, 
  { code: 'DK', names: [{iso639: 'fr', value: "Danemark"}]}, 
  { code: 'DG', names: [{iso639: 'fr', value: "Diego Garcia"}]}, 
  { code: 'DJ', names: [{iso639: 'fr', value: "Djibouti"}]}, 
  { code: 'DM', names: [{iso639: 'fr', value: "Dominique"}]}, 
  { code: 'EG', names: [{iso639: 'fr', value: "Égypte"}]}, 
  { code: 'SV', names: [{iso639: 'fr', value: "El Salvador"}]}, 
  { code: 'AE', names: [{iso639: 'fr', value: "Émirats arabes unis"}]}, 
  { code: 'EC', names: [{iso639: 'fr', value: "Équateur"}]}, 
  { code: 'ER', names: [{iso639: 'fr', value: "Érythrée"}]}, 
  { code: 'ES', names: [{iso639: 'fr', value: "Espagne"}]}, 
  { code: 'EE', names: [{iso639: 'fr', value: "Estonie"}]}, 
  { code: 'VA', names: [{iso639: 'fr', value: "État de la Cité du Vatican"}]}, 
  { code: 'FM', names: [{iso639: 'fr', value: "États fédérés de Micronésie"}]}, 
  { code: 'US', names: [{iso639: 'fr', value: "États-Unis"}]}, 
  { code: 'ET', names: [{iso639: 'fr', value: "Éthiopie"}]}, 
  { code: 'FJ', names: [{iso639: 'fr', value: "Fidji"}]}, 
  { code: 'FI', names: [{iso639: 'fr', value: "Finlande"}]}, 
  { code: 'FR', names: [{iso639: 'fr', value: "France"}]}, 
  { code: 'GA', names: [{iso639: 'fr', value: "Gabon"}]}, 
  { code: 'GM', names: [{iso639: 'fr', value: "Gambie"}]}, 
  { code: 'GE', names: [{iso639: 'fr', value: "Géorgie"}]}, 
  { code: 'GS', names: [{iso639: 'fr', value: "Géorgie du Sud et les îles Sandwich du Sud"}]}, 
  { code: 'GH', names: [{iso639: 'fr', value: "Ghana"}]}, 
  { code: 'GI', names: [{iso639: 'fr', value: "Gibraltar"}]}, 
  { code: 'GR', names: [{iso639: 'fr', value: "Grèce"}]}, 
  { code: 'GD', names: [{iso639: 'fr', value: "Grenade"}]}, 
  { code: 'GL', names: [{iso639: 'fr', value: "Groenland"}]}, 
  { code: 'GP', names: [{iso639: 'fr', value: "Guadeloupe"}]}, 
  { code: 'GU', names: [{iso639: 'fr', value: "Guam"}]}, 
  { code: 'GT', names: [{iso639: 'fr', value: "Guatemala"}]}, 
  { code: 'GG', names: [{iso639: 'fr', value: "Guernesey"}]}, 
  { code: 'GN', names: [{iso639: 'fr', value: "Guinée"}]}, 
  { code: 'GQ', names: [{iso639: 'fr', value: "Guinée équatoriale"}]}, 
  { code: 'GW', names: [{iso639: 'fr', value: "Guinée-Bissau"}]}, 
  { code: 'GY', names: [{iso639: 'fr', value: "Guyana"}]}, 
  { code: 'GF', names: [{iso639: 'fr', value: "Guyane française"}]}, 
  { code: 'HT', names: [{iso639: 'fr', value: "Haïti"}]}, 
  { code: 'HN', names: [{iso639: 'fr', value: "Honduras"}]}, 
  { code: 'HU', names: [{iso639: 'fr', value: "Hongrie"}]}, 
  { code: 'BV', names: [{iso639: 'fr', value: "Île Bouvet"}]}, 
  { code: 'CX', names: [{iso639: 'fr', value: "Île Christmas"}]}, 
  { code: 'CP', names: [{iso639: 'fr', value: "Île Clipperton"}]}, 
  { code: 'AC', names: [{iso639: 'fr', value: "Île de l'Ascension"}]}, 
  { code: 'IM', names: [{iso639: 'fr', value: "Île de Man"}]}, 
  { code: 'NF', names: [{iso639: 'fr', value: "Île Norfolk"}]}, 
  { code: 'AX', names: [{iso639: 'fr', value: "Îles Åland"}]}, 
  { code: 'KY', names: [{iso639: 'fr', value: "Îles Caïmans"}]}, 
  { code: 'IC', names: [{iso639: 'fr', value: "Îles Canaries"}]}, 
  { code: 'CC', names: [{iso639: 'fr', value: "Îles Cocos - Keeling"}]}, 
  { code: 'CK', names: [{iso639: 'fr', value: "Îles Cook"}]}, 
  { code: 'FO', names: [{iso639: 'fr', value: "Îles Féroé"}]}, 
  { code: 'HM', names: [{iso639: 'fr', value: "Îles Heard et MacDonald"}]}, 
  { code: 'FK', names: [{iso639: 'fr', value: "Îles Malouines"}]}, 
  { code: 'MP', names: [{iso639: 'fr', value: "Îles Mariannes du Nord"}]}, 
  { code: 'MH', names: [{iso639: 'fr', value: "Îles Marshall"}]}, 
  { code: 'UM', names: [{iso639: 'fr', value: "Îles Mineures Éloignées des États-Unis"}]}, 
  { code: 'SB', names: [{iso639: 'fr', value: "Îles Salomon"}]}, 
  { code: 'TC', names: [{iso639: 'fr', value: "Îles Turks et Caïques"}]}, 
  { code: 'VG', names: [{iso639: 'fr', value: "Îles Vierges britanniques"}]}, 
  { code: 'VI', names: [{iso639: 'fr', value: "Îles Vierges des États-Unis"}]}, 
  { code: 'IN', names: [{iso639: 'fr', value: "Inde"}]}, 
  { code: 'ID', names: [{iso639: 'fr', value: "Indonésie"}]}, 
  { code: 'IQ', names: [{iso639: 'fr', value: "Irak"}]}, 
  { code: 'IR', names: [{iso639: 'fr', value: "Iran"}]}, 
  { code: 'IE', names: [{iso639: 'fr', value: "Irlande"}]}, 
  { code: 'IS', names: [{iso639: 'fr', value: "Islande"}]}, 
  { code: 'IL', names: [{iso639: 'fr', value: "Israël"}]}, 
  { code: 'IT', names: [{iso639: 'fr', value: "Italie"}]}, 
  { code: 'JM', names: [{iso639: 'fr', value: "Jamaïque"}]}, 
  { code: 'JP', names: [{iso639: 'fr', value: "Japon"}]}, 
  { code: 'JE', names: [{iso639: 'fr', value: "Jersey"}]}, 
  { code: 'JO', names: [{iso639: 'fr', value: "Jordanie"}]}, 
  { code: 'KZ', names: [{iso639: 'fr', value: "Kazakhstan"}]}, 
  { code: 'KE', names: [{iso639: 'fr', value: "Kenya"}]}, 
  { code: 'KG', names: [{iso639: 'fr', value: "Kirghizistan"}]}, 
  { code: 'KI', names: [{iso639: 'fr', value: "Kiribati"}]}, 
  { code: 'KW', names: [{iso639: 'fr', value: "Koweït"}]}, 
  { code: 'LA', names: [{iso639: 'fr', value: "Laos"}]}, 
  { code: 'LS', names: [{iso639: 'fr', value: "Lesotho"}]}, 
  { code: 'LV', names: [{iso639: 'fr', value: "Lettonie"}]}, 
  { code: 'LB', names: [{iso639: 'fr', value: "Liban"}]}, 
  { code: 'LR', names: [{iso639: 'fr', value: "Libéria"}]}, 
  { code: 'LY', names: [{iso639: 'fr', value: "Libye"}]}, 
  { code: 'LI', names: [{iso639: 'fr', value: "Liechtenstein"}]}, 
  { code: 'LT', names: [{iso639: 'fr', value: "Lituanie"}]}, 
  { code: 'LU', names: [{iso639: 'fr', value: "Luxembourg"}]}, 
  { code: 'MK', names: [{iso639: 'fr', value: "Macédoine"}]}, 
  { code: 'MG', names: [{iso639: 'fr', value: "Madagascar"}]}, 
  { code: 'MY', names: [{iso639: 'fr', value: "Malaisie"}]}, 
  { code: 'MW', names: [{iso639: 'fr', value: "Malawi"}]}, 
  { code: 'MV', names: [{iso639: 'fr', value: "Maldives"}]}, 
  { code: 'ML', names: [{iso639: 'fr', value: "Mali"}]}, 
  { code: 'MT', names: [{iso639: 'fr', value: "Malte"}]}, 
  { code: 'MA', names: [{iso639: 'fr', value: "Maroc"}]}, 
  { code: 'MQ', names: [{iso639: 'fr', value: "Martinique"}]}, 
  { code: 'MU', names: [{iso639: 'fr', value: "Maurice"}]}, 
  { code: 'MR', names: [{iso639: 'fr', value: "Mauritanie"}]}, 
  { code: 'YT', names: [{iso639: 'fr', value: "Mayotte"}]}, 
  { code: 'MX', names: [{iso639: 'fr', value: "Mexique"}]}, 
  { code: 'MD', names: [{iso639: 'fr', value: "Moldavie"}]}, 
  { code: 'MC', names: [{iso639: 'fr', value: "Monaco"}]}, 
  { code: 'MN', names: [{iso639: 'fr', value: "Mongolie"}]}, 
  { code: 'ME', names: [{iso639: 'fr', value: "Monténégro"}]}, 
  { code: 'MS', names: [{iso639: 'fr', value: "Montserrat"}]}, 
  { code: 'MZ', names: [{iso639: 'fr', value: "Mozambique"}]}, 
  { code: 'MM', names: [{iso639: 'fr', value: "Myanmar"}]}, 
  { code: 'NA', names: [{iso639: 'fr', value: "Namibie"}]}, 
  { code: 'NR', names: [{iso639: 'fr', value: "Nauru"}]}, 
  { code: 'NP', names: [{iso639: 'fr', value: "Népal"}]}, 
  { code: 'NI', names: [{iso639: 'fr', value: "Nicaragua"}]}, 
  { code: 'NE', names: [{iso639: 'fr', value: "Niger"}]}, 
  { code: 'NG', names: [{iso639: 'fr', value: "Nigéria"}]}, 
  { code: 'NU', names: [{iso639: 'fr', value: "Niue"}]}, 
  { code: 'NO', names: [{iso639: 'fr', value: "Norvège"}]}, 
  { code: 'NC', names: [{iso639: 'fr', value: "Nouvelle-Calédonie"}]}, 
  { code: 'NZ', names: [{iso639: 'fr', value: "Nouvelle-Zélande"}]}, 
  { code: 'OM', names: [{iso639: 'fr', value: "Oman"}]}, 
  { code: 'UG', names: [{iso639: 'fr', value: "Ouganda"}]}, 
  { code: 'UZ', names: [{iso639: 'fr', value: "Ouzbékistan"}]}, 
  { code: 'PK', names: [{iso639: 'fr', value: "Pakistan"}]}, 
  { code: 'PW', names: [{iso639: 'fr', value: "Palaos"}]}, 
  { code: 'PA', names: [{iso639: 'fr', value: "Panama"}]}, 
  { code: 'PG', names: [{iso639: 'fr', value: "Papouasie-Nouvelle-Guinée"}]}, 
  { code: 'PY', names: [{iso639: 'fr', value: "Paraguay"}]}, 
  { code: 'NL', names: [{iso639: 'fr', value: "Pays-Bas"}]}, 
  { code: 'PE', names: [{iso639: 'fr', value: "Pérou"}]}, 
  { code: 'PH', names: [{iso639: 'fr', value: "Philippines"}]}, 
  { code: 'PN', names: [{iso639: 'fr', value: "Pitcairn"}]}, 
  { code: 'PL', names: [{iso639: 'fr', value: "Pologne"}]}, 
  { code: 'PF', names: [{iso639: 'fr', value: "Polynésie française"}]}, 
  { code: 'PR', names: [{iso639: 'fr', value: "Porto Rico"}]}, 
  { code: 'PT', names: [{iso639: 'fr', value: "Portugal"}]}, 
  { code: 'QA', names: [{iso639: 'fr', value: "Qatar"}]}, 
  { code: 'HK', names: [{iso639: 'fr', value: "R.A.S. chinoise de Hong Kong"}]}, 
  { code: 'MO', names: [{iso639: 'fr', value: "R.A.S. chinoise de Macao"}]}, 
  { code: 'QO', names: [{iso639: 'fr', value: "régions éloignées de l’Océanie"}]}, 
  { code: 'CF', names: [{iso639: 'fr', value: "République centrafricaine"}]}, 
  { code: 'CD', names: [{iso639: 'fr', value: "République démocratique du Congo"}]}, 
  { code: 'DO', names: [{iso639: 'fr', value: "République dominicaine"}]}, 
  { code: 'CZ', names: [{iso639: 'fr', value: "République tchèque"}]}, 
  { code: 'RE', names: [{iso639: 'fr', value: "Réunion"}]}, 
  { code: 'RO', names: [{iso639: 'fr', value: "Roumanie"}]}, 
  { code: 'GB', names: [{iso639: 'fr', value: "Royaume-Uni"}]}, 
  { code: 'RU', names: [{iso639: 'fr', value: "Russie"}]}, 
  { code: 'RW', names: [{iso639: 'fr', value: "Rwanda"}]}, 
  { code: 'EH', names: [{iso639: 'fr', value: "Sahara occidental"}]}, 
  { code: 'BL', names: [{iso639: 'fr', value: "Saint-Barthélémy"}]}, 
  { code: 'KN', names: [{iso639: 'fr', value: "Saint-Kitts-et-Nevis"}]}, 
  { code: 'SM', names: [{iso639: 'fr', value: "Saint-Marin"}]}, 
  { code: 'MF', names: [{iso639: 'fr', value: "Saint-Martin"}]}, 
  { code: 'PM', names: [{iso639: 'fr', value: "Saint-Pierre-et-Miquelon"}]}, 
  { code: 'VC', names: [{iso639: 'fr', value: "Saint-Vincent-et-les Grenadines"}]}, 
  { code: 'SH', names: [{iso639: 'fr', value: "Sainte-Hélène"}]}, 
  { code: 'LC', names: [{iso639: 'fr', value: "Sainte-Lucie"}]}, 
  { code: 'WS', names: [{iso639: 'fr', value: "Samoa"}]}, 
  { code: 'AS', names: [{iso639: 'fr', value: "Samoa américaines"}]}, 
  { code: 'ST', names: [{iso639: 'fr', value: "Sao Tomé-et-Principe"}]}, 
  { code: 'SN', names: [{iso639: 'fr', value: "Sénégal"}]}, 
  { code: 'RS', names: [{iso639: 'fr', value: "Serbie"}]}, 
  { code: 'CS', names: [{iso639: 'fr', value: "Serbie-et-Monténégro"}]}, 
  { code: 'SC', names: [{iso639: 'fr', value: "Seychelles"}]}, 
  { code: 'SL', names: [{iso639: 'fr', value: "Sierra Leone"}]}, 
  { code: 'SG', names: [{iso639: 'fr', value: "Singapour"}]}, 
  { code: 'SK', names: [{iso639: 'fr', value: "Slovaquie"}]}, 
  { code: 'SI', names: [{iso639: 'fr', value: "Slovénie"}]}, 
  { code: 'SO', names: [{iso639: 'fr', value: "Somalie"}]}, 
  { code: 'SD', names: [{iso639: 'fr', value: "Soudan"}]}, 
  { code: 'LK', names: [{iso639: 'fr', value: "Sri Lanka"}]}, 
  { code: 'SE', names: [{iso639: 'fr', value: "Suède"}]}, 
  { code: 'CH', names: [{iso639: 'fr', value: "Suisse"}]}, 
  { code: 'SR', names: [{iso639: 'fr', value: "Suriname"}]}, 
  { code: 'SJ', names: [{iso639: 'fr', value: "Svalbard et Île Jan Mayen"}]}, 
  { code: 'SZ', names: [{iso639: 'fr', value: "Swaziland"}]}, 
  { code: 'SY', names: [{iso639: 'fr', value: "Syrie"}]}, 
  { code: 'TJ', names: [{iso639: 'fr', value: "Tadjikistan"}]}, 
  { code: 'TW', names: [{iso639: 'fr', value: "Taïwan"}]}, 
  { code: 'TZ', names: [{iso639: 'fr', value: "Tanzanie"}]}, 
  { code: 'TD', names: [{iso639: 'fr', value: "Tchad"}]}, 
  { code: 'TF', names: [{iso639: 'fr', value: "Terres australes françaises"}]}, 
  { code: 'IO', names: [{iso639: 'fr', value: "Territoire britannique de l'océan Indien"}]}, 
  { code: 'PS', names: [{iso639: 'fr', value: "Territoire palestinien"}]}, 
  { code: 'TH', names: [{iso639: 'fr', value: "Thaïlande"}]}, 
  { code: 'TL', names: [{iso639: 'fr', value: "Timor oriental"}]}, 
  { code: 'TG', names: [{iso639: 'fr', value: "Togo"}]}, 
  { code: 'TK', names: [{iso639: 'fr', value: "Tokelau"}]}, 
  { code: 'TO', names: [{iso639: 'fr', value: "Tonga"}]}, 
  { code: 'TT', names: [{iso639: 'fr', value: "Trinité-et-Tobago"}]}, 
  { code: 'TA', names: [{iso639: 'fr', value: "Tristan da Cunha"}]}, 
  { code: 'TN', names: [{iso639: 'fr', value: "Tunisie"}]}, 
  { code: 'TM', names: [{iso639: 'fr', value: "Turkménistan"}]}, 
  { code: 'TR', names: [{iso639: 'fr', value: "Turquie"}]}, 
  { code: 'TV', names: [{iso639: 'fr', value: "Tuvalu"}]}, 
  { code: 'UA', names: [{iso639: 'fr', value: "Ukraine"}]}, 
  { code: 'EU', names: [{iso639: 'fr', value: "Union européenne"}]}, 
  { code: 'UY', names: [{iso639: 'fr', value: "Uruguay"}]}, 
  { code: 'VU', names: [{iso639: 'fr', value: "Vanuatu"}]}, 
  { code: 'VE', names: [{iso639: 'fr', value: "Venezuela"}]}, 
  { code: 'VN', names: [{iso639: 'fr', value: "Viêt Nam"}]}, 
  { code: 'WF', names: [{iso639: 'fr', value: "Wallis-et-Futuna"}]}, 
  { code: 'YE', names: [{iso639: 'fr', value: "Yémen"}]}, 
  { code: 'ZM', names: [{iso639: 'fr', value: "Zambie"}]}, 
  { code: 'ZW', names: [{iso639: 'fr', value: "Zimbabwe"}]}
];

export interface ICountry {
  value: string;
  label: string;
}

export class Countries {

  private static _countryMap: Map<string, string>|null = null;
  private static _countryArray: ICountry[]|null = null;

  public static GetCountryMap(iso639: string): Map<string, string> {
    if (!Countries._countryMap) {
      Countries._countryMap = new Map<string, string>();
  
      CountrySeed.forEach(
        (seed: ICountrySeed) => {
  
          const countryName: ICountrySeedName|undefined = seed.names.find(
            (name: ICountrySeedName) => {
              return (name.iso639 === iso639);
            }
          )
  
          if (countryName && Countries._countryMap) {
            Countries._countryMap.set(seed.code, countryName.value);
          }
        }
      );
    }
  
    return Countries._countryMap;
  
  }
  
  public static GetCountryArray(iso639: string): ICountry[] {
    if (!Countries._countryArray) {
      Countries._countryArray = [];
      CountrySeed.forEach(
        (seed: ICountrySeed) => {
  
          const countryName: ICountrySeedName|undefined = seed.names.find(
            (name: ICountrySeedName) => {
              return (name.iso639 === iso639);
            }
          )
  
          if (countryName && Countries._countryArray) {
            Countries._countryArray.push({
              value: seed.code,
              label: countryName.value
            })
          }
        }
      );
    }
    return Countries._countryArray;
  }

  public static GetCountryName(langIso639: string, country: string): string {
    const map: Map<string, string>  = this.GetCountryMap(langIso639);
    const name = map.get(country);
    if (name) {
      return name;
    } else {
      return '';
    }

  }

  public static GetLocalizedCountryName(i18n: any, countryCode: string) {
    countryCode = countryCode.toUpperCase();
    return i18n[countryCode];
  }
}



module.exports = {
  fr: {
    // added variables
    new_orders_traduction: "Passer une commande de traduction",
    new_orders_interprestation: "Passer une commande d'interprétation",
    order_asser_or_not: "S'agit-il d'une traduction assermentée ?",
    //
    select: "Selectionner",
    traduction_type: "Type de la traduction",
    find_interpertor: "Trouver un interprète",
    page_number: "Nombre de pages",
    order_interprete_delegate: "Interprètes",
    facture_echeance: "échéance",
    facture_conditions: "conditions",
    facture_comptant: "Comptant",
    facture_adress: "adresse de facturation",
    facture_pro: "Professionnel",
    facture_qte: "qte",
    facture_price: "prix",
    facture_unitaire: "unitaire",
    facture_montant: "Montant",
    facute_solde: "solde à payer",
    facture_tva_fr: "20 % TVA FR",

    // pro_dashboard
    dashboard_pro_asser: "Commandes de traductions assermentées",
    dashboard_pro_classic: "Commandes de traductions classiques",
    dashboard_pro_inter: "Commandes d'interpretations",
    dashboard_pro_as_of: "au",
    dashboard_compared_last_month: "par rapport au mois dernier",
    dashboard_pro_evolution_month: "Evolution au cours du mois",
    dashboard_pro_budget_month: "Budget pour le mois de",
    dashboard_pro_budget: "Sommes déduites",
    dashboard_pro_amount_month: "Dernières sommes déduites",
    dashboard_pro_no_amount_month: "Aucune somme à déduire pour le moment",
    dashboard_pro_amount: "Sommes déduites du budget",
    dashboard_pro_amount_left: "Reste",
    dashboard_pro_amount_detail: "Detail des sommes déja déduites",
    dashboard_pro_amount_btn: "Detail des sommes",
    dashboard_pro_command: "Détails des commandes en cours",
    dashboard_pro_command_date: "Date de commande:",
    dashboard_pro_command_status: "Statut:",
    dashboard_pro_no_command: "Aucune commande passée pour le moment",
    dashboard_pro_command_company: "Commandes passées par mon entreprise",
    dashboard_pro_command_company_date: "Passé le",
    dashboard_pro_command_company_priceHT: "Prix HT:",
    dashboard_pro_command_company_type: "Type:",
    dashboard_pro_command_company_status: "Statut:",
    dashboard_pro_no_command_company:
      "Aucune commande passée par vos collegues pour le moment",
    dashboard_pro_command_year: "Nombre de commandes à l'année",

    // trad_dashboard
    dashboard_turnover: "Mon chiffre d'affaires",
    dashboard_evolution_month: "Evolution au cours du mois",
    dashboard_trad_month: "Traductions du mois",
    dashboard_interpretation_month: "Interprétations du mois",
    dashboard_new_missions: "Nouvelles missions",
    dashboard_my_missions: "Mes missions en cours",
    dashboard_command_date: "Date de commande :",
    dashboard_type: "Type :",
    dashboard_total_sells: "Total des ventes depuis mon inscription",
    dashboard_doc_type: "Type de document les plus traduits",
    dashboard_my_clients: "Mes clients récurrents",
    dashboard_trad: "Traduction",
    dashboard_interpretation: "Interprétation",
    dashboard_particulier: "Particulier",
    dashboard_pro: "Professionnel",
    dashboard_traductor_asser: "Traduction assermentée",
    dashboard_traductor_classic: "Traduction classique",
    dashboard_prestation_lang: "Mes prestations par langue",
    dashboard_evolution_year: "Evolution sur l'année en cours",
    dashboard_status: "Statut :",
    dashboard_month_janv: "Janv",
    dashboard_month_fevr: "Févr",
    dashboard_month_mars: "Mars",
    dashboard_month_avr: "Avr",
    dashboard_month_mai: "Mai",
    dashboard_month_juin: "Juin",
    dashboard_month_juill: "Juil",
    dashboard_month_aout: "Août",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Oct",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Déc",

    // pro
    online: "Rester connecté",
    login_caption_title_pro: "Hierø - Interface professionnelle",
    login_main_title_pro: "Interface professionnelle",
    login_main_subtitle_pro:
      "Accédez à votre interface professionnelle Hiero !",
    signup_caption_title_pro: "Hierø - Interface professionnelle",
    signup_main_subtitle_pro:
      " Pour nous rejoindre, merci de remplir ce formulaire !",
    agency_setup_title_pro: "À propos de votre entreprise",
    agency_setup_explanation_pro:
      "Trouvez un traducteur assermenté rapidement grâce à notre solution. Vous pourrez ainsi faire traduire vos documents dans les plus brefs délais !",
    main_header_subtitle_pro: "Interface Professionnelle",
    main_left_menu_title_pro: "Compte professionnel",
    main_left_menu_section_navigation_order: "Mes commandes",
    order_id: "Id",
    order_language_from: "De",
    order_language_to: "Vers",
    order_status: "Etat",
    new_orders: "Passer une nouvelle commande",

    status_orders: "Statut de votre commande",
    final_intervention: "Finaliser l'intervention",
    finaliser_commande: "Terminer la commande",
    order_traductor_delegate: "Traducteurs",
    new_order: "Commander une traduction",
    new_order_ass: "Commander une traduction assermentée",
    section_order_title: "Mes commandes",
    section_card_title: "Abonnement",
    state_pro_wait: "En attente",
    sold: "Montant du budget alloué",
    account_expl_pro_cheque: "Merci d'adresser un chèque à Hiero",
    account_expl_pro_viremement: "Virements ",
    account_expl_pro_iban: "IBAN FR 000",
    account_hist_pro_date: "Date d'intervention",
    account_hist_pro_debit: "Débit ",
    account_hist_pro_credit: "Crédit ",
    account_hist_pro_way: "Moyen ",
    account_hist_pro_ref: "Référence ",
    account_budget: "Budget ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Langue Origine",
    select_choose: "Choisir...",
    find_traductors: "Trouver des traducteurs",
    tasks_price_ttc: "Prix (TTC)",
    tasks_price_tva: "TVA",

    order_no_asser_document_name: "Nom du document",
    order_no_asser_query_pdf_page_number:
      "Votre document contient-il plus d'une page ?",
    order_no_asser_query_pdf_page_number_yes:
      "Entrer le nombre de pages du document",
    no_traductor: "Pas encore de traducteurs disponible! ",
    no_traductor_message: "Fermer la fenêtre puis réessayer",
    loading: "En cours ...",
    menu: "Afficher les commandes de",
    menu_label_traductor_asser: "Traduction Assermentée",
    menu_label_traductor_classic: "Traduction Classique",
    menu_label_interprestation: "Interprétation",
    menu_label_all: "Tout afficher",
    alert_form_error_pages_number: "Le nombre de pages ne peut être négatif.",
    urgency: "Délai de livraison demandé pour cette traduction",

    test: "",
    message_no_budget: "Vous disposez actuellement d'un budget de ",
    message_contact: "Veuillez nous contacter si vous souhaitez le modifier.",
    agency_pro_budget_help:
      "Le budget que vous souhaitez allouer à vos traductions.",

    //Subscription's translate

    sub_training: "Formation d'équipe ",
    sub_search: "Recherche automatique de traducteur / interprête ",
    sub_geo: "Géolocalisation du traducteur et/ou de l'interprête ",
    sub_dem: "Dématérialisation de toutes les commandes ",
    sub_save: "Sauvegarde des documents avec gestionnaire de fichiers ",
    sub_recap: "Récapitulatif des commandes au mois ",
    sub_proj: "Evolution des projets en temps réel ",
    sub_management: "Gestion de budget en temps réel ",
    sub_message: "Messagerie automatique",
    sub_send: "Livraison de vos traductions par courrier ",
    sub_impl: "Implantation du budget voté sur 12 mois ",
    sub_account: "Account manager à vos côtés ",
    sub_orientation:
      "Orientation personnalisée des langues les plus demandées ",
    sub_split:
      "Division du budget en plusieurs départements, selon les utilisateurs",
    sub_event: "Enlèvement par coursier ",
    choose: "Choisir",
    sub_function: "Fonctionnalités",
    sub_standard: "Standard",
    sub_essen: "Essentiel",
    sub_premium: "Premium",
    sub_month: "mois",
    sub_subscription: "Abonnement",
    sub_associations_label: "Associations",
    sub_cabinet_label: "Cabinets d'avocats",
    sub_bank_label: "Mobilité internationale",
    sub_academia_label: "Etablissement academiques",
    sub_who_are_you: "Vous êtes ?",
    sub_explanation_academia:
      "Nous préférons travailler sous forme de partenariats avec les établissements académiques, type universités, grandes écoles ...",
    sub_explanation_systeme: "2 systèmes sont disponibles: ",
    sub_system_one:
      "L'utilisation de l'application mobile s'ils ne sont pas conventionnés.",
    sub_system_two:
      "La négociation d'une mise en place de l'espace professionnel s'ils sont conventionnés.",

    sub_credit_card_message:
      "Activer les services en payant votre abonnement. Dans l'onglet ",

    agree: "En cliquant, vous acceptez",
    our_term: "conditions d’utilisation et de vente",
    and_the: "et le",
    stripe_agreement: " Contrat de compte connecté Stripe. ",

    created_at: "Créer le ",
    updated_at: "Mise à jour le ",
    validate_credit_card_message: "Votre commande a été prise en compte",

    assermente: "Assermenté(e)",
    subscriber_choose: "Choisir un abonnement",
    services_none_yet_subscribe: "Abonnez-vous pour activer vos prestations",

    asser_placeholder: "Êtes-vous un(e) traducteur(rice) assermenté(e) ?",
    agency_expl_4_expl:
      "Il est nécessaire de nous fournir un justificatif prouvant votre assermentation avant de pouvoir utiliser nos services.",
    agency_expl_4_expl_1: "Vous pouvez l'envoyer par ",
    agency_expl_4_expl_2:
      "Pour toute question concernant cette procédure, n'hésitez pas à nous contacter.",

    label_assermentation: "Assermentation",
    find_out_more: "En savoir plus",
    description: "Description",

    menu_label_relecture: "Relecture",

    // end pro by brin
    login_caption_title: "Hierø - Interface traducteur",
    login_caption_subtitle:
      "Un logiciel pensé par des traducteurs pour des traducteurs.",
    login_main_logo: "Hierø",
    login_main_title: "Interface traducteur",
    login_main_subtitle:
      "Accédez à vos missions ainsi qu'à l'éditeur de document grâce à l'interface traducteur Hiero !",
    login_main_connect: "Se connecter",
    login_main_email: "E-mail",
    login_main_email_placeholder: "Renseignez votre adresse mail",
    login_main_email_help: "Veuillez vous identifier avec votre adresse mail.",
    login_main_password: "Mot de passe",
    login_main_password_placeholder: "Indiquez votre mot de passe",
    login_main_password_help: "",
    login_main_button: "Se connecter",
    login_main_to_signup: "Pas encore inscrit ? Inscrivez-vous !",
    login_main_copyright: "© Hierø - HIERØ 2020",
    login_error_required_field: "Champ obligatoire",
    login_error_invalid_email: "L'adresse saisie n'est pas valide.",
    login_error_email_not_found: "L'adresse mail n'existe pas.",
    signup_caption_title: "Hierø - Interface traducteur",
    signup_caption_subtitle: "Choisissez Hierø et gagnez en visibilité !",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscription",
    signup_main_subtitle:
      "Créez votre compte, rejoignez notre interface et devenez visible auprès de nos nombreux utilisateurs !",
    signup_main_email: "Adresse mail",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Mot de passe",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmer votre mot de passe",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Nom",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Prénom",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Téléphone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "S'inscrire",
    signup_main_to_signup: "Déjà membre ? Se connecter",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Champ obligatoire",
    signup_error_invalid_email: "L'adresse saisie n'est pas valide.",
    signup_error_email_not_found: "L'adresse mail n'existe pas.",
    signup_error_min_password: "Minimum 8 caractères",
    signup_error_max_password: "Maximum 30 caractères",
    signup_error_passwords_do_not_match:
      "Les mots de passe ne correspondent pas",
    login_forgot_password: "Mot de passe oublié ?",
    reset_password_main: "Réinitialiser votre mot de passe",
    reset_password_email_title: "Email",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Envoyé",
    reset_password_login: "Connexion",
    reset_password_success:
      "Un email a été envoyé à votre adresse. Cliquez ensuite sur le lien pour modifier votre mot de passe.",
    reset_password_error_required: "Champ obligatoire",
    reset_password_error_invalid_email: "L'adresse saisie n'est pas valide.",
    reset_password_error_user_not_found: "L'adresse mail n'existe pas.",
    main_header_title: "Hierø -",
    main_header_title_particulier:
      "Demandez vos traductions assermentées avec Hierø ",
    main_header_subtitle: "Interface traducteur",
    main_header_user_menu_logout: "Se déconnecter",
    main_left_menu_title_asser: "Traduction assermentée", //
    main_left_menu_title: "Traducteur",
    main_left_menu_section_navigation: "Menu de navigation",
    main_left_menu_section_accueil: "Accueil",
    main_left_menu_section_navigation_missions: "Mes missions",
    main_left_menu_section_parameters: "Paramètres",
    main_left_menu_section_compte: "Compte",
    main_left_menu_section_parameters_agency: "Mon entreprise",
    main_left_menu_section_parameters_services: "Mes services",
    main_left_menu_section_parameters_user: "Mon profil",
    main_left_menu_section_parameters_card: "Mon Abonnement",
    main_left_menu_section_parameters_support: "Support",
    agency_setup_title: "À propos de votre agence",
    agency_setup_explanation:
      "Hierø propose vos services aux clients proches de vos locaux.<br>Merci de nous préciser le nom de votre entreprise ainsi que votre adresse postale. <br>Ces informations seront proposées aux clients lorsqu'ils cherchent un traducteur.",
    agency_setup_companyname: "Raison sociale",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      " Le nom de l’entreprise qui s’affiche pour toutes les commandes et factures, etc…",
    agency_setup_address: "Adresse",
    agency_setup_siret: "Siret",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help:
      "Entrer votre numéro de siret si c'est une entreprise",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Commencez à taper votre adresse, et sélectionnez la bonne adresse dans le menu déroulant.",
    agency_setup_addresscompl: "Complément d'adresse",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Bâtiment, escalier, digicode, etc. Lorsque vos clients viennent chercher leurs documents, ils doivent avoir toutes les informations nécessaires.",
    agency_setup_tel: "Télephone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "Le numéro de téléphone à utiliser pour toute correspondance avec vos clients.",
    agency_setup_email: "Adresse mail",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "L'adresse mail à utiliser pour toute correspondance avec vos clients. Veuillez en indiquer une autre s'il ne s'agit pas de votre adresse d'inscription sur Hierø.",
    agency_setup_email_help_pro:
      "L'adresse mail à utiliser pour toute correspondance avec nos traducteurs et l'équipe Hiero.",
    agency_setup_save: "Enregistrer",
    agency_setup_error_required_field: "Champ obligatoire",
    agency_setup_error_invalid_email: "L'adresse saisie n'est pas valide.",
    agency_setup_error_valid_address:
      "Veuillez sélectionner une des adresses proposées dans le menu déroulant.",
    agency_address_not_found: "Votre adresse est introuvable ?",
    agency_address_title: "Saisir manuellement vos coordonnées",
    agency_address_address: "Adresse :",
    agency_address_number: "N°",
    agency_address_street: "Rue",
    agency_address_code: "Code postal",
    agency_address_city: "Ville",
    agency_address_country: "Pays",
    agency_address_coords: "Coordonnées GPS :",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Annuler",
    agency_address_validate: "Valider",

    //mamadou
    main_left_menu_section_navigation_dashboard: "Tableau de bord",
    main_menu_left_section_navigation_entreprise: "Mon entreprise",
    main_menu_left_section_navigation_factures: "Mes factures",
    main_menu_left_section_navigation_messages: "Mes messages",
    main_menu_left_section_navigation_messagerie: "Service de messagerie",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestion des fichiers",
    main_menu_left_section_navigation_assistance: "Assistance",
    main_menu_left_section_navigation_ticket: "Ouvrir un ticket",
    main_menu_left_section_navigation_question: "Foire aux questions",
    head_facture: "Facture",
    section_date: "Date",
    section_numero_facture: "N° Facture",
    section_numero_commande: "N° de commande",
    section_type_prestation: "Type de prestation",
    section_prestation: "Prestation",
    section_price: "Montant Total",
    section_print: "Imprimer",
    section_generate_facture: "Générer la facture en PDF",
    section_consulter: "Consulter",
    prestation: "Préstation",
    date_commande: "Date de la commande",
    commande_qte: "Quantité",
    commande_produit: "Produit",
    commande_desc: "Description",
    montant_total: "Montant Total",
    total_ht: "Total HT",
    total_tva: "Total TVA",
    total_ttc: "Total TTC",
    retour_btn: "Retour",
    single_commande_paragraphe:
      "Si le traducteur a accepté votre commande. Une fois la traduction terminée, vous devrez la valider et confirmer. En cas de refus de la part du traducteur choisi, notre logiciel soumettra automatiquement votre commande à un autre traducteur.",
    progress_commande: "Progression de la commande",
    single_commande_p1:
      "Le traducteur télécharge le document pour le traduire ",
    single_commande_p2:
      "Une fois traduit il l'ajoute à cette commande pour que vous puissiez valider la traduction et confirmer la commande.",
    accept_intervention: "intervention acceptée",
    load_intervention: "intervention en cours",
    delai_intervention: "Durée de l'intervention",
    commande_numero: "Commande n° ",
    commande_statut: "Statut de votre commande",
    commande_discuss: "Discuter directement avec votre traducteur",
    commande_details: "Détails de la commande",
    intervention_details: "Détails de l'intervention",
    commande_receive: "Commande reçue le",
    type_doc_commande: "Type de document demandé",
    doc_a_traduire: " Document à traduire",
    trad_asser: "Traducteur assermenté",
    trad_name: "Nom du traducteur",
    capital_sasu: "SASU au capital social de 7 000,00 €",
    rcs_infos: "RCS EVRY B 843 119 728 ",
    siret: " SIRET 84311972800023",
    tva_fr: "de TVA : FR00843119728",
    condition_paragraphe_1: "Pénalités de retard (taux annuel): 10%",
    condition_paragraphe_2: "Pas d'escompte en cas de paiement anticipé",
    condition_paragraphe_3:
      "Indemnité forfaitaire pour frais de recouvrement en cas de retard de paiement : 40€",
    prestataire: "Prestataire",
    pays_origine: "Pays d'origine",
    visioner_doc: "Visionner le document",
    visioner: "Visioner",
    auth_doc:
      "Le traducteur vérifie l'authencité du document avant de débuter la traduction.",
    doc_bug:
      "En cas de problèmes, le traducteur peut vous contacter directement par messagerie.",
    infos_interv: "Informations supplémentaires sur l'intervention",
    add_file: "Ajouter une pièce jointe",
    section_questions_frequentes: "Questions les plus fréquentes",
    section_questions_profil_entreprise:
      "Pourquoi différencier mon profil et mon entreprise ?",
    section_questions_paragraphe_one:
      "Votre profil ne concerne que vous. 'Mon entreprise' regroupe tous les collaborateurs de votre société qui utilisent le logiciel. Seuls les responsables de département peuvent ajouter de nouveaux comptes et s'occuper des modifications de budgets.",
    section_questions_original:
      "J'ai déjà la version téléchargeable de la traduction assermentée... Pourquoi récupérer l'original ?",
    section_questions_paragraphe_two:
      "La version téléchargeable de la traduction n'a aucune valeur juridique et ne peut donc pas être utilisée pour les procédures qui nécessitent une traduction réalisée par un traducteur assermenté. La version originale de la traduction est la seule acceptée par les autorités compétentes",
    section_questions_tarducteur:
      "Que se passe t'il si le traducteur que j'ai choisi ne réponds pas ?",
    section_questions_paragraphe_three:
      "En théorie, les traducteurs qui s'affichent sont ceux qui sont disponibles pour s'occuper de votre commande. Il peut arriver que certains prennent du temps à répondre. Dans ce cas là, notre logiciel  vous redirigera vers un traducteur proche de chez vous qui pourra prendre en charge votre demande de traduction/interpretation ",
    section_questions_price_deplacement:
      "Le prix du déplacement est-il compris dans le prix annoncé par le logiciel ?",
    section_questions_paragraphe_four:
      "Chaque interprète aura un montant différent en fonction du nombres de km qu'il accepte de faire pour se déplacer. Dans tous les cas ce montant sera inclut dans le prix, vous trouverez le montant exact du supplément dans les détails de commandes",
    section_questions_gestions_files:
      "Qu'est ce que le gestionnaire de fichiers ?",
    section_questions_paragraphe_five:
      "Le gestionnaire de fichiers vous permet de retrouver tous vos fichiers téléchargeable dans un même endroit. Les fichiers peuvent être retrouvés par type de fichiers ou date d'ajout. Vous pouvez aussi créer des dossiers pour les classer",
    section_questions_contact:
      "Est-il possible de contacter le traducteur pour des questions ou des détails sur la prestation ?",
    section_questions_paragraphe_six:
      "Absolument! Vous avez accès à la messagerie lorsque vous accédez au détail de la commande, ou bien en allant dans l'onglet 'Service de messagerie'. La messagerie vous sert aussi pour convenir d'un lieu de rendez vous pour la remise des documents",
    section_questions_traductions:
      "Pourquoi la prestation de traduction classique diffère de celle dite 'assermentée' ?",
    section_questions_paragraphe_seven:
      "Les documents classiques étant plus aléatoires, un modèle ne peut pas être générer par l'algorithme. Le client transmet le document qui est téléchargeable lorsque vous accédez au détail de la commande. Une fois traduit, le traducteur le charge et l'envoie au client pour validation",
    section_questions_budget:
      "De quelle façon pouvons-nous gérer le budget pour nos commandes ?",
    section_questions_paragraphe_eight:
      "Votre entreprise ou bien votre département vous alloue un certain budget pour toutes vos commandes. Nous partons du principe que votre responsable gère tous les mouvements sur le budget. C'est donc lui qui doit créditer ou débiter le compte en utilisant la fonction adéquate.",
    head_particulier:
      "Les réponses à vos questions sont peut être sur cette page. Dans le cas contraire n'hésitez pas à ouvrir un ticket.",
    partuclier_trad_doc_head1:
      "Le traducteur n'a pas livré mon document. Que faire ?",
    partuclier_trad_doc1:
      "Vous pouvez tenter de le contacter en utilisant la messagerie ou bien passer par le support technique si le traducteur ne réponds pas à vos requêtes. Le traducteur se doit de vous donner une raison valable pour laquelle il n'a pas pu livrer votre document dans les délais demandés.",
    partuclier_trad_doc_head2:
      "J'ai déjà la version téléchargeable de la traduction assermentée... Pourquoi récupérer l'original ?",
    partuclier_trad_doc2:
      "La version téléchargeable de la traduction n'a aucune valeur juridique et ne peut donc pas être utilisée pour les procedures qui nécessitent une traduction rélisée par un traducteur assermenté. La version originale de la traduction est la seule acceptée par les autorités compétentes.",
    partuclier_trad_doc_head3:
      "Que se passe t-il si le traducteur que j'ai choisi ne répond pas ?",
    partuclier_trad_doc3:
      "En théorie les traducteurs qui s'affichent sont ceux qui sont disponibles pour s'occuper de votre commande. Il peut arriver que certains prennent du temps à répondre. Dans ce cas, là notre logiciel vous dirigera vers un traducteur proche de chez vous qui pourra prendre en charge votre demande de traduction/interpretation",
    partuclier_trad_doc_head4:
      "Je ne peux pas me déplacer pour récuperer mon document auprès du traducteur.",
    partuclier_trad_doc4:
      "Si vous ne pouvez pas vous déplacer pour récupérer l'original, le traducteur a toujours la possibilité de vous faire parvenir les documents par courrier.",
    partuclier_trad_doc_head5:
      "La traduction que j'ai reçue me semble être de mauvaise qualité, voire erronée ou imcomplète.",
    partuclier_trad_doc5:
      "Gardez le document comme preuve, contactez le traducteur pour obtenir des explications. Si le traducteur refuse de faire des changements, contactez directement le support technique pour qu'il intervienne.",
    partuclier_trad_doc_head6:
      "Que faire si le logiciel ne détecte aucun traducteur à proximité ?",
    partuclier_trad_doc6:
      "Il peut arriver que nous n'ayons pas de traducteur recensé pour la langue qui vous intéresse, ou bien qu'il ne soit pas à proximité. Le cas écheant contactez le support technique, ou bien sélectionnez un traducteur éloigné dans le cas où aucun autre n'aura été suggéré.",
    agency_support: "Support Technique",
    agency_support_service_client: "Numéro de téléphone- Service Clients",
    agency_support_ouverture: "Ouvert du lundi au vendredi de 9h00 à 17h30",
    agency_support_questions:
      "Vous avez des questions à nous soumettre, des bugs à nous rapporter? Contactez-nous par mail",
    agency_support_guide: "Le guide Hierø",
    agency_support_guide_pro: "Le guide Professsionnel",
    agency_support_guide_p:
      "Retrouvez notre guide d'utilisation ! Vous avez raté une étape ? Vous souhaitez savoir comment fonctionne la platforme Hierø ? ",
    agency_support_download_guide: "Téléchargez notre guide",
    agency_ticket_title: "Mes anciens tickets",
    agency_ticket_objet: "Objet",
    agency_ticket_question: "Votre question",
    agency_ticket_state: "Statut",
    agency_ticket_head:
      "Besoin d'aide ? Vous n'avez pas trouvé la réponse dans le FAQ? Posez-nous toutes vos questions, nous répondons aussi vite que possible !",
    my_info: "Mes informations",
    geolocation_paragraphe:
      "Le logiciel a besoin de connaître votre position pour rechercher les traducteurs les plus proches de vous avec plus de précision",
    update_password: "Modifier mon mot de passe",
    delete_account: "Supprimer mon compte",
    account_paragraphe1:
      "Vous n’avez plus besoin de faire des demandes de traduction ?",
    account_paragraphe2: " Vous ne souhaitez plus utilisez nos services ?",
    account_paragraphe3: "Vous pouvez vous désinscrire à tout moment !",
    account_delete: "Je souhaite supprimer mon compte",
    account_upt:
      "Si vous le souhaitez, vous pouvez modifier votre mot de passe",
    send: "Envoyer",
    message_paragraphe:
      "Discutez avec les traducteurs pour toute information concernant la mission en cours",
    consult_orders: "Consulter mes commandes",
    discuss_encours: "Conversations en cours",
    signup_main_text: "Demandez vos traductions assermentées avec HIERØ",
    cdu: "En cliquant sur 'S'inscrire', vous acceptez nos ",
    cdu1: "conditions générales d'utilisation et de vente",
    cdu2: " notre politique de confidentialité",
    cdu3: " ainsi que",
    newsletter:
      "J'accepte de recevoir par e-mail les mises à jour, les offres spéciales et les nouveautés sur la plateform et/ou les autres produits développés par Hierø ",
    login_text:
      "Avec l'interface Hierø, faites vos demandes de traduction et sélectionnez votre traducteur assermenté en un clic.",
    ticket_numero: "Ticket n°",
    intervention_time_date: "Date et heure de l'intervention",
    close_modal: "Fermer",
    inscription_date: "Inscrit depuis le ",
    loading_order: "Mes demandes de traduction en cours",
    view_all_orders: "Voir toutes les commandes",
    last_payment: "Mes derniers paiments effectués",
    view_all_payment: "Voir toutes mes paiements",
    last_send_msg: "Derniers messages envoyés",
    order_translate: "du ",
    order_translate_to: "vers le ",
    price_unitaire: " Prix unitaire HT",
    price_total_ht: "Montant total HT",
    montant_total_euro: "Montant total en euros",
    rules_conditions: "conditions de règlement",
    rules_conditions_p: "paiement à la réception de la facture",
    //end mamadou

    section_agency_title: "Mon entreprise",
    section_services_title: "Mes services",
    section_account_title: "Mon profil",
    section_support_title: "Support",
    section_dashboard_title: "Tableau de bord",
    section_tasks_title: "Mes missions",
    section_task_title: "Effectuer une mission",
    section_document_title: "Traduire un document",
    notifications_new_task: "Vous avez une nouvelle mission!",
    notifications_task_confirmed:
      "La mission a été confirmée. Vous pouvez débuter la traduction.",
    notifications_task_validated:
      "La mission vient d'être validée par le client",
    tasks_filters: "Filtres",
    tasks_sent_on: "Envoyé le",
    tasks_src_lang: "Langue d'origine",
    tasks_src_country: "Pays d'origine",
    tasks_dst_country: "Traduire vers",
    tasks_state: "Progression",
    tasks_price: "Prix (HT)",
    state_cancelled: "Annulé par le client",
    state_waiting_for_translator: "Nouveau",
    state_waiting_for_payment: "En attente de paiement",
    state_refused_by_translator: "Mission refusée",
    state_translating: "En cours de traduction",
    state_waiting_for_client_validation:
      "Finie. En attente de validation par le client.",
    state_validated: "Validée par le client",

    state_validated_pro: "Validé",
    state_accept_order: "Commande acceptée",
    prestation_breadcrumb_tasks: "Mes missions",
    prestation_order_number: "N° de commande :",
    prestation_next_action_title: "Prochaines actions",
    prestation_next_action_accept_order: "Accepter la commande",
    prestation_next_action_accept_order_expl:
      "Cliquez ici pour accepter la commande. Le client procèdera ensuite au paiement. Une fois le paiement effectué, vous pourrez commencer la traduction.",
    prestation_next_action_reject_order: "Refuser la commande",
    prestation_next_action_reject_order_expl:
      "Cliquez ici pour refuser la commande.",
    prestation_next_action_rejected: "Vous avez refusé cette commande.",
    prestation_next_action_waiting_for_payment:
      "En attente du paiement par le client.",
    prestation_next_action_translate_expl:
      "Veuillez procéder à la traduction de chaque document de cette commande en cliquant sur le bouton 'Traduire'. Une fois que tous les documents sont prêts, cliquez sur le bouton 'Envoyer au client'. Pour toute question ou précision, vous pouvez directement échanger avec le client via le messagerie.",
    prestation_next_action_translate_all_done_expl:
      "Vous avez terminé tous les documents de cette commande.",
    prestation_next_action_translate_confirm:
      "Envoyer au client pour validation",
    prestation_next_action_waiting_for_validation:
      "Le client est en train de valider la traduction.",
    prestation_next_action_validated: "Commande validée par le client.",
    prestation_order_details: "Détails de la commande :",
    prestation_order_from: "De",
    prestation_order_to: "à",
    prestation_order_country_origin: "Pays d'origine :",
    prestation_order_document_count: "Documents à traduire :",
    prestation_order_price: "Prix (HT) :",
    prestation_order_date: "Commande créée le :",
    prestation_order_documents_title: "Documents à traiter :",
    prestation_order_document_type: "Type de document",
    prestation_order_document_originals: "Originaux",
    prestation_order_document_translated: "Traduit(s)",
    prestation_order_document_not_yet_translated: "Pas encore de traduction",
    prestation_order_document_translate: "Traduire !",
    prestation_order_messaging_title: "Messagerie",
    prestation_order_messaging_send: "Envoyé",
    prestation_order_messaging_client: "Client :",
    prestation_order_messaging_me: "Moi :",
    prestation_order_confirm_ok: "Ok",
    prestation_order_confirm_cancel: "Annuler",
    prestation_order_validate_title: "Confirmer",
    prestation_order_validate_msg:
      "Etes-vous sûr de vouloir valider cette commande ? Le client recevra une notification et aura désormais accès aux documents traduits.",
    prestation_order_accept_title: "Confirmer",
    prestation_order_accept_msg:
      "Etes-vous sûr de vouloir accepter cette commande ?",
    prestation_order_refuse_title: "Confirmer",
    prestation_order_refuse_msg:
      "Etes-vous sûr de vouloir refuser cette commande ?",
    prestation_document_validate: "Valider la traduction !",
    prestation_document_show_original:
      "Visionner l'original dans un autre onglet :",
    prestation_document_edit: "Éditeur de document",
    prestation_document_edit_expl:
      "Le texte dans le champ ci-dessus est souligné dans le document ci-dessous. En saisissant le text traduit dans le champ, le document sera automatiquement mis à jour. Utilisez les flèches pour à travers le document.",
    prestation_document_get_browser:
      "Vous ne voyez pas le PDF ? Veuillez utiliser un navigateur récent comme",
    prestation_document_button_reset: "Réinitialiser le document",
    prestation_document_confirm_title: "Confirmer",
    prestation_document_confirm_message:
      "Êtes-vous sûr de vouloir réinitialiser la traduction ?  Toutes vos modifications seront perdues !",
    prestation_document_confirm_oui: "Oui",
    prestation_document_confirm_non: "Non",
    account_expl:
      "Veuillez renseigner vos coordonnées, pour vous contacter si necessaire.",
    account_save: "Enregistrer",
    account_surname: "Nom",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Prénom",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Téléphone",
    account_telephone_placeholder: "",
    account_telephone_help: "",
    account_poste: "Poste",
    account_poste_help: "",
    account_poste_placeholder: "Poste",
    account_adresse: "Adresse",
    account_adresse_help: "",
    account_adresse_placeholder: "Adresse",
    account_service: "Service",
    account_service_help: "",
    account_service_placeholder: "Service",
    account_complement_adr: "Complément d’adresse",
    account_complement_adr_help: "",
    account_complement_adr_placeholder: "Complément d’adresse",
    account_email: "Email",
    account_email_help: "",
    account_email_placeholder: "email",
    account_password: "Mot de passe actuel",
    account_password_help: "",
    account_new_password: "Nouveau mot de passe",
    account_new_password_help: "",
    account_error_required: "Champ obligatoire",
    agency_expl_1_title: "Raison sociale",
    agency_expl_1_expl:
      "Précisez le nom de votre entreprise afin que vos clients puissent vous retrouver.",
    agency_expl_2_title: "Géolocalisation",
    agency_expl_2_expl:
      "L'application nécessite vos coordonnées afin de vous mettre en relation avec les clients les plus proches de vous.",
    agency_expl_3_title: "Activation du compte",
    agency_expl_3_expl:
      "Votre profil sera visible en ligne une fois la sauvegarde des informations effectuée.",
    services_add_button: "Ajouter une nouvelle offre...",
    services_none_yet:
      "Pour être visible sur la plateforme Hierø, veuillez préciser les types de prestations que vous proposez.",
    services_add_expl:
      "Veuillez préciser les les langues que vous pratiquez, puis cliquez sur 'Ajouter'.",
    services_add_src_lang: "Langue d'origine",
    services_dst_lang: "Vers",
    services_add: "Ajouter !",
    services_error_add:
      "Cette combinaison de langues n'est pas encore possible avec Hierø. Contactez-nous pour plus d'informations !",
    services_dont_see_title: "Je n'arrive pas à consulter mes langues.",
    services_dont_see_response:
      "Hierø facilite votre travail de traduction en vous fournissant des modèles de vos documents prêts à l'emploi. Nous développons continuellement notre gamme de documents. Veuillez nous contacter à  pour demander d'autres langues.",
    services_list_title: "Votre liste d'offres de service",
    services_expl:
      "Cliquez sur chaque offre afin de personnaliser les documents que vous gérez. Vous pouvez soit choisir de refuser un certain type de document en désactivant la bouton, soit préciser votre prix personnel pour traiter ce type de document.",
    services_list_active: "Activé",
    services_list_country: "Pays",
    services_list_document_type: "Type de document",
    services_list_average_price: "Prix moyen (HT)",
    services_list_your_price: "Votre prix (HT)",
    services_list_your_price_reset: "Réinitialiser",
    services_list_delete: "Supprimer",
    support_title: "Nos locaux",
    support_company: "Hiero",
    support_addr1: "86 Rue de Paris",
    support_addr2: "91400 Orsay",
    support_addr3: "France",
    support_contact: "Contact",
    support_tel: "Tel : (+33) 01 76 38 01 40",
    support_tel_es: "Tel : (+34) 911 23 88 87",
    support_tel_en: "Tel : (+1) 929 274 5104",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Élement introuvable",
    not_found_subtitle: "La page que vous avez demandée n'existe pas",
    not_found_action: "Contactez si ",
    not_found_action1: "le problème persiste. ",
    not_found_home: "Accueil",
    // Interpretation
    menu_label_interpretation: "Demander une interpretation",
    menu_label_intervention: "L'intervention doit se dérouler sur",
    type_intervention: "Type d'intervention demandée",
    menu_label_event_name: "Nom de l'événement",
    nb_heures_estimee: "Nombre d'heures estimées",
    menu_label_sur_place: "Sur place",
    menu_label_visio: "Par visioconférence",
    menu_langue: "Langues",
    menu_head_ticket: "Mes tickets",
    table_header_type_de_document: "Type de document",
    table_header_nb_pages: "Nombre de pages",
    table_header_statut: "Statut",
    table_header_archives: "Archive commande",
    table_hader_details: "Détails",
    head_professionnal:
      "Le logiciel a besoin de connaitre votre position pour rechercher les traducteurs les plus proches de vous avec plus de précision",
    head_change_password:
      "Si vous le souhaitez, vous pouvez changer votre mot de passe",
    professionnal_paragraph: "Sommes déduites de mon budget",
    professionnal_paragraph_1:
      "Toutes les sommes pour chaque commande, qui sont ensuite déduites de votre budget",
    professionnal_paragraph_2: "Proceder à un virement",
    professionnal_paragraph_3: "Envoyer un chèque",
    professionnal_paragraph_4: "Demander un remboursement de mon solde",
    address_facturation: "Modifier mon addresse de facturation",
    consulter_facture: "Consulter mes factures",
    facture_numero: "Facture n°",
    recap_tva: "RÉCAPITULATIF DE LA TVA",
    recap_taux: "TAUX",
    recap_mt_tva: "MONTANT TVA",
    recap_base_ht: "BASE HT",
    recap_tva_fr: "20% TVA FR",
    client_facture_detail: "Client",
    client_particulier_name: "Raison sociale",
    client_particulier_email: "Email",
    client_particulier_address: "Adresse ",
    client_particulier_code_postal: "Ville",
    salle_de_conference: "Salle de conférence",
    joindre_salon: " Rejoindre la salle",
    infos_entrep: "",
    infos_commandes: "",
    action: "Action",
    client: "Client",
    hours: "Heures",
    join_room: "Rejoindre la salle",
    rooms: "Les salles (Rooms)",
    warning: "Attention ! ",
    temps_ex: "Temps estimé",
    question_inter: "Êtes-vous interprète ?",
    question_inter_price: "Prix pour une heure :",
    question_inter_1:
      "Vous réalisez des missions d'interprétation sur notre logiciel pour",
    calendar: " Calendrier",
    calendar_hidden: "Cacher le calendrier",
    conf: "La conférence",
    warning_1: "Il faut respecter l'orthographe des noms des salles.",
    interpretation_label_menu: "Interprétation",
    interpretation_label: "Interprétation",
    interpretation_info: "Explication",
    interpretation_paragraphe_1:
      "Pour participer à la mission d'interprétation, il vous faut au minimum deux onglets. Dans le premier, vous serez avec l'interprète, dans le second avec tous les participants.",
    interpretation_paragraphe_2:
      "Pour accéder à la salle de conférence, le nom de la salle (Room) à indiquer correspond à ",
    interpretation_paragraphe_4:
      "Pour accéder à la salle d’interprétation, le nom de la salle (Room) à indiquer correspond à ",
    interpretation_paragraphe_3:
      ". C’est là où se trouvent tous les participants.",
    interpretation_paragraphe_5:
      ". C’est là où se trouvent vos intervenants et l'interprète choisi. ",
    interpretation_paragraphe_6:
      "Pour réaliser correctement la mission d'interprétation de cette conférence à distance, il est nécessaire que le conférencier et l'interprète ne parlent pas en même temps dans la même salle.",
    interpretation_paragraphe_7:
      "C'est la raison pour laquelle vous êtes dans deux salles différentes. Ainsi, les participants n'entendront que vous dans la salle de conférence et vous n'entendrez que les intervenants dans la salle d'interprétation.",
    interpretation_paragraphe_8:
      "Pour des raisons de performance, la capacité maximale d'une salle est de 50 participants. Vous pouvez créer autant de salles correspondant à votre audience que vous le souhaitez et communiquer les noms de ces salles à votre interprète.",
    interpretation_paragraphe_9:
      "Pour inviter des participants, cliquez sur le bouton ci-dessous pour copier le lien d'invitation et le coller dans votre message tout en respectant le nom de la salle ",
    interpretation_paragraphe_10:
      "Cliquez ici pour copier le lien d'invitation ",
    interpretation_paragraphe_11:
      "Cliquez ici pour inviter les participants ou les intervenants",
    room_conf: " Salle de conférence",
    room_inter: "Salle d’interprétation",
    room_inter_pro: "Votre nom d'utilisateur",
    once_page_price: "Vos tarifs à la page",
    once_page_price_default: "Prix par defaut (HT)",
    once_page_information:
      "Nous vous invitons à donner votre tarif pour la traduction à la page d'un document (250 mots). Ce tarif sera proposé au client si le document qu'il souhaite traduire n'est pas enregistré par notre système.",
    copy_alert: "Copié!",
    commander_interprete: "Commander un interprète",
    particulier_name: "Nom du particulier",
    traduction_assermentee: "Traduction assermentée",
    traduction_classic: "Traduction classique",
    interpretation: "Interprétation",
    // Fin interpretation
    AF: "Afghanistan",
    ZA: "Afrique du Sud",
    AL: "Albanie",
    DZ: "Algérie",
    DE: "Allemagne",
    AD: "Andorre",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctique",
    AG: "Antigua-et-Barbuda",
    AN: "Antilles néerlandaises",
    SA: "Arabie saoudite",
    AR: "Argentine",
    AM: "Arménie",
    AW: "Aruba",
    AU: "Australie",
    AT: "Autriche",
    AZ: "Azerbaïdjan",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    BB: "Barbade",
    BY: "Bélarus",
    BE: "Belgique",
    BZ: "Belize",
    BJ: "Bénin",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BR: "Brésil",
    BN: "Brunéi Darussalam",
    BG: "Bulgarie",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-Vert",
    EA: "Ceuta et Melilla",
    CL: "Chili",
    CN: "Chine",
    CY: "Chypre",
    CO: "Colombie",
    KM: "Comores",
    CG: "Congo-Brazzaville",
    KP: "Corée du Nord",
    KR: "Corée du Sud",
    CR: "Costa Rica",
    CI: "Côte d’Ivoire",
    HR: "Croatie",
    CU: "Cuba",
    DK: "Danemark",
    DG: "Diego Garcia",
    DJ: "Djibouti",
    DM: "Dominique",
    EG: "Égypte",
    SV: "El Salvador",
    AE: "Émirats arabes unis",
    EC: "Équateur",
    ER: "Érythrée",
    ES: "Espagne",
    EE: "Estonie",
    VA: "État de la Cité du Vatican",
    FM: "États fédérés de Micronésie",
    US: "États-Unis",
    ET: "Éthiopie",
    FJ: "Fidji",
    FI: "Finlande",
    FR: "France",
    GA: "Gabon",
    GM: "Gambie",
    GE: "Géorgie",
    GS: "Géorgie du Sud et les îles Sandwich du Sud",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grèce",
    GD: "Grenade",
    GL: "Groenland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernesey",
    GN: "Guinée",
    GQ: "Guinée équatoriale",
    GW: "Guinée-Bissau",
    GY: "Guyana",
    GF: "Guyane française",
    HT: "Haïti",
    HN: "Honduras",
    HU: "Hongrie",
    BV: "Île Bouvet",
    CX: "Île Christmas",
    CP: "Île Clipperton",
    AC: "Île de l'Ascension",
    IM: "Île de Man",
    NF: "Île Norfolk",
    AX: "Îles Åland",
    KY: "Îles Caïmans",
    IC: "Îles Canaries",
    CC: "Îles Cocos - Keeling",
    CK: "Îles Cook",
    FO: "Îles Féroé",
    HM: "Îles Heard et MacDonald",
    FK: "Îles Malouines",
    MP: "Îles Mariannes du Nord",
    MH: "Îles Marshall",
    UM: "Îles Mineures Éloignées des États-Unis",
    SB: "Îles Salomon",
    TC: "Îles Turks et Caïques",
    VG: "Îles Vierges britanniques",
    VI: "Îles Vierges des États-Unis",
    IN: "Inde",
    ID: "Indonésie",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irlande",
    IS: "Islande",
    IL: "Israël",
    IT: "Italie",
    JM: "Jamaïque",
    JP: "Japon",
    JE: "Jersey",
    JO: "Jordanie",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KG: "Kirghizistan",
    KI: "Kiribati",
    KW: "Koweït",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Lettonie",
    LB: "Liban",
    LR: "Libéria",
    LY: "Libye",
    LI: "Liechtenstein",
    LT: "Lituanie",
    LU: "Luxembourg",
    MK: "Macédoine",
    MG: "Madagascar",
    MY: "Malaisie",
    MW: "Malawi",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malte",
    MA: "Maroc",
    MQ: "Martinique",
    MU: "Maurice",
    MR: "Mauritanie",
    YT: "Mayotte",
    MX: "Mexique",
    MD: "Moldavie",
    MC: "Monaco",
    MN: "Mongolie",
    ME: "Monténégro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibie",
    NR: "Nauru",
    NP: "Népal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigéria",
    NU: "Niue",
    NO: "Norvège",
    NC: "Nouvelle-Calédonie",
    NZ: "Nouvelle-Zélande",
    OM: "Oman",
    UG: "Ouganda",
    UZ: "Ouzbékistan",
    PK: "Pakistan",
    PW: "Palaos",
    PA: "Panama",
    PG: "Papouasie-Nouvelle-Guinée",
    PY: "Paraguay",
    NL: "Pays-Bas",
    PE: "Pérou",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Pologne",
    PF: "Polynésie française",
    PR: "Porto Rico",
    PT: "Portugal",
    QA: "Qatar",
    HK: "R.A.S. chinoise de Hong Kong",
    MO: "R.A.S. chinoise de Macao",
    QO: "Régions éloignées de l’Océanie",
    CF: "République centrafricaine",
    CD: "République démocratique du Congo",
    DO: "République dominicaine",
    CZ: "République tchèque",
    RE: "Réunion",
    RO: "Roumanie",
    GB: "Royaume-Uni",
    RU: "Russie",
    RW: "Rwanda",
    EH: "Sahara occidental",
    BL: "Saint-Barthélemy",
    KN: "Saint-Kitts-et-Nevis",
    SM: "Saint-Marin",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "Saint-Vincent-et-les Grenadines",
    SH: "Sainte-Hélène",
    LC: "Sainte-Lucie",
    WS: "Samoa",
    AS: "Samoa américaines",
    ST: "Sao Tomé-et-Principe",
    SN: "Sénégal",
    RS: "Serbie",
    CS: "Serbie-et-Monténégro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapour",
    SK: "Slovaquie",
    SI: "Slovénie",
    SO: "Somalie",
    SD: "Soudan",
    LK: "Sri Lanka",
    SE: "Suède",
    CH: "Suisse",
    SR: "Suriname",
    SJ: "Svalbard et Île Jan Mayen",
    SZ: "Swaziland",
    SY: "Syrie",
    TJ: "Tadjikistan",
    TW: "Taïwan",
    TZ: "Tanzanie",
    TD: "Tchad",
    TF: "Terres australes et antarctiques françaises",
    IO: "Territoire britannique de l'océan Indien",
    PS: "Territoire palestinien",
    TH: "Thaïlande",
    TL: "Timor oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinité-et-Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunisie",
    TM: "Turkménistan",
    TR: "Turquie",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "Union européenne",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viêt Nam",
    WF: "Wallis-et-Futuna",
    YE: "Yémen",
    ZM: "Zambie",
    ZW: "Zimbabwe",
  },
  en: {
    //added variables
    new_orders_traduction: "Place a translation order ",
    new_orders_interprestation: "Place an interpretation order",
    order_asser_or_not: "Do you require a sworn or certified translation?",

    // trad_dashboard
    dashboard_evolution_year: "Evolution over the current year",
    dashboard_turnover: "My turnover",
    dashboard_evolution_month: "Evolution during the month",
    dashboard_trad_month: "Translations this month",
    dashboard_interpretation_month: "Interpretations this month",
    dashboard_new_missions: "New tasks",
    dashboard_my_missions: "My current tasks",
    dashboard_command_date: "Order date:",
    dashboard_type: "Type:",
    dashboard_total_sells: "Total sales since registration",
    dashboard_doc_type: "Most translated document type",
    dashboard_my_clients: "My recurring customers",
    dashboard_trad: "Translation",
    dashboard_interpretation: "Interpretation",
    dashboard_particulier: "Individuals",
    dashboard_pro: "Professional",
    dashboard_traductor_asser: "Sworn / certified translation",
    dashboard_traductor_classic: "Classic translation",
    dashboard_prestation_lang: "My services by language",
    dashboard_status: "Status :",
    dashboard_month_janv: "Jan",
    dashboard_month_fevr: "Feb",
    dashboard_month_mars: "Mar",
    dashboard_month_avr: "Apr",
    dashboard_month_mai: "May",
    dashboard_month_juin: "June",
    dashboard_month_juill: "July",
    dashboard_month_aout: "Aug",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Oct",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dec",

    // Pro dashboard
    new_orders_traduction: "Order a translation",
    new_orders_interprestation: "Order an interpretation",
    order_asser_or_not: "Do you need a sworn/certified translation?",
    select: "Choose",
    traduction_type: "Type of translation",
    find_interpertor: "Find an interpreter",
    page_number: "Number of pages",
    order_interprete_delegate: "Interpreters",
    facture_echeance: "Due on",
    facture_conditions: "Payment",
    facture_comptant: "Upfront",
    facture_adress: "Billing Address",
    facture_pro: "Professional",
    facture_qte: "Qty",
    facture_price: "Unit",
    facture_unitaire: "Price",
    facture_montant: "Amount",
    facute_solde: "Total due",
    facture_tva_fr: "FR VAT 20%",
    dashboard_pro_asser: "Sworn/certified translation orders",
    dashboard_pro_classic: "Classic translation orders",
    dashboard_pro_inter: "Interpretation orders",
    dashboard_pro_as_of: "as of",
    dashboard_compared_last_month: "compared ot last month",
    dashboard_pro_evolution_month: "Evolution during the month",
    dashboard_pro_budget_month: "Budget for",
    dashboard_pro_budget: "Amounts deducted",
    dashboard_pro_amount_month: "Last amounts deducted",
    dashboard_pro_no_amount_month: "No amount to be deducted at the moment",
    dashboard_pro_amount: "Amounts deducted from the budget",
    dashboard_pro_amount_left: "Left",
    dashboard_pro_amount_detail: "Details of the amounts already deducted",
    dashboard_pro_amount_btn: "Details of the amounts",
    dashboard_pro_command: "Details of pending orders",
    dashboard_pro_command_date: "Order date:",
    dashboard_pro_command_status: "Status:",
    dashboard_pro_no_command: "There are no orders at the moment",
    dashboard_pro_command_company: "Orders made by my company",
    dashboard_pro_command_company_date: "Placed on",
    dashboard_pro_command_company_priceHT: "Price (excl. VAT):",
    dashboard_pro_command_company_type: "Type:",
    dashboard_pro_command_company_status: "Status:",
    dashboard_pro_no_command_company:
      "There are no orders made by your collegues at the moment",
    dashboard_pro_command_year: "Number of orders per year",

    // pro
    online: "Stay logged in",
    login_caption_title_pro: "Hierø - Professional Interface",
    login_main_title_pro: "Professional Interface",
    login_main_subtitle_pro: "Access your professional Hierø interface !",
    signup_caption_title_pro: "Hierø - Professional Interface",
    signup_main_subtitle_pro: "To join us, please fill out this form !",
    agency_setup_title_pro: "About your company",
    agency_setup_explanation_pro:
      "Find a sworn translator as soon as possible with our solution. You can then have your documents translated as soon as possible!",
    main_header_subtitle_pro: "Professional Interface",
    main_left_menu_title_pro: "Professional Account",
    main_left_menu_section_navigation_order: "My orders",
    order_id: "Id",
    order_language_from: "From",
    order_language_to: "To",
    order_status: "Statut",
    status_orders: "Order status",
    final_intervention: "Finalize the intervention",
    finaliser_commande: "Complete the order",
    order_traductor_delegate: "Translators",
    new_order: "Order a translation",
    new_order_ass: "Order a sworn translation",
    section_order_title: "My orders",
    state_pro_wait: "Pending",
    sold: "Balance ",
    account_expl_pro_cheque: "Please send a check to ...",
    account_expl_pro_viremement: "Wire transfer ",
    account_expl_pro_iban: "IBAN GB 000",
    account_hist_pro_date: "Date of Intervention",
    account_hist_pro_debit: "Debit ",
    account_hist_pro_credit: "Credit ",
    account_hist_pro_way: "Means ",
    account_hist_pro_ref: "Reference ",
    account_budget: "Budget ",
    account_budget_placeholder: "",
    account_budget_help: "",
    tasks_price_ttc: "Price (incl.)",

    order_no_asser_document_name: "Name of the document",
    no_traductor: "No translator available! ",
    no_traductor_message: "Close the window and try again",
    loading: "In progress...",
    menu: "Choose the type of translation",
    menu_label_traductor_asser: "Sworn translation",
    menu_label_traductor_classic: "Classic translation",
    menu_label_interprestation: "Interpretation",
    menu_label_all: "Show all",

    message_no_budget: "You currently have a budget of ",
    message_contact: "Should you want to update it, please contact us.",
    label_origin_lang: "Source Language",
    select_choose: "Choose...",
    find_traductors: "Find a translator",
    urgency: "Delivery time requested for your translation",
    section_card_title: "Subscription",

    alert_form_error_pages_number: "The number of pages can't be negative.",

    agency_pro_budget_help: "The budget you wish to use for your translations.",

    //Subscription's translate
    sub_training: "Team training ",
    sub_search: "Immediate translator / interpreter research ",
    sub_geo: "Translator / interpreter geolocation ",
    sub_dem: "Digital account ",
    sub_save: "File storage and management ",
    sub_recap: "Monthly order recap ",
    sub_proj: "Live project progression ",
    sub_management: "Live budget management ",
    sub_message: "Messaging service",
    sub_send: "Delivery of your translations by post ",
    sub_impl: "Full budget management ",
    sub_account: "Account manager ",
    sub_orientation:
      "Custom orientation, depending on the most requested languages",
    sub_split:
      "Division of the budget within several departments, depending on its users ",
    sub_event: "Delivery of your translations by courier ",
    choose: "Choose",
    sub_function: "Features",
    sub_standard: "Standard",
    sub_essen: "Essential",
    sub_premium: "Premium",
    sub_month: "month",
    sub_subscription: "Subscription",
    sub_associations_label: "Non-Profit Organizations",
    sub_cabinet_label: "Law firms",
    sub_bank_label: "International Mobility",
    sub_academia_label: "Academic institutions",
    sub_who_are_you: "Are you…",
    sub_explanation_academia:
      "We believe a partnership would be better for academic institutions such as universities, higher education institutions, etc. ",
    sub_explanation_systeme: "You can select one of our 2 propositions: ",
    sub_system_one:
      "Using our mobile application if your students cover their own costs.",
    sub_system_two:
      "Negotiating the implementation of a specific account if your students don’t cover their own costs. ",
    sub_credit_card_message:
      "Activate your services by choosing your subscription. In the tab",

    order_no_asser_query_pdf_page_number:
      "Does your document include more than one page ?",
    order_no_asser_query_pdf_page_number_yes:
      "Enter the number of pages in the document",

    agree: "By clicking, you agree to",
    our_term: "our terms ",
    and_the: "and the",
    stripe_agreement: " Stripe Connected Account Agreement. ",

    //new_order_ass: "Order a proofreading",
    menu_label_relecture: "Proofreading",
    sub_credit_card_message:
      "To activate your services, please proceed to the payment of your subscription fee. To do so, go to the 'My Subscription' tab",
    state_validated_pro: "Validated",
    /*
    created_at: "Subscribed on ",
		updated_at: "Updated on ",
		validate_credit_card_message: "Your order is currently being processed",
    */
    created_at: "Created on ",
    updated_at: "Updated on ",
    validate_credit_card_message: "Your order has been processed. ",
    assermente: "Sworn / Certified",

    subscriber_choose: "Choose a subscription",
    main_left_menu_section_parameters_card: "My subscription",
    services_none_yet_subscribe: "Subscribe to activate your services",

    asser_placeholder: "Are you a sworn translator?",
    agency_expl_4_expl:
      "To use our services, we need to confirm you are indeed a sworn translator.",
    agency_expl_4_expl_1: "You can send any supporting documents by ",
    agency_expl_4_expl_2:
      "Should you have any questions regarding this procedure, please contact us.",

    label_assermentation: "Sworn translation",
    find_out_more: "Find out more",
    description: "Description",

    test: "",

    //end pro
    login_caption_title: "Hierø - Translator Interface",
    login_caption_subtitle:
      "A software designed by translators for translators.",
    login_main_logo: "Hierø",
    login_main_title: "Translator Interface",
    login_main_subtitle:
      "Access your tasks and the document editor using the Hierø translator interface!",
    login_main_connect: "Log in",
    login_main_email: "Email",
    login_main_email_placeholder: "Enter your email address",
    login_main_email_help: "Use your email address to log in.",
    login_main_password: "Password",
    login_main_password_placeholder: "Please enter your password.",
    login_main_password_help: "",
    login_main_button: "Log in",
    login_main_to_signup: "Do not have an account? Sign up here!",
    login_main_copyright: "© Hierø - HIERØ 2021",
    login_error_required_field: "Required field",
    login_error_invalid_email: "The email you provided is not valid.",
    login_error_email_not_found: "This email is not registered.",
    signup_caption_title: "Hierø - Translator Interface",
    signup_caption_subtitle: "Choose Hierø and increase your visibility!",
    signup_main_logo: "Hierø",
    signup_main_title: "Sign up",
    signup_main_subtitle:
      "Create your account, join our interface and become more visible to our users!",
    signup_main_email: "Email",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Password",
    signup_main_password_placeholder: "Mot de passe",
    signup_main_password_help: "",
    signup_main_confirm: "Confirm your password",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Surname",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Name",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Phone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Sign up!",
    signup_main_to_signup: "Already have an account? Log in",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Required field",
    signup_error_invalid_email: "The email you provided is not valid.",
    signup_error_email_not_found: "This email is not registered.",
    signup_error_min_password: "Minimum 8 characters",
    signup_error_max_password: "Maximum 30 characters",
    signup_error_passwords_do_not_match: "Your passwords do not match",

    login_forgot_password: "Forgot your password?",
    reset_password_main: "Reset your password",
    reset_password_email_title: "Email",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Send",
    reset_password_login: "Log in",
    reset_password_success:
      "Email sent successfully. Please check your email and click the link in order to set a new password.",
    reset_password_error_required: "Required field.",
    reset_password_error_invalid_email: "The email you provided is not valid.",
    reset_password_error_user_not_found: "This email is not registered.",

    main_header_title: "Hierø -",
    main_header_title_particulier:
      "Order your certified translations with Hierø ",
    main_header_subtitle: "Translator Interface",
    main_header_user_menu_logout: "Log out",
    main_left_menu_title_asser: "Sworn translation", //"Certified translator",
    main_left_menu_title: "translator",
    main_left_menu_section_navigation: "Menu",
    main_left_menu_section_accueil: "Home",
    main_left_menu_section_navigation_missions: "My tasks",
    main_left_menu_section_parameters: "Configuration",
    main_left_menu_section_compte: "Account",
    main_left_menu_section_parameters_agency: "My agency",
    main_left_menu_section_parameters_services: "My services",
    main_left_menu_section_parameters_user: "My Account",
    main_left_menu_section_parameters_support: "Assistance",
    agency_setup_title: "About your agency",
    agency_setup_explanation:
      "Hierø offers your services to clients close to your offices. <br>Please provide the name of your company and your address.<br>This information will be provided to clients while searching for a translator.",
    agency_setup_companyname: "Company name",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "The name of the company that will be shown in orders, invoices, etc...",
    agency_setup_address: "Address",
    agency_setup_siret: "Company Registration Number",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Enter your siret number if it is a business",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Start typing your address, then select the correct one in the dropdown menu.",
    agency_setup_addresscompl: "Address precisions",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Building, staircase, code, etc. When your clients pick up their documents, they must have all the necessary information.",
    agency_setup_tel: "Telephone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "The phone number to use to discuss with your clients.",
    agency_setup_email: "Email",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "The email address to use when corresponding with your clients. Please indicate a new email, should it not be your Hierø registration email.",
    agency_setup_email_help_pro:
      "The email address to be used when discussing with the Hierø team.",
    agency_setup_save: "Save",
    agency_setup_error_required_field: "Required field",
    agency_setup_error_invalid_email: "The email you provided is not valid.",
    agency_setup_error_valid_address:
      "You must select a valid address from the dropdown menu.",

    agency_address_not_found: "Your address was not found?",
    agency_address_title: "Manually enter your details",
    agency_address_address: "Address:",
    agency_address_number: "No.",
    agency_address_street: "Street",
    agency_address_code: "Postal code",
    agency_address_city: "City",
    agency_address_country: "Country",
    agency_address_coords: "GPS coordinates:",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancel",
    agency_address_validate: "Continue",

    //mamadou
    main_left_menu_section_navigation_dashboard: "Dashboard",
    main_menu_left_section_navigation_entreprise: "My company",
    main_menu_left_section_navigation_factures: "My invoices",
    main_menu_left_section_navigation_messages: "My messages",
    main_menu_left_section_navigation_messagerie: "Messaging service",
    main_menu_left_section_navigation_gestion_des_fichiers: "File Manager",
    main_menu_left_section_navigation_assistance: "Assistance",
    main_menu_left_section_navigation_ticket: "Open a ticket",
    main_menu_left_section_navigation_question: "Frequently asked questions",
    head_facture: "Invoice",
    section_date: "Date",
    section_numero_facture: "Invoice number",
    section_type_prestation: "Type of service",
    section_prestation: "Service",
    section_price: "Total",
    section_print: "Print",
    section_generate_facture: "Generate the invoice in PDF",
    section_consulter: "To consult",
    section_questions_frequentes: "Frequently Asked Questions",
    section_questions_profil_entreprise:
      "How does 'My profile' differ from 'My company'?",
    section_questions_paragraphe_one:
      "'My profile' is all about you. 'My company' includes each and every collaborator using a HIERØ account. Department managers can create new accounts for their collaborators and manage the budget given to every account.",
    section_questions_original:
      "I've already downloaded a digital version of my sworn translation. Why do I need an original copy?",
    section_questions_paragraphe_two:
      "The digital version of your translation has no legal value. You can't use it for any legal procedure requiring a sworn translation done by a sworn translator. The original copy of the translation is the only one accepted by competent authorities.",
    section_questions_tarducteur:
      "What happens when the translator I've chosen does not answer?",
    section_questions_paragraphe_three:
      "In theory, any translator appearing is available to take your order. They make take time to respond to a request. Should it happen, our software redirects the order to another translator available to take your translation/interpretation order.",
    section_questions_price_deplacement:
      "Are travel expenses included in the price suggested by the software?",
    section_questions_paragraphe_four:
      "Each interpreter will charge a different amount depending on the distance they agree to travel. That amount will always be included in the price suggested by the software. The exact amount is mentionned in the details of your order.",
    section_questions_gestions_files: "What is a File Manager?",
    section_questions_paragraphe_five:
      "Our File Manager centralizes each and every file you use, you can also download them at any time. They can be categorized by type, by date, etc... You can also create folders to file them.",
    section_questions_contact:
      "Can I contact the translator if I have any question of want any details on the translation?",
    section_questions_paragraphe_six:
      "Absolutely! We provide a messaging service accessible when clicking on your order, or by clicking on the tab 'Messaging Service'. You can use the chat to discuss where you'd like to receive your original copies for example.",
    section_questions_traductions:
      "Why is there a difference between the services provided for sworn and classic translations?",
    section_questions_paragraphe_seven:
      "Classic documents are quite random in their form, our algorithm therefore can't generate a specific template. The client (you) provides the document, which the translator can download when you access the details of your order. Once the translation is done, the translator sends it back to the client.",
    section_questions_budget:
      "How can we manage the budget we use for our orders?",
    section_questions_paragraphe_eight:
      "Your company or your department decides on a certain budget for all orders. Your manager is in charge of all movements. He/she must either credit or debit the account.",
    agency_support: "Technical support",
    agency_support_service_client: "Phone number - Customer Service",
    agency_support_ouverture: "Open Monday through Friday, from 9AM to 5:30PM",
    agency_support_questions:
      "Do you have any questions, a technical bug to report? Contact us via email",
    agency_support_guide: "Our professional account user guide",
    agency_support_guide_p:
      "You've missed a step? You'd like to know exactly how our software works? Take a look at our user guide! ",
    agency_support_download_guide: "Download our user guide",
    agency_ticket_title: "My previous tickets",
    agency_ticket_objet: "Topic",
    agency_ticket_question: "Your question",
    agency_ticket_state: "Status",
    agency_ticket_head:
      "Need help? If you can't find the answer you were looking for in our FAQ, please directly ask us your questions. We'll answer as soon as possible!",
    my_info: "My details",
    geolocation_paragraphe:
      "The software needs to know your location so it can accurately search the closest translator to take your order. ",
    update_password: "Change my password ",
    delete_account: "Delete my account",
    account_paragraphe1: "You no longer need to order translations? ",
    account_paragraphe2: " You no longer wish to use our services?",
    account_paragraphe3: "You can unsubscribe at any time.",
    account_delete: "I want to delete my account ",
    account_upt: "You can change your password as you wish ",
    send: "Send",
    message_paragraphe:
      "Send messages directly to your translator for any information regarding your ongoing order.",
    consult_orders: "View my orders ",
    new_orders: "Place an order",
    table_header_archives: "Order history",
    state_accept_order: "Order accepted",
    head_particulier:
      "You may find the answer you're looking for in this page. If not, please open a ticket.",
    partuclier_trad_doc_head1:
      "The translator still hasn't delivered the translation. What can I do?",
    partuclier_trad_doc1:
      "You can contact your translator by using our messaging service or by contacting our technical support if he/she doesn't answer your messages. A translator must provide a valid reason for not delivering the translation on time.",
    partuclier_trad_doc_head2:
      "I already have the digital version of my certified translation... Why do I need the original copy?",
    partuclier_trad_doc2:
      "The digital version has no legal value. You can't use it for any legal procedure requiring a certified/sworn translation. The original version of the translation is the only one accepted by the competent authorities.",
    partuclier_trad_doc_head3:
      "What happens if the translator I've selected doesn't answer?",
    partuclier_trad_doc3:
      "In theory, all translators who appear on our system are available to translate your documents. They may take some time to repsond. In that case, our software will redirect your request to another translator available to take your order.",
    partuclier_trad_doc_head4:
      "I can't pick up my document from the translator in person.",
    partuclier_trad_doc4:
      "If you can't pick up the original version, the translator can always send you the documents by postal mail.",
    partuclier_trad_doc_head5:
      "The translation I've received seems to be of poor quality, wrong or incomplete.",
    partuclier_trad_doc5:
      "Keep the document as proof and contact your translator to get an explanation. If he/she refuses to make the changes, please contact our technical support for help.",
    partuclier_trad_doc_head6:
      "What if the software doesn't detect any translators nearby?",
    partuclier_trad_doc6:
      "There may not be a translator in the language you need, or the only translator available isn't close to your location. Should this happen, please contact our technical support or select a remote translator if no other is suggested.",
    agency_support_guide_pro: "Our user guide",
    discuss_encours: "Ongoing conversations",
    signup_main_text: "Order a certified translation with HIERØ",
    cdu: "By clicking on 'Register', you agree to our",
    cdu1: "general terms of use and sale",
    cdu2: "as well as our Privacy Policy",
    cdu3: " as well as",
    newsletter:
      "I agree to receive e-mail updates, special offers and news about the platform and/or other products developed by Hierø.",
    login_text:
      "With the Hierø interface, order your translations and select your sworn/certified translator in one click.",
    ticket_numero: "Ticket no.",
    intervention_time_date: "Date and time of the intervention",
    close_modal: "Close",
    inscription_date: "Registration date:",
    loading_order: "My current translation orders",
    view_all_orders: "View all my orders",
    last_payment: "My latest payments",
    view_all_payment: "View all my payments",
    last_send_msg: "Last messages sent",
    order_translate: "from",
    order_translate_to: "to ",
    price_unitaire: "Unit price (excl. VAT)",
    price_total_ht: "Total (excl. VAT)",
    montant_total_euro: "Total amount in EUR",
    rules_conditions: "TERMS OF PAYMENT",
    rules_conditions_p: "payment due upon receipt of the invoice",
    //end mamadou

    section_agency_title: "My agency",
    section_services_title: "My services",
    section_account_title: "My account",
    section_support_title: "Assistance",
    section_dashboard_title: "Dashboard",
    section_tasks_title: "My tasks",
    section_task_title: "Task",
    section_document_title: "Translate a document",
    notifications_new_task: "You have a new task!",
    notifications_task_confirmed:
      "Your task has just been confirmed. You can start translating.",
    notifications_task_validated: "Your client validated the task.",
    tasks_filters: "Filters",
    tasks_sent_on: "Sent on",
    tasks_src_lang: "Original language",
    tasks_src_country: "Original country",
    tasks_dst_country: "Translate to",
    tasks_state: "Order progress",
    tasks_price: "Price (excl.)",
    state_cancelled: "Canceled by the client",
    state_waiting_for_translator: "New!",
    state_waiting_for_payment: "Waiting for payment",
    state_refused_by_translator: "Mission refused",
    state_translating: "Translation in-progress",
    state_waiting_for_client_validation: "Done, waiting for client approval",
    state_validated: "Approved by client",
    prestation_breadcrumb_tasks: "My tasks",
    prestation_order_number: "Order number:",
    prestation_next_action_title: "Next steps",
    prestation_next_action_accept_order: "Accept the order",
    prestation_next_action_accept_order_expl:
      "Click here to accept the order. The client will then proceed with the payment. Once the payment has been completed, you can start the translation.",
    prestation_next_action_reject_order: "Refuse the order",
    prestation_next_action_reject_order_expl: "Click here to refuse the order.",
    prestation_next_action_rejected: "You refused the order.",
    prestation_next_action_waiting_for_payment:
      "Waiting for the client to make payment.",
    prestation_next_action_translate_expl:
      "Please proceed with the translation of each document in this order by clicking the 'Translate' button. When all documents are ready, click the 'Send to client' button. For any questions or details, you can chat directly with the client through our messaging service.",
    prestation_next_action_translate_all_done_expl:
      "You have finished all documents in this order.",
    prestation_next_action_translate_confirm: "Send to client for verification",
    prestation_next_action_waiting_for_validation:
      "The client is busy verifying the order.",
    prestation_next_action_validated: "The order was verified by the client.",
    prestation_order_details: "Order details:",
    prestation_order_from: "From",
    prestation_order_to: "to",
    prestation_order_country_origin: "Country of origin :",
    prestation_order_document_count: "Documents to translate :",
    prestation_order_price: "Price (excl.) :",
    prestation_order_date: "Order created on :",
    prestation_order_documents_title: "Documents to translate :",
    prestation_order_document_type: "Document type",
    prestation_order_document_originals: "Originals",
    prestation_order_document_translated: "Translated",
    prestation_order_document_not_yet_translated: "No translation yet!",
    prestation_order_document_translate: "Translate!",
    prestation_order_messaging_title: "Chat",
    prestation_order_messaging_send: "Send",
    prestation_order_messaging_client: "Customer :",
    prestation_order_messaging_me: "Me :",
    prestation_order_confirm_ok: "Ok",
    prestation_order_confirm_cancel: "Cancel",
    prestation_order_validate_title: "Confirm",
    prestation_order_validate_msg:
      "Are you sure you want to finalise this order? The client will receive a notification and will have access to the the translated documents.",
    prestation_order_accept_title: "Confirm",
    prestation_order_accept_msg: "Are you sure you wish to accept this order?",
    prestation_order_refuse_title: "Confirm",
    prestation_order_refuse_msg: "Are you sure you wish to refuse this order?",
    prestation_document_validate: "Finalise and save!",
    prestation_document_show_original: "Show the original in another tab :",
    prestation_document_edit: "Document editor",
    prestation_document_edit_expl:
      "The text in the field above is highlighted in the document below. When you modify the text above, the document will automatically be updated. Use the arrows to navigate between phrases in the document.",
    prestation_document_get_browser:
      "You do not see the translated PDF? Please use an up to date browser like",
    prestation_document_button_reset: "Reset the document",
    prestation_document_confirm_title: "Confirmation",
    prestation_document_confirm_message:
      "Are you sure you want to reset this document? You will lose all your changes!",
    prestation_document_confirm_oui: "Yes",
    prestation_document_confirm_non: "No",
    account_expl:
      "Please indicate your personal contact details in the event we need to get in touch.",
    account_save: "Save",
    account_surname: "Surname",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Name",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Telephone",
    account_telephone_placeholder: "",
    account_telephone_help: "",
    account_poste: "Position",
    account_poste_help: "",
    account_poste_placeholder: "Position",
    account_adresse: "Address",
    account_adresse_help: "",
    account_adresse_placeholder: "Address",
    account_service: "Department",
    account_service_help: "",
    account_service_placeholder: "Department",
    account_complement_adr: "Additional address details",
    account_complement_adr_help: "",
    account_complement_adr_placeholder: "Additional address details",
    account_email: "E-mail",
    account_email_help: "",
    account_email_placeholder: "E-mail",
    account_password: "Current password",
    account_password_help: "",
    account_new_password: "New password",
    account_new_password_help: "",
    account_error_required: "Required",
    agency_expl_1_title: "Business name",
    agency_expl_1_expl:
      "Specify your business name so that your clients can find you.",
    agency_expl_2_title: "Geolocation",
    agency_expl_2_expl:
      "The application needs your coordonates in order to put you in touch with clients close to you.",
    agency_expl_3_title: "Activate your account",
    agency_expl_3_expl:
      "Your profile will be visible once you have saved the information.",
    services_add_button: "Add a new offer...",
    services_none_yet:
      "In order to be visible in the Hierø app, you need to specify what kind of translations you provide.",
    services_add_expl:
      "Please select the language you can translate from and to, then click 'Add'.",
    services_add_src_lang: "Original language",
    services_dst_lang: "To",
    services_add: "Add!",
    services_error_add:
      "This combination of languages is not yet supported by Hierø. Please contact us for more information!",
    services_dont_see_title: "I can't see my languages.",
    services_dont_see_response:
      "Hierø facilitates your translations by providing ready-made templates for specific documents. We are continually adding to our database, so do not hesitate to contact us at <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> to add more languages.",
    services_list_title: "Your list of services",
    services_expl:
      "Click on each service in order to customize the documents you will accept. You can either choose not to handle certain document types by disabling the toggle, or you can modify the amount you charge for that document by filling in the field for that document type.",
    services_list_active: "Enabled",
    services_list_country: "Country",
    services_list_document_type: "Document type",
    services_list_average_price: "Average price (excl.)",
    services_list_your_price: "Your price (excl.)",
    services_list_your_price_reset: "Reset",
    services_list_delete: "Remove",
    support_title: "Our offices",
    support_company: "Hiero",
    support_addr1: "86 Rue de Paris",
    support_addr2: "91400 Orsay",
    support_addr3: "France",
    support_contact: "Contact",
    support_tel: "Tel: (+33) 1 80 38 30 46",
    support_tel_es: "Tel (es) : (+34) 911 23 88 87",
    support_tel_en: "Tel (en) : (+1) 929 274 5104",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Element not found",
    not_found_subtitle: "The page you requested does not exist",
    not_found_action: "Contact",
    not_found_action1: " if the problem persists.",
    not_found_home: "Home",

    // mamadou
    menu_label_interpretation: "Request an interpreter",
    menu_label_intervention: "The intervention is to be held",
    type_intervention: "Type of intervention required",
    menu_label_event_name: "Event name",
    nb_heures_estimee: "Estimate time (hours)",
    menu_label_sur_place: "On site",
    menu_label_visio: "By video conference",
    menu_langue: "Languages",
    menu_head_ticket: "My tickets",
    table_header_type_de_document: "Document type",
    table_header_nb_pages: "Number of pages",
    table_header_statut: "Status",
    table_header_archives: "Order history",
    table_hader_details: "Details",
    head_professionnal:
      "The software needs to know your location so it can find the closest translator for your order.",
    head_change_password: "You can change your password here, if you wish to.",
    professionnal_paragraph: "Amount deducted from your budget",
    professionnal_paragraph_1:
      "Every amount, for every order deducted from your budget",
    professionnal_paragraph_2: "Proceed to payment by bank transfer",
    professionnal_paragraph_3: "Proceed to payment by check",
    professionnal_paragraph_4: "Ask for a balance refund",
    address_facturation: "Change my billing address",
    consulter_facture: "View my invoices",
    facture_numero: "Invoice no.",
    recap_tva: "VAT SUMMARY",
    recap_taux: "RATE",
    recap_mt_tva: "VAT AMOUNT",
    recap_base_ht: "HT RATE",
    recap_tva_fr: "20% FR VAT ",
    client_facture_detail: "Customer",
    client_particulier_name: "Name",
    client_particulier_email: "Email",
    client_particulier_address: "Postal address",
    client_particulier_code_postal: "Zip code - CITY",
    salle_de_conference: "Conference room",
    joindre_salon: "Enter the room",
    prestation: "Service",
    commande_numero: "Order no.",
    date_commande: "Order date",
    commande_qte: "Quantity",
    commande_produit: "Product",
    commande_desc: "Description",
    montant_total: "Total amount",
    total_ht: "Total excl. VAT",
    total_tva: "Total VAT",
    total_ttc: "Total incl. VAT",
    retour_btn: "Go back",
    single_commande_paragraphe: " ",
    progress_commande: "Order progression",
    single_commande_p1:
      "The translator downloads the document for translation. ",
    single_commande_p2:
      "Once translated, he’ll add the translation to the order so you can validate the translation and confirm the order.",
    accept_intervention: "Intervention accepted ",
    load_intervention: "Intervention in process",
    delai_intervention: "Duration of the intervention",
    commande_statut: "Order status",
    commande_discuss: "Discuss directly with your translator.",
    commande_details: "Order details",
    intervention_details: "Details of the intervention",
    commande_receive: "Order received on",
    type_doc_commande: "Type of document requested",
    doc_a_traduire: "Document(s) to be translated",
    trad_asser: "Sworn translation",
    trad_name: "Name of the translator",
    capital_sasu: "SASU with a share capital of €7,000",
    rcs_infos: "Trade and Companies Register (RCS) of EVRY B 843 119 728 ",
    siret: " Company Registration Number 84311972800023",
    tva_fr: "VAT number: FR00843119728",
    condition_paragraphe_1: "Late-payment penalties (annual rate): 10%",
    condition_paragraphe_2: "No discount for early payment",
    condition_paragraphe_3:
      "Standard payment rate for recovery fees in the ent of late payment: €40",
    prestataire: "Service provider",
    pays_origine: "Country of origin",
    visioner_doc: "View the document",
    visioner: "View",
    auth_doc:
      "The translator must check the authenticity of your document before beginning the translation.",
    doc_bug:
      "Should there be an issue, the translator can contact you directly by using our messaging service.",
    infos_interv: "Additional information about this intervention",
    add_file: "Attach a file ",
    infos_entrep: "",
    infos_commandes: " ",
    action: "Action",
    client: "Customer",
    hours: "Time",
    join_room: "Enter the room",
    rooms: "Rooms",
    warning: "Warning!",
    temps_ex: "Estimated time",
    question_inter: "Are you an interpreter?",
    question_inter_price: "Rate per hour:",
    question_inter_1:
      "You agree to carry out interpretation missions on our software for",
    calendar: "Schedule",
    calendar_hidden: "Hide my schedule",
    conf: "Conference",
    warning_1: "You must pay attention to the correct spelling of each room.",
    interpretation_label: "Interpretation",
    interpretation_label_menu: "Interpretation",
    interpretation_info: "Rules",
    interpretation_paragraphe_1:
      " To begin your interpretation mission, you need to open two different tabs. You'll be with the interpreter in the first room, and all participants in the second room.",
    interpretation_paragraphe_2:
      " To access the conference room, the name to enter is",
    interpretation_paragraphe_4:
      " To access the interpretation room, the name to enter is",
    interpretation_paragraphe_3:
      " . Every participant attending the conference will be in this room, listening.",
    interpretation_paragraphe_5:
      " . Every speaker attending the conference will be with you in this room. The name of this room is likely to be the name of your client.",
    interpretation_paragraphe_6:
      " To carry out this interpretation mission correctly, this remote conference requires that the speaker and the interpreter do not speak at the same time, in the same room.",
    interpretation_paragraphe_7:
      " That's why there are two different rooms. All participants will only hear your interpretation in the conference room while you'll only hear the speaker in the interpretation room.",
    interpretation_paragraphe_8:
      " For performance reasons, the maximum capacity in a room is 50 participants. You can create additional rooms depending on your audience. Then you can give the name of each room to your interpreter.",
    interpretation_paragraphe_9:
      "To invite new participants, click on the following button to copy the invitation link. Then paste it in your email. Don't forget to check the spelling of the room name before sending your message.",
    interpretation_paragraphe_10: "Click here to copy the invitation link",
    interpretation_paragraphe_11:
      "Click here to invite participants or speakers.",
    room_conf: "Conference room",
    room_inter: "Interpretation room",
    room_inter_pro: "Your username",
    commander_interprete: "Order an interpreter",
    particulier_name: "Name",
    traduction_assermentee: "Sworn / Certified Translation",
    traduction_classic: "Classic translation",
    interpretation: "Interpretation",
    once_page_price: "Rate per page",
    once_page_price_default: "Default rate (excl. VAT)",
    once_page_information:
      "Please provide your rate for the translation of one page (250 words). This rate will be presented to the client if the document he wishes to translate isn't available on the software.",
    copy_alert: "Copied!",

    //end mamadou

    AF: "Afghanistan",
    ZA: "South Africa",
    AL: "Albania",
    DZ: "Algeria",
    DE: "Germany",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AN: "Netherlands Antilles",
    SA: "Saudi Arabia",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    EA: "Ceuta and Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Cyprus",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo-Brazzaville",
    KP: "North Korea",
    KR: "South Korea",
    CR: "Costa Rica",
    CI: "Ivory Coast",
    HR: "Croatia",
    CU: "Cuba",
    DK: "Denmark",
    DG: "Diego Garcia",
    DJ: "Djibouti",
    DM: "Dominica",
    EG: "Egypt",
    SV: "El Salvador",
    AE: "United Arab Emirates",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "Spain",
    EE: "Estonia",
    VA: "Vatican",
    FM: "Micronesia",
    US: "United States of America",
    ET: "Ethiopia",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    GS: "South Georgia and the South Sandwich Islands",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GD: "Granada",
    GL: "Greenland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Equatorial Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    GF: "French Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Hungary",
    BV: "Bouvet Island",
    CX: "Christmas Island",
    CP: "Clipperton Island",
    AC: "Ascension Island",
    IM: "Isle of Man",
    NF: "Norfolk Island",
    AX: "Åland Islands",
    KY: "Cayman Islands",
    IC: "Canary Islands",
    CC: "Cocos (Keeling) Islands",
    CK: "Cook Islands",
    FO: "Faroe Islands",
    HM: "Heard Island and McDonald Islands",
    FK: "Falkland Islands",
    MP: "Northern Mariana Islands",
    MH: "Marshall Islands",
    UM: "United States Minor Outlying Islands",
    SB: "Solomon Islands",
    TC: "Turks and Caicos Islands",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    IN: "India",
    ID: "Indonesia",
    IQ: "Iraq",
    IR: "Iran",
    IE: "Ireland",
    IS: "Iceland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Latvia",
    LB: "Lebanon",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malaysia",
    MW: "Malawi",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MA: "Morocco",
    MQ: "Martinique",
    MU: "Mauritius",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "Mexico",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Norway",
    NC: "New Caledonia",
    NZ: "New Zealand",
    OM: "Oman",
    UG: "Uganda",
    UZ: "Uzbekistan",
    PK: "Pakistan",
    PW: "Palau",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    NL: "Netherlands",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PF: "French Polynesia",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Qatar",
    HK: "Hong Kong",
    MO: "Macao",
    QO: "Remote Oceania",
    CF: "Central African Republic",
    CD: "Democratic Republic of the Congo",
    DO: "Dominican Republic",
    CZ: "Czech Republic",
    RE: "Reunion",
    RO: "Romania",
    GB: "United Kingdom",
    RU: "Russia",
    RW: "Rwanda",
    EH: "Western Sahara",
    BL: "Saint Barthélemy",
    KN: "Saint Kitts and Nevis",
    SM: "San Marino",
    MF: "Saint Martin Island",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    SH: "Saint Helena",
    LC: "Saint Lucia",
    WS: "Samoa",
    AS: "American Samoa",
    ST: "São Tomé and Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia and Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SO: "Somalia",
    SD: "Sudan",
    LK: "Sri Lanka",
    SE: "Sweden",
    CH: "Switzerland",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SY: "Syria",
    TJ: "Tajikistan",
    TW: "Taiwan",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "French Southern and Antarctic Lands",
    IO: "British Indian Ocean Territory",
    PS: "Palestinian territories",
    TH: "Thailand",
    TL: "East Timor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunisia",
    TM: "Turkmenistan",
    TR: "Turkey",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "European Union",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis and Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  es: {
    // Added variables
    new_orders_traduction: "Pedir una traducción",
    new_orders_interprestation: "Pedir una interpretación",
    order_asser_or_not: "¿Necesitas una traducción jurada?",

    // trad_dashboard
    dashboard_evolution_year: "Evolución durante el año en curso",
    dashboard_turnover: "Mi volumen de negocios",
    dashboard_evolution_month: "Evolución durante el mes",
    dashboard_trad_month: "Traducciones del mes",
    dashboard_interpretation_month: "Interpretaciones del mes",
    dashboard_new_missions: "Nuevas misiones",
    dashboard_my_missions: "Mis misiones actuales",
    dashboard_command_date: "Fecha del pedido :",
    dashboard_type: "Tipo:",
    dashboard_total_sells: "Ventas totales desde mi registro",
    dashboard_doc_type: "Tipo de documento más traducido",
    dashboard_my_clients: "Mis clientes recurrentes",
    dashboard_trad: "Traducción",
    dashboard_interpretation: "Interpretación",
    dashboard_particulier: "Individuos",
    dashboard_pro: "Profesional",
    dashboard_traductor_asser: "Traducción jurada",
    dashboard_traductor_classic: "Traducción clásica",
    dashboard_prestation_lang: "Mis servicios por idioma",
    dashboard_status: "Estatus :",
    dashboard_month_janv: "Ene",
    dashboard_month_fevr: "Feb",
    dashboard_month_mars: "Mar",
    dashboard_month_avr: "Abr",
    dashboard_month_mai: "Mayo",
    dashboard_month_juin: "Jun",
    dashboard_month_juill: "Jul",
    dashboard_month_aout: "Ago",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Oct",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dic",

    //pro dasboard

    new_orders_traduction: "Solicitar una traducción",
    new_orders_interprestation: "Solicitar una interpretación",
    order_asser_or_not: "¿Necesitas una traducción jurada/certificada?",
    select: "Elegir",
    traduction_type: "Tipo de traducción",
    find_interpertor: "Buscar un intérprete",
    page_number: "Número de páginas",
    order_interprete_delegate: "Intérpretes",
    facture_echeance: "Vencimiento",
    facture_conditions: "Condiciones de pago",
    facture_comptant: "En efeectivo",
    facture_adress: "Enviar a",
    facture_pro: "Profesional",
    facture_qte: "Cantidad",
    facture_price: "Precio",
    facture_unitaire: "Unitario",
    facture_montant: "Importe",
    facute_solde: "Total",
    facture_tva_fr: "IVA FR 20%",
    dashboard_pro_asser: "Solicitudes de traducción jurada",
    dashboard_pro_classic: "Solicitudes de traducción clásica",
    dashboard_pro_inter: "Solicitudes de interpretación",
    dashboard_pro_as_of: "al",
    dashboard_compared_last_month: "comparado al mes pasado",
    dashboard_pro_evolution_month: "Evolución durante el mes",
    dashboard_pro_budget_month: "Presupuesto para el mes de",
    dashboard_pro_budget: "Importe deducido",
    dashboard_pro_amount_month: "Últimos importes deducidos",
    dashboard_pro_no_amount_month: "No hay importe a deducir por el momento",
    dashboard_pro_amount: "Importes deducidos deducidos del presupuesto",
    dashboard_pro_amount_left: "Resto",
    dashboard_pro_amount_detail: "Detalles de los importes deducidos",
    dashboard_pro_amount_btn: "Detalles de los importes",
    dashboard_pro_command: "Detalles de los pedidos en curso",
    dashboard_pro_command_date: "Fecha de pedido:",
    dashboard_pro_command_status: "Estado:",
    dashboard_pro_no_command: "No hay pedidos en este momento",
    dashboard_pro_command_company: "Pedidos realizados por mi empresa",
    dashboard_pro_command_company_date: "Solicitado el",
    dashboard_pro_command_company_priceHT: "Precio (sin IVA):",
    dashboard_pro_command_company_type: "Tipo:",
    dashboard_pro_command_company_status: "Estado:",
    dashboard_pro_no_command_company:
      "No hay pedidos realizados por sus colegas en este momento",
    dashboard_pro_command_year: "Número de pedidos por año",

    // espace pro
    online: "quedar conectado",
    login_caption_title_pro: "Hierø - Interfaz profesional",
    login_main_title_pro: "Interfaz profesional",
    login_main_subtitle_pro: "¡Acceder a su interaz profesional Hierø!",
    signup_caption_title_pro: "Hierø - Interfaz profesional",
    signup_main_subtitle_pro:
      "¡Cree su cuenta, únase a nuestra interfaz y encuentre el traductor más cercano!",
    agency_setup_title_pro: "A propósito de su empresa",
    agency_setup_explanation_pro:
      "Encuentre un traductor jurado rápidamente con nuestra solución. ¡Así podrá traducir tus documentos lo antes posible!",
    main_header_subtitle_pro: "Interfaz profesional",
    main_left_menu_title_pro: "Cuenta profesional",
    main_left_menu_section_navigation_order: "Mis pedidos",
    order_id: "Id",
    order_language_from: "Del",
    order_language_to: "Al",
    order_status: "Estatus",
    order_traductor_delegate: "Traductores",
    new_order: "Pedir una traducción",
    new_order_ass: "Pedir una traducción jurada",
    menu_label_relecture: "Relectura",
    sub_credit_card_message:
      "Paga tu suscripción para activar tus servicios. Para realizarlo, visita la página 'Mi suscripción'",
    state_validated_pro: "Validado",

    agree: "Al hacer clic, usted acepta",
    our_term: "nuestros términos ",
    and_the: "y el",
    stripe_agreement: " Contrato de Cuenta Conectada de Stripe. ",

    /* created_at: "Suscrito el",
    updated_at: "Actualizado el",
    validate_credit_card_message: "Tu pedido ha sido aceptada",
    */
    section_order_title: "Mis pedidos",
    state_pro_wait: "Pendiente",
    sold: "Saldo ",
    account_expl_pro_cheque: "Por favor envíe un cheque a Hiero",
    account_expl_pro_viremement: "Transferencias ",
    account_expl_pro_iban: "IBAN ES 000",
    account_hist_pro_date: "Fecha de la intervención",
    account_hist_pro_debit: "Débito ",
    account_hist_pro_credit: "Crédito ",
    account_hist_pro_way: "Medio ",
    account_hist_pro_ref: "Referencia ",
    account_budget: "Presupuesto ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Idioma de origen",
    select_choose: "Elegir...",
    find_traductors: "Buscar traductores",
    tasks_price_ttc: "Precio (IVA)",
    tasks_price_tva: "IVA",

    order_no_asser_document_name: "Nombre del documento",
    no_traductor: "¡Aún no hay traductores disponibles! ",
    no_traductor_message: "Cierre la ventana e intente nuevamente",
    loading: "Pendiente ...",
    menu: "Elegir el tipo de traducción",
    menu_label_traductor_asser: "Traducción jurada",
    menu_label_traductor_classic: "Traducción clásica",
    menu_label_interprestation: "Interpretación",
    menu_label_all: "Ver todo",
    message_no_budget: "Actualmente tiene un presupuesto de ",
    message_contact: "Póngase en contacto con nosotros para modificarlo.",
    section_card_title: "Suscripción",
    alert_form_error_pages_number:
      "El número de páginas no puede ser negativo.",
    agency_pro_budget_help:
      "El presupuesto que desea asignar a sus traducciones.",

    //Subscription's translate
    sub_training: "Formación de equipo ",
    sub_search: "Búsqueda automática de traductor/intérprete ",
    sub_geo: "Geolocalización del traductor y/o del intérprete ",
    sub_dem: "Desmaterialización de todos tus pedidos ",
    sub_save: "Copia de seguridad de tus documentos con gestión de archivos ",
    sub_recap: "Resumen de tus pedidos por mes",
    sub_proj: "Progreso de tus proyectos en tiempo real ",
    sub_management: "Gestión de presupuesto en tiempo real ",
    sub_message: "Mensajería automática",
    sub_send: "Entrega de tus traducciones por correo ",
    sub_impl: "Implementación del presupuesto asignado para 12 meses ",
    sub_account: "Account manager a tu lado ",
    sub_orientation:
      "Orientación personalizada según los idiomas más solicitados",
    sub_split:
      "División del presupuesto en varios departamentos según los usuarios ",
    sub_event: "Entrega de tus traducciones por mensajero ",
    choose: "Elegir",
    sub_function: "Características",
    sub_standard: "Estándar",
    sub_essen: "Esencial",
    sub_premium: "Premium",
    sub_month: "mes",
    sub_subscription: "Suscripción",
    sub_associations_label: "Asociaciones",
    sub_cabinet_label: "Despachos de abogados",
    sub_bank_label: "Movilidad internacional",
    sub_academia_label: "Instituciones académicas",
    sub_who_are_you: "¿Eres…?",
    sub_explanation_academia:
      "Queremos trabajar en colaboración con las instituciones académicas, como universidades.",
    sub_explanation_systeme: "Ofrecemos dos sistemas diferentes: ",
    sub_system_one:
      "Utilizar nuestra aplicación móvil s la institución académica no cubre las traducciones.",
    sub_system_two:
      "Negociar la implantación de nuestro espacio profesional si la institución académica cubre las traducciones.",
    sub_credit_card_message:
      " Debes pagar su suscripción para activar tus servicios. En la pestaña",
    agree: "Al hacer clic, aceptará nuestras ",
    our_term: "condiciones de uso y de venta",
    and_the: "y el",
    stripe_agreement: " Contrato de Cuenta Conectada de Stripe. ",
    created_at: "Enviado el ",
    updated_at: "Actualizado el ",
    validate_credit_card_message: "Tu pedido ha sido procesado.",
    assermente: "Jurado(a)",
    subscriber_choose: "Elegir una suscripción",

    urgency: "Plazo de entrega solicitado para esta traducción",
    order_no_asser_query_pdf_page_number:
      "¿Su documento contiene más de una página?",
    order_no_asser_query_pdf_page_number_yes:
      "Indique el número de páginas del documento",

    main_left_menu_section_parameters_card: "Mi suscripción",
    subscriber_choose: "Elija tu suscripción. ",
    services_none_yet_subscribe: "Suscríbete para activar tus servicios",

    asser_placeholder: "¿Eres un(a) traductor(a) jurado(a)?",
    agency_expl_4_expl:
      "Antes de utilizar nuestros servicios es necesario que nos envíes el documento que acredite que eres un(a) traductor(a) jurado(a) realizado por el organismo competente",
    agency_expl_4_expl_1: "Puedes enviarlo por",
    agency_expl_4_expl_2:
      "Si tienes alguna pregunta sobre este procedimiento, no dude en ponerse en contacto con nosotros.",
    label_assermentation: "Traducción jurada",
    find_out_more: "Más información",
    description: "Descripción",

    test: "", // delimite

    // end espace pro
    login_caption_title: "Hierø - Interfaz traductor",
    login_caption_subtitle:
      "Un programa informático diseñado por traductores para los traductores.",
    login_main_logo: "Hierø",
    login_main_title: "Interfaz del traductor",
    login_main_subtitle:
      "¡Accede a sus misiones y el editor de documentos con la interfaz traductor Hierø!",
    login_main_connect: "Conectarse",
    login_main_email: "Dirección electrónica",
    login_main_email_placeholder: "Indicar la dirección electrónica",
    login_main_email_help: "Inicie la sesión con su dirección electrónica",
    login_main_password: "Contraseña",
    login_main_password_placeholder: "Indicar su contraseña",
    login_main_password_help: "",
    login_main_button: "Conectarse",
    login_main_to_signup: "¿No dispone de una cuenta? ¡Registrarse!",
    login_main_copyright: "© Hierø - HIERØ 2020",
    login_error_required_field: "Campo obligatorio",
    login_error_invalid_email: "La dirección electrónica no es válida.",
    login_error_email_not_found: "La dirección electrónica no existe.",
    signup_caption_title: "Hierø - Interfaz del traductor",
    signup_caption_subtitle: "¡Elija Hierø y gane en visibilidad!",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscripción",
    signup_main_subtitle:
      "¡Cree su cuenta, únase a nuestra interfaz y hágase en visible para nuestros usuarios!",
    signup_main_email: "Dirección electrónica",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Contraseña",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmar vuestra contraseña",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Apellido",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Nombre",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Teléfono",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Suscribir",
    signup_main_to_signup: "¿Dispone de una cuenta? Conectarse",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Campo obligatorio",
    signup_error_invalid_email: "La dirección electrónica no es válida.",
    signup_error_email_not_found: "La dirección electrónica no existe.",
    signup_error_min_password: "Al menos 8 caracteres",
    signup_error_max_password: "30 caracteres máximo",
    signup_error_passwords_do_not_match: "Las contraseñas no coinciden",

    login_forgot_password: "¿Has olvidado la contraseña?",
    reset_password_main: "Reiniciar la contraseña ",
    reset_password_email_title: "Dirección electrónica",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conexión",
    reset_password_success:
      "Se ha enviado un correo a su dirección electrónica. Luego haga clic en el enlace para cambiar la contraseña.",
    reset_password_error_required: "Campo obligatorio",
    reset_password_error_invalid_email: "La dirección electrónica no es válida",
    reset_password_error_user_not_found: "La dirección electrónica no existe",

    main_header_title: "Hierø -",
    main_header_title_particulier:
      "Solicitar tus traducciones juradas con Hierø ",
    main_header_subtitle: "Interfaz del traductor",
    main_header_user_menu_logout: "Desconectar",
    main_left_menu_title_asser: "Traducción jurada",
    main_left_menu_title: "Traductor ",
    main_left_menu_section_navigation: "Menú de navegación",
    main_left_menu_section_accueil: "Inicio",
    main_left_menu_section_navigation_missions: "Mis misiones",
    main_left_menu_section_parameters: "Configuración",
    main_left_menu_section_compte: "Cuenta",
    main_left_menu_section_parameters_agency: "Mi empresa",
    main_left_menu_section_parameters_services: "Mis servicios",
    main_left_menu_section_parameters_user: "Mi cuenta",
    main_left_menu_section_parameters_support: "Soporte",
    agency_setup_title: "A propósito de su agencia",
    agency_setup_explanation:
      "Hierø ofrece sus servicios a los clientes cercanos de sus locales. <br> Por favor especifica su razón social y su dirección. <br>Se ofrecerá las informaciones a los clientes cuando busquen un traductor.",
    agency_setup_companyname: "Razón social",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "El nombre de la empresa a utilizar para los pedidos, las facturas, etc...",
    agency_setup_address: "Dirección",
    agency_setup_siret: "NIF",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Ingrese su número de siret si es una empresa",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Empieza a escribir la dirección y después selecciona la dirección adecuada en el menú desplegable.",
    agency_setup_addresscompl: "Complementos de dirección",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Edificio, escalera, código de acceso, etc. Cuando tus clientes recogen los documentos, necesitan todas las informaciones.",
    agency_setup_tel: "Teléfono",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "El número de teléfono a utilizar para conversar con tus clientes.",
    agency_setup_email: "Dirección electrónica",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "La dirección electrónica a utilizar para conversar con tus clientes. Por favor indica otra si no es la dirección de registro de Hierø.",
    agency_setup_email_help_pro:
      "El correo electrónico que se utilizará para corresponder con nuestros traductores y el equipo de Hierø.",
    agency_setup_save: "Guardar",
    agency_setup_error_required_field: "Campo obligatorio",
    agency_setup_error_invalid_email: "La dirección electrónica no es válida.",
    agency_setup_error_valid_address:
      "Por favor escoge una de las direcciones propuestas en el menú desplegable.",

    agency_address_not_found: "¿No se puede encontrar su dirección?",
    agency_address_title: "Introducir manualmente sus datos",
    agency_address_address: "Dirección :",
    agency_address_number: "N.°",
    agency_address_street: "Calle",
    agency_address_code: "Código postal",
    agency_address_city: "Ciudad",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS:",
    agency_address_coords_lat: "Latitud",
    agency_address_coords_long: "Longitud",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",

    //mamadou
    main_left_menu_section_navigation_dashboard: "Panel de control",
    main_menu_left_section_navigation_entreprise: "Mi empresa",
    main_menu_left_section_navigation_factures: "Mis facturas",
    main_menu_left_section_navigation_messages: "Mis mensajes",
    main_menu_left_section_navigation_messagerie: "Mis mensajes",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestión de archivos",
    main_menu_left_section_navigation_assistance: "Asistencia",
    main_menu_left_section_navigation_ticket: "Crear un tiquete",
    main_menu_left_section_navigation_question: "Preguntas frecuentes",
    head_facture: "Factura",
    section_date: "Fecha",
    section_numero_facture: "Factura",
    section_type_prestation: "Tipo de servicio",
    section_prestation: "Servicio",
    section_price: "Total",
    section_print: "Imprimir",
    section_generate_facture: "Generar la factura en PDF",
    section_consulter: "Consultar",
    section_questions_frequentes: "Preguntas frecuentes",
    section_questions_profil_entreprise:
      "¿Por qué diferenciar mi perfil y mi empresa?",
    section_questions_paragraphe_one:
      "Solo tu puedes acceder a tu perfil. 'Mi empresa' incluye a todos los empleados de tu empresa que utilicen el software. Sólo los directores de departamento pueden añadir nuevas cuentas y ocuparse de las modificaciones del presupuesto",
    section_questions_original:
      "Ya tengo la versión descargable de la traducción jurada... ¿Por qué obtener el original?",
    section_questions_paragraphe_two:
      "La versión descargable de la traducción no tiene ningun valor jurídico y, por tanto, no puede utilizarse para los procedimientos que requieren la traducción de un traductor jurado. La versión original de la traducción es la única aceptada por las autoridades competentes",
    section_questions_tarducteur:
      "¿Qué pasa si el traductor que he elegido no responde?",
    section_questions_paragraphe_three:
      "En teoría, los traductores que aparecen son los que están disponibles para atender su pedido. Puede ocurrir que algunos de ellos tarden en responder. En este caso, nuestro software le redirigirá a un traductor cercano que pueda ocuparse de su solicitud de traducción/interpretación.",
    section_questions_price_deplacement:
      "¿El precio de envio está incluido en el precio que aparece en el software?",
    section_questions_paragraphe_four:
      "Cada intérprete tendrá una cantidad diferente en función del número de kilómetros que acepte recorrer. En cualquier caso este coste estará incluido en el precio, encontrarás el valor exacto en los detalles del pedido",
    section_questions_gestions_files: "¿Qué es el gestor de archivos?",
    section_questions_paragraphe_five:
      "El gestor de archivos te permite encontrar todos tus archivos descargables en un solo lugar. Los archivos se pueden recuperar por tipo de archivo o por fecha de entrega. También puedes crear carpetas para almacenarlas",
    section_questions_contact:
      "¿Es posible ponerse en contacto con el traductor para hacer preguntas o conocer detalles sobre el servicio?",
    section_questions_paragraphe_six:
      "¡Absolutamente! Tienes acceso al chat cuando accedes a los detalles del pedido, o en la pestaña 'Servicio de mensajes'. También puedes utilizar el servicio de mensajería para concertar un lugar de destino para la entrega de documentos",
    section_questions_traductions:
      "¿Por qué el servicio de traducción clásica es diferente del servicio de traducción jurada?",
    section_questions_paragraphe_seven:
      "Al ser los documentos clásicos más aleatorios, el algoritmo no puede generar un modelo. El cliente transmite el documento, que puede descargarse al acceder a los detalles del pedido. Una vez traducido, el traductor lo carga y lo envía al cliente para que lo valide",
    section_questions_budget:
      "¿Cómo podemos gestionar el presupuesto de nuestros pedidos? ",
    section_questions_paragraphe_eight:
      "Tu empresa o departamento asigna un determinado presupuesto para todos tus pedidos. Partimos del principio que el responsable gestiona todos los movimientos del presupuesto. Por lo tanto, tiene que acreditar o debitar la cuenta utilizando la función apropiada",

    agency_support: "Asistencia técnica",
    agency_support_service_client: "Número de teléfono - Servicio al cliente",
    agency_support_ouverture:
      "Horario de atención de lunes a viernes, desde las 09:00 hasta las 17:30.",
    agency_support_questions:
      "¿Tienes una pregunta, has detectado algún error? Contáctanos por correo electrónico",
    agency_support_guide: "Guía del usuario profesional",
    agency_support_guide_p:
      "¿Te perdiste una etapa? ¿Quieres saber cómo funciona la plataforma Hierø? Descarga nuestra guía de usuario.",
    agency_support_download_guide: "Descargar nuestra guía",
    agency_ticket_title: "Mis tiquetes antiguos",
    agency_ticket_objet: "Tema",
    agency_ticket_question: "Su pregunta",
    agency_ticket_state: "Estatuto",
    agency_ticket_head:
      "¿Necesitas ayuda? ¿No has encontrado la respuesta que necesitas en las preguntas frecuentes? ¡Realiza todas tus preguntas, te responderemos lo antes posible!",
    my_info: "Mi información",
    geolocation_paragraphe:
      "El software necesita conocer tu ubicación para buscar traductores cerca de ti con precisiónn",
    update_password: "Cambiar mi contraseña",
    delete_account: "Suprimir mi cuenta",
    account_paragraphe1: " ¿Ya no es necesario solicitar traducciones? ",
    account_paragraphe2: " ¿Yo no quieres utilizar nuestros servicios?",
    account_paragraphe3: "Puedes suprimir tu cuenta en cualquier momento.",
    account_delete: "Quiero suprimir mi cuenta",
    account_upt: "Si quieres, puedes cambiar tu contraseña.",
    send: "enviado",
    message_paragraphe:
      "Puedes hablar con los traductores para obtener más información sobre el pedido en proceso. ",
    consult_orders: "Ver mis pedidos",
    new_orders: "Hacer un nuevo pedido",

    status_orders: "Estado del pedido",
    final_intervention: "Finalizar la intervención",
    finaliser_commande: "Finalizar el pedido",
    table_header_archives: "Archivo de pedidos",
    state_accept_order: "Pedido aceptado",
    head_particulier:
      "Puedes encontrar las respuestas a tus preguntas en esta página. En caso contratio, puedes abrir un ticket.",
    partuclier_trad_doc_head1:
      "El traductor no entregó mi documento. ¿Qué debo hacer?",
    partuclier_trad_doc1:
      "Puedes ponerte en contacto con el traductor utilisando la mensajeria o hablar con el soporte técnico si el traductor no responde a tus peticiones. El traductor debe darle una razón valida por la que no pudo entregar su documento en el plazo solicitado.",
    partuclier_trad_doc_head2:
      "Ya tengo la versión digital de la traducción jurada. ¿Porqué necesito el original?",
    partuclier_trad_doc2:
      "La versión digital de la traducción no tiene valor jurídico. Por lo tanto, no puede utilizarse para los procedimientos que requieren una traducción jurada. La versión original de la traduccion es la única aceptada por las autoridades competentes.",
    partuclier_trad_doc_head3:
      "¿Qué ocurre si el traductor que he elegido no responde?",
    partuclier_trad_doc3:
      "En teoría, los traductores que aparecen están disponibles para atender su pedido. Puede occurrir que algunos tarden en responder. Nuestro software dirigirá el pedido a un traductor cercano y disponible para atender su pedido de traducción/interpretación.",
    partuclier_trad_doc_head4: "No puedo recoger mi documento en persona.",
    partuclier_trad_doc4:
      "Si no es posible recoger personalmente el original, el traductor puede enviarte los documentos por correo ordinario.",
    partuclier_trad_doc_head5:
      "La traducción que he recibido parece ser de mala calidad, o incluso errónea o incompleta.",
    partuclier_trad_doc5:
      "Guarde el documento como prueba, póngate en contacto con el traductor para que te dé explicaciones. Si el traductor no quiere realizar cambios, póngata en contacto directamente con el servicio técnico.",
    partuclier_trad_doc_head6:
      "¿Y si el software no detecta ningún traductor cerca?",
    partuclier_trad_doc6:
      "Puede ocurrir que no hay ningún traductor (o cerca de tu posición) para el idioma que necesitas. Póngate en contacto con el servicio de asistencia técnica, o puedes seleccionar un traductor remoto si no hay otro.",
    agency_support_guide_pro: "La guía del usuario",
    discuss_encours: "Conversaciones en curso",
    signup_main_text: "Consigue tus traducciones juradas con HIERØ",
    cdu: "Al hacer clic en 'Registrarse', acepto las ",
    cdu1: "condiciones generales de uso y venta",
    cdu2: "y nuestra política de privacidad",
    cdu3: "así como",
    newsletter:
      "Acepto recibir por correo electrónico actualizaciones, ofertas especiales y noticias sobre la plataforma y/o otros productos desarrollados por Hierø ",
    login_text:
      "Con Hierø, pueder pedir tus traducciones y selectionar a tu traductor jurado en un solo clic.",
    ticket_numero: "Ticket no.",
    intervention_time_date: "Fecha y hora de la intervención",
    close_modal: "Cerrar",
    inscription_date: "Registrado desde ",
    loading_order: "Mis pedidos de traducción en curso",
    view_all_orders: "Ver todos los pedidos",
    last_payment: "Mis últimos pagos realizados",
    view_all_payment: "Ver todos mis pagos",
    last_send_msg: "Últimos mensajes enviados",
    order_translate: "del ",
    order_translate_to: "al",
    price_unitaire: " Precio unitario sin IVA",
    price_total_ht: "Total sin IVA",
    montant_total_euro: "Total en EUR",
    rules_conditions: "CONDICIONES DE PAGO",
    rules_conditions_p: "pago al recibir la factura",
    //end mamadou

    section_questions_translate_document:
      "¿Cómo se traduce un documento jurado?",
    section_questions_paragraphe_1:
      "El programa te permite traducir el documento directamente utilizando un esquema generado por su algoritmo. Puedes traducir el documento sección por sección, antes de descargarlo y guardarlo, y enviarlo a tu cliente para que lo valide.",
    section_questions_original:
      "¿Puede verificarse la autenticidad de los documentos?",
    section_questions_paragraphe_2:
      "Por supuesto. El cliente te envía el o los documento(s) para que revisarlos en cuanto recibes el pedido. Si observa algún error en el documento, o si tiene dudas sobre su valor real, puede discutirlo directamente con su cliente.",
    section_questions_traducteurs:
      "Tenemos que entregar una versión del original, salvo que se prefiera descargar la traducción...",
    section_questions_paragraphe_3:
      "El cliente puede descargar una versión digital de la traducción, pero sabemos que no tiene valor legal. Utilizamos la geolocalización para sugerir un traductor cercano al cliente para facilitar el desplazamiento y la entrega de la traducción en versión original con seguimiento de los documentos. ",
    section_questions_langues: "¿Cómo agregar los idiomas que practico?",
    section_questions_paragraphe_4:
      "En la pestaña 'Modificar mis servicios'. Para añadir tus servicios, sólo tienes que añadir tus idiomas y el tipo de servicio correspondiente (traducción o interpretación). No olvides seleccionar los tipos de documentos. Si tus idiomas no están disponibles, ponte en contacto con nosotros para que podamos activarlos ",
    section_questions_precision_trad:
      "No soy traductor o intérprete jurado, ¿cómo puedo explicarlo?",
    section_questions_paragraphe_5:
      "En la pestaña 'Mi perfil', puedes indicar si eres traductor/a jurado/a o no. Si no lo está, sólo se tendrá acceso a los servicios que le corresponden.",
    section_questions_contact_client:
      "¿Es posible contactar a mi cliente para hacer preguntas o retrasos en el servicio solicitado?",
    section_questions_paragraphe_6:
      "Por supuesto. Se puede acceder al sistema de mensajería cuando accedas a los detalles del pedido, o dirigiéndote a la pestaña 'Servicio de mensajería'. También puedes utilizar el servicio de mensajería para acordar un lugar de encuentro para la entrega de los documentos en persona si es necesario.",
    section_questions_traductions_doc:
      "¿Cómo funciona la traducción de un documento 'clásico'?",
    section_questions_paragraphe_7:
      "Como los documentos clásicos son más irregulares, el algoritmo no puede generar una plantilla. El cliente envía el documento que puede descargarse al acceder a los datos del pedido. Una vez traducido, sólo hay que cargarlo y enviarlo al cliente para su validación",
    section_questions_abonnement: "¿Deseo cancelar o cambiar mi suscripción?",
    section_questions_paragraphe_8:
      "Puedes cancelar tu suscripción o modificarla en cualquier momento dirigiéndote a 'Mi perfil'. Encontraras un resumen de tu suscripción actual, así como de las demás opciones. En caso de problemas, puedes ponerte en contacto con nosotros directamente.",
    menu_label_interpretation: "Solicitar una interpretación ",
    menu_label_intervention: "La intervención debe tener lugar en",
    type_intervention: "Tipo de intervención solicitada",
    menu_label_event_name: "Nombre del evento",
    nb_heures_estimee: "Horas estimadas",
    menu_label_sur_place: "En el lugar",
    menu_label_visio: "Por videoconferencia",
    menu_langue: "Idiomas",
    menu_head_ticket: "Mis tickets",
    table_header_type_de_document: "Tipo de documento",
    table_header_nb_pages: "Número de páginas",
    table_header_statut: "Estatus",
    table_header_archives: "Archivo de pedidos",
    table_hader_details: "Detalles",
    head_professionnal:
      "El software necesita conocer tu posición para buscar los traductores más cercanos con una mayor precisión",
    head_change_password: "Si lo deseas, puedes cambiar tu contraseña",
    professionnal_paragraph: "Cantidad descontada de mi presupuesto ",
    professionnal_paragraph_1:
      "Todos los importes de cada pedido, se descuentan de tu presupuesto",
    professionnal_paragraph_2: "Realizar una transferencia",
    professionnal_paragraph_3: "Enviar un chèque",
    professionnal_paragraph_4: "Solicitar el reembolso de mi saldo",
    address_facturation: " Cambiar mi dirección de facturación",
    consulter_facture: "Consultar mis facturas",
    facture_numero: "Factura N°",
    recap_tva: "RESUMEN DEL IVA",
    recap_taux: "TASA",
    recap_mt_tva: "TOTAL DEL IVA",
    recap_base_ht: "BASE SIN IVA",
    recap_tva_fr: "20% IVA FR",
    client_facture_detail: "Cliente",
    client_particulier_name: "Razón social",
    client_particulier_email: "Correo electrónico",
    client_particulier_address: "Dirección ",
    client_particulier_code_postal: "Ciudad",
    salle_de_conference: "Sala de conferencias",
    joindre_salon: "Entrar en la sala",
    prestation: "Servicio",
    commande_numero: "Nº de pedido°",
    date_commande: "Fecha del pedido",
    commande_qte: "Cantidad",
    commande_produit: "Producto",
    commande_desc: "Descripción",
    montant_total: "Importe total",
    total_ht: "Total antes de impuestos",
    total_tva: "Total con IVA",
    total_ttc: "Total, con IVA incluido",
    retour_btn: "Regresar",
    single_commande_paragraphe:
      "El traductor seleccionado ha aceptado tu pedido. Una vez terminada la traducción, deberás validar y confirmar. Si el traductor seleccionado rechaza el pedido, nuestro software enviará automáticamente tu pedido a otro traductor.",
    progress_commande: "Seguimiento del pedido",
    single_commande_p1: "El traductor descarga el documento para traducirlo. ",
    single_commande_p2:
      "Una vez traducido, lo añade al pedido para que puedes validar la traducción y confirmar el pedido.",
    accept_intervention: "Intervención aceptada",
    load_intervention: "Intervención en proceso",
    delai_intervention: "Duración de la intervención",
    commande_receive: "Pedido recibido el",
    type_doc_commande: "Tipo de documento solicitado",
    doc_a_traduire: " Documento a traducir",
    trad_asser: "Traductor jurado",
    trad_name: "Nombre del traductor",
    capital_sasu: "SASU con un capital social de 7 000,00 euros",
    rcs_infos: "RCS EVRY B 843 119 728 ",
    siret: " NIF 84311972800023",
    tva_fr: "Número de IVA: FR00843119728",
    condition_paragraphe_1: "Penalidad por retraso (tasa anual): 10%",
    condition_paragraphe_2: "No hay descuento por pago anticipado",
    condition_paragraphe_3:
      "Indemnización fija para los costes de cobro en caso de retraso en el pago: 40€",
    prestataire: "Proveedor",
    pays_origine: "País de origen",
    visioner_doc: "Ver el documento",
    visioner: "Ver",
    auth_doc:
      "El traductor verifica la autenticidad del documento antes de comenzar la traducción.",
    doc_bug:
      "En caso de problemas, el traductor puede contactarte directamente por correo electrónico.",
    infos_interv: "Información adicional sobre la participación",
    add_file: "Añadir un documento adjunto",
    infos_entrep: "",
    infos_commandes: "",
    action: "Acción",
    client: "Cliente",
    hours: "Horas",
    join_room: "Entrar en la sala",
    rooms: "Las salas (salas de reuniones)",
    warning: "¡Atención! ",
    temps_ex: "Tiempo estimado",
    question_inter: "¿Eres intérprete?",
    question_inter_price: "Precio de una hora:",
    question_inter_1:
      "Realizará tareas de interpretación en nuestro software para",
    calendar: " Calendario",
    calendar_hidden: "Ocultar calendario",
    conf: "La conferencia",
    warning_1: "Debe respetarse la ortografía de los nombres de las salas.",
    interpretation_label_menu: "Interpretación",
    interpretation_label: "Interpretación",
    interpretation_info: "Explicación",
    interpretation_paragraphe_1:
      "Para participar en la misión de interpretación, necesita al menos dos ventanas. En la primera, estarás con el intérprete, en la segunda con todos los participantes.",
    interpretation_paragraphe_2:
      "Para acceder a la sala de conferencias, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_4:
      "Para acceder a la sala de interpretación, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_3:
      ". Aquí se encuentran todos los participantes.",
    interpretation_paragraphe_5:
      ". Aquí se encuentran sus oradores y el intérprete seleccionado.",
    interpretation_paragraphe_6:
      "Para realizar correctamente el trabajo de interpretación de esta conferencia a distancia, es necesario que el orador y el intérprete no hablen al mismo tiempo en la misma sala.",
    interpretation_paragraphe_7:
      "Por eso están en dos salas diferentes. De este modo, los participantes sólo le oirán a usted en la sala de conferencias y usted sólo oirá a los oradores en la sala de interpretación.",
    interpretation_paragraphe_8:
      "Por razones de rendimiento, la capacidad máxima de una sala es de 50 participantes. Puede crear tantas salas correspondientes a su público como desee y comunicar los nombres de estas salas a su intérprete.",
    interpretation_paragraphe_9:
      "Para invitar a los participantes, haga clic en el siguiente botón para copiar el enlace de invitación y pegarlo en su mensaje respetando el nombre de la sala ",
    interpretation_paragraphe_10:
      "Haga clic aquí para copiar el enlace de la invitación ",
    interpretation_paragraphe_11:
      "Haga clic aquí para invitar a oradores o participantes ",
    room_conf: " Sala de conferencias",
    room_inter: "Sala de interpretación ",
    room_inter_pro: "Su nombre de usuario",
    once_page_price: "Sus tarifas en la página",
    once_page_price_default: "Precio predeterminado (SIN IVA)",
    once_page_information:
      "Le invitamos a indicar su tarifa para la traducción por página de un documento (250 palabras). Esta tarifa se ofrecerá al cliente si el documento que desea traducir no está registrado por nuestro sistema.",
    copy_alert: "¡Copiado!",
    commander_interprete: "Pedir una interpretación",
    particulier_name: "Nombre del individuo",
    traduction_assermentee: "Traducción jurada",
    traduction_classic: "Traducción clásica",
    interpretation: "Interpretación",

    //end mamadou
    section_agency_title: "Mi empresa",
    section_services_title: "Mis servicios",
    section_account_title: "Mi cuenta",
    section_support_title: "Soporte",
    section_dashboard_title: "Panel de control",
    section_tasks_title: "Mis misiones",
    section_task_title: "Efectuar una misión",
    section_document_title: "Traducir un documento",
    notifications_new_task: "¡Usted recibió una nueva misión!",
    notifications_task_confirmed:
      "La misión fue confirmada. Usted puede empezar la traducción.",
    notifications_task_validated: "El cliente validó la misión.",
    tasks_filters: "Filtros",
    tasks_sent_on: "Enviado el",
    tasks_src_lang: "Idioma original",
    tasks_src_country: "País de origen",
    tasks_dst_country: "Traducir al",
    tasks_state: "Estado",
    tasks_price: "Precio (neto)",
    state_cancelled: "Cancelado por el cliente",
    state_waiting_for_translator: "Nuevo",
    state_waiting_for_payment: "Esperando el pago",
    state_refused_by_translator: "Misión rechazada",
    state_translating: "Traducción pendiente",
    state_waiting_for_client_validation:
      "Terminada. Esperando la validación por el cliente",
    state_validated: "Validado por el cliente",
    prestation_breadcrumb_tasks: "Mis misiones",
    prestation_order_number: "Número de pedido:",
    prestation_next_action_title: "Próximas etapas",
    prestation_next_action_accept_order: "Aceptar el pedido",
    prestation_next_action_accept_order_expl:
      "Haga clic aquí para aceptar el pedido. Luego, el cliente procederá al pago. Una vez el pago realizado, puede empezar la traducción.",
    prestation_next_action_reject_order: "Rechazar el pedido",
    prestation_next_action_reject_order_expl:
      "Haga clic aquí para rechazar el pedido.",
    prestation_next_action_rejected: "Usted rechazó el pedido.",
    prestation_next_action_waiting_for_payment:
      "Esperando el pago por el cliente.",
    prestation_next_action_translate_expl:
      "Puede proceder a la traducción de cada documento en este pedido haciendo clic en el botón 'Traducir'. Una vez los documentos listos, haga clic en el botón 'Enviar al cliente'. Para cualquier pregunta o precisión, puede conversar con el cliente directamente por medio de la mensajería electrónica.",
    prestation_next_action_translate_all_done_expl:
      "Usted acabó todos los documentos de este pedido.",
    prestation_next_action_translate_confirm:
      "Enviar al cliente para validación",
    prestation_next_action_waiting_for_validation:
      "El cliente está validando la traducción.",
    prestation_next_action_validated: "Pedido confirmado por el cliente.",
    prestation_order_details: "Detalles del pedido:",
    prestation_order_from: "De",
    prestation_order_to: "Para",
    prestation_order_country_origin: "País de origen:",
    prestation_order_document_count: "Documentos para traducir:",
    prestation_order_price: "Precio (neto):",
    prestation_order_date: "Pedido hecho el:",
    prestation_order_documents_title: "Documentos para traducir:",
    prestation_order_document_type: "Tipo de documento",
    prestation_order_document_originals: "Originales",
    prestation_order_document_translated: "Traducido(s)",
    prestation_order_document_not_yet_translated: "Aún no hay traducción",
    prestation_order_document_translate: "¡Traducir!",
    prestation_order_messaging_title: "Mensajería electrónica",
    prestation_order_messaging_send: "Enviar",
    prestation_order_messaging_client: "Cliente:",
    prestation_order_messaging_me: "Yo:",
    prestation_order_confirm_ok: "Ok",
    prestation_order_confirm_cancel: "Cancelar",
    prestation_order_validate_title: "Confirmar",
    prestation_order_validate_msg:
      "¿Usted está seguro de que quiere confirmar el pedido? El cliente recibirá una notificación y podrá acceder a los documentos traducidos.",
    prestation_order_accept_title: "Confirmar",
    prestation_order_accept_msg:
      "¿Usted está seguro de que quiere aceptar este pedido?",
    prestation_order_refuse_title: "Confirmar",
    prestation_order_refuse_msg:
      "¿Usted está seguro de que quiere rechazar este pedido?",
    prestation_document_validate: "¡Confirmar la traducción!",
    prestation_document_show_original: "Ver el original en otra pestaña:",
    prestation_document_edit: "Editor de documento",
    prestation_document_edit_expl:
      "El texto en el campo más arriba es subrayado en el documento más abajo. Al entrar el texto traducido en el campo, el documento se actualizará automáticamente. Utilice las flechas para navegar el documento.",
    prestation_document_get_browser:
      "¿Usted no consigue ver el PDF? Por favor utilice un navegador reciente como",
    prestation_document_button_reset: "Reiniciar el documento",
    prestation_document_confirm_title: "Confirmar",
    prestation_document_confirm_message:
      "¿Usted está seguro de que quiere reiniciar la traducción? ¡Todos los cambios se perderán!",
    prestation_document_confirm_oui: "Sí",
    prestation_document_confirm_non: "No",
    account_expl:
      "Indique sus datos para que podemos contactarle si es necesario.",
    account_save: "Guardar",
    account_surname: "Apellido",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Nombre",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Teléfono",
    account_telephone_placeholder: "",
    account_telephone_help: "",
    account_poste: "Puesto",
    account_poste_help: "",
    account_poste_placeholder: "Puesto",
    account_adresse: "Dirección",
    account_adresse_help: "",
    account_adresse_placeholder: "Dirección",
    account_service: "Servicio",
    account_service_help: "",
    account_service_placeholder: "Servicio",
    account_complement_adr: "Dirección adicional",
    account_complement_adr_help: "",
    account_complement_adr_placeholder: "Dirección adicional",
    account_email: "Correo electrónico",
    account_email_help: "",
    account_email_placeholder: "Correo electrónico",
    account_password: "Contraseña actual",
    account_password_help: "",
    account_new_password: "Nueva contraseña",
    account_new_password_help: "",
    account_error_required: "Campo obligatorio",
    agency_expl_1_title: "Razón social",
    agency_expl_1_expl:
      "Especifique el nombre de su empresa para que los clientes pueden encontrarlo.",
    agency_expl_2_title: "Localización",
    agency_expl_2_expl:
      "La aplicación necesita su información de contact para ponerlo en  contacto con los clientes más cercano a usted.",
    agency_expl_3_title: "Activar su cuenta",
    agency_expl_3_expl:
      "Vuestro perfil será visible en línea, una vez que guarde sus informaciones de contacto.",
    services_add_button: "Añadir una nueva oferta…",
    services_none_yet:
      "Para ser visible en la plataforma Hierø, especifique los tipos de traducciones que ofrece.",
    services_add_expl:
      "Por favor, especifique los idiomas que practica y luego haga clic en 'Añadir'.",
    services_add_src_lang: "Idioma original",
    services_dst_lang: "Al",
    services_add: "¡Añadir!",
    services_error_add:
      "Esta combinación de idiomas aún no es posible con Hierø. ¡Contáctenos para tener más información!",
    services_dont_see_title: "No puedo consultar mis idiomas.",
    services_dont_see_response:
      "Hierø simplifica vuestra traducción al proporcionar unos modelos de documentos listos par usar. Siempre desarrollamos nuestros modelos. Contàctenos por <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> para añadir otros idiomas.",
    services_list_title: "Su lista de servicios",
    services_expl:
      "Haga clic en cada servicio para personalizar los documentos que acepta. Puede rechazar un tipo de documento, deshabilitando el botón, o especificar su precio para procesar este tipo de documento.",
    services_list_active: "Activado",
    services_list_country: "País",
    services_list_document_type: "Tipo de documento",
    services_list_average_price: "Precio medio (neto)",
    services_list_your_price: "Su precio (neto)",
    services_list_your_price_reset: "Reiniciar",
    services_list_delete: "Suprimir",
    support_title: "Nuestros locales",
    support_company: "Hiero",
    support_addr1: "86 Rue de Paris",
    support_addr2: "91400 Orsay",
    support_addr3: "Francia",
    support_contact: "Contacto",
    support_tel: "Tel.: (+33) 1 80 38 30 46",
    support_tel_es: "Tel (es) : (+34) 911 23 88 87",
    support_tel_en: "Tel (en) : (+1) 929 274 5104",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Elemento no encontrado",
    not_found_subtitle: "La página que solicitó no existe.",
    not_found_action: "Contactar el soporte ",
    not_found_action1: "si el problema persiste. ",
    not_found_home: "Inicio",
    AF: "Afganistán",
    ZA: "Sudáfrica",
    AL: "Albania",
    DZ: "Argelia",
    DE: "Alemania",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    AN: "Antillas Neerlandesas",
    SA: "Arabia Saudita",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Baréin",
    BD: "Bangladés",
    BB: "Barbados",
    BY: "Belarús",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunéi",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    CV: "Cabo Verde",
    EA: "Ceuta y Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    CO: "Colombia",
    KM: "Comoras",
    CG: "República del Congo",
    KP: "Corea del Norte",
    KR: "Corea del Sur",
    CR: "Costa Rica",
    CI: "Costa de Marfil",
    HR: "Croacia",
    CU: "Cuba",
    DK: "Dinamarca",
    DG: "Diego García",
    DJ: "Yibuti",
    DM: "Dominica",
    EG: "Egipto",
    SV: "El Salvador",
    AE: "Emiratos Árabes Unidos",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "España",
    EE: "Estonia",
    VA: "Estado de la Ciudad del Vaticano",
    FM: "Estados Federados de Micronesia",
    US: "Estados Unidos",
    ET: "Etiopia",
    FJ: "Fiyi",
    FI: "Finlandia",
    FR: "Francia",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    GS: "Islas Georgias del Sur y Sándwich del Sur",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GD: "Granada",
    GL: "Groenlandia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Guinea ecuatorial",
    GW: "Guinea-Bisáu",
    GY: "Guyana",
    GF: "Guayana Francesa",
    HT: "Haití",
    HN: "Honduras",
    HU: "Hungría",
    BV: "Isla Bouvet",
    CX: "Isla de Navidad",
    CP: "Isla Clipperton",
    AC: "Isla Ascensión",
    IM: "Isla de Man",
    NF: "Isla Norfolk",
    AX: "Islas Åland",
    KY: "Islas Caimán",
    IC: "Islas Canarias",
    CC: "Islas Cocos / Islas Keeling",
    CK: "Islas Cook",
    FO: "Islas Feroe",
    HM: "Islas Heard y McDonald",
    FK: "Islas Malvinas",
    MP: "Islas Marianas del Norte",
    MH: "República de las Islas Marshall",
    UM: "Islas Ultramarinas Menores de los Estados Unidos",
    SB: "Islas Salomón",
    TC: "Islas Turcas y Caicos",
    VG: "Islas Vírgenes Británicas",
    VI: "Islas Vírgenes de os Estados Unidos",
    IN: "India",
    ID: "Indonesia",
    IQ: "Irak",
    IR: "Irán",
    IE: "Irlanda",
    IS: "Islandia",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajistán",
    KE: "Kenia",
    KG: "Kirguistán",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesoto",
    LV: "Letonia",
    LB: "Líbano",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malasia",
    MW: "Malaui",
    MV: "Maldivas",
    ML: "Malí",
    MT: "Malta",
    MA: "Marruecos",
    MQ: "Martinica",
    MU: "Mauricio",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "México",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelandia",
    OM: "Omán",
    UG: "Uganda",
    UZ: "Uzbekistán",
    PK: "Pakistán",
    PW: "Palaos",
    PA: "Panamá",
    PG: "Papúa Nueva Guinea",
    PY: "Paraguay",
    NL: "Países Bajos",
    PE: "Perú",
    PH: "Filipinas",
    PN: "Islas Pitcairn",
    PL: "Polonia",
    PF: "Polinesia Francesa",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Catar",
    HK: "R.A.E de Hong Kong de la República Popular China",
    MO: "R.A.E. de Macao",
    QO: "Oceanía Lejana",
    CF: "República Centroafricana",
    CD: "República Democrática del Congo",
    DO: "República Dominicana",
    CZ: "República Checa",
    RE: "Reunión",
    RO: "Rumanía",
    GB: "Reino Unido",
    RU: "Rusia",
    RW: "Ruanda",
    EH: "Sahara Occidental",
    BL: "San Bartolomé",
    KN: "San Cristóbal y Nieves",
    SM: "San Marino",
    MF: "Isla San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    SH: "Isla Santa Elena",
    LC: "Santa Lucia",
    WS: "Samoa",
    AS: "Samoa Americana",
    ST: "Santo Tomé y Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia y Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SO: "Somalia",
    SD: "Sudán",
    LK: "Sri Lanka",
    SE: "Suecia",
    CH: "Suiza",
    SR: "Surinam",
    SJ: "Svalbard y Jan Mayen",
    SZ: "Suazilandia",
    SY: "Siria",
    TJ: "Tayikistán",
    TW: "Taiwán",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "Tierras Australes y Antárticas Francesas",
    IO: "Territorio Británico del Océano Índico",
    PS: "Territorios Palestinos",
    TH: "Tailandia",
    TL: "Timor Oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TA: "Tristán da Cunha",
    TN: "Túnez",
    TM: "Turkmenistán",
    TR: "Turquía",
    TV: "Tuvalu",
    UA: "Ucrania",
    EU: "Unión Europea",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis y Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabue",
  },
  pt: {
    new_orders_traduction: "Pedir uma tradução",
    new_orders_interprestation: "Pedir uma interpretação",
    order_asser_or_not: "Será uma tradução juramentada?",

    // trad_dashboard
    dashboard_evolution_year: "Evolução ao longo do ano atual",
    dashboard_turnover: "Meu volume de negócios",
    dashboard_evolution_month: "Evolução durante o mês",
    dashboard_trad_month: "Traduções do mês",
    dashboard_interpretation_month: "Interpretações do mês",
    dashboard_new_missions: "Novas missões",
    dashboard_my_missions: "Minhas missões atuais",
    dashboard_command_date: "Data do pedido:",
    dashboard_type: "Tipo:",
    dashboard_total_sells: "Vendas totais desde meu registro",
    dashboard_doc_type: "Tipo de documento mais traduzido",
    dashboard_my_clients: "Meus clientes recorrentes",
    dashboard_trad: "Tradução",
    dashboard_interpretation: "Interpretação",
    dashboard_particulier: "Indivíduos",
    dashboard_pro: "Profissional",
    dashboard_traductor_asser: "Tradução juramentada",
    dashboard_traductor_classic: "Tradução clássica",
    dashboard_prestation_lang: "Meus serviços por idioma",
    dashboard_status: "Estatus :",
    dashboard_month_janv: "Jan",
    dashboard_month_fevr: "Fev",
    dashboard_month_mars: "Mar",
    dashboard_month_avr: "Abr",
    dashboard_month_mai: "Maio",
    dashboard_month_juin: "Jun",
    dashboard_month_juill: "Jul",
    dashboard_month_aout: "Ago",
    dashboard_month_sept: "Set",
    dashboard_month_oct: "Out",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dez",

    // pro dashboard

    new_orders_traduction: "Pedir uma tradução",
    new_orders_interprestation: "Pedir uma interpretação",
    order_asser_or_not: "Precisa de uma tradução juramentada?",
    select: "Escolher",
    traduction_type: "Tipo de tradução",
    find_interpertor: "Encontrar um intérprete",
    page_number: "Número de páginas",
    order_interprete_delegate: "Intérpretes",
    facture_echeance: "Data de vencimento",
    facture_conditions: "Pagamento",
    facture_comptant: "A vista",
    facture_adress: "Endereço de facturação",
    facture_pro: "Profissional",
    facture_qte: "Qtd",
    facture_price: "Preço",
    facture_unitaire: "por Unidade",
    facture_montant: "Valor",
    facute_solde: "Total",
    facture_tva_fr: "IVA FR 20%",
    dashboard_pro_asser: "Pedidos de tradução juramentada",
    dashboard_pro_classic: "Pedidos de tradução clássica",
    dashboard_pro_inter: "Pedidos de interpretação",
    dashboard_pro_as_of: "a",
    dashboard_compared_last_month: "comparado ao mês passado",
    dashboard_pro_evolution_month: "Evolução ao mês",
    dashboard_pro_budget_month: "Orçamento para o mês de",
    dashboard_pro_budget: "Montantes deduzidos",
    dashboard_pro_amount_month: "Últimos montantes deduzidos",
    dashboard_pro_no_amount_month: "Nenhum montante a deduzir no momento",
    dashboard_pro_amount: "Montantes deduzidos do orçamento",
    dashboard_pro_amount_left: "Resto",
    dashboard_pro_amount_detail: "Detalhes dos montantes já deduzidos",
    dashboard_pro_amount_btn: "Detalhes dos montantes",
    dashboard_pro_command: "Detalhes dos pedidos actuais",
    dashboard_pro_command_date: "Data de pedido:",
    dashboard_pro_command_status: "Estado :",
    dashboard_pro_no_command: "Ainda não há pedidos ",
    dashboard_pro_command_company: "Pedidos feitos pela minha empresa",
    dashboard_pro_command_company_date: "Passado em",
    dashboard_pro_command_company_priceHT: "Preço sem impostos :",
    dashboard_pro_command_company_type: "Tipo:",
    dashboard_pro_command_company_status: "Estado:",
    dashboard_pro_no_command_company:
      "Ainda não há pedidos feitos pelos seus colegas",
    dashboard_pro_command_year: "Númeo de pedidos por ano",

    // espace pro
    online: "Permaneça conectado ",
    login_caption_title_pro: "Hierø - Interface profissional",
    login_main_title_pro: "Interface profissional",
    login_main_subtitle_pro: "Accsse sua interface profissional Hierø!",
    signup_caption_title_pro: "Hierø - Interface profissional",
    signup_main_subtitle_pro:
      "Crie sua conta, entre na nossa interface e encontre o tradutor mais perto!",
    agency_setup_title_pro: "A propósito da sua empresa",
    agency_setup_explanation_pro:
      "Encontre um tradutor juramentado rapidamente graças a nossa solução. Você poderá traduzir seus documentos o mais rápido possível!",
    main_header_subtitle_pro: "Interface profissional",
    main_left_menu_title_pro: "Conta professionnel",
    main_left_menu_section_navigation_order: "Minhas ordens",
    order_id: "Id",
    order_language_from: "Do",
    order_language_to: "Para o",
    order_status: "Estado",
    order_traductor_delegate: "Tradutores",
    new_order: "Pedir uma tradução traduction",
    new_order_ass: "Pedir uma tradução juramentada",
    section_order_title: "Minhas ordens",
    state_pro_wait: "Pendente",
    sold: "Saldo ",
    account_expl_pro_cheque: "Por favor, envie um cheque para Hierø",
    account_expl_pro_viremement: "Transferências bancárias ",
    account_expl_pro_iban: "IBAN FR 000",
    account_hist_pro_date: "Data de intervenção",
    account_hist_pro_debit: "Débito ",
    account_hist_pro_credit: "Crédito ",
    account_hist_pro_way: "Médio ",
    account_hist_pro_ref: "Referência ",
    account_budget: "Orçamento ",
    account_budget_placeholder: "",
    account_budget_help: "",
    label_origin_lang: "Língua de origem",
    select_choose: "Escolher...",
    find_traductors: "Encontrar tradutores",
    tasks_price_ttc: "Preço (Impostos incl.)",
    tasks_price_tva: "IVA",

    order_no_asser_document_name: "Nome do documento",
    no_traductor: "Ainda não há tradutores disponíveis! ",
    no_traductor_message: "Feche a janela e tene novamente",
    loading: "Pendente ...",
    menu: "Escolher o tipo de tradução",
    menu_label_traductor_asser: "Tradução juramentada",
    menu_label_traductor_classic: "Tradução clássica",
    menu_label_interprestation: "Interpretação",
    menu_label_all: "Ver tudo ",
    message_no_budget: "No momento, você tem um orçamento de ",
    message_contact: "Contacte-nos se quiser mudá-lo.",
    section_card_title: "Subscrição",
    alert_form_error_pages_number: "O número de páginas não pode ser negativo.",
    agency_pro_budget_help:
      "O orçamento que deseja atribuir às suas traduções.",

    sub_training: "Formação da equipe",
    sub_search: "Pesquisa automática de tradutor/intérprete",
    sub_geo: "Geolocalização do tradutor/intérprete",
    sub_dem: "Desmaterialização de todos os pedidos",
    sub_save: "Backup de documentos com o gerenciador de arquivos",
    sub_recap: "Resumo do pedido mensal",
    sub_proj: "Progresso dos projetos em tempo real",
    sub_management: "Gestão orçamental em tempo real",
    sub_message: "Mensagens automáticas",
    sub_send: "Entrega das suas traduções por correio",
    sub_impl: "Implementação do orçamento votado para 12 meses",
    sub_account: "Gerente de conta ao seu lado",
    sub_orientation: "Orientação personalizada das línguas mais solicitadas",
    sub_split:
      "Divisão do orçamento em vários departamentos, segundo os utilizadores",
    sub_event: "Recolha por mensageiro",
    choose: "Escolher",
    sub_function: "Funcionalidades",
    sub_standard: "Padrão",
    sub_essen: "Essencial",
    sub_premium: "Prêmio",
    sub_month: "mês",
    sub_subscription: "Assinatura",
    sub_associations_label: "Associações",
    sub_cabinet_label: "Gabinete de Advogados",
    sub_bank_label: "Bancos e Gabinetes de seguro",
    sub_academia_label: "Instituições acadêmicas",
    sub_who_are_you: "Você é ?",
    sub_explanation_academia:
      "Preferimos trabalhar em forma de parceria com instituições acadêmicas, como universidades, estabelecimentos de ensino superior, ...",
    sub_explanation_systeme: "2 sistemas estão disponíveis:",
    sub_system_one: "A utilização da aplicação móvel se não forem contratadas.",
    sub_system_two:
      "Negociar a criação de um espaço profissional se forem contratados.",
    sub_credit_card_message:
      "Ative os serviços pagando a sua assinatura. Na aba",
    agree: "Ao clicar, você aceita",
    our_term: "nossas condições",
    and_the: "e o",
    stripe_agreement: "Contrato de conta conectada do Stripe.",
    created_at: "Criado em",
    updated_at: "Actualizado em",
    validate_credit_card_message: "Seu pedido foi processado",
    assermente: "Juramentado",
    subscriber_choose: "Escolha uma assinatura",

    urgency: "Tempo de entrega desejado para esta tradução",
    order_no_asser_query_pdf_page_number:
      " O seu documento contém mais do que uma página?",
    order_no_asser_query_pdf_page_number_yes:
      "Indicar o número de páginas no documento",

    new_order_ass: "Pedir uma revisão",
    menu_label_relecture: "Revisão",
    sub_credit_card_message:
      "Para activar os serviços, pague a sua subscrição. Para fazer isso, vá para a guida 'A minha subscrição'",
    state_validated_pro: "Validado",

    agree: "Ao clicar, você aceita",
    our_term: "nossos termos ",
    and_the: "e o",
    stripe_agreement: "Acordo de conta conectada do Stripe.",

    /* created_at: "Subscrito em",
    updated_at: "Actualizado em ",
    validate_credit_card_message: "A sua encomenda foi tida em conta aceitada", */

    main_left_menu_section_parameters_card: "Mi suscripción",
    send: "enviei",
    message_paragraphe:
      "Fala com os tradutores para qualquer informação sobre o seu pedido",
    consult_orders: "Ver os meus pedidos",
    subscriber_choose: "Escolha uma subscrição. ",
    services_none_yet_subscribe: "Subscreva para activar os seus serviços",

    asser_placeholder: "Você é um tradutor ajuramentado?",
    agency_expl_4_expl:
      "É necessário fornecer-nos uma prova do seu juramento antes de poder utilizar os nossos serviços.",
    agency_expl_4_expl_1: "Pode enviá-lo por e-mail para : ",
    agency_expl_4_expl_2:
      "Se tiver alguma dúvida sobre este procedimento, por favor contacte-nos.",

    label_assermentation: "Tradução juramentada",
    find_out_more: "Saber mais",
    description: "Descrição",

    test: "", // delimite

    // end espace pro
    login_caption_title: "Hierø - Interface tradutor",
    login_caption_subtitle:
      "Um programa imaginado por tradutores para tradutores.",
    login_main_logo: "Hierø",
    login_main_title: "Interface do tradutor",
    login_main_subtitle:
      "Acesse suas missões e o editor de documentos con a interfaca tradutor Hierø!",
    login_main_connect: "Conectar",
    login_main_email: "Correio eletrónico",
    login_main_email_placeholder: "Indicar o endereço de e-mail",
    login_main_email_help: "Inicie a sessão com seu endereço de e-mail",
    login_main_password: "Senha",
    login_main_password_placeholder: "Indicar sua senha",
    login_main_password_help: "",
    login_main_button: "Conectar",
    login_main_to_signup: "Ainda não tem uma conta ? Registrar-se !",
    login_main_copyright: "© Hierø - HIERØ 2020",
    login_error_required_field: "Campo obrigatório",
    login_error_invalid_email: "O endereço de e-mail não é válido.",
    login_error_email_not_found: "O endereço de e-mail não existe.",
    signup_caption_title: "Hierø - Interface do tradutor",
    signup_caption_subtitle: "Escolha Hierø e ganhe visibilidade!",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscrição",
    signup_main_subtitle:
      "Crie a sua conta, entre na nossa interface e seja visível para nossos usuários!",
    signup_main_email: "Endereço de e-mail",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Senha",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmar sua senha",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Sobrenome",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Nome",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Telefone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "assinar",
    signup_main_to_signup: "Já está inscrito ? Conectar",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Campo obrigatório",
    signup_error_invalid_email: "O endereço de e-mail não é válido.",
    signup_error_email_not_found: "O endereço de e-mail não existe.",
    signup_error_min_password: "Mínimo de 8 caracteres",
    signup_error_max_password: "30 caracteres máximo",
    signup_error_passwords_do_not_match: "As senhas não correspondem",

    login_forgot_password: "Esqueceu a senha ?",
    reset_password_main: "Reiniciar a senha",
    reset_password_email_title: "E-mail",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conectar",
    reset_password_success:
      "Un email foi enviado para seu endereço. Depois, clique no link para modificar a senha.",
    reset_password_error_required: "Campo obrigatório",
    reset_password_error_invalid_email: "O endereço de e-mail não é válido.",
    reset_password_error_user_not_found: " Oendereço de e-mail não existe.",

    main_header_title: "Hierø -",
    main_header_title_particulier:
      "Solicite as suas traduções juramentadas com Hierø",
    main_header_subtitle: "Interface do tradutor",
    main_header_user_menu_logout: "Desconectar",
    main_left_menu_title_asser: "Tradução juramentada",
    main_left_menu_title: "Tradutor ",
    main_left_menu_section_navigation: "Menu de navegação",
    main_left_menu_section_accueil: "Página inicial",
    main_left_menu_section_navigation_missions: "Minhas missões",
    main_left_menu_section_parameters: "Configuração",
    main_left_menu_section_compte: "Conta",
    main_left_menu_section_parameters_agency: "Minha agência",
    main_left_menu_section_parameters_services: "Meus serviços",
    main_left_menu_section_parameters_user: "Minha conta",
    main_left_menu_section_parameters_support: "Suporte",
    agency_setup_title: "Sobre sua agência",
    agency_setup_explanation:
      "Hierø ofrece seus serviços a clientes pertos das suas instalações. <br>Por favor, indique aqui sua razão social e seu endereço postal. <br>Essas informações serão oferecidas aos clientes que procuram um tradutor.",
    agency_setup_companyname: "Razão social",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "O nome da empresa a ser usado para as ordens, as faturas, etc...",
    agency_setup_address: "Endereço postal",
    agency_setup_siret: "NIF",
    agency_setup_siret_placeholder:
      "Digite o número do seu siret se for uma empresa",
    agency_setup_siret_help: "Digite o número do seu siret se for uma empresa",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Comece a digitar o endereço postal e depois escolha o endereço correto na lista suspensa.",
    agency_setup_addresscompl: "Complementos de endereço",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Edifício, escadas, código de acesso, etc. Quando seus clientes procuram seus documentos, eles precisam de todas as informações.",
    agency_setup_tel: "Telefone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "O número de telefone a ser usado para conversar com seus clientes.",
    agency_setup_email: "Endereço de e-mail",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "O endereço de e-mail a ser usado para conversar com seus clientes. Por favor, digite outro se não é o seu endereço de registro de Hierø.",
    agency_setup_email_help_pro:
      "O endereço de correio electónico a utilizar para todas as correspondências com nossos tradutores e a equipe Hiero.",
    agency_setup_save: "Salvar",
    agency_setup_error_required_field: "Campo obrigatório",
    agency_setup_error_invalid_email: "O endereço de e-mail não é válido.",
    agency_setup_error_valid_address:
      "Por favor, escolha uma dos endereços sugeridos no menu suspenso.",

    agency_address_not_found: "Seu endereço não foi encontrado?",
    agency_address_title: "Entrar manualmente seus detalhes",
    agency_address_address: "Endereço :",
    agency_address_number: "N.°",
    agency_address_street: "Rua",
    agency_address_code: "Código postal",
    agency_address_city: "Cidade",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS :",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",

    //mamadou
    main_left_menu_section_navigation_dashboard: "Painel de controle",
    main_menu_left_section_navigation_entreprise: "Minha empresa",
    main_menu_left_section_navigation_factures: "Minhas faturas",
    main_menu_left_section_navigation_messages: "Minhas mensagens",
    main_menu_left_section_navigation_messagerie: "Minhas mensagens",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestor de ficheiros",
    main_menu_left_section_navigation_assistance: "Suporte técnico",
    main_menu_left_section_navigation_ticket: "Abrir um novo ticket",
    main_menu_left_section_navigation_question: "Preguntas Mais Frequentes",
    head_facture: "Factura",
    section_date: "Datado",
    section_numero_facture: "Fatura",
    section_type_prestation: "Tipo de serviço",
    section_prestation: "Serviço",
    section_price: "Total",
    section_print: "Imprimir",
    section_generate_facture: "Gerar a fatura em PDF",
    section_consulter: "Consultar",
    section_questions_frequentes: "Perguntas mais frequentes",
    section_questions_profil_entreprise:
      "Porquê diferenciar 'Minha conta' da 'Minha empresa'?",
    section_questions_paragraphe_one:
      "O seu perfil é só para você. 'Minha empresa' inclui todos os empregados da sua empresa que utilizam o software. Os gerentes de departamento podem criar novas contas e tratar de alterações orçamentais. ",
    section_questions_original:
      "Já tenho a versão descarregável da tradução juramentada ... Porquê recuperar o original?",
    section_questions_paragraphe_two:
      "A versão descarregável da tradução não tem valor jurídico e não pode ser utilizada para procedimentos que requerem uma tradução por um tradutor juramentado. A versão original da tradução é a única que será aceita pelas autoridades competentes.",
    section_questions_tarducteur:
      "O que sucede se o tradutor que escolhi não responder?",
    section_questions_paragraphe_three:
      "Em teoria, os tradutores que aparecem no software estão disponíveis para realizar seu pedido. Alguns podem demorar muito tempo para responder. Neste caso, o nosso software irá redireccioná-lo para um tradutor perto de você que pode tratar seu pedido de tradução / interpretação.",
    section_questions_price_deplacement:
      "O preço da viagem está incluído no preço anunciado pelo software?",
    section_questions_paragraphe_four:
      "Cada intérprete terá um preço diferente dependendo do número de km que aceita viajar. Em todos os casos, este montante será incluído no preço, encontrará o montante exacto do suplemento nos detalhes do pedido.",
    section_questions_gestions_files: "O que é o gestor de ficheiros ?",
    section_questions_paragraphe_five:
      "O gestor de ficheiros permite-lhe encontrar todos os seus ficheiros descarregáveis no mesmo local. Os ficheiros podem ser encontrados por tipo de ficheiro ou data adicionada. Também pode criar pastas para classificá-los.",
    section_questions_contact:
      "É possível contactar o tradutor se tiver dúvidas ou para detalhes sobre o pedido?",
    section_questions_paragraphe_six:
      "Absolutamente! Tem acesso às mensagens quando acede aos detalhes do pedido, ou no separador 'Serviço de mensagens'. O chat também pode ser utilizado para discutir da entrega de documentos em mão própria.",
    section_questions_traductions:
      "Porque o serviço de tradução 'clássica' é diferente do 'juramentado'?",
    section_questions_paragraphe_seven:
      "Os documentos classicos são todos diferentes, um modelo não pode ser gerado pelo algoritmo. O cliente envia o documento em versão descarregável quando se acede aos detalhes do pedido. Uma vez traduzido, o tradutor carrega e envia a tradução ao cliente para validação.",
    section_questions_budget:
      "Como podemos gerir o orçamento para nossos pedidos?",
    section_questions_paragraphe_eight:
      "A sua empresa ou departamento atribui um determinado orçamento para todas as suas ordens. O seu gestor gere todos os movimentos sobre o orçamento. Isto significa que ele ou ela é responsável por creditar ou debitar a conta. ",

    agency_support: "Suporte Técnico",
    agency_support_service_client:
      "Número de telefone - Serviço de Apoio ao Cliente",
    agency_support_ouverture: "Aberto de segunda a sexta feira, das 9h as 17h",
    agency_support_questions:
      "Você tem uma pergunta, você detectou um erro? ? Contate-nos por correio eletrónico.",
    agency_support_guide: "Guia do usuário profissional",
    agency_support_guide_p:
      "Você perdeu uma etapa? Quer saber como funciona a plataforma Hierø? Descarregue o nosso guia do usuário!",
    agency_support_download_guide: "Descarregar o guia",
    agency_ticket_title: "Meus tickets antigos",
    agency_ticket_objet: "Assunto",
    agency_ticket_question: "Sua pergunta",
    agency_ticket_state: "Estatuto",
    agency_ticket_head:
      "Precisa de ajuda? Não encontrou a resposta nas preguntas mais frequentes? Faça todas as suas perguntas, responderemos o mais rápido possível!",
    my_info: "As minhas informações",
    geolocation_paragraphe:
      "O software precisa saber a sua localização para procurar tradutores mais perto de você com precisão",
    update_password: "Mudar a sua senha",
    delete_account: "Cancelar a minha conta",
    account_paragraphe1: "Você não precisa mais solicitar traduções?",
    account_paragraphe2: "Você não deseja mais usar nossos serviços?",
    account_paragraphe3: "Pde cancelar a sua assinatura a qualquer momento",
    account_delete: "Eu desejo cancelar a minha conta",
    account_upt: "Se desejar, pode mudar a sua senha",
    section_questions_translate_document:
      "¿Cómo se traduce un documento jurado?",
    section_questions_paragraphe_1:
      "El programa te permite traducir el documento directamente utilizando un esquema generado por su algoritmo. Puedes traducir el documento sección por sección, antes de descargarlo y guardarlo, y enviarlo a tu cliente para que lo valide.",
    section_questions_original:
      "¿Puede verificarse la autenticidad de los documentos?",
    section_questions_paragraphe_2:
      "Por supuesto. El cliente te envía el o los documento(s) para que revisarlos en cuanto recibes el pedido. Si observa algún error en el documento, o si tiene dudas sobre su valor real, puede discutirlo directamente con su cliente.",
    section_questions_traducteurs:
      "Tenemos que entregar una versión del original, salvo que se prefiera descargar la traducción...",
    section_questions_paragraphe_3:
      "El cliente puede descargar una versión digital de la traducción, pero sabemos que no tiene valor legal. Utilizamos la geolocalización para sugerir un traductor cercano al cliente para facilitar el desplazamiento y la entrega de la traducción en versión original con seguimiento de los documentos. ",
    section_questions_langues: "¿Cómo agregar los idiomas que practico?",
    section_questions_paragraphe_4:
      "En la pestaña 'Modificar mis servicios'. Para añadir tus servicios, sólo tienes que añadir tus idiomas y el tipo de servicio correspondiente (traducción o interpretación). No olvides seleccionar los tipos de documentos. Si tus idiomas no están disponibles, ponte en contacto con nosotros para que podamos activarlos ",
    section_questions_precision_trad:
      "No soy traductor o intérprete jurado, ¿cómo puedo explicarlo?",
    section_questions_paragraphe_5:
      "En la pestaña 'Mi perfil', puedes indicar si eres traductor/a jurado/a o no. Si no lo está, sólo se tendrá acceso a los servicios que le corresponden.",
    section_questions_contact_client:
      "¿Es posible contactar a mi cliente para hacer preguntas o retrasos en el servicio solicitado?",
    section_questions_paragraphe_6:
      "Por supuesto. Se puede acceder al sistema de mensajería cuando accedas a los detalles del pedido, o dirigiéndote a la pestaña 'Servicio de mensajería'. También puedes utilizar el servicio de mensajería para acordar un lugar de encuentro para la entrega de los documentos en persona si es necesario.",
    section_questions_traductions_doc:
      "¿Cómo funciona la traducción de un documento 'clásico'?",
    section_questions_paragraphe_7:
      "Como los documentos clásicos son más irregulares, el algoritmo no puede generar una plantilla. El cliente envía el documento que puede descargarse al acceder a los datos del pedido. Una vez traducido, sólo hay que cargarlo y enviarlo al cliente para su validación",
    section_questions_abonnement: "¿Deseo cancelar o cambiar mi suscripción?",
    section_questions_paragraphe_8:
      "Puedes cancelar tu suscripción o modificarla en cualquier momento dirigiéndote a 'Mi perfil'. Encontraras un resumen de tu suscripción actual, así como de las demás opciones. En caso de problemas, puedes ponerte en contacto con nosotros directamente.",
    menu_label_interpretation: "Solicitar una interpretación ",
    menu_label_intervention: "A intervenção deve ter lugar em",
    type_intervention: "Tipo de intervenção solicitada",
    menu_label_event_name: "Nome do evento",
    nb_heures_estimee: "Número estimado de horas",
    menu_label_sur_place: "Presente",
    menu_label_visio: "Por videoconferência",
    menu_langue: "Línguas",
    menu_head_ticket: "Mis tickets",
    table_header_type_de_document: "Tipo de documento",
    table_header_nb_pages: "Número de páginas",
    table_header_statut: "Estatus",
    table_header_archives: "Archivo de pedidos",
    table_hader_details: "Detalles",
    head_professionnal:
      "El software necesita conocer tu posición para buscar los traductores más cercanos con una mayor precisión",
    head_change_password: "Si lo deseas, puedes cambiar tu contraseña",
    professionnal_paragraph: "Cantidad descontada de mi presupuesto ",
    professionnal_paragraph_1:
      "Todos los importes de cada pedido, se descuentan de tu presupuesto",
    professionnal_paragraph_2: "Realizar una transferencia",
    professionnal_paragraph_3: "Enviar un chèque",
    professionnal_paragraph_4: "Solicitar el reembolso de mi saldo",
    address_facturation: " Cambiar mi dirección de facturación",
    consulter_facture: "Consultar mis facturas",
    facture_numero: "Factura N°",
    recap_tva: "RESUMO DO IVA",
    recap_taux: "TAXA",
    recap_mt_tva: "VALOR IVA",
    recap_base_ht: "BASE LIVRES DE IMPOSTOS",
    recap_tva_fr: "20% de IVA FR",
    client_facture_detail: "Cliente",
    client_particulier_name: "Razón social",
    client_particulier_email: "Correo electrónico",
    client_particulier_address: "Dirección ",
    client_particulier_code_postal: "Ciudad",
    salle_de_conference: "Sala de conferencias",
    joindre_salon: "Entrar en la sala",
    prestation: "Servicio",
    commande_numero: "Nº de pedido°",
    date_commande: "Fecha del pedido",
    commande_qte: "Cantidad",
    commande_produit: "Producto",
    commande_desc: "Descripción",
    montant_total: "Importe total",
    total_ht: "Total antes de impuestos",
    total_tva: "Total con IVA",
    total_ttc: "Total, con IVA incluido",
    retour_btn: "Regresar",
    single_commande_paragraphe:
      "El traductor seleccionado ha aceptado tu pedido. Una vez terminada la traducción, deberás validar y confirmar. Si el traductor seleccionado rechaza el pedido, nuestro software enviará automáticamente tu pedido a otro traductor.",
    progress_commande: "Seguimiento del pedido",
    single_commande_p1: "O tradutor descarrega o documento para traduzi-lo",
    single_commande_p2:
      "Uma vez traduzido, o tradutor o adiciona a este pedido para que possa validar a tradução e confirmar o pedido.",
    accept_intervention: "Intervenção aceita",
    load_intervention: "Intervenção em curso",
    delai_intervention: "Duração da intervenção",
    commande_statut: "Estado de su pedido",
    commande_discuss: "Hablar directamente con tu traductor",
    commande_details: "Detalles del pedido",
    intervention_details: "Detalhes da intervenção",
    commande_receive: "Pedido recibido el",
    type_doc_commande: "Tipo de documento solicitado",
    doc_a_traduire: " Documento a traducir",
    trad_asser: "Traductor jurado",
    trad_name: "Nome do tradutor",
    capital_sasu: "SASU com um capital social de 7.000,00 euros",
    rcs_infos: "RCS EVRY B 843 119 728",
    siret: "NIF 84311972800023",
    tva_fr: "Número de IVA: FR00843119728",
    condition_paragraphe_1: "Multas por atraso de pagamento (taxa anual): 10%",
    condition_paragraphe_2: "Sem desconto para pagamento antecipado",
    condition_paragraphe_3:
      "Subsídio fixo para custos de cobrança em caso de atraso de pagamento: € 40",
    prestataire: "Provedor de serviço",
    pays_origine: "País de origen",
    visioner_doc: "Ver el documento",
    auth_doc:
      "El traductor verifica la autenticidad del documento antes de comenzar la traducción.",
    doc_bug:
      "En caso de problemas, el traductor puede contactarte directamente por correo electrónico.",
    infos_interv: "Información adicional sobre la participación",
    add_file: "Adicionar um anexo",
    infos_entrep: "",
    infos_commandes: "",
    action: "Acción",
    client: "Cliente",
    hours: "Horas",
    join_room: "Entrar en la sala",
    rooms: "Las salas (salas de reuniones)",
    warning: "¡Atención! ",
    temps_ex: "Tiempo estimado",
    question_inter: "¿Eres intérprete?",
    question_inter_price: "Precio de una hora:",
    question_inter_1:
      "Realizará tareas de interpretación en nuestro software para",
    calendar: " Calendario",
    calendar_hidden: "Ocultar calendario",
    conf: "La conferencia",
    warning_1: "Debe respetarse la ortografía de los nombres de las salas.",
    interpretation_label_menu: "Interpretación",
    interpretation_label: "Interpretación",
    interpretation_info: "Explicación",
    interpretation_paragraphe_1:
      "Para participar en la misión de interpretación, necesita al menos dos ventanas. En la primera, estarás con el intérprete, en la segunda con todos los participantes.",
    interpretation_paragraphe_2:
      "Para acceder a la sala de conferencias, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_4:
      "Para acceder a la sala de interpretación, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_3:
      ". Aquí se encuentran todos los participantes.",
    interpretation_paragraphe_5:
      ". Aquí se encuentran sus oradores y el intérprete seleccionado.",
    interpretation_paragraphe_6:
      "Para realizar correctamente el trabajo de interpretación de esta conferencia a distancia, es necesario que el orador y el intérprete no hablen al mismo tiempo en la misma sala.",
    interpretation_paragraphe_7:
      "Por eso están en dos salas diferentes. De este modo, los participantes sólo le oirán a usted en la sala de conferencias y usted sólo oirá a los oradores en la sala de interpretación.",
    interpretation_paragraphe_8:
      "Por razones de rendimiento, la capacidad máxima de una sala es de 50 participantes. Puede crear tantas salas correspondientes a su público como desee y comunicar los nombres de estas salas a su intérprete.",
    interpretation_paragraphe_9:
      "Para invitar a los participantes, haga clic en el siguiente botón para copiar el enlace de invitación y pegarlo en su mensaje respetando el nombre de la sala ",
    interpretation_paragraphe_10:
      "Haga clic aquí para copiar el enlace de la invitación ",
    interpretation_paragraphe_11:
      "Haga clic aquí para invitar a oradores o participantes ",
    room_conf: " Sala de conferencias",
    room_inter: "Sala de interpretación ",
    room_inter_pro: "Su nombre de usuario",
    once_page_price: "Sus tarifas en la página",
    once_page_price_default: "Precio predeterminado (SIN IVA)",
    once_page_information:
      "Le invitamos a indicar su tarifa para la traducción por página de un documento (250 palabras). Esta tarifa se ofrecerá al cliente si el documento que desea traducir no está registrado por nuestro sistema.",
    copy_alert: "¡Copiado!",
    commander_interprete: "Pedir um intérprete",
    particulier_name: "Nome do indivíduo",
    traduction_assermentee: "Tradução juramentada",
    interpretation: "Interpretação",
    new_orders: "Fazer uma nova encomenda",

    status_orders: "Status do seu pedido",
    final_intervention: "Concluir a intervenção",
    finaliser_commande: "Terminar o pedido",
    table_header_archives: "Arquivo de encomendas",
    state_accept_order: "Encomenda aceitada",
    head_particulier:
      "As respostas às suas perguntas podem estar nesta página. Caso contrário, não hesite em abrir um bilhete.",
    partuclier_trad_doc_head1:
      "O tradutor não entregou o meu documento. O que fazer?",
    partuclier_trad_doc1:
      "Pode tentar contactar o tradutor usando a mensageria ou através do apoio técnico se o tradutor não responde aos seus pedidos. O tradutor deve dar-lhe uma razão válida para não poder entregar o seu documento dentro do prazo solicitado.",
    partuclier_trad_doc_head2:
      "Já tenho a versão digital da tradução juramentada... Porquê receber o original?",
    partuclier_trad_doc2:
      "A versão digital da tradução não tem valor legal e não pode ser utilizada para procedimentos que requerem uma tradução por um tradutor juramentado. A versão original da tradução é a única aceitada pelas autoridades competentes.",
    partuclier_trad_doc_head3:
      "O que acontece se o tradutor que escolhi não responde?",
    partuclier_trad_doc3:
      " Em teoria, os tradutores expostos são aqueles que estão disponíveis para tratar a sua encomenda. Pode acontecer que alguns deles demorem muito tempo a responder. Neste caso, o nosso programa informático irá encaminhá-lo para um tradutor próximo de si que será capaz de tratar o seu pedido de tradução/interpretação.",
    partuclier_trad_doc_head4:
      "Não posso ir ao tradutor para obter o meu documento.",
    partuclier_trad_doc4:
      "Se não conseguir recuperar pessoalmente o documento original, o tradutor pode sempre enviar-lhe os documentos pelo correio.",
    partuclier_trad_doc_head5:
      "A tradução que recebi parece-me ser de má qualidade, ou mesmo errada ou incompleta",
    partuclier_trad_doc5:
      "Guarde o documento como prova, contacte o tradutor para uma explicação. Se o tradutor se recusar a fazer alterações, contacte directamente o suporte técnico para intervenção.",
    partuclier_trad_doc_head6:
      "E se o software não detectar um tradutor nas proximidades ?",
    partuclier_trad_doc6:
      "Pode acontecer que não tenhamos um tradutor listado para a língua que lhe interessa, ou que um não esteja por perto. Se for este o caso, contacte o suporte técnico, ou selecionar um tradutor remoto se não tiver sido sugerido nenhum",
    agency_support_guide_pro: "O guia do usuário",
    discuss_encours: "Conversações em progresso",
    signup_main_text: "Solicite as suas traduções juramentadas com HIERØ",
    cdu: "Ao clicar em 'Registar', está a concordar com os nossos",
    cdu1: "termos e condições de utilização e venda",
    cdu2: "e a nossa política de privacidade",
    cdu3: "assim como",
    newsletter:
      " Aceito receber por e-mail atualizações, ofertas especiais e novidades da plataforma e/ou outros produtos desenvolvidos por Hierø",
    login_text:
      "Com a interface Hierø, faça os seus pedidos de tradução e selecione o seu tradutor juramentado em um clique.",
    ticket_numero: "Bilhete nº",
    intervention_time_date: "Data e hora da intervenção",
    close_modal: "Fechar",
    inscription_date: "Registado desde",
    loading_order: "Os meus pedidos de tradução em curso",
    view_all_orders: "Ver todas as encomendas",
    last_payment: "Os meus últimos pagamentos efectuados",
    view_all_payment: "Ver todos os meus pagamentos",
    last_send_msg: "Últimas mensagens enviadas",
    order_translate: "de",
    order_translate_to: "para",
    price_unitaire: "Preço unitário excluindo impostos",
    price_total_ht: "Montante total excluindo impostos",
    montant_total_euro: "Montante total em euros",
    rules_conditions: "CONDIÇÕES DE PAGAMENTO",
    rules_conditions_p: "pagamento na recepção da factura",
    //end mamadou

    section_agency_title: "Minha agência",
    section_services_title: "Meus serviços",
    section_account_title: "Minha conta",
    section_support_title: "Suporte",
    section_dashboard_title: "",
    section_tasks_title: "Minhas missões",
    section_task_title: "Realizar uma missão",
    section_document_title: "Traduzir um documento",
    notifications_new_task: "Você tem uma nova missão!",
    notifications_task_confirmed:
      "A missão foi confirmada. Você pode iniciar a tradução.",
    notifications_task_validated: "O cliente validou a missão.",
    tasks_filters: "Filtros",
    tasks_sent_on: "Enviado em",
    tasks_src_lang: "Língua original",
    tasks_src_country: "País de origem",
    tasks_dst_country: "Traduzir para",
    tasks_state: "Progressão",
    tasks_price: "Preço (s/IVA)",
    state_cancelled: "Cancelado pelo cliente",
    state_waiting_for_translator: "Novo",
    state_waiting_for_payment: "À espera de pagamento",
    state_refused_by_translator: "Missão recusada",
    state_translating: "Tradução pendente",
    state_waiting_for_client_validation:
      "Acabada. Aguardando a verificação pelo cliente.",
    state_validated: "Validado pelo cliente",
    prestation_breadcrumb_tasks: "Minhas missões",
    prestation_order_number: "Número do pedido :",
    prestation_next_action_title: "Próximas etapas",
    prestation_next_action_accept_order: "Aceitar o pedido",
    prestation_next_action_accept_order_expl:
      "Clique aqui para aceitar o pedido. Depois, o cliente procederá ao pagamento. Após o pagamento ser processado, você pode iniciar a tradução.",
    prestation_next_action_reject_order: "Recusar o pedido",
    prestation_next_action_reject_order_expl:
      "Clique aqui para recusar o pedido.",
    prestation_next_action_rejected: "Você recusou o pedido.",
    prestation_next_action_waiting_for_payment:
      "Aguardando o pagamento pelo cliente.",
    prestation_next_action_translate_expl:
      "Pode iniciar a tradução de cada documento do pedido, clicando no botão 'Traduzir'. Uma vez que todos os documentos estão prontos, clique no botão 'Enviar para o cliente'. Para qualquer pergunta o precisão, você pode conversar diretamente com o cliente por e-mail.",
    prestation_next_action_translate_all_done_expl:
      "Você terminou todos os documentos neste pedido.",
    prestation_next_action_translate_confirm:
      "Envíar ao cliente para verificaação.",
    prestation_next_action_waiting_for_validation:
      "O cliente está validando a tradução.",
    prestation_next_action_validated: "Pedido confirmado pelo cliente.",
    prestation_order_details: "Detalhes do pedido :",
    prestation_order_from: "De",
    prestation_order_to: "Para",
    prestation_order_country_origin: "País de origem :",
    prestation_order_document_count: "Documentos para traduzir :",
    prestation_order_price: "Preço (s/IVA):",
    prestation_order_date: "Pedido criado em :",
    prestation_order_documents_title: "Documentos para traduzir :",
    prestation_order_document_type: "Tipo de documento",
    prestation_order_document_originals: "Originais",
    prestation_order_document_translated: "Traduzido(s)",
    prestation_order_document_not_yet_translated: "Ainda não tem traduçao",
    prestation_order_document_translate: "Traduzir !",
    prestation_order_messaging_title: "Mensagem eletrônica",
    prestation_order_messaging_send: "Enviar",
    prestation_order_messaging_client: "Cliente :",
    prestation_order_messaging_me: "Eu:",
    prestation_order_confirm_ok: "Ok",
    prestation_order_confirm_cancel: "Cancelar",
    prestation_order_validate_title: "Confirmar",
    prestation_order_validate_msg:
      "Tem a certeza de que deseja confirmar este pedido? O cliente receberá uma notificação e terá acesso aos documentos traduzidos.",
    prestation_order_accept_title: "Confirmar",
    prestation_order_accept_msg:
      "Tem a certeza de que deseja aceitar este pedido?",
    prestation_order_refuse_title: "Confirmar",
    prestation_order_refuse_msg:
      "Tem a certeza de que deseja recusar este pedido?",
    prestation_document_validate: "Confirmar a tradução !",
    prestation_document_show_original: "Ver o original em outra aba :",
    prestation_document_edit: "Editor de documento",
    prestation_document_edit_expl:
      "O texto no campo acima está sublinhado no documento abaixo. Ao entrar  o texto traduzido no campo, o doumento será atualizado automaticamente. Utilize as setas para navegar o documento.",
    prestation_document_get_browser:
      "Você não vê o PDF? Use um navegador recente como",
    prestation_document_button_reset: "Reiniciar o documento",
    prestation_document_confirm_title: "Confirmar",
    prestation_document_confirm_message:
      "Tem a certeza de que deseja reiniciar a tradução? Todas as modificações serão perdidas !",
    prestation_document_confirm_oui: "Sim",
    prestation_document_confirm_non: "Não",
    account_expl:
      "Indique suas informações de contacto, para contatá-lo se necessário.",
    account_save: "Salvar",
    account_surname: "Sobrenome",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Nome",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Telefone",
    account_telephone_placeholder: "",
    account_telephone_help: "",
    account_poste: "Posto",
    account_poste_help: "",
    account_poste_placeholder: "Posto",
    account_adresse: "Endereço",
    account_adresse_help: "",
    account_adresse_placeholder: "Endereço",
    account_service: "Serviço",
    account_service_help: "",
    account_service_placeholder: "Serviço",
    account_complement_adr: "Outros dados de endereço",
    account_complement_adr_help: "",
    account_complement_adr_placeholder: "Outros dados de endereço",
    account_email: "Correio eletrónico",
    account_email_help: "",
    account_email_placeholder: "Correio eletrónico",
    account_password: "Senha atual",
    account_password_help: "",
    account_new_password: "Nova Senha",
    account_new_password_help: "",
    account_error_required: "Campo obrigatório",
    agency_expl_1_title: "Nome comercial",
    agency_expl_1_expl:
      "Especifique o nome da sua empresa para que seus clientes possam encontrá-lo.",
    agency_expl_2_title: "Geolocalização",
    agency_expl_2_expl:
      "A aplicação requer suas informações de contato para entrar em contacto com os clientes mais próximos de você.",
    agency_expl_3_title: "Ativar sua conta",
    agency_expl_3_expl:
      "Seu perfil ficará visível on-line, uma vez a informação salvada.",
    services_add_button: "Adicionar uma nova oferta…",
    services_none_yet:
      "Para estar visível na plataforma Hierø, especifique os tipos de serviços que você ofrece.",
    services_add_expl:
      "Por favor, especifique as línguas que você practica, e clique em 'Adicionar'.",
    services_add_src_lang: "Língua original",
    services_dst_lang: "Para",
    services_add: "Adicionar !",
    services_error_add:
      "Essa combinação de línguas ainda não é possível com o Hierø. Contacte-nos para mais informações!",
    services_dont_see_title: "Não consigo ver minhas línguas.",
    services_dont_see_response:
      "Hierø facilita sua tradução, fornecendo modelos para os documentos prontos para uso. Desenvolvemos continuamente nossos documentos. Contacte-nos por  contact@hiero-solution.com para ter línguas adicionais.",
    services_list_title: "Sua lista de serviços",
    services_expl:
      "Clique em cada serviço para personalizar os documentos que você aceita.  Pode recusar un tipo de documento, desativando o botão, o especificar seu preço para tratar este tipo de documento.",
    services_list_active: "Ativado",
    services_list_country: "País",
    services_list_document_type: "Tipo de documento",
    services_list_average_price: "Preço médio (s/IVA)",
    services_list_your_price: "Seu preço (s/IVA)",
    services_list_your_price_reset: "Reiniciar",
    services_list_delete: "Remover",
    support_title: "Nossas instalações",
    support_company: "Hiero",
    support_addr1: "86 Rue de Paris",
    support_addr2: "91400 Orsay",
    support_addr3: "França",
    support_contact: "Contacto",
    support_tel: "Tel. : (+33) 1 80 38 30 46",
    support_tel_es: "Tel (es) : (+34) 911 23 88 87",
    support_tel_en: "Tel (en) : (+1) 929 274 5104",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Elemento não encontrado",
    not_found_subtitle: "A página solicitada não existe.",
    not_found_action: "Contactar o suporte ",
    not_found_action1: "se o problema persistir. ",
    not_found_home: "Início",
    AF: "Afeganistão",
    ZA: "África do Sul",
    AL: "Albânia",
    DZ: "Argélia",
    DE: "Alemanha",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antartica",
    AG: "Antiga e Barbuda",
    AN: "Antilhas Neerlandesas",
    SA: "Arábia Saudita",
    AR: "Argentina",
    AM: "Arménia",
    AW: "Aruba",
    AU: "Austrália",
    AT: "Áustria",
    AZ: "Azerbaijão",
    BS: "Bahamas",
    BH: "Barém",
    BD: "Bangladexe",
    BB: "Barbados",
    BY: "Bielorrússia",
    BE: "Bélgica",
    BZ: "Belize",
    BJ: "Benim",
    BM: "Bermudas",
    BT: "Butão",
    BO: "Bolívia",
    BA: "Bósnia e Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunei",
    BG: "Bulagária",
    BF: "Burquina Faso",
    BI: "Burúndi",
    KH: "Camboja",
    CM: "Camarões",
    CA: "Canadá",
    CV: "Cabo Verde",
    EA: "Ceuta e Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    CO: "Colômbia",
    KM: "Comores",
    CG: "Congo-Brazzaville",
    KP: "Coreia do Norte",
    KR: "Coreia do Sul",
    CR: "Costa Rica",
    CI: "Costa do Marfim",
    HR: "Croácia",
    CU: "Cuba",
    DK: "Dinamarca",
    DG: "Diego Garcia",
    DJ: "Jibuti",
    DM: "Dominica",
    EG: "Egito",
    SV: "El Salvador",
    AE: "Emirados Árabes Unidos",
    EC: "Equador",
    ER: "Eritreia",
    ES: "Espanha",
    EE: "Estónia",
    VA: "Estado da Cidade do Vaticano",
    FM: "Estados Federados da Micronésia",
    US: "Estados Unidos",
    ET: "Etiópia",
    FJ: "Fiji",
    FI: "Finlândia",
    FR: "França",
    GA: "Gabão",
    GM: "Gâmbia",
    GE: "Geórgia",
    GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
    GH: "Gana",
    GI: "Gibraltar",
    GR: "Grécia",
    GD: "Granada",
    GL: "Gronelândia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guiné",
    GQ: "Guiné Equatorial",
    GW: "Guiné-Bissau",
    GY: "Guiana",
    GF: "Guiana Francesa",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Hungria",
    BV: "Ilha Bouvet",
    CX: "Ilha Christmas",
    CP: "Ilha Clipperton",
    AC: "Ilha de Ascensão",
    IM: "Ilha de Man",
    NF: "Ilha Norfolk",
    AX: "Ilhas de Aland",
    KY: "Ilhas Caimão",
    IC: "Ilhas Canárias",
    CC: "Ilhas Cocos (Keeling)",
    CK: "Ilhas Cook",
    FO: "Ilhas Feroe",
    HM: "Ilha Heard e Ilhas McDonald",
    FK: "Ilhas Malvinas",
    MP: "Ilhas Marianas Setentrionais",
    MH: "Ilhas Marechal",
    UM: "Ilhas Menores Distantes dos Estados Unidos",
    SB: "Ilhas Salomão",
    TC: "Ilhas Turcas e Caicos",
    VG: "Ilhas Virgens Britânicas",
    VI: "Ilhas Virgens Americanas",
    IN: "India",
    ID: "Indonésia",
    IQ: "Iraque",
    IR: "Irão",
    IE: "Irlanda",
    IS: "Islândia",
    IL: "Israel",
    IT: "Itália",
    JM: "Jamaica",
    JP: "Japão",
    JE: "Jersey",
    JO: "Jordânia",
    KZ: "Cazaquistão",
    KE: "Quénia",
    KG: "Quirguistão",
    KI: "Kiribati",
    KW: "Cuaite",
    LA: "Laus",
    LS: "Lesoto",
    LV: "Letónia",
    LB: "Líbano",
    LR: "Libéria",
    LY: "Líbia",
    LI: "Listenstaine",
    LT: "Lituânia",
    LU: "Luxemburgo",
    MK: "Macedónia",
    MG: "Madagáscar",
    MY: "Malásia",
    MW: "Maláui",
    MV: "Maldivas",
    ML: "Mali",
    MT: "Malta",
    MA: "Marrocos",
    MQ: "Martinica",
    MU: "Maurícia",
    MR: "Mauritânia",
    YT: "Maiote",
    MX: "México",
    MD: "Moldávia",
    MC: "Mónaco",
    MN: "Mongólia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Moçambique",
    MM: "Mianmar",
    NA: "Namíbia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicarágua",
    NE: "Níger",
    NG: "Nigéria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nova Caledónia",
    NZ: "Nova Zelândia",
    OM: "Omã",
    UG: "Uganda",
    UZ: "Uzbequistão",
    PK: "Paquistão",
    PW: "Palau",
    PA: "Panamá",
    PG: "Papua Nova Guiné",
    PY: "Paraguai",
    NL: "Países Baixos",
    PE: "Peru",
    PH: "Filipinas",
    PN: "Ilhas Pitcairn",
    PL: "Polónia",
    PF: "Polinésia Francesa",
    PR: "Porto Rico",
    PT: "Portugal",
    QA: "Catar",
    HK: "R.A.E. de Hong Kong da República Popular da China",
    MO: "R.A.E. de Macau da República Popular da China",
    QO: "Ocenia Remoto",
    CF: "República Centro-Africana",
    CD: "República Democrática do Congo",
    DO: "República Dominicana",
    CZ: "República Checa",
    RE: "Reunião",
    RO: "Roménia",
    GB: "Reino Unido",
    RU: "Rússia",
    RW: "Ruanda",
    EH: "Saara Ocidental",
    BL: "São Bartolomeu",
    KN: "São Cristóvão e Neves",
    SM: "São Marinho",
    MF: "Ilha de São Martinho",
    PM: "São Pedro e Miquelão",
    VC: "São Vicente e Granadinas",
    SH: "Ilha Santa Helena",
    LC: "Santa Lúcia",
    WS: "Samoa Wúcia",
    AS: "Samoa Americana",
    ST: "São Tomé e Príncipe",
    SN: "Senegal",
    RS: "Sérvia",
    CS: "Sérvia e Montenegro",
    SC: "Seicheles",
    SL: "Serra Leoa",
    SG: "Singapura",
    SK: "Eslóvaquia",
    SI: "Eslóvenia",
    SO: "Somália",
    SD: "Sudã",
    LK: "Sri Lanca",
    SE: "Suécia",
    CH: "Suíça",
    SR: "Suriname",
    SJ: "Svalbard e Jan Mayen",
    SZ: "Suazilândia",
    SY: "Síria",
    TJ: "Tajiquistão",
    TW: "Taiwan",
    TZ: "Tanzânia",
    TD: "Chade",
    TF: "Terras Austrais e Antárticas Francesas",
    IO: "Território Britânico do Oceano Índico",
    PS: "Territórios palestinianos",
    TH: "Tailândia",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidade e Tobago",
    TA: "Tristão da Cunha",
    TN: "Tunísia",
    TM: "Turcomenistão",
    TR: "Turquia",
    TV: "Tuvalu",
    UA: "Ucrânia",
    EU: "União Europeia",
    UY: "Uruguai",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietname",
    WF: "Wallis e Futuna",
    YE: "Iemen",
    ZM: "Zâmbia",
    ZW: "Zimbábue",
  },

  de: {
    // added variables
    new_orders_traduction: "Einen Übersetzungsauftrag aufgeben",
    new_orders_interprestation: "Einen Dolmetscherauftrag aufgeben",
    order_asser_or_not: "Handelt es sich um eine beglaubigte Übersetzung?",

    // trad_dashboard
    dashboard_turnover: "",
    dashboard_evolution_month: "",
    dashboard_trad_month: "",
    dashboard_interpretation_month: "",
    dashboard_new_missions: "",
    dashboard_my_missions: "",
    dashboard_command_date: "",
    dashboard_type: "",
    dashboard_total_sells: "",
    dashboard_doc_type: "",
    dashboard_my_clients: "",
    dashboard_trad: "",
    dashboard_interpretation: "",
    dashboard_particulier: "",
    dashboard_pro: "",
    dashboard_traductor_asser: "Beeidigte Übersetzung",
    dashboard_traductor_classic: "Klassische Übersetzung",
    dashboard_prestation_lang: "",
    dashboard_evolution_year: "",
    dashboard_status: "Status :",
    dashboard_month_janv: "Jan",
    dashboard_month_fevr: "Feb",
    dashboard_month_mars: "März",
    dashboard_month_avr: "Apr",
    dashboard_month_mai: "Mai",
    dashboard_month_juin: "Juni",
    dashboard_month_juill: "Juli",
    dashboard_month_aout: "Aug",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Okt",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dez",

    signup_main_text: "Bestellen Sie eine beglaubigte Übersetzung bei HIERØ ",
    cdu: "Indem Sie auf „Abonnieren“ klicken, akzeptieren Sie unsere ",
    cdu1: "Allgemeine Nutzungs- und Verkaufsbedingungen",
    cdu2: "Datenschutzbestimmungen",
    cdu3: "sowie",
    newsletter:
      "Ich stimme zu per E-Mail Update, Sonderangebote und Neuigkeiten auf der Plattform und/oder anderer von Hiero entwickelten Produkten zu erhalten.",

    /* online: "Bleibe eingeloggt", */
    online: "Verbunden bleiben",
    login_text:
      "Stellen Sie über die Hierø-Benutzeroberfläche Ihre Übersetzung Anfragen und wählen Sie Ihren vereidigten Übersetzer mit einem Klick aus.",
    ticket_numero: "Ticketnummer",
    intervention_time_date: "Datum und Uhrzeit",
    close_modal: "Schliessen",
    inscription_date: "Mitglied seit ",
    loading_order: "Meine aktuellen Übersetzungsanfragen",
    view_all_orders: "Alle Aufträge anzeigen",
    last_payment: "Meine letzten Zahlungen",
    view_all_payment: "Alle meine Zahlungen anzeigen",
    last_send_msg: "Letzte gesendete Nachrichten",
    order_translate: "von ",
    order_translate_to: "ins ",
    price_unitaire: " Preis(ohne MwSt.)",
    price_total_ht: "Gesamtpreis (ohne MwSt.)",
    montant_total_euro: "Gesamtpreis in EURO",
    rules_conditions: "Zahlungsbedingungen",
    rules_conditions_p: "Zahlung bei Rechungseingang",
    login_caption_title_pro: "Hierø - Interface Fachkräfte",
    login_main_title_pro: "Interface Fachkräfte",
    login_main_subtitle_pro: "Ihr Zugang",
    signup_caption_title_pro: "Hierø - Interface Fachkräfte",
    signup_main_subtitle_pro: " Bitte füllen Sie das folgende Formular aus!",

    agency_setup_title_pro: "Ihr Unternehmen",

    agency_setup_explanation_pro:
      "Finden Sie dank unserer Lösung schnell einen vereidigten Übersetzer. Lassen Sie Ihre Dokumente schnellstens übersetze!",

    main_header_subtitle_pro: "Interface Fachkräfte",

    main_left_menu_title_pro: "Zugang für Fachkräfte",

    main_left_menu_section_navigation_order: "Meine Aufträge",

    order_id: "Benutzer",

    order_language_from: "Von",

    order_language_to: "Nach",

    order_status: "Status",
    new_orders: "Eine Bestellung aufgeben",

    order_traductor_delegate: "Übersetzer",

    new_order: "Übersetzung in Auftrag geben",

    new_order_ass: "Beeidigte Übersetzung in Auftrag geben",

    section_order_title: "Meine Aufträge",

    section_card_title: "Abonnement",

    state_pro_wait: "Warten",

    sold: "Guthaben",

    account_expl_pro_cheque: "Schicken Sie uns einen Scheck",

    account_expl_pro_viremement: "Überweisung",

    account_expl_pro_iban: "IBAN DE 000",

    account_hist_pro_date: "Datum der Mission",

    account_hist_pro_debit: "Lastschrift",

    account_hist_pro_credit: "Gutschrift",

    account_hist_pro_way: "Zahlungsmittel ",

    account_hist_pro_ref: "Referenz ",

    account_budget: "Budget ",

    account_budget_placeholder: "",

    account_budget_help: "",

    label_origin_lang: "Ausgangssprache",

    select_choose: "Wählen Sie...",

    find_traductors: "Übersetzer finden",

    tasks_price_ttc: "Preis (ohne MwSt.)",

    tasks_price_tva: "MwSt.",

    order_no_asser_document_name: "Dokumentenname",

    order_no_asser_query_pdf_page_number:
      "Enthält Ihr Dokument mehr als eine Seite?",

    order_no_asser_query_pdf_page_number_yes: " Geben Sie die Seitenanzahl an",

    no_traductor: "Momentan sind keine Übersetzer verfügbar! ",

    no_traductor_message: "Fenster schließen und erneut versuchen",

    loading: "In Bearbeitung ...",

    menu: "Aufträge anzeigen von",

    menu_label_traductor_asser: "Beeidigte Übersetzung",

    menu_label_traductor_classic: "Klassische Übersetzung",

    menu_label_interprestation: "Dolmetscher",

    menu_label_all: "Alle anzeigen",

    alert_form_error_pages_number: "Die Seitenanzahl kann nicht negativ sein",

    urgency: "Angefragter Liefertermin",

    test: "",

    message_no_budget: "Ihr aktuelles Budget beträgt ",

    message_contact: "Kontaktieren Sie uns, um Ihr Budget zu ändern.",

    agency_pro_budget_help: "Budget für Ihre Übersetzungen.",

    //Subscription's translate

    sub_training: "Schulung Ihrer Mitarbeiter ",

    sub_search: "Automatische Suche eines Übersetzers / Dolmetschers ",

    sub_geo: "Geolokalisierung des Übersetzers / Dolmetschers",

    sub_dem: "Digitalisierung Ihrer Aufträge ",

    sub_save: "Speicherung Ihrer Dokumente mit dem Datei Manager",

    sub_recap: "Zusammenfassung der monatlichen Aufträge ",

    sub_proj: "Bearbeitungsstatus Ihrer Aufträge in Echtzeit ",

    sub_management: "Budgetverwaltung in Echtzeit ",

    sub_message: "Instant Messaging",

    sub_send: "Lieferung Ihrer Übersetzung per Post ",

    sub_impl: "12 Monate Budget ",

    sub_account: "persönlicher Account Manager ",

    sub_orientation:
      "Personalisierte Ausrichtung nach den am häufigsten angefragten Sprachen ",

    sub_split:
      "Aufteilung des Budgets in mehrere Abteilungen (nach Übersetzer) ",

    sub_event: "Abholung durch Kurierdienst ",

    choose: "Wahlen Sie",

    sub_function: "Inhalt ",

    sub_standard: "Standard",

    sub_essen: "Essentiell",

    sub_premium: "Premium",

    sub_month: "Monat",

    sub_subscription: "Abonnement",

    sub_associations_label: "Assoziationen",

    sub_cabinet_label: "Anwaltskanzleien",

    sub_bank_label: "Internationale Mobilitä",

    sub_academia_label: "Akademische Einrichtungen",

    sub_who_are_you: "Sie sind?",

    sub_explanation_academia:
      " Wir arbeiten bevorzugt in Form von Partnerschaften mit akademischen Einrichtungen wie Universitäten, Internationalen Schulen ...",

    sub_explanation_systeme: "2 mögliche Systeme: ",

    sub_system_one: "Nutzung der mobilen HIERO App.",

    sub_system_two: "Nutzung des Interfaces für Fachkräfte.",

    sub_credit_card_message:
      "Aktivieren Sie unsere Dienstleistungen durch die Zahlung Ihres Abonnements ",

    agree: "Akzeptieren ",

    our_term: "Unsere Konditionen",

    and_the: "und der",

    stripe_agreement: " Vertrag Stripe. ",

    created_at: "Erstellt am ",

    updated_at: "Aktualisiert am ",

    validate_credit_card_message: "Ihr Bestellung wurde berücksichtigt",

    label_assermentation: " Vereidigung ",
    agency_expl_4_expl:
      " Bevor Sie unsere Dienste nutzen können, ist es notwendig, dass Sie einen Nachweis Ihrer Vereidigung erbringen. ",
    agency_expl_4_expl_1: " Senden Sie eine E-Mail : ",
    agency_expl_4_expl_2:
      "Bei Fragen zu diesem Verfahren, zögern Sie nicht und kontaktieren Sie uns. ",

    assermente: " Vereidigte(r)",

    subscriber_choose: "Wählen Sie Ihr Abonnement",
    services_none_yet_subscribe:
      "Abonnieren Sie sich um Ihre Dienstleistungen freizuschalten",
    asser_placeholder: "Sind Sie ein:e vereidigte:r Übersetzer:in?",
    find_out_more: "Erfahren Sie mehr",
    description: "Beschreibung",

    menu_label_relecture: "Korrekturlesen ",

    // end pro by brin

    login_caption_title: "Hierø - Interface Übersetzer / Dolmetscher",

    login_caption_subtitle:
      "Die Software entwickelt von Übersetzern für Übersetzer.",

    login_main_logo: "Hierø",

    login_main_title: "Interface Übersetzer / Dolmetscher",

    login_main_subtitle:
      " Behalten Sie die Kontrolle über Ihre Aufträge und Übersetzungen dank dem HIERO Interface Übersetzer / Dolmetscher!",

    login_main_connect: "Anmelden",

    login_main_email: "E-Mail",

    login_main_email_placeholder: "E-Mail-Adresse einfügen",

    login_main_email_help: "Melden Sie sich mit Ihrer E-Mail-Adresse an.",

    login_main_password: "Password",

    login_main_password_placeholder: "Geben Sie Ihr Passwort ein",

    login_main_password_help: "",

    login_main_button: "Anmelden",

    login_main_to_signup: "Noch nicht registriert? Registrieren Sie sich!",

    login_main_copyright: "© Hierø - HIERØ 2020",

    login_error_required_field: "Pflichtfeld",

    login_error_invalid_email: " Die eingegebene E-Mail-Adresse ist ungültig.",

    login_error_email_not_found: "Die E-Mail-Adresse existiert nicht.",

    signup_caption_title: "Hierø - Interface Übersetzer",

    signup_caption_subtitle:
      "Wählen Sie Hierø und erweitern Sie Ihren Horizont!",

    signup_main_logo: "Hierø",

    signup_main_title: "Registrierung",

    signup_main_subtitle:
      " Erstellen Sie Ihren Zugang, nutzen Sie unser Interface und werden Sie sichtbar für eine Vielzahl an Nutzern!",

    signup_main_email: "E-Mail-Adresse",

    signup_main_email_placeholder: "",

    signup_main_email_help: "",

    signup_main_password: "Passwort",

    signup_main_password_placeholder: "",

    signup_main_password_help: "",

    signup_main_confirm: "Bestätigen Sie Ihr Passwort",

    signup_main_confirm_placeholder: "",

    signup_main_confirm_help: "",

    signup_main_surname: "Name",

    signup_main_surname_placeholder: "",

    signup_main_surname_help: "",

    signup_main_name: "Vorname",

    signup_main_name_placeholder: "",

    signup_main_name_help: "",

    signup_main_telephone: "Telefon",

    signup_main_telephone_placeholder: "",

    signup_main_telephone_help: "",

    signup_main_button: "Registrieren",

    signup_main_to_signup: "Bereits registriert? Melden Sie sich an",

    signup_main_copyright: "© Hierø - HIERØ 2020",

    signup_error_required_field: "Pflichtfeld",

    signup_error_invalid_email: "Die eingegebene E-Mail-Adresse ist ungültig.",

    signup_error_email_not_found: "Die E-Mail-Adresse existiert nicht.",

    signup_error_min_password: "Mindestens 8 Zeichen",

    signup_error_max_password: "Maximal 30 Zeichen",

    signup_error_passwords_do_not_match: "Die Passwörter stimmen nicht überein",

    login_forgot_password: "Passwort vergessen?",

    reset_password_main: "Passwort wiederherstellen",

    reset_password_email_title: "E-Mail-Adresse",

    reset_password_email_placeholder: "",

    reset_password_email_help: "",

    reset_password_send: "Gesendet",

    reset_password_login: "Anmelden",

    reset_password_success:
      " Eine Nachricht wurde an Ihre E-Mail-Adresse gesendet. Klicken Sie auf den Link, um Ihr Passwort zu ändern.",

    reset_password_error_required: "Pflichtfeld",

    reset_password_error_invalid_email:
      "Die eingegebene E-Mail-Adresse ist ungültig ",

    reset_password_error_user_not_found: "Die E-Mail-Adresse existiert nicht.",

    main_header_title: "Hierø -",
    main_header_title_particulier:
      "Fordern Sie Ihre beeidigten übersetzungen bei Hierø an",
    main_header_subtitle: "Interface Übersetzer / Dolmetscher",
    main_header_user_menu_logout: "Abmelden",
    main_left_menu_title_asser: "Beeidigte Übersetzung", //
    main_left_menu_title: "Übersetzer / Dolmetscher",
    main_left_menu_section_navigation: "Navigation",
    main_left_menu_section_accueil: "Startseite",
    main_left_menu_section_navigation_missions: "Meine Aufträge",
    main_left_menu_section_parameters: "Einstellung",
    main_left_menu_section_compte: "Zugang",
    main_left_menu_section_parameters_agency: "Mein Unternehmen",
    main_left_menu_section_parameters_services: "Mein Service",
    main_left_menu_section_parameters_user: "Mein Profil",
    main_left_menu_section_parameters_card: "Mein Abonnement",
    main_left_menu_section_parameters_support: "Hilfe",

    agency_setup_title: "Über Ihre Agentur",
    agency_setup_explanation:
      " HIERO erleichtert die Sichtbarkeit Ihrer Dienstleistungen für potenzielle Kunden in Ihrer Nähe. <br> Bitte geben Sie Ihren Firmennamen und Ihre E-Mail-Adresse an. <br> Diese Informationen werden den Kunden angeboten, wenn sie einen Übersetzer suchen.",

    agency_setup_companyname: "Name des Unternehmens",

    agency_setup_companyname_placeholder: "",

    agency_setup_companyname_help:
      " Der Name des Unternehmens, der auf Ihren Auftragsbestätigungen, Rechnungen usw. erscheinen soll.",

    agency_setup_address: "Adresse",

    agency_setup_siret: "USt-ID",

    agency_setup_siret_placeholder: "",

    agency_setup_siret_help: "Geben Sie Ihre SIRET an ",

    agency_setup_address_placeholder: "",

    agency_setup_address_help:
      "Tragen Sie Ihre Adresse ein und wählen Sie die korrekte Adresse aus der erscheinenden Liste.",

    agency_setup_addresscompl: "Adresszusatz",

    agency_setup_addresscompl_placeholder: "",

    agency_setup_addresscompl_help:
      "Gebäude, Treppenhaus, Zugangscode, usw. Falls Ihre Kunden Ihre Übersetzungen vor Ort abholen möchten, werden so viele Informationen wie möglich benötigt.",

    agency_setup_tel: "Telefon",

    agency_setup_tel_placeholder: "",

    agency_setup_tel_help: "Die Telefonnummer auf der Sie erreichbar sind",

    agency_setup_email: "E-Mail-Adresse",

    agency_setup_email_placeholder: "",

    agency_setup_email_help:
      " Die E-Mail-Adresse mit der Sie erreichbar sind für Ihre Kunden. Geben Sie eine andere E-Mail-Adresse an, falls es sich nicht um die E-Mail-Adresse handelt, mit der Sie Ihren Zugang erstellt haben.",

    agency_setup_email_help_pro:
      " Die E-Mail-Adresse mit der Sie erreichbar sind für HIERO und andere Übersetzer / Dolmetscher.",

    agency_setup_save: "Speichern",

    agency_setup_error_required_field: "Pflichtfeld",

    agency_setup_error_invalid_email:
      " Die eingegebene E-Mail-Adresse ist ungültig.",

    agency_setup_error_valid_address:
      " Wählen Sie eine vorgeschlagene Adresse aus der Dropdown-Liste.",

    agency_address_not_found: "Ihre Adresse ist nicht gelistet?",

    agency_address_title: "Geben Sie Ihre Adresse ein",

    agency_address_address: "Adresse:",

    agency_address_number: "Nr.",

    agency_address_street: "Straße",

    agency_address_code: "Postleitzahl",

    agency_address_city: "Stadt",

    agency_address_country: "Land",

    agency_address_coords: "GPS-Koordinaten:",

    agency_address_coords_lat: "Breitengrad",

    agency_address_coords_long: "Längengrad",

    agency_address_cancel: "Abbrechen",

    agency_address_validate: "Bestätigen",

    //mamadou
    main_left_menu_section_navigation_dashboard: "Dashboard",
    main_menu_left_section_navigation_entreprise: "Mein Unternehmen",
    main_menu_left_section_navigation_factures: "Meine Rechnungen",
    main_menu_left_section_navigation_messages: "Meine Nachrichten",
    main_menu_left_section_navigation_messagerie: "Instant-Messaging",
    main_menu_left_section_navigation_gestion_des_fichiers: "Ordner",
    main_menu_left_section_navigation_assistance: "Hilfe",
    main_menu_left_section_navigation_ticket: "Erstellen Sie ein Ticket",
    main_menu_left_section_navigation_question: "Fragen?",
    head_facture: "Rechnung",
    section_date: "Datum",
    section_numero_facture: "Rechnungsnummer",
    section_type_prestation: "Art der Leistung",
    section_prestation: "Leistung",
    section_price: "Total (+ MwSt.)",
    section_print: "Ausdrucken",
    section_generate_facture: "PDF-Rechnung erstellen",
    section_consulter: "Konsultieren",
    section_questions_frequentes: "Häufig gestellte Fragen",
    section_questions_profil_entreprise:
      "Warum mein Profil und mein Unternehmen differenzieren?",
    section_questions_paragraphe_one:
      "„Mein Profil“ ist nur für Sie sichtbar. « Mein Unternehmen » betrifft alle Mitarbeiter die einen Zugang besitzen. Nur Abteilungsleiter können neue Zugänge hinzufügen und Budgetänderungen vornehmen.",
    section_questions_original:
      " Ich habe bereits die digitale Version der Übersetzung… Warum benötige ich das Original?",
    section_questions_paragraphe_two:
      "Die herunterladbare Version der Übersetzung hat keinen Rechtswert und kann daher nicht für Verfahren verwendet werden, die eine Übersetzung durch einen vereidigten Übersetzer erfordern. Die Originalversion der Übersetzung ist die Einzige, die von den zuständigen Behörden akzeptiert wird.",
    section_questions_tarducteur:
      "Was passiert, wenn der ausgewählte Übersetzer nicht antwortet?",
    section_questions_paragraphe_three:
      "Theoretisch sind die angezeigten Übersetzer diejenigen, die zur Bearbeitung Ihres Auftrags zur Verfügung stehen. Es kann vorkommen, dass manche zu lange brauchen, um zu antworten. In diesem Fall leitet Sie unsere Software an einen Übersetzer in Ihrer Nähe weiter, der sich um Ihren Auftrag kümmert. ",
    section_questions_price_deplacement:
      "Sind die Reisekosten im vom Tool genannten Preisen inbegriffen?",
    section_questions_paragraphe_four:
      " Jeder Dolmetscher erhält einen unterschiedlichen Betrag, abhängig von der Anzahl der zurückzulegenden Kilometer. Der Betrag ist im Preis enthalten. Die genaue Höhe des Betrags finden Sie in den Bestelldetails",
    section_questions_gestions_files: "Was ist der Datei-Manager?",
    section_questions_paragraphe_five:
      "Der Datei-Manager ermöglicht es Ihnen alle Ihre herunterladbaren Dateien an einem Ort zu finden. Ihre Dateien können nach Dateityp oder Datum gefunden werden. Sie können Ordner erstellen, um die Dateien abzuordnen.",
    section_questions_contact:
      "Ist es möglich, den Übersetzer bei Fragen oder Informationsbedarf zu kontaktieren ?",
    section_questions_paragraphe_six:
      "Absolut! Sie haben Zugriff auf den Chat, wenn Sie auf Bestelldetails oder Nachrichten klicken. Der Chat dient Ihnen ebenfalls zur Vereinbarung der Dokumentübergabe.",
    section_questions_traductions:
      "Warum unterscheidet sich der klassische Übersetzungsdienst von dem beeidigten Übersetzungsdienst?",
    section_questions_paragraphe_seven:
      "Da die klassischen Dokumente eher zufällig sind, kann durch den Algorithmus kein Modell regeneriert werden. Der Kunde sendet sein Dokument, das unter Bestelldetails heruntergeladen werden kann. Nach der Übersetzung lädt der Übersetzer sein Dokument hoch und sendet es an den Kunden zur Bestätigung. ",
    section_questions_budget:
      "Auf welche Art und Weise können wir das für unsere Aufträge benötigte Budget verwalten?",
    section_questions_paragraphe_eight:
      "Ihr Unternehmen oder Abteilung weist Ihnen für Ihre Aufträge ein vorgesehenes Budget zu. Wir gehen davon aus, dass Ihr Vorgesetzter alle Budgetbewegungen verwaltet. Dementsprechend ist er zuständig für die Gutschrift oder Belastung Ihres Kontos über die entsprechende Funktion.",
    head_particulier:
      "Die Antworten auf Ihre Fragen finden Sie möglicherweise auf dieser Seite. Falls nicht, zögern Sie nicht und öffnen ein Ticket.",
    partuclier_trad_doc_head1:
      "Der Übersetzer hat mein Dokument nicht geliefert. Was ist zu tun?",
    partuclier_trad_doc1:
      "Sie können versuchen ihn über den Chat zu kontaktieren. Falls der Übersetzer Ihnen nicht antwortet, wenden Sie sich an den technischen Support. Der Übersetzer muss einen triftigen Grund nennen, warum er Ihr Dokument nicht innerhalb der geforderten Frist liefern konnte.",
    partuclier_trad_doc_head2:
      " Ich habe bereits die digitale Version der Übersetzung… Warum benötige ich das Original?",
    partuclier_trad_doc2:
      " Die herunterladbare Version der Übersetzung hat keinen Rechtswert und kann daher nicht für Verfahren verwendet werden, die eine Übersetzung durch einen vereidigten Übersetzer erfordern. Die Originalversion der Übersetzung ist die Einzige, die von den zuständigen Behörden akzeptiert wird. ",
    partuclier_trad_doc_head3:
      " Was passiert, wenn der ausgewählte Übersetzer nicht antwortet?",
    partuclier_trad_doc3:
      " Theoretisch sind die angezeigten Übersetzer diejenigen, die zur Bearbeitung Ihres Auftrags zur Verfügung stehen. Es kann vorkommen, dass manche zu lange brauchen, um zu antworten. In diesem Fall leitet Sie unsere Software an einen Übersetzer in Ihrer Nähe weiter, der sich um Ihren Auftrag kümmert.  ",
    partuclier_trad_doc_head4:
      "Ich kann mein Dokument nicht beim Übersetzer abholen.",
    partuclier_trad_doc4:
      "Wenn Sie das Original nicht abholen können, kann Ihnen der Übersetzer das Dokument jederzeit per Post schicken. ",
    partuclier_trad_doc_head5:
      "Die Übersetzung, die ich erhalten habe, scheint von schlechter Qualität, falsch oder unvollständig zu sein.",
    partuclier_trad_doc5:
      "Bewahren Sie das Dokument als Beweis auf und kontaktieren Sie Übersetzer zur Klärung. Weigert sich der Übersetzer Änderungen vorzunehmen, wenden Sie sich bitte an den technischen Support.",
    partuclier_trad_doc_head6:
      "Was tun, falls die Software keinen Übersetzer in der Nähe ausfindig machen kann?",
    partuclier_trad_doc6:
      "Es kann vorkommen, dass für die ausgewählte Sprachen kein sich in Ihrer Nähe befindenden Übersetzer aufgeführt werden. Wenden Sie sich an den technischen Support oder wählen sie einen sich entfernter befindenden Übersetzer aus.",
    agency_support: "Technische Hilfe",
    agency_support_service_client: "Telefonnummer - Kundenservice",
    agency_support_ouverture:
      "Geöffnet von Montag – Freitag zwischen 9h00 -17h30",
    agency_support_questions:
      " Haben Sie Fragen oder Fehlermeldungen? Senden Sie uns eine E-Mail? ",
    agency_support_guide: "Gebrauchsanleitung Hierø",
    agency_support_guide_pro: "Handbuch für Fachkräfte",
    agency_support_guide_p:
      " Finden Sie unsere Gebrauchsanleitung. Haben Sie einen Schritt übersehen. Sie möchten wissen wie unsere Plattform funktioniert?",
    agency_support_download_guide:
      "Laden Sie unsere Gebrauchsanweisung herunter",
    agency_ticket_title: "Mein altes Ticket",
    agency_ticket_objet: "Objekt",
    agency_ticket_question: "Ihre Frage",
    agency_ticket_state: "Status",
    agency_ticket_head:
      "Brauchen Sie Hilfe? Haben Sie keine Antwort in der FAQ? Stellen Sie uns Ihre Fragen. Wir antworten in so schnell wie möglich.",
    my_info: "Meine Daten",
    consult_orders: "Meine Bestellungen ansehen",
    discuss_encours: "Laufende Gespräche",

    status_orders: "Auftragsstatus",
    final_intervention: "Intervention abschließen",
    finaliser_commande: "Auftrag abschließen",
    visioner: "Ansehen",
    single_commande_paragraphe:
      " Wenn der Übersetzer Ihren Auftrag angenommen hat und die Übersetzung abgeschlossen ist, müssen Sie die Übersetzung lediglich bestätigen. Im Falle einer Ablehnung durch den ausgewählten Übersetzer leitet unsere Software Ihren Auftrag automatisch an einen anderen Übersetzer weiter.",
    single_commande_p1:
      " Der Übersetzer lädt das zu übersetzende Dokument herunter ",
    single_commande_p2:
      " Nach der Fertigstellung wird die Übersetzung hinzugefügt, damit sie von Ihnen bestätigt werden kann.",
    accept_intervention: "Intervention akzeptieren ",

    load_intervention: "Intervention laufend",

    delai_intervention: "Dauer der Intervention",

    commande_discuss: "Diskutieren Sie mit Ihrem Übersetzer",

    commande_details: "Details Ihrer Bestellung",

    intervention_details: "Details Ihrer Intervention",

    commande_receive: "Bestellung erhalten am ",

    type_doc_commande: "Angefragte Dokumentenart",

    doc_a_traduire: " zu übersetzendes Dokument ",

    trad_name: "Name des Übersetzers",

    capital_sasu: " SASU mit Stammkapital von 7 000,00 €",

    rcs_infos: "RCS EVRY 843119728 ",

    siret: " ",

    tva_fr: " USt-IdNr: FR00843119728",

    condition_paragraphe_1: "Verzugszinsen (Jahresrate): 10%",

    condition_paragraphe_2: "Kein Skonto möglich",

    condition_paragraphe_3:
      " Pauschaler Schadensersatz für Beitreibungskosten bei Zahlungsverzug: 40€",

    prestataire: "Leistungserbringer",

    geolocation_paragraphe:
      " Die Softaware benötigt Ihre Position, um Ihnen die nächstgelegenen Übersetzer anzuzeigen.",
    update_password: "Passwort ändern",
    delete_account: "Zugang löschen",
    account_paragraphe1: "Sie haben keinen Bedarf mehr an Übersetzungen?",
    account_paragraphe2:
      " Sie möchten unsere Dienstleistung nicht weiter nutzen?",
    account_paragraphe3: "Sie können sich jeder Zeit abmelden",
    account_delete: "Ich möchte meinen Zugang löschen",
    account_upt: "Falls Sie es wünschen, können Sie Ihr Passwort ändern",
    send: "Senden",
    message_paragraphe:
      "Chatten Sie mit dem Übersetzer, um Informationen über den aktuellen Auftrag zu erhalten.",
    find_interpertor: "Dolmetscher finden",

    //end mamadou

    section_agency_title: "Mein Unternehmen",

    section_services_title: "Meine Dienstleistungen",

    section_account_title: "Mein Profil",

    section_support_title: "Hilfe",
    section_dashboard_title: "Dashboard",

    section_tasks_title: "Meine Aufträge",

    section_task_title: "Auftrag ausführen",

    section_document_title: "Dokument übersetzen",

    notifications_new_task: "Sie haben einen neuen Auftrag!",

    notifications_task_confirmed:
      " Der Auftrag wurde bestätigt. Sie können die Übersetzung beginnen.",

    notifications_task_validated:
      " Der Auftrag wurde von Ihrem Kunden bestätigt.",

    tasks_filters: "Filter",

    tasks_sent_on: "Gesendet am",

    tasks_src_lang: "Ausgangssprache",

    tasks_src_country: "Ursprungsland",

    tasks_dst_country: "Zielsprache",

    tasks_state: "Status",

    tasks_price: "Preis (ohne MwSt.)",

    state_cancelled: "storniert vom Kunden",

    state_waiting_for_translator: "Neu",

    state_waiting_for_payment: "Warten auf die Zahlung",

    state_refused_by_translator: "Auftrag abgelehnt",

    state_translating: "Übersetzung in Arbeit",

    state_waiting_for_client_validation:
      "Fertig. Warten auf die Bestätigung des Kunden.",

    state_validated: "Bestätigt vom Kunden",

    state_validated_pro: "Bestätigt",
    state_accept_order: "Auftrag akzeptiert",

    prestation_breadcrumb_tasks: "Meine Aufträge",

    prestation_order_number: "Auftragsnummer:",

    prestation_next_action_title: "Nächste Schritte",

    prestation_next_action_accept_order: "Auftrag akzeptieren",

    prestation_next_action_accept_order_expl:
      " Klicken Sie hier, um die Bestellung zu akzeptieren. Der Kunde wird dann mit der Zahlung fortfahren. Sobald die Zahlung eingegangen ist, können Sie mit der Übersetzung beginnen.",

    prestation_next_action_reject_order: "Auftrag ablehnen",

    prestation_next_action_reject_order_expl:
      "Klicken Sie hier dem Auftrag abzulehnen.",

    prestation_next_action_rejected: "Sie haben den Auftrag abgelehnt",

    prestation_next_action_waiting_for_payment:
      "Warten auf die Zahlung des Kunden.",

    prestation_next_action_translate_expl:
      "Bitte übersetzen Sie jedes Dokument dieser Bestellung, indem Sie auf die Schaltfläche 'Übersetzen' klicken. Wenn alle Dokumente fertig sind, klicken Sie auf die Schaltfläche 'senden'. Bei Fragen oder Unklarheiten können Sie direkt mit dem Kunden über das Instant Messaging kommunizieren.",

    prestation_next_action_translate_all_done_expl:
      " Sie haben alle Dokumente des Auftrags erledigt.",

    prestation_next_action_translate_confirm:
      "Senden zur Bestätigung des Kunden",

    prestation_next_action_waiting_for_validation:
      "Der Kunde bestätigt momentan die Übersetzung.",

    prestation_next_action_validated: "Auftrag bestätigt durch den Kunden ",

    prestation_order_details: "Detail des Auftrags:",

    prestation_order_from: "Von",

    prestation_order_to: "an",

    prestation_order_country_origin: "Ursprungsland:",

    prestation_order_document_count: "Zu übersetzendes Dokument:",

    prestation_order_price: "Preis (ohne MwSt.):",

    prestation_order_date: "Auftrag erstellt am:",

    prestation_order_documents_title: "Zu bearbeitendes Dokument",

    prestation_order_document_type: "Dokumentenart",

    prestation_order_document_originals: "Originale",

    prestation_order_document_translated: "Übersetzung(en)",

    prestation_order_document_not_yet_translated: "noch nicht übersetzt",

    prestation_order_document_translate: "Übersetzen!",

    prestation_order_messaging_title: "Nachrichten",

    prestation_order_messaging_send: "Gesendet",

    prestation_order_messaging_client: "Kunden:",

    prestation_order_messaging_me: "Ich:",

    prestation_order_confirm_ok: "Ok",

    prestation_order_confirm_cancel: "Stornieren",

    prestation_order_validate_title: "Bestätigt",

    prestation_order_validate_msg:
      " Sind Sie sicher den Auftrag bestätigen zu wollen? Der Kunde erhält somit eine Benachrichtigung und folglich Zugriff auf die übersetzten Dokumente.",

    prestation_order_accept_title: "Bestätigen",

    prestation_order_accept_msg:
      "Sind Sie sicher den Auftrag akzeptieren zu wollen?",

    prestation_order_refuse_title: "Bestätigen",

    prestation_order_refuse_msg:
      "Sind Sie sicher den Auftrag ablehnen zu wollen?",

    prestation_document_validate: "Übersetzung bestätigen!",

    prestation_document_show_original:
      " Original in einem anderen Fenster ansehen:",

    prestation_document_edit: "Dokument-Editor",

    prestation_document_edit_expl:
      " Der Text im obigen Feld ist im folgenden Dokument unterstrichen. Durch die Eingabe des übersetzten Textes in das Feld wird das Dokument automatisch aktualisiert. Verwenden Sie die Pfeile, um sich durch das Dokument zu navigieren.",

    prestation_document_get_browser:
      " Sie sehen keine PDF-Datei? Verwenden Sie den aktuellsten Webbrowser wie",

    prestation_document_button_reset: "Initialisierung des Dokuments",

    prestation_document_confirm_title: "Bestätigen",

    prestation_document_confirm_message:
      " Sind Sie sicher die Übersetzung initialisieren zu wollen? Alle Ihre Änderungen gehen so verloren!",

    prestation_document_confirm_oui: "Ja",

    prestation_document_confirm_non: "Nein",

    account_expl:
      " Geben Sie Ihre Kontaktdaten an, um Sie bei Bedarf kontaktieren zu können.",
    account_save: "Speichern",
    account_surname: "Name",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Vorname",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Telefon",
    account_telephone_placeholder: "",
    account_telephone_help: "",
    account_poste: "Position",
    account_poste_help: "",
    account_adresse: "Adresse",
    account_adresse_help: "",
    account_adresse_placeholder: "Adresse",
    account_service: "Service",
    account_service_help: "",
    account_service_placeholder: "Service",
    account_complement_adr: "Adresszusatz",
    account_complement_adr_help: "",
    account_complement_adr_placeholder: " Adresszusatz ",
    account_email: "E-Mail-Adresse",
    account_email_help: "",
    account_email_placeholder: " E-Mail-Adresse ",
    account_password: "Aktuelles Passwort",
    account_password_help: "",
    account_new_password: "Neues Passwort",
    account_new_password_help: "",
    account_error_required: "Pflichtfeld",

    agency_expl_1_title: "Firmennamen",
    agency_expl_1_expl:
      " Geben Sie exakt den Namen Ihres Unternehmens an, damit Sie besser von Ihren Kunden gefunden werden können.",

    agency_expl_2_title: "Geolokalisierung",

    agency_expl_2_expl:
      " Die Software benötigt Ihre Kontaktdaten, damit Sie an die nächstgelegenen Kunden vermittelt werden können.",

    agency_expl_3_title: "Zugangsaktivierung",

    agency_expl_3_expl:
      " Ihr Profil wird sichtbar durch die Speicherung Ihrer Informationen.",

    services_add_button: "Neues Angebot hinzufügen...",

    services_none_yet:
      " Um auf der HIERO Plattform sichtbar zu sein, geben Sie präzise Ihre Dienstleistungen an. ",

    services_add_expl:
      " Geben Sie Ihre Sprachen an und klicken Sie auf ‘Hinzufügen’.",

    services_add_src_lang: "Ausgangssprache",

    services_dst_lang: "Zielsprache",

    services_add: "Hinzufügen!",

    services_error_add:
      " Die ausgewählte Sprachkombination ist momentan nicht möglich. Kontaktieren Sie uns für mehr Informationen!",

    services_dont_see_title: "Ich kann meine Sprachen nicht aufrufen.",

    services_dont_see_response:
      " Hierø erleichtert Ihnen Ihre Übersetzungsarbeit, indem wir Ihnen gebrauchsfertige Vorlagen für Ihre Dokumente zur Verfügung stellen. Wir entwickeln unser Dokumentenangebot ständig weiter. Kontaktieren Sie uns <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> um zusätzliche Sprachen anzufordern.",

    services_list_title: "Ihre Dienstleistungen",

    services_expl:
      "Klicken Sie auf jedes Angebot, um die von Ihnen verwalteten Dokumente anzupassen. Sie können entweder einen bestimmten Dokumententyp durch Deaktivieren des Buttons ablehnen oder Ihren persönlichen Preis für die Bearbeitung dieses Dokumententyps angeben.",

    services_list_active: "Aktiviert",

    services_list_country: "Land",

    services_list_document_type: "Dokumentenart",

    services_list_average_price: "Durchschnittspreis (ohne MwSt.)",

    services_list_your_price: "Ihr Preis (ohne MwSt.)",

    services_list_your_price_reset: "Initialisierung",

    services_list_delete: "Löschen",

    support_title: "Unser Büro",
    support_company: "Hiero",
    support_addr1: "86 Rue de Paris",
    support_addr2: "91400 Orsay",
    support_addr3: "Frankreich",
    support_contact: "Kontakt",
    support_tel: "Tel: (+33) 01 76 38 01 40",
    support_tel_es: "Tel : (+34) 911 23 88 87",
    support_tel_en: "Tel : (+1) 929 274 5104",
    support_email:
      "Mail: <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a>",

    not_found_title: "Element kann nicht gefunden werden",
    not_found_subtitle: " Die gewünschte Seite existiert nicht ",
    not_found_action: "Kontaktieren Sie",
    not_found_action1: "Das Problem besteht weiterhin. ",
    not_found_home: "Startseite",

    // Interpretation

    menu_label_interpretation: "Dolmetscher beauftragen",
    menu_label_intervention: "Die Intervention soll stattfinden",
    type_intervention: "Art der angefragten Mission",
    menu_label_event_name: "Name der Intervention",
    nb_heures_estimee: "Geschätzte Dauer in Stunden",
    menu_label_sur_place: "Vor Ort",
    menu_label_visio: "Via Videokonferenz",
    menu_langue: "Sprachen",
    menu_head_ticket: "Meine Tickets",
    table_header_type_de_document: "Dokumentenart",
    table_header_nb_pages: "Seitenanzahl",
    table_header_statut: "Status",
    table_header_archives: "Archive Ihrer Aufträge",
    table_hader_details: "Details",
    head_professionnal:
      " Die Software benötigt Ihre Position um Sie an die nächstgelegenen Übersetzer / Dolmetschers vermitteln zu können ",
    head_change_password: "Falls gewünscht, können Sie Ihr Passwort ändern",
    professionnal_paragraph: "Von Ihrem Budget abgezogene Summen  ",
    professionnal_paragraph_1:
      " Alle Summen für jeden Auftrag, die von Ihrem Budget abgezogen wurden ",
    professionnal_paragraph_2: " Überweisung beauftragen ",
    professionnal_paragraph_3: "Scheck senden",
    professionnal_paragraph_4: "Auszahlung Ihres Guthabens",
    address_facturation: "Rechnungsadresse ändern",
    consulter_facture: "Meine Rechnungen ansehen",
    facture_numero: "Rechnungsnummer",
    recap_tva: " ZUSAMMENFASSUNG DER MWST",
    recap_taux: "RATE",
    recap_mt_tva: "BETRAG DER MWST",
    recap_base_ht: "BASIS OHNE MWST",
    recap_tva_fr: "19% MWTS DE",
    client_facture_detail: "Kunde",
    client_particulier_name: "Name",
    client_particulier_email: "E-Mail-Adresse",
    client_particulier_address: "Adresse ",
    client_particulier_code_postal: "Stadt",
    salle_de_conference: "Konferenzraum",
    joindre_salon: " In Konferenzraum eintreten",
    prestation: "Dienstleistung",
    commande_numer: "Auftragsnummer",
    date_commande: "Datum",
    commande_qte: "Menge",
    commande_produit: "Produkt",
    commande_desc: "Beschreibung",
    montant_total: "Total (+ MwSt.)",
    total_ht: "Total (+ MwSt.)",
    total_tva: "Total (+ MwSt.)",
    total_ttc: "Total (+ MwSt.)",
    retour_btn: "Zurück",
    progress_commande: "Auftragsfortschritt",
    commande_numero: "Auftragsnummer",
    commande_statut: "Auftragsstatus",
    commande_discuss: "Diskutieren Sie mit Ihrem Übersetzer / Dolmetscher",
    commande_details: "Auftragsdetails",
    commande_receive: "Auftrag erhalten am ",
    type_doc_commande: "Angefragte Dokumentenart",
    doc_a_traduire: " Zu übersetzendes Dokument",
    trad_asser: "Vereidigter Übersetzer / Dolmetscher",
    pays_origine: "Ursprungsland",
    visioner_doc: "Dokument ansehen",
    auth_doc:
      " Der Übersetzer kontrolliert die Authentizität des Dokuments vor der Übersetzung.",
    doc_bug:
      " Bei Problemen benachrichtigt Sie der Übersetzer über Instant Messaging.",
    infos_interv: "Zusätzliche Informationen zur Intervention",
    add_file: "Einen Anhang hinzufügen",
    infos_entrep: "",
    infos_commandes: "",
    action: "Aktion",
    client: "Kunde",
    hours: "Uhrzeit",
    join_room: "Konferenzraum betreten ",
    rooms: "Räume",
    warning: "Achtung! ",
    temps_ex: "geschätzte Dauer",
    question_inter: " Sind Sie Dolmetscher?",
    question_inter_price: "Preis pro Stunde:",
    question_inter_1: " Sie dolmetschen über unsere Software um ",
    calendar: " Kalender",
    calendar_hidden: "Kalender verbergen",
    conf: "Konferenz",
    warning_1: " Beachten Sie die Schreibweise des Namens des Konferenzraums.",
    interpretation_label_menu: "Dolmetschen",
    interpretation_label: "",
    interpretation_info: "Erläuterung",
    interpretation_paragraphe_1:
      " Um den Dolmetscher-Service wahrzunehmen, benötigen Sie mindestens zwei Fenster. Das erste Fenster dient zum Kontakt mit dem Dolmetscher. Das zweite Fenster dient zum Kontakt mit allen anderen Teilnehmern.",
    interpretation_paragraphe_2:
      " Um den Konferenzraum zu betreten, der Name des Konferenzraums ist ",
    interpretation_paragraphe_4:
      " Um den Dolmetscherraum zu betreten, der Name des Dolmetscherraums ist ",
    interpretation_paragraphe_3: ". Dort befinden sich alle Teilnehmer.",
    interpretation_paragraphe_5:
      ". Dort befinden sich Ihre Teilnehmer und Ihr Dolmetscher.",
    interpretation_paragraphe_6:
      " Zur korrekten Durchführung des Dolmetschereinsatz dieser Fernkonferenz ist es erforderlich, dass Redner und Dolmetscher nicht gleichzeitig im selben Raum sprechen.",
    interpretation_paragraphe_7:
      " Aus diesem Grund befinden Sie sich in zwei verschiedenen Räumen. Auf diese Weise hören die Teilnehmer nur Sie im Konferenzraum und Sie hören nur die Redner im Dolmetscherraum.",
    interpretation_paragraphe_8:
      " Aus Leistungsgründen beträgt die maximale Kapazität eines Raumes 50 Teilnehmer. Sie können beliebig viele Räume entsprechend Ihrer Zuhörerschaft erstellen und die Namen dieser Räume Ihrem Dolmetscher mitteilen.",
    interpretation_paragraphe_9:
      " Um Teilnehmer einzuladen, klicken Sie auf die Schaltfläche unten, um den Einladungslink zu kopieren und in Ihre Nachricht einzufügen. Beachten Sie dabei den Namen des Raums ",
    interpretation_paragraphe_10:
      " Klicken Sie hier, um den Link Ihrer Einladung zu kopieren",
    interpretation_paragraphe_11:
      " Klicken Sie hier, um Ihre Teilnehmer einzuladen",
    room_conf: " Konferenzraum",
    room_inter: "Dolmetscherraum",
    room_inter_pro: "Ihr Benutzername",
    once_page_price: "Ihr Tarif pro Seite",
    once_page_price_default: "Preis (ohne MwSt.)",
    once_page_information:
      " Wir bitten Sie, Ihren Preis für die Übersetzung pro Seite eines Dokuments (250 Wörter) zu berechnen. Dieser Tarif wird dem Kunden angeboten, wenn das Dokument, das er übersetzen möchte, nicht von unserem System registriert ist.",
    copy_alert: "Kopiert!",
    commander_interprete: "Dolmetscher beauftragen",
    particulier_name: "Name",
    traduction_assermentee: "Beglaubigte Übersetzung",
    traduction_classic: "Klassische Übersetzung",
    interpretation: "Dolmetschermission",

    // Fin interpretation
    AF: "Afghanistan",
    ZA: "Südafrika",
    AL: "Albanien",
    DZ: "Algerien",
    DE: "Deutschland",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarktis",
    AG: "Antigua und Barbuda",
    AN: "Niederländische Antillen",
    SA: "Saudi-Arabien",
    AR: "Argentinien",
    AM: "Armenien",
    AW: "Aruba",
    AU: "Australien",
    AT: "Österreich",
    AZ: "Aserbaidschan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesch",
    BB: "Barbados",
    BY: "Weißrussland",
    BE: "Belgien",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BR: "Brasilien",
    BN: "Brunei Darussalam",
    BG: "Bulgarien",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    EA: "Ceuta und Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Zypern",
    CO: "Kolumbien",
    KM: "Komoren",
    CG: "Kongo-Brazzaville",
    KP: "Nordkorea",
    KR: "Südkorea",
    CR: "Costa Rica",
    CI: "Elfenbeinküste",
    HR: "Kroatien",
    CU: "Kuba",
    DK: "Dänemark",
    DG: "Diego Garcia",
    DJ: "Dschibuti",
    DM: "Dominique",
    EG: "Ägypten",
    SV: "El Salvador",
    AE: "Vereinigte Arabische Emirate",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "Spanien",
    EE: "Estland",
    VA: "Staat der Vatikanstadt",
    FM: "Föderierte Staaten von Mikronesien",
    US: "Vereinigte Staaten",
    ET: "Äthiopien",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    GA: "Gabun",
    GM: "Gambia",
    GE: "Georgien",
    GS: "Südgeorgien und die Südlichen Sandwichinseln",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Griechenland",
    GD: "Granate",
    GL: "Grönland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Äquatorialguinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    GF: "Französisch-Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Ungarn",
    BV: "Île Bouvet",
    CX: "Weihnachtsinsel",
    CP: "Clipperton-Insel",
    AC: "Insel der Himmelfahrt",
    IM: "Isle of Man",
    NF: "Norfolkinsel",
    AX: "Åland-Inseln",
    KY: "Kaimaninseln",
    IC: "Kanarische Inseln",
    CC: "Kokos - Keelinginseln",
    CK: "Kochinseln",
    FO: "Färöer-Inseln",
    HM: "Heard and MacDonald Islands",
    FK: "Falklandinseln",
    MP: "Nördliche Marianen",
    MH: "Marshall-Inseln",
    UM: "Minor Outlying Islands of the United States",
    SB: "Salomonen",
    TC: "Turks- und Caicosinseln",
    VG: "Britische Jungferninseln",
    VI: "Jungferninseln der Vereinigten Staaten",
    In: "Indien",
    ID: "Indonesien",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irland",
    IS: "Island",
    IL: "Israel",
    IT: "Italien",
    JM: "Jamaika",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordanien",
    KZ: "Kasachstan",
    KE: "Kenia",
    KG: "Kirgisistan",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Lettland",
    LB: "Libanon",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MK: "Mazedonien",
    MG: "Madagaskar",
    MY: "Malaysia",
    MW: "Malawi",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MA: "Marokko",
    MQ: "Martinique",
    MU: "Maurice",
    MR: "Mauretanien",
    YT: "Mayotte",
    MX: "Mexiko",
    MD: "Moldawien",
    MC: "Monaco",
    MN: "Mongolei",
    HK: "Chinesische Sonderverwaltungszone Hongkong",
    MO: "China SAR Macau",
    QO: "Abgelegene Regionen Ozeaniens",
    CF: "Zentralafrikanische Republik",
    CD: "Demokratische Republik Kongo",
    DO: "Dominikanische Republik",
    CZ: "Tschechische Republik",
    RE: "Besprechung",
    RO: "Rumänien",
    GB: "Großbritannien",
    RU: "Russland",
    RW: "Ruanda",
    EH: "Westsahara",
    BL: "Saint-Barthélemy",
    KN: "St.-Kitts-und-Nevis",
    SM: "San Marino",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "St. Vincent und die Grenadinen",
    SH: "Heilige Helena",
    LC: "Heilige Lucia",
    WS: "Samoa",
    AS: "Amerikanisch-Samoa",
    ST: "Sao Tome und Principe",
    SN: "Senegal",
    RS: "Serbien",
    ME: "Serbien und Montenegro",
    MS: "Montserrat",
    MZ: "Mosambik",
    MM: "Myanmar",
    NA: "Namibie",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SK: "Slowakei",
    SI: "Slowenien",
    SO: "Somalia",
    SD: "Sudan",
    LK: "Sri Lanka",
    SE: "Schweden",
    CH: "Schweiz",
    SR: "Suriname",
    NR: "Suriname",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Norwegen",
    NC: "Neukaledonien",
    NZ: "Neuseeland",
    OM: "Oman",
    UG: "Uganda",
    UZ: "Usbekistan",
    PK: "Pakistan",
    PW: "Palau",
    PA: "Panama",
    PG: "Papua-Neuguinea",
    PY: "Paraguay",
    NL: "Niederlande",
    PE: "Peru",
    PH: "Philippinen",
    PN: "Pitcairn",
    PL: "Polen",
    PF: "Französisch-Polynesien",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Katar",
    SJ: "Svalbard und Jan Mayen Island",
    SZ: "Swasiland",
    SY: "Syrien",
    TJ: "Tadschikistan",
    TW: "Taiwan",
    TZ: "Tansania",
    TD: "Tschad",
    TF: "Französische Süd- und Antarktisgebiete",
    IO: "Britisches Territorium im Indischen Ozean",
    PS: "Palästinensisches Territorium",
    TH: "Thailand",
    TL: "Ost-Timor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad und Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunesien",
    TM: "Turkmenistan",
    TR: "Türkei",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "Europäische Union",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis und Futuna",
    YE: "Jemen",
    ZM: "Sambia",
    ZW: "Simbabwe",
  },
};

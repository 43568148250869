import { Pipe, PipeTransform } from '@angular/core';
import { LocalisationService } from './localisation.service';


@Pipe({name: 'localise'})
export class LocalisePipe implements PipeTransform {

  constructor(
    private localisation: LocalisationService
  ) {}

  transform(key: string): string {
    return this.localisation.localise(key);
  }
}

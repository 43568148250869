var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const FIREBASE_SETTINGS_COLLECTION = 'settings';
const FIREBASE_SETTINGS_I18N = 'i18n';
export class Localisation {
    constructor(dictionaries) {
        this._dicts = dictionaries;
    }
    get Current() {
        return this._dicts;
    }
    getDict(langCode) {
        if (!this._dicts) {
            return {};
        }
        if (langCode in this._dicts) {
            return this._dicts[langCode];
        }
        else {
            return {};
        }
    }
    get AvailableLanguages() {
        const langs = [];
        if (this._dicts) {
            Object.keys(this._dicts).forEach((key) => {
                langs.push(key);
            });
        }
        return langs;
    }
    localise(langCode, phraseKey) {
        let phrase = null;
        if (this._dicts) {
            const dict = this._dicts[langCode];
            if (dict) {
                phrase = dict[phraseKey];
            }
        }
        if (!phrase) {
            return '';
        }
        return phrase;
    }
    static Init(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = user.DB.collection(FIREBASE_SETTINGS_COLLECTION).doc(FIREBASE_SETTINGS_I18N);
            const snapshot = yield doc.get();
            if (snapshot.exists) {
                const dicts = snapshot.data();
                return new Localisation(dicts);
            }
            else {
                throw Error('Failed to download localisation');
            }
        });
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./singleProfil.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./singleProfil.component";
var styles_SingleProfilComponent = [i0.styles];
var RenderType_SingleProfilComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SingleProfilComponent, data: {} });
export { RenderType_SingleProfilComponent as RenderType_SingleProfilComponent };
export function View_SingleProfilComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-xl-12 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Profil de"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid"], ["src", "../../../../../assets/img/user/avatarUser.png"], ["width", "200"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-md-8"]], null, null, null, null, null))], null, null); }
export function View_SingleProfilComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "single-profile", [], null, null, null, View_SingleProfilComponent_0, RenderType_SingleProfilComponent)), i1.ɵdid(1, 114688, null, 0, i2.SingleProfilComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SingleProfilComponentNgFactory = i1.ɵccf("single-profile", i2.SingleProfilComponent, View_SingleProfilComponent_Host_0, {}, {}, []);
export { SingleProfilComponentNgFactory as SingleProfilComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-wait.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-wait.component";
var styles_AppWaitComponent = [i0.styles];
var RenderType_AppWaitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppWaitComponent, data: {} });
export { RenderType_AppWaitComponent as RenderType_AppWaitComponent };
export function View_AppWaitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "app-waiting"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "appshow": 0, "apphide": 1, "appdisabled": 2, "appenabled": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app-waiting"; var currVal_1 = _ck(_v, 2, 0, _co._show, !_co._show, _co._disabled, !_co._disabled); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppWaitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wait", [], null, null, null, View_AppWaitComponent_0, RenderType_AppWaitComponent)), i1.ɵdid(1, 573440, null, 0, i3.AppWaitComponent, [], null, null)], null, null); }
var AppWaitComponentNgFactory = i1.ɵccf("app-wait", i3.AppWaitComponent, View_AppWaitComponent_Host_0, { show: "show" }, {}, []);
export { AppWaitComponentNgFactory as AppWaitComponentNgFactory };

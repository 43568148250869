var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";
export class AllUsersComponent {
    constructor(hiero, modalService, localisation) {
        this.hiero = hiero;
        this.modalService = modalService;
        this.localisation = localisation;
        this.p = 1;
    }
    ngOnInit() {
        this.numberOfUsers().then((value) => {
            this.newRegistration = value.sort(function (a, b) {
                return (new Date(b.metadata.creationTime).getTime() -
                    new Date(a.metadata.creationTime).getTime());
            });
        });
        this.allUsersReq().then((value) => (this.data = value));
    }
    numberOfUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return result;
        });
    }
    allUsersReq() {
        const req = this.hiero.DB.collection("users");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
    seeProfil() {
        const modalRef = this.modalService.open(AppModalConfirmComponent, {
            centered: true,
        });
        modalRef.componentInstance.header = "Profil de Thomas Martin";
        modalRef.componentInstance.message = this.localisation.localise("prestation_order_accept_msg");
        modalRef.componentInstance.ok = this.localisation.localise("prestation_order_confirm_ok");
        modalRef.componentInstance.cancel = this.localisation.localise("prestation_order_confirm_cancel");
    }
}

<!-- <div class="form-group invalid-feedback" style="display: block;" [hidden]="submitForm.GeneralErrorHidden()">
  <div [hidden]="!submitForm.GeneralErrorHasMessage()">{{submitForm.Error}}</div>
  <div [hidden]="submitForm.GeneralErrorHasMessage()"> Erreur inconnue </div>
</div>
-->
<div class="form-group invalid-feedback alert alert-danger" style="display: block;" [hidden]="submitForm.GeneralErrorHidden()">
  <div [hidden]="!submitForm.GeneralErrorHasMessage()">{{submitForm.Error}}</div>
  <div [hidden]="submitForm.GeneralErrorHasMessage()"> Erreur inconnue </div>
</div>


import { Injectable } from '@angular/core';
// import { HieroBDD } from '../../../../hiero/app/services/hierobdd.service';
import { Localisation } from '../../bdd/settings/Localisation';
import { Storage } from '../../utility/storage.class';
import * as PHRASES from './phrases.js';
import { BehaviorSubject, PartialObserver, Subscription } from 'rxjs';
import { Language } from '../../bdd/utility/Language';

const langCookieKey = 'hiero-lang';

const FIREBASE_SETTINGS_COLLECTION = 'settings';

export interface ILocalisedLanguage {
  iso639: string;
  name: string;
  current: boolean;
}


@Injectable()
export class LocalisationService {

  private _localisation: Localisation;
  private _currentLanguageISO639 = 'fr';
  private _availableLanguages: BehaviorSubject<ILocalisedLanguage[]>;


  constructor(
    // private hiero: HieroBDD
  ) {
    // Load local
    this._localisation = new Localisation(PHRASES);
    this.determineLanguage();
    this._availableLanguages = new BehaviorSubject<ILocalisedLanguage[]>(this.constructLocalisedList());

    // Load remote
    this.Init('i18n_traducteur');
  }

  public get CurrentLanguageISO639(): string {
    return this._currentLanguageISO639;
  }

  public get CurrentLanguageDictionary(): any {
    if (!this._localisation) {
      return {};
    }
    return this._localisation.getDict(this._currentLanguageISO639);
  }

  private constructLocalisedList(): ILocalisedLanguage[] {
    const list: ILocalisedLanguage[] = [];
    this._localisation.AvailableLanguages.forEach(
      (iso639) => {
        list.push({
          iso639: iso639,
          name: Language.GetNameFromISO639(iso639),
          current: iso639 === this._currentLanguageISO639
        });
      }
    );
    return list;
  }

  private determineLanguage() {
    // Try get last specified language or navigator language
    const cookieLang = Storage.GetValue(langCookieKey);
    if (!cookieLang) {
      const navLang = navigator.language;

      this._localisation.AvailableLanguages.forEach(
        (lang) => {
          if (navLang.indexOf(lang) > -1) {
            this._currentLanguageISO639 = lang;
          }
        }
      );
    } else {
      this._currentLanguageISO639 = cookieLang;
    }
  }

  public async Init(service: string) {
    /* const doc = this.hiero.DB.collection(FIREBASE_SETTINGS_COLLECTION).doc(service);
    const snapshot: firebase.firestore.DocumentSnapshot = await doc.get();
    if (snapshot.exists) {
      const dicts = snapshot.data();
      this._localisation = new Localisation(dicts);
      this.determineLanguage();
      this._availableLanguages.next(this.constructLocalisedList());
    } else {
      console.warn('Could not get localisations. Defaulting to static.');
    } */
  }

  public localise(key: string) {
    if (!this._localisation) { return ''; }
    const clean = key.toLowerCase().trim();
    return this._localisation.localise(this._currentLanguageISO639, clean);
  }

  public changeLanguage(langCodeISO639: string) {
    this._currentLanguageISO639 = langCodeISO639;
    Storage.SetValue(langCookieKey, this._currentLanguageISO639, 5000);
    window.location.reload(true);
  }

  public WatchAvailableLanguages(observer: PartialObserver<ILocalisedLanguage[]>): Subscription {
    return this._availableLanguages.subscribe(observer);
  }


  /* private _dict: Map<string, string> = null;
  private _json: any = null;

  public initialize(system: LocalisationSystem, makeDict: boolean = true): Promise<boolean> {
    this._system = system;
    if (this._dict) {
      return Promise.resolve(true);
    } else {
      return this.getLanguage(this._system, '', makeDict);
    }
  }

  public append(system: LocalisationSystem, makeDict: boolean = true): Promise<boolean> {
    return this.getLanguage(system, system + '.', makeDict);
  }

  public changeLanguage(languageCode: string) {
    Storage.SetValue(langCookieKey, languageCode, 5000);
    window.location.reload(true);
  }

  private getLanguage(system: LocalisationSystem, prefix: string, makeDict: boolean = true): Promise<boolean> {

    const cookieLang = Storage.GetValue(langCookieKey);

    return ApiUtility.GetLocalisation(this.api, system, cookieLang)
    .then(
      (locInfo: ILocalisation) => {

        return new Promise<boolean>(
          (resolve, reject) => {
            this.http.get(
              locInfo.url,
              {}
            ).toPromise<any>()
            .then(
              result => {
                // Successfully received a language, set it as official lang for all requests
                this.api.CurrentLanguage = locInfo.language;

                // Process the data
                if (makeDict) {
                  if (!this._dict) {
                    this._dict = new Map<string, string>();
                  }
                  this.process(prefix, result);
                } else {
                  this._json = result;
                }

                resolve(true);
              },
              err => {
                Logger.Error(err);
                // Parse error ?
                reject(err.error);
              }
            );
          }
        );

      }
    );
  }

  public get dictionary(): any {
    return this._json;
  }


  private process(base: string, json: any) {

    Object.keys(json).forEach(
      (key: string) => {
        const val = json[key];
        const clean = key.toLowerCase().trim();

        if (val) {
          if (typeof val === 'string') {
            // It is a value
            this._dict.set(base + clean, val);
          } else {
            // It is an object
            this.process(base + '' + clean + '.', val);
          }
        }
      }
    );
  }

  public localise(key: string) {
    if (!this._dict) { return ''; }

    const clean = key.toLowerCase().trim();
    if (this._dict.has(clean)) {
      return this._dict.get(clean);
    } else {
      return '';
    }
  }

  public listLanguages(): Promise<ILocalisation[]> {
    return ApiUtility.GetLocalisations(this.api, this._system);
  }
  */

}

import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NotifierModule } from "angular-notifier";
import { NgxFileDropModule } from "ngx-file-drop";
import { NgbModule } from "../../../node_modules/@ng-bootstrap/ng-bootstrap";
import { Config } from "../../common/src/services/config.service";
import { Logger } from "../../common/src/utility/logger.class";
// Environment
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { CountryPipe } from "./pipes/CountryPipe";
import { LanguagePipe } from "./pipes/LanguagePipe";
import { AccountComponent } from "./routes/account/account.component";
import { LoginComponent } from "./routes/account/login/login.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { GoogleGeo } from "./services/google.services";
import { HieroBDD } from "./services/hierobdd.service";
import { AppWaitComponent } from "../../common/src/utility/app-wait/app-wait.component";
import { AppFormDropdownComponent } from "../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component";
import { AppFormGeneralErrorComponent } from "../../common/src/utility/forms/app-form-general-error/app-form-generl-error.component";
import { AppFormInputComponent } from "../../common/src/utility/forms/app-form-input/app-form-input.component";
import { AppFormTypeaheadComponent } from "../../common/src/utility/forms/app-form-typeahead/app-form-typeahead.component";
import { DocumentTypeListComponent } from "./routes/app/appbase/documentTypes/documentTypes.component";
import { DocumentTypeComponent } from "./routes/app/appbase/documentType/documentType.component";
import { TemplateListComponent } from "./routes/app/appbase/templates/templates.component";
import { TestComponent } from "./routes/base/test/test.component";
import { PDFEditorComponent } from "../../common/src/components/pdfeditor/pdfeditor.component";
import { PDFPreviewComponent } from "../../common/src/components/pdfpreview/pdfpreview.component";
import { PDFInputComponent } from "../../common/src/components/pdfeditor/pdfinput/pdfinput.component";
import { TemplateComponent } from "./routes/app/appbase/template/template.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { LocalisationService } from "../../common/src/modules/localisation/localisation.service";
import { LocalisePipe } from "../../common/src/modules/localisation/localise.pipe";
import { AppModalConfirmComponent } from "../../common/src/utility/modal/confirm-modal.component";
import { AddTemplateComponent } from "./routes/app/appbase/addTemplate/addtemplate.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DashboardComponent } from "./routes/dashboard/dasboard.component";
import { CommandesComponent } from "./routes/commandes/commandes.component";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxPaginationModule } from "ngx-pagination";
import { SingleCommandComponent } from "./routes/single-command/single-command.component";
import { AllUsersComponent } from "./routes/allUsers/allUsers.component";
import { SingleProfilComponent } from "./routes/singleProfil/singleProfil.component";

// Set logging level
Logger.LogLevel = environment.logLevel;

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    BaseComponent,

    // Account
    AccountComponent,
    LoginComponent,

    // App (logged in)
    AppBaseComponent,
    DocumentTypeListComponent,
    DocumentTypeComponent,
    TemplateListComponent,
    AddTemplateComponent,
    TemplateComponent,

    TestComponent,

    DashboardComponent,

    SingleCommandComponent,

    CommandesComponent,

    AllUsersComponent,

    SingleProfilComponent,

    // Components
    AppWaitComponent,
    AppFormInputComponent,
    AppFormGeneralErrorComponent,
    AppFormDropdownComponent,
    AppFormTypeaheadComponent,

    PDFEditorComponent,
    PDFPreviewComponent,
    PDFInputComponent,

    AppModalConfirmComponent,

    // Pipes
    LanguagePipe,
    LocalisePipe,
    CountryPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NotifierModule,
    NgxFileDropModule,
    BsDropdownModule.forRoot(),
    MatTabsModule,
    NgxPaginationModule,

    AppRoutingModule,
  ],
  providers: [
    { provide: "CONFIG", useValue: environment },
    Config,
    HieroBDD,
    GoogleGeo,
    LocalisationService,
    LoggedInGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [AppModalConfirmComponent],
})
export class AppModule {}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
export class DocumentTypeListComponent {
    constructor(hiero, fb, router, route) {
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.busy = true;
        this.adminSub = this.hiero.WatchAdmin({
            next: (admin) => {
                DocumentType.Init(admin.User, true)
                    .then((docType) => {
                    this.docType = docType;
                    this.busy = false;
                    this.docTypeSub = this.docType.Watch({
                        next: (updated) => {
                            this.docType = updated;
                            this.docTypes = this.docType.ToArray;
                            this.busy = false;
                        }
                    });
                });
            }
        });
        this.createForm();
    }
    ngOnDestroy() {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
        if (this.docTypeSub) {
            this.docTypeSub.unsubscribe();
        }
    }
    getDocTypeFor(code, documentType) {
        return this.docType.GetDocumentName(code, documentType._id);
    }
    createForm() {
        this.newForm = new SubmitForm(this.fb, [
            {
                name: 'label',
                value: '',
                validators: [],
                type: 'text',
                title: 'Nom (en français)',
                autocomplete: '',
                placeholder: 'Le nom du document',
                help: 'Précisez le nom du type de document en français. Une foir crée, vous pouvez ajouter autres noms par langue',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        (changes) => {
        }, 
        // Success callback
        () => {
            // What to do with login success ?
            this.busy = false;
        }, 
        // Fail callback
        err => {
            // What to do with login failuer
            console.error(err);
            this.busy = false;
        }, 
        // Changes callback
        null);
    }
    addType() {
        return __awaiter(this, void 0, void 0, function* () {
            const name = this.newForm.GetValue('label');
            if (!name) {
                return;
            }
            const validated = name.trim().toLowerCase();
            if (validated.length === 0) {
                return;
            }
            this.newForm.ClearGeneralError();
            this.busy = true;
            const nDoc = {
                label: [{
                        code: 'fr',
                        value: validated
                    }]
            };
            try {
                yield this.docType.Add(nDoc);
            }
            catch (err) {
                this.newForm.SetGeneralError(err.message);
            }
        });
    }
    edit(docType) {
        this.router.navigate(['..', 'documentType', docType._id], { relativeTo: this.route });
    }
}

<div class="container mt-3 mb-4 d-flex justify-content-center">
  <div class="col-lg-9 mt-4 mt-lg-0">
    <div class="row">
      <div class="col-md-12">
        <div
          class="user-dashboard-info-box table-responsive mb-0 bg-light p-4 shadow-sm"
        >
          <table class="table manage-candidates-top mb-0">
            <thead>
              <tr>
                <th>Utilisateur</th>
                <th class="text-center">Status</th>
                <th class="action text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="candidates-list"
                *ngFor="
                  let user of newRegistration
                    | paginate: { itemsPerPage: 4, currentPage: p }
                "
              >
                <td class="title">
                  <div class="thumb">
                    <img
                      class="img-fluid"
                      src="{{
                        user.photoURL
                          ? user.photoURL
                          : '../../../../../assets/img/user/avatarUser.png'
                      }}"
                      alt=""
                    />
                  </div>
                  <div class="candidate-list-details">
                    <div class="candidate-list-info">
                      <div class="candidate-list-title">
                        <h5 class="mb-0">
                          <span *ngFor="let item of data">
                            {{
                              item.id === user.uid
                                ? item.data().profile.givenName +
                                  " " +
                                  item.data().profile.familyName
                                : null
                            }}
                          </span>
                          <span class="text-muted" *ngFor="let item of data">
                            {{
                              user.uid === item.id
                                ? "(" +
                                  item.data().roles[0].type.toLowerCase() +
                                  ")"
                                : ""
                            }}
                          </span>
                        </h5>
                      </div>
                      <div class="candidate-list-option">
                        <ul class="list-unstyled">
                          <li>{{ user.email }}</li>
                          <li>
                            <i class="fas fa-map-marker-alt pr-1"></i>
                            <span *ngFor="let item of data">
                              {{
                                item.id === user.uid
                                  ? item.data().profile.telephone
                                  : null
                              }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="candidate-list-favourite-time text-center">
                  <a
                    class="candidate-list-favourite order-2 text-danger"
                    href="#"
                  ></a>
                  <span class="text-muted"
                    >Inscrit le
                    {{ user.metadata.creationTime | date: "dd/MM/yyyy" }}</span
                  >
                  <div>
                    <button
                      [ngClass]="
                        user.emailVerified
                          ? 'btn btn-success'
                          : 'btn btn-danger'
                      "
                      style="pointer-events: none"
                    >
                      {{
                        user.emailVerified
                          ? "Adresse mail vérifiée"
                          : "Adresse mail non Vérifiée"
                      }}
                    </button>
                  </div>
                </td>
                <td>
                  <ul class="list-unstyled mb-0 d-flex justify-content-end">
                    <li>
                      <button class="btn btn-secondary">
                        <i class="fa fa-user-circle"></i> Profil
                      </button>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <pagination-controls
            (pageChange)="p = $event"
            class="my-pagination"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

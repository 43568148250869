import { Component } from '@angular/core';

@Component({
  templateUrl: './account.component.html'
})

export class AccountComponent {
  constructor(
  ) {
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "single-profile",
  templateUrl: "./singleProfil.component.html",
  styleUrls: ["./singleProfil.component.scss"],
})
export class SingleProfilComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EPDFRotation } from '../../bdd/interfaces/IPDF';
import { PDFRender } from './pdfrender';
const pdfjsLib = window['pdfjs-dist/build/pdf'];
export class PDFParser {
    static Parse(fileEntry) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!fileEntry.file) {
                return Promise.reject('File must not be null');
            }
            return new Promise((resolve, reject) => {
                // console.log(this.fileEntry);
                fileEntry.file((fileData) => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        // Step 4:turn array buffer into typed array
                        const typedarray = new Uint8Array(fileReader.result);
                        resolve(typedarray);
                    };
                    fileReader.onerror = () => {
                        fileReader.abort();
                        return reject(Error('Error reading pdf file'));
                    };
                    // Step 3: Read the file as ArrayBuffer
                    fileReader.readAsArrayBuffer(fileData);
                });
            })
                .then((typedarray) => {
                // Step 5:PDFJS should be able to read this
                const task = pdfjsLib.getDocument(typedarray);
                return task.promise;
            })
                .then((pdf) => {
                return this.processPdf(pdf);
            })
                .then((pdf) => {
                const pdfRender = new PDFRender(pdf);
                return pdfRender;
            });
        });
    }
    static processPdf(pdf) {
        return __awaiter(this, void 0, void 0, function* () {
            // console.log("Pages: " + pdf.numPages);
            const pdfDef = {
                pageCount: pdf.numPages,
                pages: []
            };
            const promises = [];
            for (let i = 1; i <= pdfDef.pageCount; ++i) {
                const pageDef = {
                    valid: false,
                    width: 0,
                    height: 0,
                    orientation: EPDFRotation.Portrait,
                    inputs: []
                };
                pdfDef.pages.push(pageDef);
                promises.push(pdf.getPage(i)
                    .then((page) => {
                    console.log(page);
                    pageDef.valid = true;
                    if ((page.rotate % 360) === 0) {
                        pageDef.width = page.view[2];
                        pageDef.height = page.view[3];
                        pageDef.orientation = EPDFRotation.Portrait;
                    }
                    else if ((page.rotate % 360) === 90) {
                        pageDef.width = page.view[3];
                        pageDef.height = page.view[2];
                        pageDef.orientation = EPDFRotation.LandscapeRight;
                    }
                    else if ((page.rotate % 360) === -90 || (page.rotate % 360) === 270) {
                        pageDef.width = page.view[3];
                        pageDef.height = page.view[2];
                        pageDef.orientation = EPDFRotation.LandscapeLeft;
                    }
                    else {
                        pageDef.width = page.view[2];
                        pageDef.height = page.view[3];
                        pageDef.orientation = EPDFRotation.PortaitInvert;
                    }
                    return page.getTextContent()
                        .then((textContent) => {
                        return page.getAnnotations();
                    })
                        .then((annotations) => {
                        let id = 0;
                        annotations.forEach((annotation) => {
                            if (annotation.fieldType === 'Tx') {
                                const annot = PDFParser.parseTextBoxAnnotation(annotation, pageDef);
                                annot.id = id;
                                id++;
                                pageDef.inputs.push(annot);
                            }
                        });
                    });
                }));
            }
            yield Promise.all(promises);
            return pdfDef;
        });
    }
    static parseTextBoxAnnotation(annotation, pageDef) {
        console.log(annotation);
        // RECT:
        // 0 = left side of rect in pts from left margin
        // 1 = bottom side of rect in pts from bottom margin
        // 2 = right side of rect in pts from left margin
        // 3 = top side of rect in pts from bottom margin
        let align = 'left';
        let x = 0;
        let y = 0;
        let width = 0;
        let height = 0;
        switch (pageDef.orientation) {
            case EPDFRotation.Portrait:
                x = annotation.rect[0];
                y = pageDef.height - annotation.rect[3];
                width = annotation.rect[2] - annotation.rect[0];
                height = annotation.rect[3] - annotation.rect[1];
                break;
            case EPDFRotation.LandscapeRight:
                x = annotation.rect[1]; // bottom
                y = annotation.rect[0]; // left
                width = annotation.rect[3] - annotation.rect[1];
                height = annotation.rect[2] - annotation.rect[0];
                break;
            case EPDFRotation.LandscapeLeft:
                x = pageDef.width - annotation.rect[3];
                y = pageDef.height - annotation.rect[2];
                width = annotation.rect[3] - annotation.rect[1];
                height = annotation.rect[2] - annotation.rect[0];
                break;
            case EPDFRotation.PortaitInvert:
                x = pageDef.width - annotation.rect[2];
                y = annotation.rect[1];
                width = annotation.rect[2] - annotation.rect[0];
                height = annotation.rect[3] - annotation.rect[1];
                break;
        }
        const rectX = x;
        const rectY = y;
        if (annotation.textAlignment) {
            switch (annotation.textAlignment) {
                case 1:
                    align = 'center';
                    x = x + (width * 0.5);
                    break;
                case 2:
                    align = 'right';
                    x = x + width;
                    break;
            }
        }
        let bold = false;
        let italic = false;
        let fontSize = 10;
        if (annotation.defaultAppearance) {
            const texts = annotation.defaultAppearance.split(' ');
            if (texts.length > 0) {
                const fontName = texts[0];
                if (fontName.endsWith('BO')) {
                    bold = true;
                    italic = true;
                }
                else if (fontName.endsWith('Bo')) {
                    bold = true;
                    italic = false;
                }
                else if (fontName.endsWith('Ob')) {
                    bold = false;
                    italic = true;
                }
            }
            if (texts.length > 1) {
                try {
                    fontSize = parseFloat(texts[1]);
                }
                catch (_a) { }
            }
        }
        // Center the text
        y = rectY + ((height - fontSize) / 2);
        const annot = {
            id: 0,
            x: x,
            y: y,
            rectX: rectX,
            rectY: rectY,
            width: width,
            height: height,
            text: annotation.fieldValue,
            fill: null,
            textAlign: align,
            description: '',
            bold: bold,
            italic: italic,
            fontSize: fontSize
        };
        console.log(annot);
        return annot;
    }
}

import * as ISO6391 from 'iso-639-1';

export class Language {

  public static GetAllNativeNames(): string[] {
    return ISO6391.default.getAllNativeNames();
  }

  public static GetAllNativeAndRegularNames(): string[] {
    return [...ISO6391.default.getAllNativeNames(), ...ISO6391.default.getAllNames()];
  }

  public static GetISO639(name: string): string {
    return ISO6391.default.getCode(name);
  }

  public static GetNameFromISO639(iso639: string): string {
    return ISO6391.default.getNativeName(iso639);
  }

}

<ng-container *ngIf="!!template">

  <div class="row">
    <div class="col">          
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page"><a [routerLinkActive]="['breadcrumbactive']" [routerLink]="['/app', 'templates']">Templates</a></li>
          <li class="breadcrumb-item" aria-current="page"><a [routerLinkActive]="['breadcrumbactive']" >{{ template.Id }}</a></li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">  

      <div class="card mb-3">
        <div class="card-header">
          <h5 class="mb-0">Modifier les détails</h5>
        </div>
        <div class="card-body">
          <form [formGroup]="updateForm.Group" novalidate>
            <app-form-typeahead [parameters]="updateForm.GetInputElement('srcCode')" [search]="search" [formatter]="formatter"></app-form-typeahead>
            <app-form-typeahead [parameters]="updateForm.GetInputElement('srcCountry')" [search]="countrySearch" [formatter]="countryFormatter"></app-form-typeahead>
            <app-form-typeahead [parameters]="updateForm.GetInputElement('dstCode')" [search]="search" [formatter]="formatter"></app-form-typeahead>
            <app-form-dropdown [parameters]="documentTypeDropdown" (onSelect)="selectDocumentType($event)"></app-form-dropdown>
            <app-form-input [parameters]="updateForm.GetInputElement('price')"></app-form-input>
            
            <p>Remplacer le template en glissant un nouveau ici :</p>
            <ngx-file-drop 
              dropZoneLabel="Glisser le template PDF ici" 
              (onFileDrop)="dropped($event)" 
              accept=".pdf"
              class="mb-3"
            >
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                Glisser le template ici.
                <button type="button" (click)="openFileSelector()">Parcourir</button>
              </ng-template>
            </ngx-file-drop>
            
            <app-form-general-error class="mt-3" [submitForm]="updateForm"></app-form-general-error>
            <button class="btn btn-success mt-3" (click)='update()' [disabled]="!updateForm.Valid">Mettre à jour !</button>
            
            <p class="small text-danger" *ngIf="updateForm.Dirty && !pdfRender">Merci d'ajouter le template avant de cliquer sur Ajouter</p>
            <p class="small text-danger" *ngIf="updateForm.Dirty && !updateForm.Valid">Merci de completer le formulaire ou résoudre les problèmes en rouge avant cliquer sur Ajouter</p>          
          </form>
        </div>
      </div>  

      <div class="card mb-3">
        <div class="card-header">
          <h5 class="mb-0">Supprimer</h5>
        </div>
        <div class="card-body">
          <button class="btn btn-danger" (click)='remove()'>Supprimer ce template !</button>
          <div class="alert alert-danger mt-3 mb-0" [hidden]="!deleteError">
            {{deleteError}}
          </div>
        </div>
      </div>

      <div class="card mb-3">
          <div class="card-header">
            <h5 class="mb-0">Avancé</h5>
          </div>
          <div class="card-body">
            <p>Vous pouvez modifier votre configuration avec un éditeur de text, ensuite la retélécharger ici.</p>
            <button class="btn btn-success mr-3 mb-3 " (click)='update()'>Sauvegarder</button>
            <button class="btn btn-primary  mb-3 " (click)='downloadJson()'>Télécharger la configuration</button>            
            <ngx-file-drop 
              dropZoneLabel="Glisser la config .json ici" 
              (onFileDrop)="droppedJson($event)" 
              accept=".pdf"
            >
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                Glisser le fichier .json ici.  
                <button type="button" (click)="openFileSelector()">Parcourir</button>
              </ng-template>
            </ngx-file-drop>
        </div>
      </div>

      <app-wait [show]='busy'></app-wait>

    </div>


    <div class="col-md-6">  
      <app-pdf-editor  *ngIf="!!pdfRender" [objectEntry]="pdfRender"></app-pdf-editor>
    </div>
  </div>

</ng-container>


<!--
<style type="text/css">
  .barrelaterale {
  clear: left;
  float: left;
  width: 25%;
  margin-right: 0%;
  padding: 1%;
  margin-left: 2%;
  background-color: rgba(255, 139, 135, 0.2);
}

.tableauprincipal {
  float: left;
  width: 72%;
  margin-left: 0%;
  padding: 1%;
}
</style>

<button class="btn btn-info" href="#" style="margin-left:10px;"> ↤ Retour</button>
<br/><br/>
<div class="barrelaterale">
        
        <h4>Détail du template</h4>  
        <ul *ngIf="!!template">
          <li>Langue d'origine : {{ template.Data.srcLanguageIso639 | iso639ToNative }} </li>
          <li>Pays d'origine : {{ getCountryName(template.Data.srcCountryCode) }}</li>
          <li>Traduire vers : {{ template.Data.destLanguageIso639 | iso639ToNative  }} </li>
          <li>Type de document : {{ getDocTypeFor('fr', template.Data.documentTypeId ) }}</li>
        </ul>
        
        <form [formGroup]="newForm.Group" novalidate>
          <app-form-input [parameters]="newForm.GetInputElement('price')"></app-form-input>

          <ngx-file-drop 
            dropZoneLabel="Glisser le template PDF ici" 
            (onFileDrop)="dropped($event)" 
            accept=".pdf"
          >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              Glisser le template ici. 
              <button type="button" (click)="openFileSelector()">Parcourir</button>
            </ng-template>
          </ngx-file-drop>

          <app-form-general-error [submitForm]="newForm"></app-form-general-error>         
          
        </form>

        <div class="mt-3">
            <hr>
            <h4>Avancé</h4>
            <button class="btn btn-success mt-3" (click)='update()'>Sauvegarder</button>
            <button class="btn btn-primary mt-3" (click)='downloadJson()'>Télécharger la configuration</button>
            <p>Vous pouvez modifier votre configuration avec un éditeur de text, ensuite la retélécharger ici.</p>
            <ngx-file-drop 
                dropZoneLabel="Glisser la config .json ici" 
                (onFileDrop)="droppedJson($event)" 
                accept=".pdf"
              >
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  Glisser le fichier .json ici.  
                  <button type="button" (click)="openFileSelector()">Parcourir</button>
                </ng-template>
              </ngx-file-drop>
          </div>
  </div>

  <div class="tableauprincipal">
      <div class="mt-3 mb-3">
          <app-pdf-editor  *ngIf="!!pdfRender" [objectEntry]="pdfRender"></app-pdf-editor>
      </div>



  </div>
        

-->
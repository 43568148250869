import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Admin } from '../../../../common/src/bdd/admin/Admin';
import { HieroBDD } from '../../services/hierobdd.service';

@Component({
  templateUrl: './base.component.html'
})

export class BaseComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // WATCH LOG-IN STATUS
    this._subscription = this.hiero.WatchAdmin(
      {
        next: (admin: Admin) => {

          if (admin != null) {
            this.router.navigate(['app']);
          }

        }
      }
    );

  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}

<h4>Test</h4>

<div class="row">
  <div class="col">

    <ngx-file-drop 
      dropZoneLabel="Glisser le template PDF ici" 
      (onFileDrop)="dropped($event)" 
      accept=".pdf"
    >
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        Glisser le template ici.
        <button type="button" (click)="openFileSelector()">Parcourir</button>
      </ng-template>
    </ngx-file-drop>


  </div>
</div>


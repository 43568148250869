/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pdfinput.component";
var styles_PDFInputComponent = [];
var RenderType_PDFInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PDFInputComponent, data: {} });
export { RenderType_PDFInputComponent as RenderType_PDFInputComponent };
export function View_PDFInputComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { inputValue: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["value", 1]], null, 0, "input", [["class", "form-control"], ["style", "width: 100%"], ["type", "text"]], [[8, "value", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onUpdate($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.input.text, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_PDFInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pdf-input", [], null, null, null, View_PDFInputComponent_0, RenderType_PDFInputComponent)), i0.ɵdid(1, 4374528, null, 0, i1.PDFInputComponent, [], null, null)], null, null); }
var PDFInputComponentNgFactory = i0.ɵccf("app-pdf-input", i1.PDFInputComponent, View_PDFInputComponent_Host_0, { input: "input", metaEditing: "metaEditing" }, { update: "update" }, []);
export { PDFInputComponentNgFactory as PDFInputComponentNgFactory };

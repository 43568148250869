import { BehaviorSubject, PartialObserver } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { EnumPrestationState, IPrestation } from '../interfaces/IPrestation';
import { ITemplate } from '../interfaces/ITemplate';
import { User } from '../user/User';

const FIREBASE_TEMPLATE_COLLECTION = 'templates';
const FIREBASE_PRESTATIONS_COLLECTION = 'prestations';

export class Template {

  private _user: User;
  private _docRef: firebase.firestore.DocumentReference;
  private _id: string;
  private _raw: ITemplate;
  private _stopListening;

  private _templateSubject: BehaviorSubject<Template>;


  constructor(user: User, docRef: firebase.firestore.DocumentReference, id: string, raw: ITemplate, watch?: boolean) {
    this._user = user;
    this._docRef = docRef;
    this._id = id;
    this._raw = raw;
    this._templateSubject = new BehaviorSubject(this);

    if (watch) {
      this._stopListening = docRef.onSnapshot({
        next: (snapshot) => {
          if (snapshot.exists) {
            this._raw = snapshot.data() as ITemplate;
            this._templateSubject.next(this);
          }
        },
        error: (err) => {
        }
      });
    }
  }

  public cleanup() {
    if (this._stopListening) {
      this._stopListening();
      this._stopListening = null;
    }
  }

  public get Id(): string {
    return this._id;
  }

  public get Data(): ITemplate {
    return this._raw;
  }

  public Watch(observer: PartialObserver<Template>): Subscription {
    return this._templateSubject.subscribe(observer);
  }

  public async Update(template: ITemplate) {
    const willCreateDouble = await Template.WillCreateDouble(this._user, template, this.Data);
    if (willCreateDouble) {
      throw Error('Pas autorisé : un template avec la même configuration existe déjà');
    }
    await this._docRef.update(template);
  }

  public async Remove() {
    const willImpactPrestations = await this.WillImpactPrestations();
    if (willImpactPrestations) {
      throw Error('Pas autorisé : il y a déjà des missions qui utilisent ce template');
    }
    await this._docRef.delete();
  }

  
  private async WillImpactPrestations(): Promise<boolean> {

    const query = this._user.DB.collection(FIREBASE_PRESTATIONS_COLLECTION)
    .where('srcLanguageIso639', '==', this.Data.srcLanguageIso639)
    .where('destLanguageIso639', '==', this.Data.destLanguageIso639)
    .where('srcCountryCode', '==', this.Data.srcCountryCode);

    const snapshots = await query.get();
    let foundUsed = false;
    snapshots.forEach(
      (snapshot) => {
        const prestation: IPrestation = snapshot.data() as IPrestation;
        if (
          prestation.state !== EnumPrestationState.RefusedByTranslator &&
          prestation.state !== EnumPrestationState.Validated &&
          prestation.state !== EnumPrestationState.CancelledByClient
        ) {
          // Check only prestations that can currently change
          const found = prestation.documents.find(doc => doc.documentTypeId === this.Data.documentTypeId);
          if (found) {
            foundUsed = true;
          }
        }

      }
    );

    return foundUsed;
  }

  private static async WillCreateDouble(user: User, template: ITemplate, current?: ITemplate): Promise<boolean> {

    if (current) {
      if (
        template.srcLanguageIso639 === current.srcLanguageIso639 &&
        template.destLanguageIso639 === current.destLanguageIso639 &&
        template.srcCountryCode === current.srcCountryCode &&
        template.documentTypeId === current.documentTypeId
        ) {
          // No change, no need to check
          return Promise.resolve(false);
      }
    }


    const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
    .where('srcLanguageIso639', '==', template.srcLanguageIso639)
    .where('destLanguageIso639', '==', template.destLanguageIso639)
    .where('srcCountryCode', '==', template.srcCountryCode)
    .where('documentTypeId', '==', template.documentTypeId);

    const snapshots = await query.get();

    return !snapshots.empty;
  }


  public static async Create(user: User, template: ITemplate): Promise<Template> {
    const willCreateDouble = await Template.WillCreateDouble(user, template);
    if (willCreateDouble) {
      throw Error('Pas autorisé : un template avec la même configuration existe déjà');
    }

    const docRef = await user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).add(template);
    const snapshot = await docRef.get();
    const raw = snapshot.data() as ITemplate;
    return new Template(user, docRef, snapshot.id, raw);
  }

  public static async Load(user: User, templateId: string, watch: boolean) {
    const doc = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).doc(templateId);

    const snapshot: firebase.firestore.DocumentSnapshot = await doc.get();
    if (!snapshot.exists) {
      return Promise.reject('No template found with that id');
    } else {
      return new Template(user, doc, templateId, snapshot.data() as ITemplate, watch);
    }
  }

  public static async Find(user: User, srcLanguageIso639: string, destLanguageIso639: string, srcCountryCode: string, documentTypeId: string) {
    const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
    .where('srcLanguageIso639', '==', srcLanguageIso639)
    .where('destLanguageIso639', '==', destLanguageIso639)
    .where('srcCountryCode', '==', srcCountryCode)
    .where('documentTypeId', '==', documentTypeId);

    const snapshot: firebase.firestore.QuerySnapshot = await query.get();
    if (snapshot && !snapshot.empty && snapshot.docs.length > 0) {
      const snapDoc = snapshot.docs[0];
      return Template.Load(user, snapDoc.id, false);
    } else {
      return Promise.reject('No template found matching src lang, dst lang, country and document type');
    }

  }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { EnumPrestationState } from '../interfaces/IPrestation';
const FIREBASE_TEMPLATE_COLLECTION = 'templates';
const FIREBASE_PRESTATIONS_COLLECTION = 'prestations';
export class Template {
    constructor(user, docRef, id, raw, watch) {
        this._user = user;
        this._docRef = docRef;
        this._id = id;
        this._raw = raw;
        this._templateSubject = new BehaviorSubject(this);
        if (watch) {
            this._stopListening = docRef.onSnapshot({
                next: (snapshot) => {
                    if (snapshot.exists) {
                        this._raw = snapshot.data();
                        this._templateSubject.next(this);
                    }
                },
                error: (err) => {
                }
            });
        }
    }
    cleanup() {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    }
    get Id() {
        return this._id;
    }
    get Data() {
        return this._raw;
    }
    Watch(observer) {
        return this._templateSubject.subscribe(observer);
    }
    Update(template) {
        return __awaiter(this, void 0, void 0, function* () {
            const willCreateDouble = yield Template.WillCreateDouble(this._user, template, this.Data);
            if (willCreateDouble) {
                throw Error('Pas autorisé : un template avec la même configuration existe déjà');
            }
            yield this._docRef.update(template);
        });
    }
    Remove() {
        return __awaiter(this, void 0, void 0, function* () {
            const willImpactPrestations = yield this.WillImpactPrestations();
            if (willImpactPrestations) {
                throw Error('Pas autorisé : il y a déjà des missions qui utilisent ce template');
            }
            yield this._docRef.delete();
        });
    }
    WillImpactPrestations() {
        return __awaiter(this, void 0, void 0, function* () {
            const query = this._user.DB.collection(FIREBASE_PRESTATIONS_COLLECTION)
                .where('srcLanguageIso639', '==', this.Data.srcLanguageIso639)
                .where('destLanguageIso639', '==', this.Data.destLanguageIso639)
                .where('srcCountryCode', '==', this.Data.srcCountryCode);
            const snapshots = yield query.get();
            let foundUsed = false;
            snapshots.forEach((snapshot) => {
                const prestation = snapshot.data();
                if (prestation.state !== EnumPrestationState.RefusedByTranslator &&
                    prestation.state !== EnumPrestationState.Validated &&
                    prestation.state !== EnumPrestationState.CancelledByClient) {
                    // Check only prestations that can currently change
                    const found = prestation.documents.find(doc => doc.documentTypeId === this.Data.documentTypeId);
                    if (found) {
                        foundUsed = true;
                    }
                }
            });
            return foundUsed;
        });
    }
    static WillCreateDouble(user, template, current) {
        return __awaiter(this, void 0, void 0, function* () {
            if (current) {
                if (template.srcLanguageIso639 === current.srcLanguageIso639 &&
                    template.destLanguageIso639 === current.destLanguageIso639 &&
                    template.srcCountryCode === current.srcCountryCode &&
                    template.documentTypeId === current.documentTypeId) {
                    // No change, no need to check
                    return Promise.resolve(false);
                }
            }
            const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                .where('srcLanguageIso639', '==', template.srcLanguageIso639)
                .where('destLanguageIso639', '==', template.destLanguageIso639)
                .where('srcCountryCode', '==', template.srcCountryCode)
                .where('documentTypeId', '==', template.documentTypeId);
            const snapshots = yield query.get();
            return !snapshots.empty;
        });
    }
    static Create(user, template) {
        return __awaiter(this, void 0, void 0, function* () {
            const willCreateDouble = yield Template.WillCreateDouble(user, template);
            if (willCreateDouble) {
                throw Error('Pas autorisé : un template avec la même configuration existe déjà');
            }
            const docRef = yield user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).add(template);
            const snapshot = yield docRef.get();
            const raw = snapshot.data();
            return new Template(user, docRef, snapshot.id, raw);
        });
    }
    static Load(user, templateId, watch) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).doc(templateId);
            const snapshot = yield doc.get();
            if (!snapshot.exists) {
                return Promise.reject('No template found with that id');
            }
            else {
                return new Template(user, doc, templateId, snapshot.data(), watch);
            }
        });
    }
    static Find(user, srcLanguageIso639, destLanguageIso639, srcCountryCode, documentTypeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                .where('srcLanguageIso639', '==', srcLanguageIso639)
                .where('destLanguageIso639', '==', destLanguageIso639)
                .where('srcCountryCode', '==', srcCountryCode)
                .where('documentTypeId', '==', documentTypeId);
            const snapshot = yield query.get();
            if (snapshot && !snapshot.empty && snapshot.docs.length > 0) {
                const snapDoc = snapshot.docs[0];
                return Template.Load(user, snapDoc.id, false);
            }
            else {
                return Promise.reject('No template found matching src lang, dst lang, country and document type');
            }
        });
    }
}

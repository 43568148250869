var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { User } from "./User";
import * as yup from "yup";
import { environment } from "../../../../pro/environments/environment";
/**
 * Represents status options of the user login. This is not a binary option, as we may be in a 'waiting' state.
 */
export var ELoggedInStatus;
(function (ELoggedInStatus) {
    /**
     * Still waiting to determing if the user is logged in or not
     */
    ELoggedInStatus[ELoggedInStatus["Waiting"] = 0] = "Waiting";
    /**
     * User is logged in and authorized
     */
    ELoggedInStatus[ELoggedInStatus["LoggedIn"] = 1] = "LoggedIn";
    /**
     * User is not logged in
     */
    ELoggedInStatus[ELoggedInStatus["NotLoggedIn"] = 2] = "NotLoggedIn";
})(ELoggedInStatus || (ELoggedInStatus = {}));
/**
 * Represents the different signup types permitted on the platform
 */
export var ESignupType;
(function (ESignupType) {
    ESignupType["EmailPassword"] = "email-password";
})(ESignupType || (ESignupType = {}));
/**
 * Auth service is the starting point for the Hiero API library. A valid and initialised firebase instance must be passed to the
 * constructor, and this service then handles the rest.
 *
 * Auth service will automatically monitor changes to the state of the user, and fire events via subscriptions (the Watch* methods).
 */
export class AuthService {
    constructor(auth, db, role) {
        // Additional login steps
        this.extraLoginSteps = [];
        // Just before
        this.justBeforeStateFireSteps = [];
        this._role = role;
        this._auth = auth;
        this._db = db;
        this._userSubject = new BehaviorSubject(null);
        this._userLoggedInSubject = new BehaviorSubject(ELoggedInStatus.Waiting); // Initial state is waiting
        this._stateListenerUnsubscribe = null;
        this._signupProfile = null;
        this._lastInitUid = null;
        this._initMap = new Map();
    }
    Listen() {
        // Make sure we only have one listener ever!
        if (!this._stateListenerUnsubscribe) {
            // Can be called multiple times be careful!!
            this._stateListenerUnsubscribe = this._auth.onAuthStateChanged((user) => {
                if (user) {
                    // Handle user state change
                    this.handleStateChange(user);
                }
                else {
                    // Signout
                    this.signalLoggedOut();
                }
            }, (err) => {
                // Error was encountered, signout
                this.signalLoggedOut();
            });
        }
    }
    StopListening() {
        if (this._stateListenerUnsubscribe) {
            this._stateListenerUnsubscribe();
            this._stateListenerUnsubscribe = null;
        }
    }
    /**
     * Logs the current user out.
     */
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this._auth.signOut();
            }
            catch (err) {
                console.log("Error while signing out. Ignoring.");
            }
        });
    }
    /**
     * Watch for changes on the user. This will fire with the value null if the user is logged out.
     * The observer pass should be of the form:
     * ```
     * {
     *    next: (user: User) => { ... }
     * }
     * ```
     * @param observer The observer that will receive an update
     * @returns A subscription that can be cancelled by calling `unsubscribe()` on this object.
     */
    WatchUser(observer) {
        return this._userSubject.subscribe(observer);
    }
    get User() {
        return this._userSubject.value;
    }
    /**
     * Watch for changes on the login status. The user is considered logged in if a series of validation steps have been completed.
     * The observer pass should be of the form:
     * ```
     * {
     *    next: (status: ELoggedInStatus) => { ... }
     * }
     * ```
     * @param observer The observer that will receive an update
     * @returns A subscription that can be cancelled by calling `unsubscribe()` on this object.
     */
    WatchLoggedInStatus(observer) {
        return this._userLoggedInSubject.subscribe(observer);
    }
    /**
     * Get the current logged in status of the user.
     * @returns The current logged in status.
     */
    get LoggedInStatus() {
        return this._userLoggedInSubject.value;
    }
    /***
     * Logs in the user as a specific role.
     * Note, this function will start by logging out the user (just in case), to ensure startig from a clean slate.
     * The promise will eventually return true when the use is logged in, or reject if the login failed.
     * @param signupProfile If a profile is passed, this profile will be used to create a new profile for the user.
     */
    login(loginData, signupProfile) {
        return __awaiter(this, void 0, void 0, function* () {
            this._signupProfile = signupProfile;
            // Do a logout
            yield this.logout();
            this._userLoggedInSubject.next(ELoggedInStatus.Waiting);
            let sub;
            return new Promise((resolve, reject) => {
                // Set up a subscription to wait for a change in status
                sub = this.WatchLoggedInStatus({
                    next: (status) => {
                        if (sub) {
                            sub.unsubscribe();
                        }
                        if (status === ELoggedInStatus.LoggedIn) {
                            return resolve(true);
                        }
                        else if (status === ELoggedInStatus.NotLoggedIn) {
                            return reject(false);
                        }
                    },
                });
                switch (loginData.type) {
                    case ESignupType.EmailPassword:
                        const schema = yup.object({
                            email: yup.string().ensure().trim().lowercase(),
                            password: yup.string().ensure().trim(),
                        });
                        schema
                            .validate(loginData)
                            .then((validated) => {
                            return this._auth.signInWithEmailAndPassword(validated.email, validated.password);
                        })
                            .catch((err) => {
                            console.log(err);
                            reject(err);
                        });
                        break;
                    default:
                        reject("Unknown login type.");
                        break;
                }
            });
        });
    }
    /***
     * Sign's a new user for a specified role.
     * If the user already exists, the signup will fail.
     * @param signupData The data needed to sign the user up
     * @param signupProfile Profile information necessary for the signup.
     * @param verifyData The options for sending the verify email
     */
    signup(signupData, signupProfile, verifyData) {
        return __awaiter(this, void 0, void 0, function* () {
            this._signupProfile = signupProfile;
            // Do a logout
            yield this.logout();
            // Notify that we are going into a waiting state
            this._userLoggedInSubject.next(ELoggedInStatus.Waiting);
            let sub;
            return new Promise((resolve, reject) => {
                // Set up a subscription to wait for a change in status
                sub = this.WatchLoggedInStatus({
                    next: (status) => {
                        if (sub) {
                            sub.unsubscribe();
                        }
                        if (status === ELoggedInStatus.LoggedIn) {
                            return resolve(true);
                        }
                        else if (status === ELoggedInStatus.NotLoggedIn) {
                            return reject(false);
                        }
                    },
                });
                switch (signupData.type) {
                    case ESignupType.EmailPassword:
                        // Create account
                        const schema = yup.object({
                            email: yup.string().ensure().trim().lowercase(),
                            password: yup.string().ensure().trim(),
                        });
                        schema
                            .validate(signupData)
                            .then((validated) => {
                            return this._auth.createUserWithEmailAndPassword(validated.email, validated.password);
                        })
                            // TODO: Do we need email verification ??
                            .then((cred) => {
                            console.log("SIGNUP OK... SENDING EMAIL");
                            return cred.user.sendEmailVerification({
                                url: environment.resetRedirectUrl,
                            });
                        })
                            .then(() => {
                            resolve(true);
                        })
                            .catch((err) => {
                            reject(err);
                        });
                        break;
                    default:
                        return Promise.reject("Unknown signup type.");
                }
            });
        });
    }
    /***
     * Logs in the user as a specific role.
     * Note, this function will start by logging out the user (just in case), to ensure startig from a clean slate.
     * The promise will eventually return true when the use is logged in, or reject if the login failed.
     * @param signupProfile If a profile is passed, this profile will be used to create a new profile for the user.
     */
    sendPasswordReset(email, resetLink) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const resetData = {
                    email: email,
                };
                const schema = yup.object({
                    email: yup.string().ensure().trim().lowercase(),
                });
                schema
                    .validate(resetData)
                    .then((validated) => {
                    //console.log("Sending password reset to: " + validated.email);
                    return this._auth.sendPasswordResetEmail(validated.email, {
                        url: resetLink,
                        android: {
                            packageName: "com.bymeunivers.hieromobile",
                        },
                        iOS: {
                            bundleId: "com.bymeunivers.hieromobile",
                        },
                        handleCodeInApp: false,
                    });
                })
                    .then(() => {
                    resolve(true);
                })
                    .catch((err) => {
                    console.log(err);
                    reject(err);
                });
            });
        });
    }
    setAuthLanguage(iso639) {
        this._auth.languageCode = iso639;
    }
    removeFromInitMap(uid) {
        // Remove from the map
        if (this._initMap.has(uid)) {
            this._initMap.delete(uid);
        }
    }
    setupUser(fbUser) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const loggedUser = yield User.Init(fbUser, this._db, this._role, this._signupProfile);
                if (!loggedUser) {
                    this.removeFromInitMap(fbUser.uid);
                    return null;
                }
                // Extra login steps
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.extraLoginSteps.length; ++i) {
                    yield this.extraLoginSteps[i](loggedUser);
                }
                // Remove from the map
                this.removeFromInitMap(fbUser.uid);
                // Signal, if still relevant
                if (this._lastInitUid === fbUser.uid) {
                    // Activate the user event
                    this._userSubject.next(loggedUser);
                    for (let i = 0; i < this.justBeforeStateFireSteps.length; ++i) {
                        yield this.justBeforeStateFireSteps[i](loggedUser);
                    }
                    // Activate the logged in status
                    this._userLoggedInSubject.next(ELoggedInStatus.LoggedIn);
                    return loggedUser;
                }
                else {
                    // OOPS: this user is an old one, ignore it
                    return null;
                }
            }
            catch (err) {
                // If an error was caught, the user should log out
                // This should automatically end up firing the log-out below
                console.warn("Error encountered while logging in: " + err);
                console.warn("Signing out");
                this.removeFromInitMap(fbUser.uid);
                yield this.logout();
                return null;
            }
        });
    }
    handleStateChange(fbUser) {
        // Most recent state change... last come is the one served.
        this._lastInitUid = fbUser.uid;
        if (this._initMap.has(fbUser.uid)) {
            // Already have an init running for this user... do nothing
        }
        else {
            this._initMap.set(fbUser.uid, this.setupUser(fbUser));
        }
    }
    signalLoggedOut() {
        // User is logged out
        this._signupProfile = null;
        // Activate the user event
        this._userSubject.next(null);
        // Activate the logged in status
        this._userLoggedInSubject.next(ELoggedInStatus.NotLoggedIn);
    }
}

import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, ValidationErrors, AbstractControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Admin } from '../../../../../../common/src/bdd/admin/Admin';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { IDocumentType } from '../../../../../../common/src/bdd/interfaces/IDocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { TemplateList, QueryOrderParam } from '../../../../../../common/src/bdd/documents/TemplateList';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
import { ICountry, Countries } from '../../../../../../common/src/bdd/utility/countries';
import { IAppFormDropdowParameters, IDDFormNameData } from '../../../../../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component';
import { ITemplate } from '../../../../../../common/src/bdd/interfaces/ITemplate';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { IPDFDocument } from '../../../../../../common/src/bdd/interfaces/IPDF';
import { PDFParser } from '../../../../../../common/src/components/pdfpreview/pdfparser';
import { PDFRender } from '../../../../../../common/src/components/pdfpreview/pdfrender';





@Component({
  templateUrl: './templates.component.html'
})
export class TemplateListComponent implements OnDestroy {

  busy = true;
  private admin: Admin;
  private adminSub: Subscription;
  private listSub: Subscription;
  
  private docTypeMap: DocumentType;

  templateList: TemplateList;
  templates: Template[];
  newForm: SubmitForm;

  documentTypeDropdown: IAppFormDropdowParameters;
  selectedDocType: string;


  page = 1;
  pageSize = 10;

  order: QueryOrderParam[] = [];

  private getData = (columnName: string, data: any): any => {
    if (columnName === 'srcLanguageIso639' || columnName === 'destLanguageIso639') {
      return Language.GetNameFromISO639(data);
    } else if (columnName === 'srcCountryCode') {
      return Countries.GetCountryMap('fr').get(data);
    } else if (columnName === 'documentTypeId') {
      return this.getDocTypeFor('fr', data);
    } else {
      return data;
    }
  }


  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.order = [
      { column: 'srcLanguageIso639', desc: false },
      { column: 'srcCountryCode', desc: false },
      { column: 'destLanguageIso639', desc: false },
      { column: 'documentTypeId', desc: false },
    ];


    this.adminSub = this.hiero.WatchAdmin({
      next: (admin: Admin) => {
        this.admin = admin;

        DocumentType.Init(admin.User, true)
        .then(
          (map) => {
            this.docTypeMap = map;
            return TemplateList.Init(admin.User, true);
          }
        )
        .then(
          (list: TemplateList) => {
            this.templateList = list;

            this.templateList.getData = this.getData;

            this.processQuery();
            this.listSub = this.templateList.WatchList({
              next: (templates: Template[]) => {
                this.templates = templates;
                this.busy = false;
              }
            });
          }
        );
      }
    });


  }

  ngOnDestroy()  {
    if (this.adminSub) {
      this.adminSub.unsubscribe();
    }
    if (this.listSub) {
      this.listSub.unsubscribe();
    }
    if (this.templateList) {
      this.templateList.cleanup();
    }
  }

  getDocTypeFor(code: string, documentTypeId: string) {
    return this.docTypeMap.GetDocumentName(code, documentTypeId);
  }

  getCountryName(code: string) {
    return Countries.GetCountryMap('fr').get(code);
  }


  edit(template: Template) {
    console.log("Navigate to : " + template.Id);
    this.router.navigate(['..', 'template', template.Id], { relativeTo: this.route });
  }

 
  gotoAddTemplate() {
    this.router.navigate(['/app', 'addtemplate']);
  }

  // ORDERING AND PAGING

  isDesc(column: string) {
    const query = this.order.find(q => q.column === column);
    if (query) {
      return query.desc;
    } else {
      return false;
    }
  }

  private processQuery() {
    this.templateList.updateQuery([], this.order, this.page - 1, this.pageSize);
  }

  addOrder(column: string, desc: boolean) {
    let objIndex = -1;
    this.order.find(
      (param, index) => {
        if (param.column === column) {
          param.desc = desc;
          objIndex = index;
          return true;
        }
        return false;
      }
    );

    if (objIndex > -1) {
      const tmp = this.order[0];
      this.order[0] = this.order[objIndex];
      this.order[objIndex] = tmp;
    }

    this.processQuery();
  }

  onPageChange(pageNumber: number) {
    this.page = pageNumber;
    this.processQuery();
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./pdfinput/pdfinput.component.ngfactory";
import * as i3 from "./pdfinput/pdfinput.component";
import * as i4 from "../pdfpreview/pdfpreview.component.ngfactory";
import * as i5 from "../pdfpreview/pdfpreview.component";
import * as i6 from "../../modules/localisation/localise.pipe";
import * as i7 from "../../modules/localisation/localisation.service";
import * as i8 from "@angular/common";
import * as i9 from "./pdfeditor.component";
var styles_PDFEditorComponent = [];
var RenderType_PDFEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PDFEditorComponent, data: {} });
export { RenderType_PDFEditorComponent as RenderType_PDFEditorComponent };
function View_PDFEditorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 19, "div", [["class", "card"], ["style", "border-bottom-right-radius: 0; border-bottom-left-radius: 0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 18, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 10, "form", [["class", "form-inline"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(8, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(10, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-info"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousElement() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-arrow-circle-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "app-pdf-input", [["style", "flex: 1 0 auto;"]], null, [[null, "update"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update" === en)) {
        var pd_0 = (_co.onUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PDFInputComponent_0, i2.RenderType_PDFInputComponent)), i0.ɵdid(14, 4374528, null, 0, i3.PDFInputComponent, [], { input: [0, "input"] }, { update: "update" }), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-info"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextElement() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-arrow-circle-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 3, "p", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["", ""])), i0.ɵppd(20, 1)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.currentInput; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "prestation_document_edit")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 10).ngClassValid; var currVal_6 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = !_co.hasPrevious; _ck(_v, 11, 0, currVal_8); var currVal_10 = !_co.hasNext; _ck(_v, 15, 0, currVal_10); var currVal_11 = i0.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i0.ɵnov(_v.parent, 0), "prestation_document_edit_expl")); _ck(_v, 19, 0, currVal_11); }); }
function View_PDFEditorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pdf-preview", [], null, null, null, i4.View_PDFPreviewComponent_0, i4.RenderType_PDFPreviewComponent)), i0.ɵdid(1, 770048, null, 0, i5.PDFPreviewComponent, [], { pdf: [0, "pdf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdfRender; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PDFEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.LocalisePipe, [i7.LocalisationService]), i0.ɵqud(402653184, 1, { listContainer: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PDFEditorComponent_1)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PDFEditorComponent_2)), i0.ɵdid(7, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.currentInput; _ck(_v, 5, 0, currVal_0); var currVal_1 = !!_co.pdfRender; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_PDFEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pdf-editor", [], null, null, null, View_PDFEditorComponent_0, RenderType_PDFEditorComponent)), i0.ɵdid(1, 4767744, null, 0, i9.PDFEditorComponent, [i0.ChangeDetectorRef, i0.NgZone], null, null)], null, null); }
var PDFEditorComponentNgFactory = i0.ɵccf("app-pdf-editor", i9.PDFEditorComponent, View_PDFEditorComponent_Host_0, { objectEntry: "objectEntry" }, { update: "update" }, []);
export { PDFEditorComponentNgFactory as PDFEditorComponentNgFactory };

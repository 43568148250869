<div class="row">
  <div class="col">
    <div  *ngIf="!!currentInput">
      <div class="card" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
        <div class="card-body">
          
          <h5>{{ 'prestation_document_edit' | localise }}</h5>
          <form class="form-inline">
            <button class="btn btn-info" (click)="previousElement()" [disabled]="!hasPrevious"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></button>
            <app-pdf-input [input]="currentInput" (update)="onUpdate($event)" style="flex: 1 0 auto;"></app-pdf-input>
            <button class="btn btn-info" (click)="nextElement()" [disabled]="!hasNext"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
          </form>

          <p class="mb-0"><small>{{ 'prestation_document_edit_expl' | localise }}</small></p>
          
         
        </div>
      </div>
    </div>
    <app-pdf-preview *ngIf="!!pdfRender" [pdf]="pdfRender"></app-pdf-preview>
  </div>
</div>
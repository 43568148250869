<div class="container">
  <div class="d-flex justify-content-around">
    <div class="lead">
      <i class="fa fa-folder-open text-info"></i> Gestion de toutes les
      commandes de Hierø
    </div>
  </div>

  <div class="card mt-4" *ngIf="particular">
    <div class="d-flex justify-content-between">
      <div class="h5 mt-3 font-weight-bold text-info ml-4">
        Commandes de particuliers
        <button class="btn btn-outline-dark ml-2" disabled>
          Filtrer par partenaires <i class="fa fa-filter text-dark"></i>
        </button>
      </div>
      <div class="mt-3 mr-4">
        <button class="btn btn-outline-primary" (click)="switchBetweenProPar()">
          <i class="fa fa-repeat"></i> Voir commandes professionnels
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Nom du document</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Traducteur</th>
            <th scope="col" class="align-middle">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of commandParticular
                | paginate: { itemsPerPage: 10, currentPage: p }
            "
            class="clickable"
            [routerLink]="[item.prestationId]"
          >
            <td class="align-middle">
              {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>

  <div class="card mt-4" *ngIf="particular === false">
    <div class="d-flex justify-content-between">
      <div class="h5 mt-3 font-weight-bold text-info ml-4">
        Commandes de professionels
        <button class="btn btn-outline-dark ml-2" disabled>
          Filtrer par catégories <i class="fa fa-filter text-dark"></i>
        </button>
      </div>
      <div class="mt-3 mr-4">
        <button class="btn btn-outline-primary" (click)="switchBetweenProPar()">
          <i class="fa fa-repeat"></i> Voir commandes de particuliers
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Nom du document</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Traducteur</th>
            <th scope="col" class="align-middle">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of commandPro
                | paginate: { itemsPerPage: 10, currentPage: p }
            "
            class="clickable"
            [routerLink]="[item.prestationId]"
          >
            <td class="align-middle">
              {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>

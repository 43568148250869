<style type="text/css">
	.column {
	float: left;
	width: 45%;
	margin-left:auto;
	margin-right:auto;
	}

	/* Clear floats after the columns */
	.row:after {
	content: "";
	display: table;
	clear: both;
  }
  
  .barrelaterale {
  clear: left;
  float: left;
  width: 28%;
  margin-right: 0%;
  padding: 1%;
  margin-left: 2%;
  background-color: rgba(255, 139, 135, 0.2);
}

.tableauprincipal {
  float: left;
  width: 68%;
  margin-left: 0%;
  padding: 1%;
}
</style>

<br/>
  <div class="barrelaterale">
      <h4>Types de Document</h4>
      <br/>Ajouter des documents à la base de donnée Hierø<br/>
      <form [formGroup]="newForm.Group" novalidate>
              <app-form-input [parameters]="newForm.GetInputElement('label')"></app-form-input>
              <app-form-general-error [submitForm]="newForm"></app-form-general-error>
              <button class="btn btn-danger" (click)='addType()'>Ajouter !</button>
      </form>
  </div>

  <div class="tableauprincipal">
        <div class="row">
          <div class="col">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">ID firebase</th>
                  <th scope="col">Type de document</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let docType of docTypes; let i = index">
                  <td>{{ docType._id }} </td>
                  <td>{{ getDocTypeFor('fr', docType ) }}</td>
                  <td>
                    <button class="btn btn-danger btn-sm" (click)="edit(docType)">Modifier</button>          
                  </td>
                </tr>      
              </tbody> 
            </table>

            <app-wait [show]='busy'></app-wait>
          </div>
        </div>

    </div>


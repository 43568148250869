/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/ngx-file-drop/ngx-file-drop.ngfactory";
import * as i2 from "ngx-file-drop";
import * as i3 from "./test.component";
var styles_TestComponent = [];
var RenderType_TestComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TestComponent, data: {} });
export { RenderType_TestComponent as RenderType_TestComponent };
function View_TestComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, [" Glisser le template ici. "])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.openFileSelector() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Parcourir"]))], null, null); }
export function View_TestComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Test"])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "ngx-file-drop", [["accept", ".pdf"], ["dropZoneLabel", "Glisser le template PDF ici"]], null, [[null, "onFileDrop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFileDrop" === en)) {
        var pd_0 = (_co.dropped($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_NgxFileDropComponent_0, i1.RenderType_NgxFileDropComponent)), i0.ɵdid(5, 180224, null, 1, i2.NgxFileDropComponent, [i0.NgZone, i0.Renderer2], { accept: [0, "accept"], dropZoneLabel: [1, "dropZoneLabel"] }, { onFileDrop: "onFileDrop" }), i0.ɵqud(335544320, 1, { contentTemplate: 0 }), (_l()(), i0.ɵand(0, [[1, 2]], null, 1, null, View_TestComponent_1)), i0.ɵdid(8, 16384, null, 0, i2.ɵa, [i0.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = ".pdf"; var currVal_1 = "Glisser le template PDF ici"; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_TestComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TestComponent_0, RenderType_TestComponent)), i0.ɵdid(1, 49152, null, 0, i3.TestComponent, [], null, null)], null, null); }
var TestComponentNgFactory = i0.ɵccf("ng-component", i3.TestComponent, View_TestComponent_Host_0, {}, {}, []);
export { TestComponentNgFactory as TestComponentNgFactory };

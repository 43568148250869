export class Storage {

  private static GetCookie(key: string) {
    const name = key + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
  }

  private static SetCookie(key: string, value: string, expiresIn: number) {
    // Calculate the expires fiels
    const d = new Date();
    d.setTime(d.getTime() + (expiresIn * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();

    // Set the cookie for browsers that don't support

    const cookieStr = key + '=' + value + ';path=/;' + expires + ';';  // SameSite=Strict
    document.cookie = cookieStr;
  }

  private static get UseCookie(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return (ua.indexOf('safari') > -1) && (ua.indexOf('android') < 0) && (ua.indexOf('chrome') < 0) && (ua.indexOf('firefox') < 0) && (ua.indexOf('edge') < 0);
  }

  public static SetValue(key: string, value: string, expiresIn: number) {
    if (this.UseCookie) {
      this.SetCookie(key, value, expiresIn);
    } else {
      localStorage.setItem(key, value);
    }
  }

  public static RemoveValue(key: string) {
    if (this.UseCookie) {
      this.SetCookie(key, '', -1);
    } else {
      localStorage.removeItem(key);
    }
  }

  public static GetValue(key: string): string {
    if (this.UseCookie) {
      return this.GetCookie(key);
    } else {
      return localStorage.getItem(key);
    }
  }
}

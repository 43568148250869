import { Component, Input } from '@angular/core';
import { SubmitForm } from '../submitform.class';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-form-general-error',
  templateUrl: './app-form-general-error.component.html'
})
export class AppFormGeneralErrorComponent {
  @Input() submitForm: SubmitForm ;
}

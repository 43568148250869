import { APP_DOMAIN, APP_SUBPATH,GOOGLE_GEOCODE_API_KEY } from '../../common/environment/config.devdeploy';
import { IConfig, LoggingLevel } from '../../common/src/interfaces/config/IConfig';

export const environment: IConfig = {
  firebase: {
    apiKey: 'AIzaSyAEY7wVAYri0RDBOw8oKWjWPaPrrIPXtCg',
    authDomain: 'hierodev.firebaseapp.com',
    databaseURL: 'https://hierodev.firebaseio.com',
    projectId: 'hierodev',
    storageBucket: 'hierodev.appspot.com',
    messagingSenderId: '974891592058',
    appId: '1:974891592058:web:a1ca3fe7d346a810594ec3'
  },
  app: {
    origin: APP_DOMAIN,
    subpath: APP_SUBPATH
  },
  appBundleId: 'tech.kevinglass.hiero',
  appMinimumVersion: '0',
  production: false,
  logLevel: LoggingLevel.All,
  google_geocode_apikey: GOOGLE_GEOCODE_API_KEY,
  resetRedirectUrl: '',
  public_key: ''
};

export enum EPDFRotation {
  Portrait = 0,
  LandscapeRight = 1,
  LandscapeLeft = 2,
  PortaitInvert = 3
}

export interface IPDFInput {
  id: number;

  // TEXT
  x: number;
  y: number;
  // RECT
  rectX: number;
  rectY: number;
  width: number;
  height: number;

  text: string;
  description: string;
  fill: string;
  textAlign?: string;

  bold?: boolean;
  italic?: boolean;
  fontSize?: number;
}

export interface IPDFPage {
  valid: boolean;
  width: number;
  height: number;
  orientation: EPDFRotation;

  inputs: IPDFInput[];
}

export interface IPDFDocument {
  pageCount: number;
  pages: IPDFPage[];
}

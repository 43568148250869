import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../common/src/services/config.service';
import { NotifierService } from 'angular-notifier';
import { HieroBDD } from './services/hierobdd.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    private config: Config,
    private router: Router,
    
  ) {
  }
}

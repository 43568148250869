import { IDocumentType } from '../interfaces/IDocumentType';
import { User } from '../user/User';
import { BehaviorSubject, PartialObserver } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
const FIREBASE_DOCUMENT_TYPE_COLLECTION = 'document_types';

/**
 * This class initialises and stores a map of the document types handled by the system.
 * The list of supported documents comes from the firestore database.
 */
export class DocumentType {

  private _user: User;
  private _unsubscribe;
  private _documentMap: Map<string, IDocumentType>;
  private _documentMapSubject: BehaviorSubject<DocumentType>;

  private constructor(user: User, query: firebase.firestore.CollectionReference, firstSnapshot: firebase.firestore.QuerySnapshot, watch?: boolean) {
    this._user = user;

    this._documentMapSubject = new BehaviorSubject<DocumentType>(this);
    this.processMap(firstSnapshot);

    if (watch) {
      this._unsubscribe = query.onSnapshot(
        (snapshot) => {
          this.processMap(snapshot);
        },
        (err) => {}
      );
    }
  }

  private processMap(snapshot: firebase.firestore.QuerySnapshot) {
    this._documentMap = new Map<string, IDocumentType>();
    if (snapshot && !snapshot.empty) {
      snapshot.docs.forEach(
        (docSnapshot) => {
          const data: IDocumentType = docSnapshot.data() as IDocumentType;
          data._id = docSnapshot.id;
          this._documentMap.set(docSnapshot.id, data);
        }
      );
    }
    this._documentMapSubject.next(this);
  }

  public cleanup() {
    if (this._unsubscribe) {
      this._unsubscribe();
      this._unsubscribe = null;
    }
  }

  public Watch(observer: PartialObserver<DocumentType>): Subscription {
    return this._documentMapSubject.subscribe(observer);
  }

  public GetDocumentName(iso639: string, docId: string): string {
    if (this._documentMap.has(docId)) {
      const type = this._documentMap.get(docId);
      const label = type.label.find( docType => docType.code === iso639 );
      if (label) {
        return label.value;
      } else {
        return docId;
      }
    } else {
      return docId;
    }
  }

  public async Add(documenttype: IDocumentType) {
    await this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).add(documenttype);
  }

  public async AddLabel(docTypeId: string, codeISO639: string, text: string) {
    const docRef = await this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).doc(docTypeId);
    const doc = await docRef.get();
    if (doc.exists) {

      const docType: IDocumentType = doc.data() as IDocumentType;
      const label = docType.label.find( type => type.code === codeISO639 );
      if (label) {
        label.value = text;
      } else {
        docType.label.push({
          code: codeISO639,
          value: text
        });
      }
      await docRef.update({
        label: docType.label
      });

    } else {
      throw Error('Document type with that id does not exist.');
    }
  }

  public async RemoveLabel(docTypeId: string, codeISO639: string) {
    const docRef = await this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).doc(docTypeId);
    const doc = await docRef.get();
    if (doc.exists) {

      const docType: IDocumentType = doc.data() as IDocumentType;
      const labels = docType.label.filter( type => type.code !== codeISO639 );
      await docRef.update({
        label: labels
      });

    } else {
      throw Error('Document type with that id does not exist.');
    }
  }

  public getDocumentType(id: string) {
    return this._documentMap.get(id);
  }

  /**
   * This asynchronous method will load a map of supported document types.
   * @param user The logged in user.
   */
  public static async Init(user: User, watch?: boolean): Promise<DocumentType> {
    const query = user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION);
    const snapshot: firebase.firestore.QuerySnapshot = await query.get();
    return new DocumentType(user, query, snapshot, watch);
  }

  /** If Init has already been called, this will return the map of document types */
  public get Map(): Map<string, IDocumentType> {
    return this._documentMap;
  }

  public get ToArray(): IDocumentType[] {
    const arr: IDocumentType[] = [];
    this._documentMap.forEach(
      (docType: IDocumentType) => {
        arr.push(docType);
      }
    );
    return arr;
  }

}


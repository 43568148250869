import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';

export interface ISubmitFormElement {
  name: string;
  value: any;
  validators: ValidationErrors[];

  type: string;
  title: string;
  placeholder: string;
  help: string;
  errors: ISubmitFormInputErrors[];
  autocomplete: string;
  min?: number;   // Min value on form element
  max?: number;   // Max value on form element
  step?: number;  // Step for numeric input values
  disabled?: boolean;   // Disable the control
  accept?: string;      // File type for uploads

  /**
   * Custom output path. If none provided, will use element name
   */
  output?: string[];
  /**
   * Custom output name. If none provided, will use element name
   */
  output_name?: string;

  _outputPath?: string;
  _submitForm?: SubmitForm;
}

export interface ISubmitFormInputErrors {
  code: string;
  message: string;
}

export interface ISubmitFormGeneralErrors {
  field: string;
  code: string;
  message: string;
}

interface IApiErrorData {
  path: string;
  message: string;
  keys: {
    [key: string]: boolean;
  };
  children: IApiErrorData[];
}

interface IAPIError {
  data: IApiErrorData[];
  message: string;
  statusCode: number;
}


export class SubmitForm {
  public Group: FormGroup;
  public Enabled = true;
  public Submitting = false;
  public Error = '';
  public HasGeneralError = false;

  private _fb: FormBuilder;
  private _elements: ISubmitFormElement[] = null;
  private _elementMap: {};
  private _asyncCall: any = null;
  private _onSuccess: any = null;
  private _onError: any = null;
  private _onChange: any = null;

  private _groupParams;
  private _changeMap;
  private _outputPathMap;

  private _generalErrors: ISubmitFormGeneralErrors[] = [];

  constructor(fb: FormBuilder, elements: ISubmitFormElement[], asyncCall: any, onSuccess: any, onError: any, onChange: any, errors: ISubmitFormGeneralErrors[] = []) {
    this._fb = fb;
    this._elements = elements;
    this._asyncCall = asyncCall;
    this._onSuccess = onSuccess;
    this._onError = onError;
    this._onChange = onChange;

    this._groupParams = {};
    this._elementMap = {};
    this._outputPathMap = {};

    this._changeMap = {};

    // Setup input fields if there are any
    for (let i = 0; i < this._elements.length; ++i) {
      const element: ISubmitFormElement = this._elements[i];

      if (!element.type) {
        element.type = 'text';
      }

      if (!element.errors) {
        element.errors = [];
      }

      if (!element.autocomplete) {
        element.autocomplete = 'off';
      }

      if (!element.output) {
        element.output = [];
      }

      if (!element.output_name) {
        element.output_name = element.name;
      }

      // Setup output path
      element._outputPath = '';
      for (let j = 0; j < element.output.length; ++j) {
        element._outputPath += element.output[j] + '.';
      }
      element._outputPath += element.output_name;
      this._outputPathMap[element._outputPath] = element.name;

      // Setup the form group parameters
      this._groupParams[element.name] = [{ value: element.value, disabled: element.disabled }, element.validators];

      // Set reference to this
      element._submitForm = this;

      // Save to map
      this._elementMap[element.name] = element;
    }

    // Setup form group
    this.Group = this._fb.group(this._groupParams);

    // Convert all inputs to form inputs
    /*for (let k = 0; k < this._elements.length; ++k)
    {
      var element: ISubmitFormElement = this._elements[k];
    }*/


    // Setup listener for changes
    this.Group.valueChanges.forEach(
      next => {
        if (this.Group.dirty) {
          for (let k = 0; k < this._elements.length; ++k) {
            const element = this._elements[k];

            const comp = this.Group.get(element.name);
            if (comp.dirty && comp.valid) {
              // Update the internal change map
              this.updateChangeMap(element, comp);

              // Signal external
              if (this._onChange) {
                this._onChange(element.name, comp.value);
              }
            }
          }
        }
      }
    );

    // Setup general errors
    if (errors) {
      this._generalErrors = errors;
    }

  }

  private updateChangeMap(element: ISubmitFormElement, component) {
    this.AddToChangeMap(element.output, element.output_name, component.value);
  }

  private removeFromChangeMap(element: ISubmitFormElement, component) {
    this.RemoveFromChangeMap(element.output, element.output_name, component.value);
  }

  public get ChangeMap(): any {
    return this._changeMap;
  }

  public AddToChangeMap(path: string[], outName: string, value: any) {
    let current = this._changeMap;
    for (let i = 0; i < path.length; ++i) {
      const name = path[i];
      if (!(name in current)) {
        current[name] = {};
      }
      current = current[name];
    }
    current[outName] = value;
  }

  public RemoveFromChangeMap(path: string[], outName: string, value: any) {
    let current = this._changeMap;
    for (let i = 0; i < path.length; ++i) {
      const name = path[i];
      if (!(name in current)) {
        current[name] = {};
      }
      current = current[name];
    }

    if (outName in current) {
      delete current[outName];
    }
  }

  public GetInputElement(key: string) {
    return this._elementMap[key];
  }

  public SetEnabled(enabled: boolean) {
    /*this.Enabled = enabled;
    this.Submitting = !enabled;
    if (!this.Enabled) {
      this.Group.disable();
    } else {
      this.Group.enable();
    }*/

    // Go through and disable all already disabled
    /* for (let i = 0; i < this._elements.length; ++i) {
      const element = this._elements[i];
      if (element.disabled) {
        const comp = this.Group.get(element.name);
        comp.disable();
      }
    } */
  }

  public GetValue(key) {
    const comp = this.Group.get(key);
    if (comp) {
      return comp.value;
    } else {
      return null;
    }
  }

  public SetValue(key, value, updateChangeMap: boolean = true) {
    const comp = this.Group.get(key);
    if (comp) {
      comp.setValue(value);
      if (updateChangeMap) {
        comp.markAsTouched();
      }

      if (updateChangeMap) {
        this.updateChangeMap(this._elementMap[key], comp);
      }
    }
  }

  public RemoveValue(key, updateChangeMap: boolean = true) {
    const comp = this.Group.get(key);
    if (comp) {
      comp.reset();
      if (updateChangeMap) {
        comp.markAsTouched();
      }

      if (updateChangeMap) {
        this.removeFromChangeMap(this._elementMap[key], comp);
      }
    }
  }

  public HasValue(key) {
    const comp = this.Group.get(key);
    if (comp) {
      return comp.dirty && comp.valid;
    }
    return false;
  }

  public CheckValidity() {
    this.HasGeneralError = false;
    this.Error = null;

    for (let i = 0; i < this._elements.length; ++i) {
      const element = this._elements[i];
      const comp = this.Group.get(element.name);

      // Mark the controls as touched
      comp.markAsTouched();
    }

    // Cancel if not valid
    if (this.Group.invalid) {
      return false;
    } else {
      return true;
    }
  }

  public Submit() {
    this.HasGeneralError = false;
    this.Error = null;

    for (let i = 0; i < this._elements.length; ++i) {
      const element = this._elements[i];
      const comp = this.Group.get(element.name);

      // Mark the controls as touched
      comp.markAsTouched();
    }

    // Cancel if not valid
    if (this.Group.invalid) {
      return;
    }

    this.SetEnabled(false);

    this._asyncCall(this._changeMap)
    .then(
      result => {
        this.SetEnabled(true);

        if (this._onSuccess) {
          this._onSuccess(result);
        }
      },
      (err: IAPIError) => {
        this.OverrideError(err);
      }
    );
  }
  public OverrideError(err: IAPIError) {
    this.SetEnabled(true);
    let hasErr = false;
    if (err.data && err.data.length > 0) {
      err.data.forEach(
        (errData: IApiErrorData) => {
          hasErr = this._processData(errData) || hasErr;
        }
      );
    }

    if (!hasErr) {
      let errMessage = err.message;
      if (err.data && err.data.length > 0) {
        for (let i = 0; i < err.data.length; ++i) {
          errMessage = this._getGeneralError(err.data[i]) || errMessage;
          break;
        }
      }
      this.SetGeneralError(errMessage);
    }

    if (this._onError) {
      this._onError(err);
    }
  }

  private _getGeneralError(err: IApiErrorData): string {
    for (let i = 0; i < this._generalErrors.length; ++i) {
      const gErr = this._generalErrors[i];
      if (err.path === gErr.field) {
        if (err.keys[gErr.code]) {
          return gErr.message;
        }
      }
    }
    return null;
  }

  private _processData(data: IApiErrorData, prefix: string = null): boolean {
    let hasErr = false;

    let path: string = data.path;
    if (prefix) {
      path = prefix + '.' + path;
    }

    let comp = this.Group.get(path);

    if (!comp && path in this._outputPathMap) {
      comp = this.Group.get(this._outputPathMap[path]);
    }

    if (comp) {
      comp.setErrors(data.keys);
      hasErr = true;
    }

    if (data.children) {
      data.children.forEach((childData: IApiErrorData) => {
        hasErr = this._processData(childData, path) || hasErr;
      });
    }

    return hasErr;
  }

  public SetGeneralError(message) {
    this.HasGeneralError = true;
    if (message) {
      this.Error = message;
    } else {
      this.Error = null;
    }
  }

  public ClearGeneralError() {
    this.HasGeneralError = false;
    this.Error = null;
  }

  public Reset() {
    this.Group.reset();
    this._changeMap = {};
  }

  public FeedbackHidden(item: string): boolean {
    const comp = this.Group.get(item);
    return (comp.valid || comp.pristine) && !comp.touched;
  }

  public ErrorHidden(item: string, error: string): boolean {
    if (this.Group.get(item).errors) {
      // console.log(this.Group.get(item).errors);              /// ------------------ UNCOMMENT TO SEE WHY ERROR MESSAGES NOT SHOWING ----------------- ///
      if (error in this.Group.get(item).errors) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  public GeneralErrorHidden(): boolean {
    return !this.HasGeneralError;
  }

  public GeneralErrorHasMessage(): boolean {
    return this.Error != null;
  }

  get Valid(): boolean {
    return this.Group.valid;
  }

  get Dirty(): boolean {
    return this.Group.dirty;
  }

}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  templateUrl: './test.component.html'
})
export class TestComponent {

  pdfFile: FileSystemFileEntry;

  public dropped(files: NgxFileDropEntry[]) {
    if (files.length === 0) {
      return;
    }
    const droppedFile  = files[0];


    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      this.pdfFile = fileEntry;
    }

  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }
}

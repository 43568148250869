/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirm-modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
var styles_AppModalConfirmComponent = [];
var RenderType_AppModalConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppModalConfirmComponent, data: {} });
export { RenderType_AppModalConfirmComponent as RenderType_AppModalConfirmComponent };
export function View_AppModalConfirmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-describedby", "modal-title"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary"], ["ngbAutofocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.cancel; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.ok; _ck(_v, 12, 0, currVal_3); }); }
export function View_AppModalConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-confirm", [], null, null, null, View_AppModalConfirmComponent_0, RenderType_AppModalConfirmComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppModalConfirmComponent, [i2.NgbActiveModal], null, null)], null, null); }
var AppModalConfirmComponentNgFactory = i0.ɵccf("app-modal-confirm", i1.AppModalConfirmComponent, View_AppModalConfirmComponent_Host_0, { header: "header", message: "message", ok: "ok", cancel: "cancel" }, {}, []);
export { AppModalConfirmComponentNgFactory as AppModalConfirmComponentNgFactory };

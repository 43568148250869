var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
export class DocumentTypeComponent {
    constructor(hiero, fb, router, route) {
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.busy = true;
        this.formatter = (lang) => lang ? lang : '';
        this.search = (text$) => text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 2
            ? []
            : this.fullLanguageList.filter((value) => (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null), 10)));
        this.fullLanguageList = Language.GetAllNativeAndRegularNames();
        this.createForm();
    }
    ngAfterViewInit() {
        this.docTypeId = this.route.snapshot.paramMap.get('documentTypeId');
        this.adminSub = this.hiero.WatchAdmin({
            next: (admin) => {
                DocumentType.Init(admin.User, true)
                    .then((docType) => {
                    this.docTypeColl = docType;
                    this.busy = false;
                    this.extract(this.docTypeId);
                    this.docTypeSub = this.docTypeColl.Watch({
                        next: (updated) => {
                            this.docTypeColl = updated;
                            this.busy = false;
                            this.extract(this.docTypeId);
                        }
                    });
                });
            }
        });
    }
    extract(id) {
        this.docType = this.docTypeColl.getDocumentType(id);
    }
    ngOnDestroy() {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
        if (this.docTypeSub) {
            this.docTypeSub.unsubscribe();
        }
    }
    getDocTypeFor(code, documentType) {
        return this.docTypeColl.GetDocumentName(code, documentType._id);
    }
    createForm() {
        this.newForm = new SubmitForm(this.fb, [
            {
                name: 'code',
                value: '',
                validators: [],
                type: 'text',
                title: 'Langue',
                autocomplete: '',
                placeholder: 'Commencer à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'label',
                value: '',
                validators: [],
                type: 'text',
                title: 'Nom (en français)',
                autocomplete: '',
                placeholder: 'Le nom du document dans la langue précisée',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        (changes) => {
        }, 
        // Success callback
        () => {
            // What to do with login success ?
            this.busy = false;
        }, 
        // Fail callback
        err => {
            // What to do with login failuer
            console.error(err);
            this.busy = false;
        }, 
        // Changes callback
        null);
    }
    removeLabel(label) {
        return __awaiter(this, void 0, void 0, function* () {
            this.newForm.ClearGeneralError();
            this.busy = true;
            try {
                this.docTypeColl.RemoveLabel(this.docType._id, label.code);
            }
            catch (err) {
                this.newForm.SetGeneralError(err.message);
            }
        });
    }
    addLabel() {
        return __awaiter(this, void 0, void 0, function* () {
            const name = this.newForm.GetValue('label');
            if (!name) {
                return;
            }
            const validated = name.trim().toLowerCase();
            if (validated.length === 0) {
                return;
            }
            const code = this.newForm.GetValue('code');
            if (!code) {
                return;
            }
            const codeISO = Language.GetISO639(code);
            this.newForm.ClearGeneralError();
            this.busy = true;
            try {
                this.docTypeColl.AddLabel(this.docType._id, codeISO, validated);
                this.newForm.Reset();
            }
            catch (err) {
                this.newForm.SetGeneralError(err.message);
            }
        });
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IPDFDocument, IPDFInput } from '../../bdd/interfaces/IPDF';
import { PDFRender } from '../pdfpreview/pdfrender';



@Component({
  selector: 'app-pdf-editor',
  templateUrl: './pdfeditor.component.html'
})
export class PDFEditorComponent implements AfterViewInit, OnChanges {
  @Input() objectEntry: PDFRender;
  @Output() update: EventEmitter<IPDFDocument> = new EventEmitter();

  @ViewChild('listContainer') listContainer: ElementRef;

  private pdf: IPDFDocument;
  pdfRender: PDFRender;

  private pageIndex = 0;
  private inputIndex = 0;
  currentInput: IPDFInput;


  constructor(
    private cdRef: ChangeDetectorRef,
    private zone: NgZone
  ) {

  }

  ngAfterViewInit() {
    this.load();
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('objectEntry' in changes) {
      this.load();
    }
  }


  load() {
    if (this.objectEntry) {
      this.pageIndex = 0;
      this.pageIndex = 0;
      this.currentInput = null;
      this.pdf = this.objectEntry.Data;
      this.pdfRender = this.objectEntry;
      this.nextElement(true);
    }
  }

  get hasPrevious(): boolean {
    return !(this.inputIndex  === 0 && this.pageIndex === 0);
  }

  get hasNext(): boolean {
    let nextEl = this.inputIndex + 1;
    let nextPage = this.pageIndex;

    if (nextEl >= this.pdf.pages[this.pageIndex].inputs.length) {
      nextEl = 0;
      nextPage = this.pageIndex + 1;
    }

    return (nextPage < this.pdf.pageCount && nextEl < this.pdf.pages[nextPage].inputs.length);
  }

  nextElement(forceRender: boolean = true) {
    this.zone.run(
      () => {
        if (this.currentInput) {
          this.currentInput.fill = null;

          let nextEl = this.inputIndex + 1;
          let nextPage = this.pageIndex;

          if (nextEl >= this.pdf.pages[this.pageIndex].inputs.length) {
            nextEl = 0;
            nextPage = this.pageIndex + 1;
          }

          if (nextPage < this.pdf.pageCount && nextEl < this.pdf.pages[nextPage].inputs.length) {
            this.inputIndex = nextEl;
            this.pageIndex = nextPage;

            this.currentInput = this.pdf.pages[this.pageIndex].inputs[this.inputIndex];
          }

        } else {
          this.currentInput = this.pdf.pages[0].inputs[0];
        }

        this.currentInput.fill = '#00acac';
        if (forceRender) {
          this.pdfRender.renderToUriString();
        }
      }
    );
  }

  previousElement(forceRender: boolean = true) {
    this.zone.run(
      () => {
        if (this.currentInput) {

          this.currentInput.fill = null;

          let nextEl = this.inputIndex - 1;
          let nextPage = this.pageIndex;

          if (nextEl < 0) {
            nextPage = this.pageIndex - 1;

            if (nextPage >= 0) {
              nextEl = this.pdf.pages[nextPage].inputs.length - 1;
            } else {
              nextEl = 0;
              nextPage = 0;
            }
          }

          if (nextPage < this.pdf.pageCount && nextEl < this.pdf.pages[nextPage].inputs.length) {
            this.inputIndex = nextEl;
            this.pageIndex = nextPage;

            this.currentInput = this.pdf.pages[this.pageIndex].inputs[this.inputIndex];
          }

        } else {
          this.currentInput = this.pdf.pages[0].inputs[0];
        }
        this.currentInput.fill = '#00acac';

        if (forceRender) {
          this.pdfRender.renderToUriString();
        }
      }
    );
  }

  onUpdate(input: IPDFInput) {
    this.pdfRender.renderToUriString();
    this.update.emit(this.pdf);
  }
}


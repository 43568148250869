import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ISubmitFormElement } from '../submitform.class';

export interface ITypeAheadInputButton {
  text: string;
  alwaysEnabled: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-form-typeahead',
  templateUrl: './app-form-typeahead.component.html'
})
export class AppFormTypeaheadComponent implements OnChanges {
  @Input() parameters: ISubmitFormElement ;
  @Input() button: ITypeAheadInputButton;
  @Input() search;
  @Input() formatter;
  @Output() onButtonClick = new EventEmitter();
  @Output() selected = new EventEmitter();

  public model = null;

  canValidate = false;
  chosenItem = null;

  ngOnChanges(changeMap) {
    if ('parameters' in changeMap) {
      const changes: SimpleChanges = changeMap['parameters'];
      if (changes.currentValue) {
        this.model = this.formatter(changes.currentValue['value']);
      }
    }
  }

  getValue(m): string {
    if (m) {
      return m;
    } else {
      return '';
    }
  }

  onSelected(event: NgbTypeaheadSelectItemEvent) {
    if (event.item) {
      this.chosenItem = event.item;
      this.canValidate = true;

      this.selected.emit(event.item);

      const displayVal =  this.formatter(event.item);
      this.parameters._submitForm.SetValue(this.parameters.name, displayVal, true);
      this.model = displayVal;
    }
  }

  onClick() {
    this.onButtonClick.emit(this.chosenItem);
  }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
const FIREBASE_DOCUMENT_TYPE_COLLECTION = 'document_types';
/**
 * This class initialises and stores a map of the document types handled by the system.
 * The list of supported documents comes from the firestore database.
 */
export class DocumentType {
    constructor(user, query, firstSnapshot, watch) {
        this._user = user;
        this._documentMapSubject = new BehaviorSubject(this);
        this.processMap(firstSnapshot);
        if (watch) {
            this._unsubscribe = query.onSnapshot((snapshot) => {
                this.processMap(snapshot);
            }, (err) => { });
        }
    }
    processMap(snapshot) {
        this._documentMap = new Map();
        if (snapshot && !snapshot.empty) {
            snapshot.docs.forEach((docSnapshot) => {
                const data = docSnapshot.data();
                data._id = docSnapshot.id;
                this._documentMap.set(docSnapshot.id, data);
            });
        }
        this._documentMapSubject.next(this);
    }
    cleanup() {
        if (this._unsubscribe) {
            this._unsubscribe();
            this._unsubscribe = null;
        }
    }
    Watch(observer) {
        return this._documentMapSubject.subscribe(observer);
    }
    GetDocumentName(iso639, docId) {
        if (this._documentMap.has(docId)) {
            const type = this._documentMap.get(docId);
            const label = type.label.find(docType => docType.code === iso639);
            if (label) {
                return label.value;
            }
            else {
                return docId;
            }
        }
        else {
            return docId;
        }
    }
    Add(documenttype) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).add(documenttype);
        });
    }
    AddLabel(docTypeId, codeISO639, text) {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = yield this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).doc(docTypeId);
            const doc = yield docRef.get();
            if (doc.exists) {
                const docType = doc.data();
                const label = docType.label.find(type => type.code === codeISO639);
                if (label) {
                    label.value = text;
                }
                else {
                    docType.label.push({
                        code: codeISO639,
                        value: text
                    });
                }
                yield docRef.update({
                    label: docType.label
                });
            }
            else {
                throw Error('Document type with that id does not exist.');
            }
        });
    }
    RemoveLabel(docTypeId, codeISO639) {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = yield this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).doc(docTypeId);
            const doc = yield docRef.get();
            if (doc.exists) {
                const docType = doc.data();
                const labels = docType.label.filter(type => type.code !== codeISO639);
                yield docRef.update({
                    label: labels
                });
            }
            else {
                throw Error('Document type with that id does not exist.');
            }
        });
    }
    getDocumentType(id) {
        return this._documentMap.get(id);
    }
    /**
     * This asynchronous method will load a map of supported document types.
     * @param user The logged in user.
     */
    static Init(user, watch) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION);
            const snapshot = yield query.get();
            return new DocumentType(user, query, snapshot, watch);
        });
    }
    /** If Init has already been called, this will return the map of document types */
    get Map() {
        return this._documentMap;
    }
    get ToArray() {
        const arr = [];
        this._documentMap.forEach((docType) => {
            arr.push(docType);
        });
        return arr;
    }
}

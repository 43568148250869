var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as firebase from "firebase";
import { HieroBDD } from "../../services/hierobdd.service";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
export class SingleCommandComponent {
    constructor(route, hiero, router, modalService) {
        this.route = route;
        this.hiero = hiero;
        this.router = router;
        this.modalService = modalService;
        this.id = this.route.snapshot.paramMap.get("id");
        this.busy = true;
    }
    ngOnInit() {
        this.getCommand(this.id).then((value) => (this.command = value));
        this.getClientType(this.id).then((value) => (this.clientType = value));
        this.clientName(this.id).then((value) => (this.name = value));
    }
    getCommand(id) {
        const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
        const req2 = this.hiero.DB.collection("professionnel_commandes").where("prestationId", "==", id);
        return req.get().then((snapshot) => {
            if (snapshot.docs[0] !== undefined) {
                return snapshot.docs[0].data();
            }
            else {
                return req2.get().then((snapshot) => {
                    return snapshot.docs[0].data();
                });
            }
        });
    }
    getClientType(id) {
        const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
        return req.get().then((snapshot) => {
            if (snapshot.docs[0] !== undefined) {
                return "Particulier";
            }
            else {
                return "Professionnel";
            }
        });
    }
    deleteCommand() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Confirmer la suppression";
            modalRef.componentInstance.message =
                "Etes vous vraiment sur de vouloir supprimer cette commande ?";
            modalRef.componentInstance.ok = "Supprimer";
            modalRef.componentInstance.cancel = "Annuler";
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    let db = firebase.firestore();
                    let type = yield this.getClientType(this.id);
                    const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", this.id);
                    const reqPro = this.hiero.DB.collection("professionnel_commandes").where("prestationId", "==", this.id);
                    const req2 = this.hiero.DB.collection("facture_particuliers").where("prestation", "==", this.id);
                    const req3 = this.hiero.DB.collection("facture").where("prestation", "==", this.id);
                    req.get().then((snap) => {
                        if (snap.docs[0] !== undefined) {
                            db.collection("particuliers_commandes")
                                .doc(snap.docs[0].id)
                                .delete()
                                .then(() => {
                                db.collection("prestations")
                                    .doc(this.id)
                                    .delete()
                                    .then(() => {
                                    req2.get().then((snap) => {
                                        db.collection("facture_particuliers")
                                            .doc(snap.docs[0].id)
                                            .delete();
                                    });
                                });
                            })
                                .catch((error) => {
                                console.error("Error removing document: ", error);
                            });
                            this.router.navigate(["app/commandes"]);
                        }
                        else {
                            reqPro.get().then((snap) => {
                                db.collection("professionnel_commandes")
                                    .doc(snap.docs[0].id)
                                    .delete()
                                    .then(() => {
                                    db.collection("prestations")
                                        .doc(this.id)
                                        .delete()
                                        .then(() => {
                                        req3.get().then((snap) => {
                                            db.collection("facture").doc(snap.docs[0].id).delete();
                                        });
                                    });
                                })
                                    .catch((error) => {
                                    console.error("Error removing document: ", error);
                                });
                                this.router.navigate(["app/commandes"]);
                            });
                        }
                    });
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
    clientName(id) {
        const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
        return req.get().then((snapshot) => {
            if (snapshot.docs[0] !== undefined) {
                return this.hiero.DB.collection("users")
                    .doc(snapshot.docs[0].data().prestationInfo.uid)
                    .get()
                    .then((snap) => {
                    return (snap.data().profile.givenName +
                        " " +
                        snap.data().profile.familyName);
                });
            }
            else {
                return this.hiero.DB.collection("professionnel_commandes")
                    .where("prestationId", "==", id)
                    .get()
                    .then((snap) => {
                    return this.hiero.DB.collection("professionnels")
                        .doc(snap.docs[0].data().professionnelId)
                        .get()
                        .then((snap) => {
                        return snap.data().businessName;
                    });
                });
            }
        });
    }
}

import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { PartialObserver } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Admin } from '../../../common/src/bdd/admin/Admin';
import { AuthService } from '../../../common/src/bdd/user/AuthService';
import { ERoleType, User } from '../../../common/src/bdd/user/User';
import { Config } from '../../../common/src/services/config.service';
import { GoogleGeo } from './google.services';


@Injectable()
export class HieroBDD {

  private _db: firebase.firestore.Firestore;
  private _auth: firebase.auth.Auth;
  private _authService: AuthService;
  private _storage: firebase.storage.Storage;

  // App specific
  private _adminSubject: BehaviorSubject<Admin>;

  constructor(
    private config: Config,
    private google: GoogleGeo
  ) {
    // Initialize Firebase
    firebase.initializeApp(this.config.Environment.firebase);

    // Create the database object
    this._db = firebase.firestore();
    this._auth = firebase.auth();
    this._storage = firebase.storage();

    this._adminSubject = new BehaviorSubject(null);

    // Setup the service provider
    this._authService = new AuthService(this._auth, this._db, ERoleType.admin);

    // Setup extra login steps:
    // 1/ Login automatically as a translator
    this._authService.extraLoginSteps.push(
      async (user: User) => {
        const admin: Admin = await Admin.Init(user);
        this._adminSubject.next(admin);
      }
    );

    // Set up a watch on user, if user becomes null, so does traducteur
    this._authService.WatchUser({
      next: (user) => {
        if (user == null) {
          this._adminSubject.next(null);
        }
      }
    });

    // Start listener
    this.Auth.Listen();
  }

  get Auth(): AuthService {
    return this._authService;
  }

  get DB(): firebase.firestore.Firestore {
    return this._db;
  }

  get Storage(): firebase.storage.Storage {
    return this._storage;
  }

  public WatchAdmin(observer: PartialObserver<Admin>): Subscription {
    return this._adminSubject.subscribe(observer);
  }

  public Admin(): Admin|null {
    return this._adminSubject.value;
  }

}

import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Admin } from '../../../../../../common/src/bdd/admin/Admin';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { IDocumentType, ITranslation } from '../../../../../../common/src/bdd/interfaces/IDocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';

@Component({
  templateUrl: './documentType.component.html'
})
export class DocumentTypeComponent implements AfterViewInit, OnDestroy {

  busy = true;
  private adminSub: Subscription;
  private docTypeSub: Subscription;
  private docTypeColl: DocumentType;
  private docTypeId: string;
  private fullLanguageList: string[];
  
  docType: IDocumentType;
  

  newForm: SubmitForm;

  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.fullLanguageList = Language.GetAllNativeAndRegularNames();
    this.createForm();

  }

  ngAfterViewInit() {
    this.docTypeId = this.route.snapshot.paramMap.get('documentTypeId');

    this.adminSub = this.hiero.WatchAdmin({
      next: (admin: Admin) => {

        DocumentType.Init(admin.User, true)
        .then(
          (docType) => {
            this.docTypeColl = docType;
            this.busy = false;
            this.extract(this.docTypeId);
            this.docTypeSub = this.docTypeColl.Watch({
              next: (updated) => {
                this.docTypeColl = updated;
                this.busy = false;
                this.extract(this.docTypeId);
              }
            });
          }
        );

      }
    });

  }

  private extract(id: string) {
    this.docType = this.docTypeColl.getDocumentType(id);
  }

  ngOnDestroy()  {
    if (this.adminSub) {
      this.adminSub.unsubscribe();
    }
    if (this.docTypeSub) {
      this.docTypeSub.unsubscribe();
    }
  }

  getDocTypeFor(code: string, documentType: IDocumentType) {
    return this.docTypeColl.GetDocumentName(code, documentType._id);
  }

  createForm() {
    this.newForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'code',
          value: '',
          validators: [],
          type: 'text',
          title: 'Langue',
          autocomplete: '',
          placeholder: 'Commencer à taper le nom de la langue',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
          ]
        },
        {
          name: 'label',
          value: '',
          validators: [],
          type: 'text',
          title: 'Nom (en français)',
          autocomplete: '',
          placeholder: 'Le nom du document dans la langue précisée',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
          ]
        }
      ],
      // Submit callback
      (changes) => {
      },

      // Success callback
      () => {
        // What to do with login success ?
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );
  }

 
  async removeLabel(label: ITranslation) {
    this.newForm.ClearGeneralError();
    this.busy = true;
    try {
      this.docTypeColl.RemoveLabel(this.docType._id, label.code);
    } catch (err) {
      this.newForm.SetGeneralError(err.message);
    }
  }

  async addLabel() {
    const name: string = this.newForm.GetValue('label');
    if (!name) {
      return;
    }
    const validated: string = name.trim().toLowerCase();
    if (validated.length === 0) {
      return;
    }

    const code = this.newForm.GetValue('code');
    if (!code) {
      return;
    }

    const codeISO = Language.GetISO639(code);

    this.newForm.ClearGeneralError();
    this.busy = true;
    try {
      this.docTypeColl.AddLabel(this.docType._id, codeISO, validated);
      this.newForm.Reset();
    } catch (err) {
      this.newForm.SetGeneralError(err.message);
    }
  }


  formatter = (lang: string) => lang ? lang : '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term =>
          term.length < 2
            ? []
            : this.fullLanguageList.filter((value) => (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null) , 10)
      )
    )


  

}

import { ActivatedRoute } from '@angular/router';

export type GetDynamicTitleHandler = (key: string) => string;
export type TitleUpdatedHandler = (titles: ITitleInfo[]) => void;

export interface ITitleInfo {
  section: boolean;
  title: string;
  heading: string;
}

export class TitleExtractor {

  private _route: ActivatedRoute = null;
  private _updateHandler: TitleUpdatedHandler = null;
  private _getDynamicHandler: GetDynamicTitleHandler = null;

  constructor(route: ActivatedRoute, getDynamicHandler: GetDynamicTitleHandler, updatedHandler: TitleUpdatedHandler ) {
    this._route = route;
    this._updateHandler = updatedHandler;
    this._getDynamicHandler = getDynamicHandler;
  }

  private _getRoute(route: ActivatedRoute, titles: ITitleInfo[]) {

    if (!route) {
      return null;
    }

    // Add title if there is one
    if (route.snapshot && route.snapshot.data) {
      const info: ITitleInfo = {
        section: route.snapshot.data.section,
        title: route.snapshot.data.title,
        heading: route.snapshot.data.heading
      };

      if ('dynamicKey' in route.snapshot.data) {
        const dynamic = this._getDynamicHandler(route.snapshot.data.dynamicKey);
        info.title = dynamic;
      }

      if (info.title || info.heading) {
        titles.push(info);
      }
    }

    this._getRoute(route.firstChild, titles);

  }

  private getTitles(): ITitleInfo[] {

    const titles: ITitleInfo[] = [];
    this._getRoute(this._route, titles);

    return titles;
  }

  update() {
    this._updateHandler(this.getTitles());
  }

}

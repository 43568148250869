/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not_found.component";
var styles_NotFoundComponent = ["./not_found.component.scss"];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "error-code m-b-10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "error-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Impossible de trouver l'\u00E9l\u00E9ment"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "error-desc m-b-30"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" la page que vous essayez d'atteindre n'existe pas"])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Contactez le support si le probl\u00E8me persiste "])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "btn btn-success p-l-20 p-r-20"], ["href", "app/home"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Acceuil"]))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i1.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("ng-component", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };

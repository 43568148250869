<!-- begin login -->
<div class="login login-with-news-feed">
  <!-- begin news-feed -->
  <div class="news-feed">
    <div class="news-image" style="background-image: url(../assets/img/login-bg/login-bg-9.jpg)"></div>
    <div class="news-caption">
      <h4 class="caption-title"><b>Hierø - Super admin</b></h4>
      <p>
        Logiciel de service de mise en relation entre clients et traducteurs.
      </p>
    </div>
  </div>
  <!-- end news-feed -->
  <!-- begin right-content -->
  <div class="right-content">
    <!-- begin login-header -->
    <div class="login-header">
      <div class="brand">
        <img src="../assets/img/logo/bird.png" height="60px"/>  <b> Hierø - Super admin</b>
        <small>Gestion d'utilisateurs et des documents template</small>
      </div>
      <div class="icon">
        <i class="fa fa-sign-in"></i>
      </div>
    </div>
    <!-- end login-header -->
    <!-- begin login-content -->
    <div class="login-content">
      <form [formGroup]="loginForm.Group" novalidate class="margin-bottom-0" data-parsley-validate="true">
        <h1 class="register-header">
          Se connecter
        </h1>
        <div class="form-group m-b-15">
            <app-form-input [parameters]="loginForm.GetInputElement('email')"></app-form-input>
        </div>
        <div class="form-group m-b-15">
            <app-form-input [parameters]="loginForm.GetInputElement('password')"></app-form-input>
        </div>
        <app-form-general-error [submitForm]="loginForm"></app-form-general-error>
        <div class="checkbox checkbox-css m-b-30">
          <input type="checkbox" id="remember_me_checkbox" value="" />
          <label for="remember_me_checkbox">
          Rester connecté
          </label>
        </div>
        <div class="login-buttons">
          <button (click)='loginForm.Submit()' [disabled]="!loginForm.Valid" class="btn btn-danger btn-block btn-lg">Se connecter</button>
        </div>
        <div class="m-t-20 m-b-40 p-b-40 text-inverse">
          Pas encore inscrit ? Cliquez <a [routerLink]="['..', 'inscription']" class="text-success">ici</a> pour vous inscrire.
        </div>
        <app-wait [show]='busy'></app-wait>
        <hr /> 
        <p class="text-center text-grey-darker">
          &copy; Hiero - HIERØ 2020
        </p>
      </form>
    </div>
    <!-- end login-content -->
  </div>
  <!-- end right-container -->
</div>
<!-- end login -->
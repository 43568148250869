<div class="form-group">

  <div class="input-group input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">{{parameters.input_title}}</span>
    </div>
    <div class="input-group-append" ngbDropdown  role="group" style="margin-left: 0"> 
        <button class="btn btn-outline-primary" ngbDropdownToggle  [ngClass]="{'rounded-0': parameters.hasButton}">{{selected}}</button>
        <div class="dropdown-menu app-form-dropdown-fixed-scrollable " ngbDropdownMenu>
          <button *ngFor="let choice of parameters.choices" class="dropdown-item app-dd-pointer" (click)="onSelected(choice)">{{choice.name}}</button>        
        </div>

        <button *ngIf="parameters.hasButton" class="btn btn-primary" type="button" (click)="onClicked()">{{parameters.button_title}}</button>   
    </div>
  </div>
  <small *ngIf="parameters.help != null" class="form-text text-muted">{{parameters.help}}</small>
</div>


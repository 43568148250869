<!-- begin login -->
<div class="login bg-black animated fadeInDown">
    <!-- begin brand -->
    <div class="login-header">
        <div class="brand">
            <img src="../assets/img/logo/bird.png" height="80px">  <b> Hierø - Super admin</b>
        </div>
        <div class="icon">
            <i class="fa fa-lock"></i>
        </div>
    </div>
    <!-- end brand -->
    <!-- begin login-content -->
    <div class="login-content">
        <form action="index.html" method="GET" class="margin-bottom-0">
            <div style="text-align:center;">
                Pensé par des traducteurs, pour des traducteurs<br/><br/>
            </div>
            <div class="login-buttons">
                <button type="submit" class="btn btn-danger btn-block btn-lg" [routerLink]="['app']">Connexion</button>
            </div>
            <div style="text-align:center;">ou</div>
            <div class="login-buttons">
                <button type="submit" class="btn btn-default btn-block btn-lg" [routerLink]="['testpdf']">Test</button>
            </div>
        </form>
    </div>
    <!-- end login-content -->
</div>
<!-- end login -->

<router-outlet></router-outlet>
import { Inject, Injectable } from '@angular/core';
import { IConfig } from '../interfaces/config/IConfig';

@Injectable()
export class Config {

  private _config: IConfig;

  constructor(
    @Inject('CONFIG') config,
  ) {
    this._config = config;
  }

  get Environment(): IConfig {
    return this._config;
  }


  /// ---------- APP ------------ ///
  public get AppFullPath(): string {
    return this.Environment.app.origin + this.Environment.app.subpath;
  }

}

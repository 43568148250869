import { Routes } from "@angular/router";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { AccountComponent } from "./routes/account/account.component";
import { LoginComponent } from "./routes/account/login/login.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
import { DocumentTypeComponent } from "./routes/app/appbase/documentType/documentType.component";
import { DocumentTypeListComponent } from "./routes/app/appbase/documentTypes/documentTypes.component";
import { TemplateListComponent } from "./routes/app/appbase/templates/templates.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { TestComponent } from "./routes/base/test/test.component";
import { TemplateComponent } from "./routes/app/appbase/template/template.component";
import { AddTemplateComponent } from "./routes/app/appbase/addTemplate/addtemplate.component";
import { DashboardComponent } from "./routes/dashboard/dasboard.component";
import { CommandesComponent } from "./routes/commandes/commandes.component";
import { SingleCommandComponent } from "./routes/single-command/single-command.component";
import { AllUsersComponent } from "./routes/allUsers/allUsers.component";
import { SingleProfilComponent } from "./routes/singleProfil/singleProfil.component";
const ɵ0 = { title: "Types de document" }, ɵ1 = { title: "Templates" }, ɵ2 = { title: "Ajouter un template" }, ɵ3 = { title: "Tableau de bord" }, ɵ4 = { title: "Toutes les commandes" }, ɵ5 = { title: "Détails de la commande" }, ɵ6 = { title: "Utilisateurs inscrits" }, ɵ7 = { title: "Profil utilisateur" };
const appRoutes = [
    // Pages for logging in
    {
        path: "compte",
        component: AccountComponent,
        children: [
            {
                path: "connexion",
                component: LoginComponent,
            },
        ],
    },
    {
        path: "testpdf",
        component: TestComponent,
    },
    // Validated section - user is connected
    {
        path: "app",
        canActivate: [LoggedInGuard],
        component: AppBaseComponent,
        children: [
            {
                path: "documentTypes",
                component: DocumentTypeListComponent,
                data: ɵ0,
            },
            {
                path: "documentType/:documentTypeId",
                component: DocumentTypeComponent,
            },
            {
                path: "templates",
                component: TemplateListComponent,
                data: ɵ1,
            },
            {
                path: "addtemplate",
                component: AddTemplateComponent,
                data: ɵ2,
            },
            {
                path: "template/:templateId",
                component: TemplateComponent,
            },
            {
                path: "dashboard",
                component: DashboardComponent,
                data: ɵ3,
            },
            {
                path: "commandes",
                component: CommandesComponent,
                data: ɵ4,
            },
            {
                path: "commandes/:id",
                component: SingleCommandComponent,
                data: ɵ5,
            },
            {
                path: "users",
                component: AllUsersComponent,
                data: ɵ6,
            },
            {
                path: "users/:id",
                component: SingleProfilComponent,
                data: ɵ7,
            },
        ],
    },
    // Logged out page(s)
    {
        path: "",
        component: BaseComponent,
        children: [],
    },
    /// Fallbacks
    { path: "", redirectTo: "BaseComponent", pathMatch: "full" },
    { path: "**", component: NotFoundComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };

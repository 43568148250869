var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { Chart } from "chart.js";
import { HieroBDD } from "../../services/hierobdd.service";
import "firebase/functions";
import { HttpClient } from "@angular/common/http";
export class DashboardComponent {
    constructor(hiero, http) {
        this.hiero = hiero;
        this.http = http;
        this.BarChart = [];
        this.date = new Date();
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.numberOfParticular().then((value) => (this.numberParticular = value.length));
            this.numberOfPro().then((value) => (this.numberPro = value.length));
            this.numberOfTrad().then((value) => (this.numberTrad = value.length));
            this.numberOfUsers().then((value) => (this.numberUser = value.length));
            this.numberOfUsersThisMonth().then((value) => (this.usersThisMonth = value.length));
            this.numberOfParticularThisMonth().then((value) => (this.particularThisMonth = value));
            this.numberOfProThisMonth().then((value) => (this.proThisMonth = value));
            this.numberOfTradThisMonth().then((value) => (this.tradThisMonth = value));
            this.currentOrders().then((value) => (this.command = value.length));
            this.currenOrderPrice().then((value) => (this.commandPriceTotal = value));
            this.salesRevenues().then((value) => (this.sales = value));
            this.numberOfUsers().then((value) => {
                this.newRegistration = value
                    .sort(function (a, b) {
                    return (new Date(b.metadata.creationTime).getTime() -
                        new Date(a.metadata.creationTime).getTime());
                })
                    .slice(0, 5);
            });
            this.commandPro().then((value) => (this.commandOfPro = value));
            this.ortherCommand().then((value) => (this.commandParticular = value));
            // this.emailCheck().then((value) => console.log(value));
            this.allUsersReq().then((value) => (this.data = value));
            this.numberOfPro().then((value) => (this.dataPro = value));
            this.numberOfParticular().then((value) => (this.dataParticulier = value));
            this.salesRevenuesLastMonth().then((value) => (this.salesLast = value));
            this.compareSales().then((value) => (this.salesRate = value));
            this.compareMonthParticular().then((value) => (this.particularRate = value));
            this.compareMonthPro().then((value) => (this.proRate = value));
            this.compareMonthTrad().then((value) => (this.translatorRate = value));
            this.compareMonthUsers().then((value) => (this.userRate = value));
            setTimeout(() => {
                this.BarChart = new Chart("barChart", {
                    type: "bar",
                    data: {
                        labels: ["MAR", "APR"],
                        datasets: [
                            {
                                label: "Chiffre d'affaires",
                                data: [this.salesLast, this.sales],
                                backgroundColor: [
                                    "rgba(255, 99, 132, 0.2)",
                                    "rgba(7, 113, 180, 0.2)",
                                ],
                                borderColor: ["rgba(255,99,132,1)", "rgba(7, 113, 180, 1)"],
                                borderWidth: 2,
                            },
                        ],
                    },
                    options: {
                        title: {
                            text: "Evolution sur l'année en cours",
                            display: false,
                            maintainAspectRatio: false,
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    },
                });
            }, 1000);
        });
    }
    numberOfParticular() {
        const req = this.hiero.DB.collection("particuliers");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
    numberOfPro() {
        const req = this.hiero.DB.collection("professionnels");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
    numberOfTrad() {
        const req = this.hiero.DB.collection("traducteurs");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
    numberOfUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return result;
        });
    }
    numberOfUsersThisMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return result.filter((user) => new Date(user.metadata.creationTime).getMonth() ===
                new Date().getMonth() &&
                new Date(user.metadata.creationTime).getFullYear() ===
                    new Date().getFullYear());
        });
    }
    numberOfUsersLastMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return result.filter((user) => new Date(user.metadata.creationTime).getMonth() ===
                new Date().getMonth() - 1 &&
                new Date(user.metadata.creationTime).getFullYear() ===
                    new Date().getFullYear());
        });
    }
    compareMonthUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const va = yield this.numberOfUsersThisMonth();
            const vd = yield this.numberOfUsersLastMonth();
            let taux;
            if (vd.length === 0 && va.length !== 0) {
                taux = 100;
            }
            else if (va.length === 0 && vd.length === 0) {
                taux = 0;
            }
            else {
                taux = ((va.length - vd.length) / vd.length) * 100;
            }
            return taux.toFixed(2);
        });
    }
    numberOfParticularThisMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("particuliers");
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            let users = [];
            return req
                .get()
                .then((snapshot) => {
                snapshot.docs.map((particular) => {
                    users = [
                        result.filter((user) => user.uid === particular.data().uid),
                        ...users,
                    ];
                });
            })
                .then(() => {
                users = users.filter((user) => user.length !== 0 &&
                    new Date(user[0].metadata.creationTime).getMonth() ===
                        new Date().getMonth() &&
                    new Date(user[0].metadata.creationTime).getFullYear() ===
                        new Date().getFullYear());
            })
                .then(() => {
                return users.length;
            });
        });
    }
    // A revoire vite fait avant Janvier 2022
    numberOfParticularLastMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("particuliers");
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            let users = [];
            return req
                .get()
                .then((snapshot) => {
                snapshot.docs.map((particular) => {
                    users = [
                        result.filter((user) => user.uid === particular.data().uid),
                        ...users,
                    ];
                });
            })
                .then(() => {
                users = users.filter((user) => user.length !== 0 &&
                    new Date(user[0].metadata.creationTime).getMonth() ===
                        new Date().getMonth() - 1 &&
                    new Date(user[0].metadata.creationTime).getFullYear() ===
                        new Date().getFullYear());
            })
                .then(() => {
                return users.length;
            });
        });
    }
    compareMonthParticular() {
        return __awaiter(this, void 0, void 0, function* () {
            const va = yield this.numberOfParticularThisMonth();
            const vd = yield this.numberOfParticularLastMonth();
            let taux;
            if (vd === 0 && va !== 0) {
                taux = 100;
            }
            else if (va === 0 && vd === 0) {
                taux = 0;
            }
            else {
                taux = ((va - vd) / vd) * 100;
            }
            return taux.toFixed(2);
        });
    }
    numberOfProThisMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("professionnels");
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            let users = [];
            return req
                .get()
                .then((snapshot) => {
                snapshot.docs.map((pro) => {
                    users = [
                        result.filter((user) => user.uid === pro.data().uid),
                        ...users,
                    ];
                });
            })
                .then(() => {
                users = users.filter((user) => user.length !== 0 &&
                    new Date(user[0].metadata.creationTime).getMonth() ===
                        new Date().getMonth() &&
                    new Date(user[0].metadata.creationTime).getFullYear() ===
                        new Date().getFullYear());
            })
                .then(() => {
                return users.length;
            });
        });
    }
    numberOfProLastMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("professionnels");
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            let users = [];
            return req
                .get()
                .then((snapshot) => {
                snapshot.docs.map((pro) => {
                    users = [
                        result.filter((user) => user.uid === pro.data().uid),
                        ...users,
                    ];
                });
            })
                .then(() => {
                users = users.filter((user) => user.length !== 0 &&
                    new Date(user[0].metadata.creationTime).getMonth() ===
                        new Date().getMonth() - 1 &&
                    new Date(user[0].metadata.creationTime).getFullYear() ===
                        new Date().getFullYear());
            })
                .then(() => {
                return users.length;
            });
        });
    }
    compareMonthPro() {
        return __awaiter(this, void 0, void 0, function* () {
            const va = yield this.numberOfProThisMonth();
            const vd = yield this.numberOfProLastMonth();
            let taux;
            if (vd === 0 && va !== 0) {
                taux = 100;
            }
            else if (va === 0 && vd === 0) {
                taux = 0;
            }
            else {
                taux = ((va - vd) / vd) * 100;
            }
            return taux.toFixed(2);
        });
    }
    numberOfTradThisMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("traducteurs");
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            let users = [];
            return req
                .get()
                .then((snapshot) => {
                snapshot.docs.map((trad) => {
                    users = [
                        result.filter((user) => user.uid === trad.data().uid),
                        ...users,
                    ];
                });
            })
                .then(() => {
                users = users.filter((user) => user.length !== 0 &&
                    new Date(user[0].metadata.creationTime).getMonth() ===
                        new Date().getMonth() &&
                    new Date(user[0].metadata.creationTime).getFullYear() ===
                        new Date().getFullYear());
            })
                .then(() => {
                return users.length;
            });
        });
    }
    numberOfTradLastMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("traducteurs");
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            let users = [];
            return req
                .get()
                .then((snapshot) => {
                snapshot.docs.map((trad) => {
                    users = [
                        result.filter((user) => user.uid === trad.data().uid),
                        ...users,
                    ];
                });
            })
                .then(() => {
                users = users.filter((user) => user.length !== 0 &&
                    new Date(user[0].metadata.creationTime).getMonth() ===
                        new Date().getMonth() - 1 &&
                    new Date(user[0].metadata.creationTime).getFullYear() ===
                        new Date().getFullYear());
            })
                .then(() => {
                return users.length;
            });
        });
    }
    compareMonthTrad() {
        return __awaiter(this, void 0, void 0, function* () {
            const va = yield this.numberOfTradThisMonth();
            const vd = yield this.numberOfTradLastMonth();
            let taux;
            if (vd === 0 && va !== 0) {
                taux = 100;
            }
            else if (va === 0 && vd === 0) {
                taux = 0;
            }
            else {
                taux = ((va - vd) / vd) * 100;
            }
            return taux.toFixed(2);
        });
    }
    currentOrders() {
        const req = this.hiero.DB.collection("prestations").where("state", "==", "translating");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
    currenOrderPrice() {
        const req = this.hiero.DB.collection("prestations").where("state", "==", "translating");
        let prices = [];
        let sum = 0;
        return req
            .get()
            .then((snapshot) => {
            snapshot.docs.map((price) => (prices = [price.data().price.ttc, ...prices]));
        })
            .then(() => {
            const initialValue = 0;
            sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        })
            .then(() => {
            return sum.toFixed(2);
        });
    }
    salesRevenues() {
        const req = this.hiero.DB.collection("prestations").where("state", "==", "translating");
        let prices = [];
        let prestationMonth = [];
        let sum = 0;
        return req
            .get()
            .then((snapshot) => {
            prestationMonth = snapshot.docs.filter((prestation) => new Date(prestation.data().createdAt).getMonth() ===
                new Date().getMonth() &&
                new Date(prestation.data().createdAt).getFullYear() ===
                    new Date().getFullYear());
        })
            .then(() => {
            prestationMonth.map((price) => (prices = [price.data().price.ttc, ...prices]));
        })
            .then(() => {
            const initialValue = 0;
            sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        })
            .then(() => {
            return parseFloat(sum.toFixed(2));
        });
    }
    // A revoir avant Janvier 2023 : salesRevenuesLastMonth car monthLast = -1
    salesRevenuesLastMonth() {
        const req = this.hiero.DB.collection("prestations").where("state", "==", "translating");
        let prices = [];
        let prestationMonth = [];
        let sum = 0;
        return req
            .get()
            .then((snapshot) => {
            prestationMonth = snapshot.docs.filter((prestation) => new Date(prestation.data().createdAt).getMonth() ===
                new Date().getMonth() - 1 &&
                new Date(prestation.data().createdAt).getFullYear() ===
                    new Date().getFullYear());
        })
            .then(() => {
            prestationMonth.map((price) => (prices = [price.data().price.ttc, ...prices]));
        })
            .then(() => {
            const initialValue = 0;
            sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        })
            .then(() => {
            return parseFloat(sum.toFixed(2));
        });
    }
    // Comparer chiffre d'affaire:
    compareSales() {
        return __awaiter(this, void 0, void 0, function* () {
            const va = yield this.salesRevenues();
            const vd = yield this.salesRevenuesLastMonth();
            let taux;
            if (vd === 0 && va !== 0) {
                taux = 100;
            }
            else if (va === 0 && vd === 0) {
                taux = 0;
            }
            else {
                taux = ((va - vd) / vd) * 100;
            }
            return taux.toFixed(2);
        });
    }
    commandPro() {
        const req = this.hiero.DB.collection("professionnel_commandes")
            .orderBy("createdAt", "desc")
            .limit(4);
        return req.get().then((snapshot) => {
            return snapshot.docs.map((command) => {
                return command.data();
            });
        });
    }
    ortherCommand() {
        const req = this.hiero.DB.collection("particuliers_commandes")
            .orderBy("createdAt", "desc")
            .limit(4);
        return req.get().then((snapshot) => {
            return snapshot.docs.map((command) => {
                return command.data();
            });
        });
    }
    allUsersReq() {
        const req = this.hiero.DB.collection("users");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
}

import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Admin } from '../../../../../../common/src/bdd/admin/Admin';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { IDocumentType } from '../../../../../../common/src/bdd/interfaces/IDocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';

@Component({
  templateUrl: './documentTypes.component.html'
})
export class DocumentTypeListComponent implements OnDestroy {

  busy = true;
  private adminSub: Subscription;
  private docTypeSub: Subscription;
  private docType: DocumentType;

  docTypes: IDocumentType[];

  newForm: SubmitForm;

  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.adminSub = this.hiero.WatchAdmin({
      next: (admin: Admin) => {

        DocumentType.Init(admin.User, true)
        .then(
          (docType) => {
            this.docType = docType;
            this.busy = false;
            this.docTypeSub = this.docType.Watch({
              next: (updated) => {
                this.docType = updated;
                this.docTypes = this.docType.ToArray;
                this.busy = false;
              }
            });
          }
        );

      }
    });

    this.createForm();

  }

  ngOnDestroy()  {
    if (this.adminSub) {
      this.adminSub.unsubscribe();
    }
    if (this.docTypeSub) {
      this.docTypeSub.unsubscribe();
    }
  }

  getDocTypeFor(code: string, documentType: IDocumentType) {
    return this.docType.GetDocumentName(code, documentType._id);
  }

  createForm() {
    this.newForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'label',
          value: '',
          validators: [],
          type: 'text',
          title: 'Nom (en français)',
          autocomplete: '',
          placeholder: 'Le nom du document',
          help: 'Précisez le nom du type de document en français. Une foir crée, vous pouvez ajouter autres noms par langue',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
          ]
        }
      ],
      // Submit callback
      (changes) => {
      },

      // Success callback
      () => {
        // What to do with login success ?
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );
  }

  async addType() {
    const name: string = this.newForm.GetValue('label');
    if (!name) {
      return;
    }
    const validated: string = name.trim().toLowerCase();
    if (validated.length === 0) {
      return;
    }

    this.newForm.ClearGeneralError();
    this.busy = true;
    const nDoc: IDocumentType = {
      label: [{
        code: 'fr',
        value: validated
      }]
    };
    try {
      await this.docType.Add(nDoc);
    } catch (err) {
      this.newForm.SetGeneralError(err.message);
    }
  }

  edit(docType: IDocumentType) {
    this.router.navigate(['..', 'documentType', docType._id], { relativeTo: this.route });
  }

}

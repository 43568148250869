
 <style type="text/css">
  .barrelaterale {
  clear: left;
  float: left;
  width: 34%;
  margin-right: 0%;
  padding: 1%;
  margin-left: 2%;
  background-color: rgba(255, 139, 135, 0.2);
}

.tableauprincipal {
  float: left;
  width: 62%;
  margin-left: 0%;
  padding: 1%;
}
</style>
 
 <button class="btn btn-info" href="#" style="margin-left:10px;"> ↤ Retour</button>
 <br/><br/>
<div class="barrelaterale"> 
    <h4>Type de document : <span *ngIf="!!docType">{{  getDocTypeFor('fr', docType) }}</span></h4>
        <form [formGroup]="newForm.Group" novalidate>
            <app-form-typeahead [parameters]="newForm.GetInputElement('code')" [search]="search" [formatter]="formatter"></app-form-typeahead>
            <app-form-input [parameters]="newForm.GetInputElement('label')"></app-form-input>
            <app-form-general-error [submitForm]="newForm"></app-form-general-error>
            <button class="btn btn-danger" (click)='addLabel()'>Ajouter</button>
          </form>
  
          <app-wait [show]='busy'></app-wait>
  </div>
  <div class="tableauprincipal">


      <table *ngIf="!!docType" class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Langue</th>
            <th scope="col">Nom</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let label of docType.label; let i = index">
            <td>{{ label.code | iso639ToNative }} </td>
            <td>{{ label.value }}</td>
            <td>
              <button class="btn btn-danger btn-sm" (click)="removeLabel(label)">X</button>          
            </td>
          </tr>      
        </tbody>
        
      </table>

  </div>


import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISubmitFormElement } from '../submitform.class';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-form-input',
  templateUrl: './app-form-input.component.html'
})
export class AppFormInputComponent {
  @Input() parameters: ISubmitFormElement ;
}

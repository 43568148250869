import { PartialObserver, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { EPDFRotation, IPDFDocument } from '../../bdd/interfaces/IPDF';
import { HELVETICA_BOLD } from './helvetica-bold';
import { HELVETICA_BOLD_ITALIC } from './helvetica-bold-italic';
import { HELVETICA_ITALIC } from './helvetica-italic';
import { HELVETICA_REGULAR } from './helvetica-regular';
import * as jsPDF from "jspdf";

export class PDFRender {

  private pdf: IPDFDocument;
  private stringSubject: BehaviorSubject<string>;

  private pageWidth = 0;
  private pageHeight = 0;

  constructor(pdf: IPDFDocument) {
    this.pdf = pdf;
    this.stringSubject = new BehaviorSubject<string>(this.renderToUriString());
  }

  public get Height(): number {
    return this.pageHeight;
  }

  public get Width(): number {
    return this.pageWidth;
  }

  public Watch(observer: PartialObserver<string>): Subscription {
    return this.stringSubject.subscribe(observer);
  }

  public renderToUriString(cleanVersion: boolean = false): string|null {
    if (this.pdf.pages.length === 0) {
      console.log("Oops, pdf empty");
      return null;
    }

    const doc = this.renderDoc(cleanVersion);
    const data = doc.output('datauristring');
    if (this.stringSubject) {
      this.stringSubject.next(data);
    }
    return data;
  }

  public renderToBlob(cleanVersion: boolean = true): Blob {

    if (this.pdf.pages.length === 0) {
      return null;
    }

    const doc = this.renderDoc(cleanVersion);
    const data = doc.output('blob');
    return data;
  }

  public get Data() {
    return this.pdf;
  }

  public clearHighlights() {
    this.pdf.pages.forEach(
      (page) => {
        page.inputs.forEach(
          (input) => {
            input.fill = null;
          }
        );
      }
    );
  }

  private renderDoc(cleanVersion: boolean) {

    const firstPage = this.pdf.pages[0];

    this.pageWidth = firstPage.width;
    this.pageHeight = firstPage.height;

    let por = 'portrait';
    switch (firstPage.orientation) {
      case EPDFRotation.Portrait: por = 'portrait'; break;
      case EPDFRotation.LandscapeLeft: por = 'landscape'; break;
      case EPDFRotation.LandscapeRight: por = 'landscape'; break;
      case EPDFRotation.PortaitInvert: por = 'portrait'; break;
    }

    const doc = new jsPDF({
      orientation: por,
      unit: 'pt',
      format: [firstPage.width, firstPage.height],
      putOnlyUsedFonts: true
    });

    doc.addFileToVFS('Helvetica-Regular-normal.ttf', HELVETICA_REGULAR);
    doc.addFileToVFS('Helvetica-Bold-normal.ttf', HELVETICA_BOLD);
    doc.addFileToVFS('Helvetica-Italic-normal.ttf', HELVETICA_ITALIC);
    doc.addFileToVFS('Helvetica-Bold-Italic-normal.ttf', HELVETICA_BOLD_ITALIC);
    doc.addFont('Helvetica-Regular-normal.ttf', 'HelveticaCustom', 'normal');
    doc.addFont('Helvetica-Bold-normal.ttf', 'HelveticaCustom', 'bold');
    doc.addFont('Helvetica-Italic-normal.ttf', 'HelveticaCustom', 'italic');
    doc.addFont('Helvetica-Bold-Italic-normal.ttf', 'HelveticaCustom', 'bolditalic');

    this.pdf.pages.forEach(
      (page, index) => {
        if (index > 0) {
          doc.addPage([ page.width, page.height ]);
        }

        page.inputs.forEach(
          (input) => {
            if (!cleanVersion) {
              if (input.fill) {
                doc.setDrawColor(input.fill);
                doc.setFillColor(input.fill);
                doc.rect(input.rectX, input.rectY, input.width, input.height, 'F');
              }
            }

            if (!!input.fontSize) {
              doc.setFontSize(input.fontSize);
            } else {
              doc.setFontSize(9);
            }

            doc.setFont('HelveticaCustom');

            if (input.bold) {
              if (input.italic) {
                doc.setFontStyle('bolditalic');
              } else {
                doc.setFontStyle('bold');
              }
            } else {
              if (input.italic) {
                doc.setFontStyle('italic');
              } else {
                doc.setFontStyle('normal');
              }
            }

            doc.text(input.text, input.x, input.y, {
              align: input.textAlign,
              baseline: 'top'
            });
          }
        );

      }
    );

    return doc;
  }



}
